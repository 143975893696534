import React from 'react'
import {FormattedMessage} from 'react-intl'
import {Card, SectionHeading} from '../../components/PageLayout'
import {QuestionList} from '../../components/QuestionList'
import _ from 'lodash/fp'
import {
  BIOPSY_FACILITIES,
  HOSPITALS,
  PHYSICIAN_OR_PROVIDER,
  SCAN_FACILITIES,
  OTHER_FACILITIES
} from '../../enums/MedicalRecordLocationType'
import {PhysicianOrProvider} from '../forms/PhysicianOrProvider'
import {Hospital} from '../forms/Hospital'
import {ScanFacilities} from '../forms/ScanFacilities'
import {OtherFacilities} from '../forms/OtherFacilities'
import {BiopsyFacilities} from '../forms/BiopsyFacilities'
import {EmptyLocationRecordText} from '../MedicalRecords'
import styled from 'styled-components'
import { IfLocale } from '../../hocs/IfLocale'
import { Combobox } from '@startlibs/form'
import { INSTITUTION, UPLOAD } from '../../enums/UploaderManagement'

export const PROVIDERS_LABELS = {[PHYSICIAN_OR_PROVIDER]:"Physicians",[HOSPITALS]:"Hospitals",[SCAN_FACILITIES]:"Scan Facilities", [BIOPSY_FACILITIES]: "Biopsy Facilities", [OTHER_FACILITIES]: "Facilities"}
export const PROVIDERS_OPTIONS = Object.keys(PROVIDERS_LABELS)

const MedicalRecordLocationFormContainer = styled.div`
  h4 span {
    font-weight: 400;
    color: rgba(0,0,0,0.4);
  }
`

const emptyRecordLocation =
  <EmptyLocationRecordText>No medical records location details provided.</EmptyLocationRecordText>

export const MEDICAL_RELEASE_PATH = ["records"]

export const ReleaseLocationForms = ({readOnly,form,locationRef,step, mode, toggle}) => {
  return <>
    {step !== INSTITUTION &&
      <SectionHeading ref={locationRef}>
        <p><FormattedMessage defaultMessage="Identify location of prior records, and complete release below." description="ReleaseLocationForms subtitle"/></p>
      </SectionHeading>
    }
    
      <MedicalRecordLocationFormContainer>
        <Card css={mode === UPLOAD ? "padding: 0; border: 0px;" : "" }>
          <QuestionList
            // filter={_.filter(_.matchesProperty('locationType', OTHER_FACILITIES))}
            getEmpty={() => ({ id: Date.now(), 'locationType': OTHER_FACILITIES })}
            disabled={readOnly}
            whenEmpty={readOnly && emptyRecordLocation}
            path={MEDICAL_RELEASE_PATH.join("")}
            addLabel="Add facility"
            step={step ? step : INSTITUTION}
          >{(otherFacility, i) =>
            <OtherFacilities
              disabled={readOnly}
              i={i}
              key={otherFacility.id}
              form={form}
              path={MEDICAL_RELEASE_PATH}
              toggle={toggle}
            />
            }
          </QuestionList>
        </Card>
        
      </MedicalRecordLocationFormContainer>
    
  </>
}
