import { Button, Icon } from '@startlibs/components';
import styled, { createGlobalStyle } from 'styled-components';
import {formatDate, getFetcher} from '@startlibs/utils'
import React, { useEffect, useState } from 'react'
import {useParams} from 'react-router'
import { Card, PageContainer } from '../components/PageLayout';
import { Header } from '../components/Header';
import {Navbar} from '../components/Navbar'
import {PATIENT} from '../enums/ContactRelationType'
import {ProviderReleaseError} from './errors/ProviderReleaseError'
import { PurviewFooter } from '../components/PurviewFooter';
import {ReleaseTermsContent} from '../request/medicalRelease/ReleaseTerms'
import {SignagureImageContainer, SignatureDate} from '../request/Payment'
import {jwtGetFetcher} from '../utils/authFetch'
import {willUseSuspense} from '../hooks/useSuspense'
import { useIntl } from 'react-intl';
import { getRelationLabel } from '../enums/ContactRelationKind';
import { lazyProviderInfo } from '../components/WithProvider';
import * as ReactDOMServer from 'react-dom/server';
import { useIsLocale } from '../hocs/IfLocale';

const PrintStyle = createGlobalStyle`
  @page {
    margin: 25mm 25mm 25mm 25mm;
    size: auto;
  }
  @media print {
    ${PageContainer} {
      padding: 0;
    }
    .nobreak {
      page-break-inside: avoid;
    }
    body {
      margin: 0px;
    }
    ${Card} {
      border: none;
      padding: 0;
    }
    ${Button} {
      display: none;
    }
  }
`

const useSuspense = willUseSuspense((code) =>
    getFetcher(`/api/locationForm/${code}`),
  true
)


export const MedicalReleaseDocument = () => {
  const {code} = useParams()
  return <ProviderReleaseError><LoadedMedicalReleaseDocument code={code} /></ProviderReleaseError>
}
export const LoadedMedicalReleaseDocument = ({code}) => {

  const SubHeader = styled.h4`
    font-size: 14px;
    font-weight: 500;
  `

  const intl = useIntl()
  const isNotLegal = useIsLocale({not:true,contains:"LEGAL"})

  const easyFormatDate = (date) => date ? formatDate(date, "MM/dd/yyyy") : ""
  const providerInfo = lazyProviderInfo.read()
  const releaseInfo = useSuspense(code)
  const contacRelationType = getRelationLabel(intl,releaseInfo.contactDetailsRelationType,'representative')
  const patientName = `${releaseInfo?.firstName} ${releaseInfo?.middleName} ${releaseInfo?.lastName}`
  const entityName = providerInfo.name
  const patientDob = easyFormatDate(new Date(releaseInfo?.dob)) === "NaN/NaN/NaN" ? releaseInfo?.dob.replace('-','/').replace('-','/') : easyFormatDate(new Date(releaseInfo?.dob))


  const contactName = releaseInfo.contactDetailsRelationType === PATIENT
    ? (releaseInfo.firstName + ' ' + releaseInfo?.middleName + ' ' + releaseInfo.lastName)
    : releaseInfo.contactDetailsName

  const [htmlTerm, setHtmlTerm] = useState('')
  useEffect(() => {
    var vHtmlTerm = ReactDOMServer.renderToStaticMarkup(<ReleaseTermsContent
      contacRelationType={contacRelationType}
      patientName={patientName}
      entityName={entityName}
      contactName={contactName}
      dateExpiration={releaseInfo.medicalRecordLocationItem.dateExpiration}
      dateTo={releaseInfo.medicalRecordLocationItem.dateTo}
      dateFrom={releaseInfo.medicalRecordLocationItem.dateFrom}
      locations={[releaseInfo.medicalRecordLocationItem]}
    />)
    setHtmlTerm(vHtmlTerm)
  },[])

  return <>
    <PrintStyle />
    <Navbar/>
    <PageContainer>
      <Header
        title="Authorization for Use and Disclosure of Protected Health Information"
      >
        <Button onClick={() => window.print()} icon="print" className="nowrap" css="margin-left: 1rem;">Print</Button>
      </Header>
      <Card>
      <SubHeader>Patient Name: <b className='fs-exclude'>{patientName}</b></SubHeader>
      <SubHeader>Date of Birth: <b className='fs-exclude'>{patientDob}</b></SubHeader>
        {isNotLegal 
          ? <p><b>Dear Healthcare Provider</b> - On behalf of {entityName}, we request that you provide to <b>Purview</b> pursuant to this request and authorization, any medical records in your possession from the period of {releaseInfo.medicalRecordLocationItem.dateFrom.replace('-','/').replace('-','/')} to {releaseInfo.medicalRecordLocationItem.dateTo.replace('-','/').replace('-','/')} regarding the Patient <span className='fs-exclude'>{patientName}</span> as authorized by the signed authorization below. These records are being requested in connection with a remote consultation to be provided on behalf of the Patient that will be provided to you for the purposes of discussing continuing care and treatment with the Patient.</p>
          : <p><b>Dear Healthcare Provider</b> - The patient named above or his/her legal representative requests that you provide to <b>Purview</b> pursuant to this request and authorization, any medical records in your possession from the period of {releaseInfo.medicalRecordLocationItem.dateFrom.replace('-','/').replace('-','/')} to {releaseInfo.medicalRecordLocationItem.dateTo.replace('-','/').replace('-','/')} regarding the Patient <span className='fs-exclude'>{patientName}</span> as authorized by the signed authorization below.</p>
        }
        <p>The following medical records release authorization was signed by <span className='fs-exclude'>{patientName}</span> or his/her legal guardian, representative or parent (as indicated) on {releaseInfo.medicalRecordsReleaseLicense.date}, and is valid through {releaseInfo.medicalRecordLocationItem.dateExpiration.replace('-','/').replace('-','/')}.</p>
        <hr></hr>
        <br></br>
        <SubHeader>PATIENT AUTHORIZATION TO RELEASE PROTECTED HEALTH INFORMATION</SubHeader>
        <ReleaseTermsContent
          contacRelationType={contacRelationType}
          patientName={patientName}
          entityName={entityName}
          contactName={contactName}
          dateExpiration={releaseInfo.medicalRecordLocationItem.dateExpiration}
          dateTo={releaseInfo.medicalRecordLocationItem.dateTo}
          dateFrom={releaseInfo.medicalRecordLocationItem.dateFrom}
          locations={[releaseInfo.medicalRecordLocationItem]}
          signedTerm={releaseInfo?.medicalRecordsReleaseLicense?.signedTerm}
          isNotLegal={isNotLegal}
        />
        {
          releaseInfo.medicalRecordsReleaseLicense.signature &&
          <SignagureImageContainer css="position: relative; margin-top: 1.5rem;">
            <img src={releaseInfo.medicalRecordsReleaseLicense.signature}/>
            <SignatureDate><Icon icon="check"/>Signed on <span>{releaseInfo.medicalRecordsReleaseLicense.date}</span></SignatureDate>
          </SignagureImageContainer>
        }
        
        {releaseInfo?.medicalRecordsReleaseLicense?.signedTerm && releaseInfo?.medicalRecordsReleaseLicense?.signedTerm?.length > 0 &&
          <div>
            {releaseInfo?.medicalRecordsReleaseLicense?.signedByName ? <p><b>Signed by: </b><b className='fs-exclude'>{releaseInfo?.medicalRecordsReleaseLicense?.signedByName}</b></p> : null}
            {releaseInfo?.medicalRecordsReleaseLicense?.signedByRelation ? <p><b>Relationship to Patient: {releaseInfo?.medicalRecordsReleaseLicense?.signedByRelation}</b></p> : null}
          </div>
        }
    </Card>
  </PageContainer>
  <PurviewFooter/>
</>
}
