import { Icon, Loading } from '@startlibs/components';
import { darken } from 'polished';
import {getColor, media, styledExtend} from '@startlibs/utils'
import styled, {css} from 'styled-components/macro'

export const WidgetHeader = styled.div`
  padding: 0.5rem 1rem 0.5rem 4.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 48px;
  width: auto;
  color: white;
  background: ${getColor('main')};
  h4 {
    margin-bottom: 0;
    font-size: 16px;
  }
  * {
    flex-shrink: 0;
  }
  a {
    opacity: 0.8;
    text-decoration: underline;
    :hover {
      opacity: 0.9;
    }
  }
  ${media.mobile`
    padding: 1rem 1.25rem;
    min-height: 58px;
    h4 {
      font-size: 16px;
      font-weight: 500;
    }
  `}
  ${props => props.isOnDialog ? css`
    border-radius: 6px 6px 0 0;
    padding: 1.25rem 1.5rem 1.25rem 2rem;
    margin: -2rem -2rem 2rem;
    ${media.mobile`
      margin: -1.5rem -1.5rem 2rem;
    `}
    ${Loading} {
      margin-right: 1.25rem;
    }
  `: css`
    :hover {
      cursor: pointer;
      background-color: ${props => (darken(0.02, getColor('main')(props)))};
    }
  `}
`
export const WidgetHeaderDetail = styled.div`
  color: rgba(0,0,0,0.4);
  font-size: 16px;
  margin-top: 1px;
`

export const WidgetBody = styled.div`
  position: relative;
  padding: 1rem;
  overflow-y: auto;
  margin-bottom: 0;
  border-top: 1px solid rgba(0,0,0,0.1);
  min-width: 31rem;
  transition: all ease-out 0.4s;
  min-height: 6rem;
`

export const PanelButton = styled(Icon)`
  margin-left: 0.5rem;
  width: 2.75rem;
  height: 2.75rem;
  line-height: 34px;
  font-size: 21px;
  text-align: center;
  border-radius: 50%;
  color: white;
  background-color: rgba(255,255,255,0.15);
  cursor: pointer;
  display: inline-block;
  :hover {
    background-color: rgba(255,255,255,0.2);
  }
`
export const WidgetIcon = styledExtend(Icon)`
  font-size: 32px;
  color: white;
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  margin-top: 2px;
  ${media.mobile`
    left: 1.25rem;
  `}
`


export const WidgetPanelComponent = styled.div`
  position: static;
  bottom: 0;
  will-change: transform;
  z-index: 100;
  box-shadow: 0 0 10px 0px rgba(0,0,0,0.2), 0 0 2px 0px rgba(0,0,0,0.15);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: white;
  transition-property: transform;
  transition-duration: 0.4s;
  transition-timing-function: ease-out;
  overflow: hidden;
  transform-origin: bottom;
  .popup {
    position: fixed !important;
    top: inherit !important;
    left: 0.5rem !important;
    bottom: -0.5rem;
    right: 0.5rem;
    :before {
      content: none !important;
    }
  }
  @supports (flex-wrap: wrap) {
    display: flex;
    flex-direction: column;
  }
  ${props => props.closing && css`
    transform: translateY(100%);
  `}
  ${media.desktop`
    right: 1.5rem;
    width: 18rem;
    transition-property: transform, max-height, width;
    @media (max-height:450px) {
      max-height: 100%;
    }
    ${props => props.maximized ? css`
      max-height: 450px;
      width: 32rem;
      max-width: 32rem;
      ${WidgetHeader} {
        min-height: 55px;
      }
      ${WidgetIcon} {
        font-size: 34px;
        margin-top: 1px;
      }
    `:`
      max-height: 48px;
      @supports (transform: translateY(calc(100% - 48px))) {
        transform: translateY(calc(100% - 48px));
        max-height: 450px;
      }
      ${WidgetHeader} {
        h4 {
          padding-top: 0.75rem;
        }
        @supports (align-items: center) {
          h4 {
            padding-top: 0;
          }
        }
      }
    `}
  `}
  ${media.mobile`
    width: 100%;
    right: 0;
    left: 0;
    @media (max-height:450px) {
      max-height: 100%;
    }
    ${props => props.maximized ? `
      max-height: 80%;
      ${WidgetHeader} {
        padding: 14px 1.5rem;
      }
      ${WidgetIcon} {
        font-size: 34px;
      }
    `:css`
      max-height: 58px;
      @supports (transform: translateY(calc(100% - 58px))) {
        transform: translateY(calc(100% - 58px));
        max-height: 80%;
      }
    `}
  `}
`

export const WidgetFooter = styled.div`
  padding: 1rem;
  box-shadow: 0 -2px 6px 0 rgba(0,0,0,0.1);
`

export const NoteCardPanel = styled.div`
  padding: 1.0rem;
  background: #e7f0f5;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  margin-bottom: 1rem;
`