import { Button, Icon } from '@startlibs/components';
import {RadioboxGroup} from '@startlibs/form'
import {useNavigate} from 'react-router'
import {useToggle} from '@startlibs/core'
import {will} from '@startlibs/utils'
import React, {useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import _ from 'lodash/fp'
import styled from 'styled-components'
import { AssignmentStatusBox } from './expertReview/ExpertReviewList';
import {
  CASE_CLOSED,
  CASE_DRAFT,
  PENDING_ASSIGNMENT,
  UNDER_REVIEW,
  WAITING_ACCEPTANCE,
  WAITING_APPROVAL,
  WAITING_MORE_INFORMATION
} from '../../enums/CaseState';
import {ClinicalInformation} from '../../request/ClinicalInformation'
import {Header} from '../../components/Header'
import {PageContainer, PageFooter} from '../../components/PageLayout'
import {PurviewFooter} from '../../components/PurviewFooter'
import {WaitingMedicalRecordsBox} from './info/WaitingMedicalRecordsBox'
import {getJwt} from '../../hooks/useJwt'
import { isStateBefore } from '../../request/utils';
import {jwtGetFetcher, jwtPostFetcher} from '../../utils/authFetch'
import {setNotification} from '../../components/Notifications'
import {useConfirmExit} from './hooks/useConfirmExit'
import { useSetConfirmExitPage } from '../../hooks/useConfirmExitPage';
import { lazyUserInfo } from '../../components/WithProvider';
import { getTimezoneOffset } from '../../utils/utils';
import { isStrEmpty } from '../../utils';

export const ContactSelectBoxes = styled(RadioboxGroup)`
  display: block;
  margin-bottom: 1rem;
`

export const SelectorTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  p {
    margin-bottom: 0;
  }
`

export const ClinicalInformationStep = ({caseRequest, setCaseRequest}) => {
  const navigate = useNavigate()
  const intl = useIntl()

  const loading = useToggle()
  const userInfo = lazyUserInfo.read()
  const [isSavingAndAssigning, setSavingAndAssigning] = useState(false)
  
  const saveAction = (v) => loading.wait(jwtPostFetcher(getJwt())('/api/saveQuestions?requestId=' + caseRequest.requestId, v, {method: "PUT"}))

  const action = (v) => loading.wait(jwtPostFetcher(getJwt())('/api/admin/sendQuestions?requestId=' + caseRequest.requestId, v))
  const formRef = useRef()

  const useSave = caseRequest.state !== CASE_DRAFT

  const readOnly = [UNDER_REVIEW, WAITING_MORE_INFORMATION, WAITING_APPROVAL, WAITING_ACCEPTANCE, CASE_CLOSED].indexOf(caseRequest.state) >= 0
  const wasSentToExpert = !isStateBefore(caseRequest.state)(WAITING_ACCEPTANCE)

  const caseCompletedWithoutReport = caseRequest.state === CASE_CLOSED && !caseRequest.report?.customReport && !caseRequest.report?.useCustomReport && !caseRequest.report
  
  const updateCase = ({questionsConsultant,caseSummary,additionalFields}) => _.flow(
    useSave  ?_.identity : _.set('state', PENDING_ASSIGNMENT),
    _.set('caseInfo.questionsConsultant', questionsConsultant),
    _.set('caseInfo.additionalFields', additionalFields),
    _.set('caseInfo.caseSummary', caseSummary)
  )

  const assignAndRedirect = () => {
    let message = ''
    let sendEmail = false

    jwtPostFetcher(getJwt())(`/api/admin/${userInfo.id}/share/${caseRequest.requestId}`, { message, sendEmail })
      .then(() => {
        jwtGetFetcher(getJwt())(`/api/viewCaseRequest?requestCode=${caseRequest.requestCode}&timezoneOffset=${getTimezoneOffset()}`)
          .then((caseRequest) => {
            setCaseRequest(caseRequest)
            setSavingAndAssigning(false)
            navigate("../review")
          })
          .catch((err) => {
            console.log(err)
            setSavingAndAssigning(false)
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleSaveAndReview = () => {
    const values = formRef.current.getValues()
    const clinicalValuesJson = {
      additionalFields: values?.caseInfo?.additionalFields,
      caseSummary: values?.caseInfo?.caseSummary,
      questionsConsultant: values?.caseInfo?.questionsConsultant
    }
    let errors = {};

    // Check case summary
    const caseSummaryOnlyImage = clinicalValuesJson?.caseSummary?.includes('<img');
    if (!caseSummaryOnlyImage && isStrEmpty(clinicalValuesJson?.caseSummary?.replace(/<[^>]*>/g, '').trim())) { 
      errors["caseInfo.caseSummary"] = "Please provide a Clinical summary.";
    }
    
    // Check questions
    const hasNoQuestions = !clinicalValuesJson || 
                           !clinicalValuesJson?.questionsConsultant || 
                           !clinicalValuesJson?.questionsConsultant?.length || 
                           !clinicalValuesJson?.questionsConsultant?.find(({question}) => {
                             return !isStrEmpty(question.replace(/<[^>]*>/g, '').trim());
                           });
    
    if (hasNoQuestions) {
      errors["caseInfo.questionsConsultant.0.question"] = "At least one question is required.";
    }
    
    // If there are any errors, set them together and stop further execution
    if (Object.keys(errors).length > 0) {
      formRef.current.setErrors(errors);
      return;
    }
    setSavingAndAssigning(true)
    if(useSave) {
      jwtPostFetcher(getJwt())('/api/saveQuestions?requestId=' + caseRequest.requestId, clinicalValuesJson, {method: "PUT"})
        .then(() => {
          assignAndRedirect()
        })
        .catch((err) => {
          setNotification({type: "alert", msg: (close) => <>Changes could not be saved.</>})
          console.log(err)
          setSavingAndAssigning(false)
        })
    }else{
      jwtPostFetcher(getJwt())('/api/admin/sendQuestions?requestId=' + caseRequest.requestId, clinicalValuesJson)
        .then(() => {
          assignAndRedirect()
        })
        .catch((err) => {
          setNotification({type: "alert", msg: (close) => <>Changes could not be saved.</>})
          console.log(err)
          setSavingAndAssigning(false)
        })
    }
  }

  const confirmExitPage = () => () => {
    if (!formRef.current) {
      return false
    }
    
    if (formRef && formRef.current && formRef.current.hasChanged && !readOnly) {
      return new Promise((exit) => {
        formRef.current.willSubmitForm()
        .then(() => {exit()})
      })
    }
  }

  // useExitSave((values) => {
  //   formRef.current.val
  //   setCaseRequest(updateCase(values))
  //   if (formRef.current.isLoading) {
  //     return Promise.resolve(values)
  //   }
  //   return saveAction(values)
  // }, formRef)

  // This works on default browser close tab/window
  useConfirmExit(formRef)

  // This works on navigating away from the page on SPA (react-router)
  useSetConfirmExitPage(confirmExitPage(false))

  return <>
    <PageContainer>
      <Header title={<FormattedMessage defaultMessage="Clinical information" description="ClinicalInformationStep title" />}/>
      <WaitingMedicalRecordsBox caseRequest={caseRequest} setCaseRequest={setCaseRequest} />
      {readOnly && wasSentToExpert &&
        <AssignmentStatusBox reviewed={caseRequest.state === CASE_CLOSED}>
          <div className="waitingBoxContent">
            <Icon icon={caseRequest.state === CASE_CLOSED ? 'check' : 'clock'}/>
            <h4>
              {caseRequest.state === CASE_CLOSED ? 'This case is already completed.' : 'Pending review'}
            </h4>
            <p>{caseRequest.state === CASE_CLOSED
              ? caseCompletedWithoutReport 
                ? 'The case has been marked as completed, but no report was generated.'
                : intl.formatMessage({
                    defaultMessage:"The report is already concluded and available for the patient contact.",
                    description:"Report concluded warning available to contact label"
                  }) 
              : 'There is an expert currently reviewing this case.'}</p>
          </div>
        </AssignmentStatusBox>
      }
      <ClinicalInformation
        setCaseRequest={setCaseRequest}
        readOnly={readOnly}
        limitQuestions={500}
        formRef={formRef}
        values={caseRequest}
        caseRequest={caseRequest}
        action={useSave ? saveAction : readOnly ? (() => Promise.resolve()) : action}
        onSuccess={_.flow(
          (props) => setCaseRequest(updateCase(props)),
          useSave ? will(setNotification,"All changes saved") : will(navigate, `/admin/request/${caseRequest.requestCode}/expert`)
        )}
      >{({isLoading}) =>
        <PageFooter>
          {
            (!useSave || caseRequest.caseFlags.adminRecordsSubmitted) && !readOnly && userInfo.canReviewCases &&
              <Button onClick={handleSaveAndReview} isLoading={isSavingAndAssigning} type="button" highlight>
                Save and review
              </Button>
          }
          {
            (!useSave || caseRequest.caseFlags.adminRecordsSubmitted) &&
            <Button isLoading={isLoading || loading.isOpen} type="submit" highlight>
              {!readOnly 
                ?  userInfo.canReviewCases 
                  ? "Save and assign" 
                  : "Save and advance" 
                : "Advance"}
            </Button>
          }
          {formRef?.current?.hasErrors && formRef?.current?.hasChanged && 
            // <Button onClick={() => formRef.current.resetForm()}>Cancel</Button>
            <Button onClick={() => navigate(-1)}>Cancel</Button>
            // navigate
          }
          {
            useSave && !readOnly &&
            <Button isLoading={isLoading || loading.isOpen} type="submit" highlight>Save</Button>
          }
        </PageFooter>
      }</ClinicalInformation>
    </PageContainer>
    <PurviewFooter/>
  </>
}
