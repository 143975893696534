import {Button, Loading} from '@startlibs/components';
import {getColor, media} from '@startlibs/utils';
import React, {useMemo} from 'react'
import {FormattedMessage} from 'react-intl'
import _ from 'lodash/fp'
import styled, {css} from 'styled-components';
import {AdminCaseCardCategories} from './AdminCaseCardCategories'
import {AdminCaseCardMenu} from './AdminCaseCardMenu'
import {
  CASE_DRAFT,
  CASE_REJECTED,
  CASE_REVIEWED,
  PENDING_ASSIGNMENT,
  PENDING_REQUEST,
  UNDER_REVIEW,
  WAITING_ACCEPTANCE,
  WAITING_APPROVAL,
  WAITING_MEDICAL_RECORDS,
  WAITING_MORE_INFORMATION
} from '../../enums/CaseState';
import {CaseRequestCard} from '../CaseRequestCard'
import {useEditCategories} from '../experts/hooks/useEditCategories'
import {lazyProviderInfo, lazyUserInfo} from '../../components/WithProvider'
import {useToggleAsPriority} from '../hooks/useMarkAsPriority'
import { useEditCaseNumber } from '../experts/hooks/useEditCaseNumber';

const filterStates = (...states) => ({state}) => states.indexOf(state) >= 0

const KanbanWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding-top: 1.5rem;
  height: calc(100vh - 20.5rem);
  min-height: 25rem;
  ${media.max(1024)`
    overflow-x: scroll;
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 7px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0,0,0,.5);
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
  `}
  ${media.max(644)`
    height: calc(100vh - 26.5rem);
  `}
  ${media.max(420)`
    height: calc(100vh - 28.5rem);
  `}
`

const ACTION_REQUIRED_STATES = [PENDING_ASSIGNMENT, CASE_REJECTED]

export const AdminKanban = ({setParams, refreshList, list, setList, isFiltering, isLoading, archiveReasons, refreshArchiveReasons}) => {
  
  const [pending, draft, underReview, reviewed] = useMemo(() =>
    [
      [PENDING_REQUEST],
      [CASE_DRAFT, WAITING_MEDICAL_RECORDS, WAITING_APPROVAL, PENDING_ASSIGNMENT],
      [WAITING_ACCEPTANCE, CASE_REJECTED, UNDER_REVIEW],
      [CASE_REVIEWED, WAITING_MORE_INFORMATION]
    ].map(states => _.orderBy([({markedAsPriorityEpochMilli}) => markedAsPriorityEpochMilli || 0,'whenModifiedEpochMilli'], ['desc','desc'], list.filter(filterStates(...states)))),
    [list]
  )

  const userInfo = lazyUserInfo.read()

  const editCategories = useEditCategories(userInfo,(caseRequest) => (categories) =>
    setList(_.map(item => item === caseRequest ? _.set('categories',categories,item) : item))
  )

  const editCaseNumber = useEditCaseNumber((caseRequest) => (caseNumber) =>
    {
      setList(_.map(item => item === caseRequest ? _.set('legalInfo',caseNumber,item) : item))
    }
  )

  const toggleAsPriority = useToggleAsPriority((caseRequest) =>
    setList(_.map(item => item === caseRequest ? _.update('markedAsPriorityEpochMilli',(v)=>v ? null : Date.now(),item) : item))
  )


  return <KanbanWrapper>
    <KanbanColumn
      refreshList={refreshList}
      isLoading={isLoading}
      isFiltering={isFiltering}
      columnTitle="Requested"
      columnFilter={pending}
      userInfo={userInfo}
      toggleAsPriority={toggleAsPriority.willOpenWith}
      editCategories={editCategories.willOpenWith}
      editCaseNumber={editCaseNumber.willOpenWith}
      archiveReasons={archiveReasons}
      refreshArchiveReasons={refreshArchiveReasons}
    />
    <KanbanColumn
      refreshList={refreshList}
      isLoading={isLoading}
      isFiltering={isFiltering}
      columnTitle="Accepted"
      columnFilter={draft}
      withButton
      userInfo={userInfo}
      toggleAsPriority={toggleAsPriority.willOpenWith}
      editCategories={editCategories.willOpenWith}
      editCaseNumber={editCaseNumber.willOpenWith}
      archiveReasons={archiveReasons}
      refreshArchiveReasons={refreshArchiveReasons}
    />
    <KanbanColumn
      refreshList={refreshList}
      isLoading={isLoading}
      isFiltering={isFiltering}
      columnTitle="Assigned"
      columnFilter={underReview}
      userInfo={userInfo}
      toggleAsPriority={toggleAsPriority.willOpenWith}
      editCategories={editCategories.willOpenWith}
      editCaseNumber={editCaseNumber.willOpenWith}
      archiveReasons={archiveReasons}
      refreshArchiveReasons={refreshArchiveReasons}
    />
    <KanbanColumn
      refreshList={refreshList}
      isLoading={isLoading}
      isFiltering={isFiltering}
      columnTitle="Reviewed"
      columnFilter={reviewed}
      userInfo={userInfo}
      toggleAsPriority={toggleAsPriority.willOpenWith}
      editCategories={editCategories.willOpenWith}
      editCaseNumber={editCaseNumber.willOpenWith}
      archiveReasons={archiveReasons}
      refreshArchiveReasons={refreshArchiveReasons}
    />
  </KanbanWrapper>
}

const KanbanColumn = React.memo(styled(({className, userInfo, isLoading, columnTitle, columnFilter, withButton, isFiltering, refreshList, editCategories, editCaseNumber, toggleAsPriority, archiveReasons, refreshArchiveReasons}) => {

  return (
    <div className={className}>
      <div className="column-heading">
        <h3>{columnTitle} {!isLoading && <span>({columnFilter.length})</span>}</h3> {withButton && <Button.Link
        small
        icon="plus-circle"
        to="create"
      >Create new</Button.Link>}
      </div>
      <div className="cases-container-wrapper">
        <div className="cases-container">
          {isLoading ? <Loading
              size="36"
              borderWidth="5"
            />
            : <>
              {columnFilter.length > 0 ?
                columnFilter.map(caseRequest => <CaseRequestCard
                  key={caseRequest.id}
                  toggleAsPriority={toggleAsPriority}
                  editCaseNumber={editCaseNumber}
                  caseRequest={caseRequest}
                  isKanban
                  linkTo={`/admin/request/${caseRequest.requestCode}`}
                  contextMenu={(isOpen, toggle) => <AdminCaseCardMenu
                    isOpen={isOpen}
                    toggle={toggle}
                    toggleAsPriority={toggleAsPriority}
                    editCategories={editCategories}
                    editCaseNumber={editCaseNumber}
                    caseRequest={caseRequest}
                    refreshList={refreshList}
                    archiveReasons={archiveReasons}
                    refreshArchiveReasons={refreshArchiveReasons}
                  />}
                  categories={<AdminCaseCardCategories categories={caseRequest.categories} editCategories={editCategories(caseRequest)} userInfo={userInfo}  />}
                />)
                : <NoResultsColumn isFiltering={isFiltering}/>
              }
            </>
          }
        </div>
      </div>
    </div>
  )
})`
  flex-basis: 25%;
  max-width: 25%;
  display: flex;
  flex-direction: column;
  ${media.max(1024)`
    min-width: 240px;
  `}
  & ~ & {
    margin-left: 1.5rem;
  }
  h3 {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 1rem 0;
  }
  .column-heading {
    flex-shrink: 0;
  }
  ${props => props.withButton && css`
    .column-heading {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  `}
  .cases-container-wrapper {
    border: 1px solid ${getColor('gray240')};
    border-radius: 10px;
    flex-grow: 1;
    overflow: hidden;
    height: 1rem;
    .cases-container {
      background: ${getColor('gray240')};
      overflow-y: auto;
      height: 100%;
      padding: 1rem;
    }
  }
  ${Loading} {
    margin: 2rem auto;
  }
`)

const NoResultsColumn = styled(({className, isFiltering}) => {
  return (
    <div className={className}>
      <div className="text-wrapper">
        {isFiltering ?
          <span>There are no cases in this status matching your query.</span>
          : <span>Currently there are no active cases with this status.</span>
        }
      </div>
    </div>
  )
})`
  padding: 2rem;
  text-align: center;
  border-radius: 5px;
  color: ${getColor('gray150')};
  background: rgba(0,0,0,0.015);
  width: 100%;
  .text-wrapper {
    margin: .5rem auto;
  }
`
