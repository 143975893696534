import {Button} from '@startlibs/components'
import { FormValue, TextInput, ToggleCheckbox} from '@startlibs/form'
import React from 'react'
import {CaseRequestCard} from '../CaseRequestCard'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {PENDING_REQUEST, UNDER_REVIEW, WAITING_ACCEPTANCE} from '../../enums/CaseState'
import {buildValidation, required} from '../../utils/validation'
import {getJwt} from '../../hooks/useJwt'
import {jwtFormFetcher} from '../../utils/authFetch'
import { DATA_MISSING, OTHER } from '../../enums/PredefinedReason'
import { InfoBox } from '../../components/InfoBox'
import _ from 'lodash/fp'
import { TypeAutoComplete } from '../../components/TypeAutoComplete'

const shouldForceDetails = (reason) => reason === OTHER || reason === DATA_MISSING || reason === "Other" || reason === "Data missing" || reason === "Other reason"

const MAX_LENGTH = 120;

const preValidation = buildValidation({
  // "predefinedReason": required,
  "predefinedReason": [(v,k,values) => {
      return !v.length && required()
    },
    (v,k,values) => v && v.length > MAX_LENGTH && `Must be less than ${MAX_LENGTH} characters`],
  "details": [(v,k,values) => 
    shouldForceDetails(values.predefinedReason) && !v.length && required()],
})


export const useArchiveDialog = (caseRequest,onSuccess, archiveReasons) => useConfirmDialog(
  <ConfirmDialog
    title={
      caseRequest.state === PENDING_REQUEST ? "Set case as Archived"
        : "Set case as Archived"
    }
    action={jwtFormFetcher(getJwt())("/api/admin/archiveCase?requestId="+(caseRequest.requestId || caseRequest.id))}
    onSuccess={onSuccess}
    preValidation={preValidation}
    values={{
      "predefinedReason" : "",
      "details" : "",
      "message": "",
      "declineReasonSharedPatient": false,
      "sendEmail": caseRequest.state === UNDER_REVIEW || caseRequest.state === WAITING_ACCEPTANCE
    }}
    notify={
      caseRequest.state === PENDING_REQUEST ? "Case archived successfully."
        : "Case archived successfully."
    }
    confirm={<Button highlight>{caseRequest.state === PENDING_REQUEST ? 'Set as Archived' : 'Set as Archived'}</Button>}
  >{form => <>
    <p>{caseRequest.state === PENDING_REQUEST ? "The following case request " : "This case "} will no longer be visible under "Active cases":</p>
    <CaseRequestCard viewOnly caseRequest={caseRequest} isResults={true} />
    <p>You will still be able to access this case under "Archived cases" and "All cases".</p>
    <TypeAutoComplete
        path="predefinedReason"
        hasErrors={form.hasErrors()}
        label="Select or type the reason for setting this case as Archived"
        bellowDescText="New values will be stored for future use in the system."
        mandatory
        initOptions={archiveReasons}
        withDropdown
        tabIndex={1}
      />
    <FormValue path="predefinedReason">{reason => <>
      <TextInput
        tabindex="1"
        tabIndex="1"
        textarea
        autoResize
        mandatory={shouldForceDetails(reason)}
        // mandatory={reason === OTHER || reason === DATA_MISSING || reason === "Other" || reason === "Data missing" || reason === "Other reason"}
        path="details"
        label={shouldForceDetails(reason) ? "Please add more details" :"Details"}
        // label={(reason === OTHER || reason === DATA_MISSING || reason === "Other" || reason === "Data missing" || reason === "Other reason") ? "Please add more details" :"Details"}
      />
      </>
    }</FormValue>
   
    {(caseRequest.state === UNDER_REVIEW || caseRequest.state === WAITING_ACCEPTANCE) && <>
      <InfoBox lightYellow>The expert will no longer be able to access or review this case.</InfoBox>
      <ToggleCheckbox
        label={<strong>Notify expert that the case was unassigned</strong>}
        path="sendEmail"
      />
    </>}
    
    <ToggleCheckbox path="declineReasonSharedPatient" label={<strong>Share this reason with patient</strong>}/>
    </>}
    {/* <p>Are you sure you want to proceed?</p> */}
  </ConfirmDialog>
)
