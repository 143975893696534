import _ from 'lodash/fp'
import {
  checkCCDAXSLT,
  createNewRecord,
  getFileNameAndExtension,
  IGNORED_FILE_PATTERNS,
  IGNORED_FILES,
  readFileAsText,
  RecordClass,
  RecordFormat,
  VALID_EXTENSIONS,
} from '../utils'
import {processQueue} from '../queues'

const isIgnoredFile = ({file}) => {
  if (IGNORED_FILES.indexOf(file.name) >= 0) {
    return true
  }
  if (IGNORED_FILE_PATTERNS.find(pattern => pattern.test(file.name))) {
    return true
  }
  return false
}

export const isNonDicom = ({file}) => {
  if (file.size === 0) {
    return true
  }
  if (
    file.type !== undefined &&
    file.type !== null &&
    file.type !== '' &&
    file.type.toLowerCase() !== 'application/dicom'
  ) {
    return true
  }
  return false
}

const isValidNonDicom = ({file}) =>
  VALID_EXTENSIONS.indexOf(file.name.toLowerCase().split('.').pop()) >= 0

const isNonDicomAndValid = (augmentedFile) => isNonDicom(augmentedFile) && isValidNonDicom(augmentedFile)

const getClassAndFormat = file => {
  const [filename, extension] = getFileNameAndExtension(file)
  if (['pdf', 'txt'].indexOf(extension.toLowerCase()) >= 0) {
    return [RecordClass.Clinical, extension.toUpperCase()]
  }
  return [RecordClass.Other, RecordFormat.Other]
}

export const createSimpleRecord = ({file, extension}, ccda = false, jpegCount = -1, jpegLimit = -1, adminUserEmail = '') => {
  const [recordClass, recordFormat] = ccda
    ? [RecordClass.Clinical, RecordFormat.CCDA]
    : getClassAndFormat(file)

  // Whe admin uploading a new file, if the file is a jpeg and the jpeg limit is not reached, do not hide the file
  const doNotHide = jpegCount <= jpegLimit && ['jpg','jpeg'].indexOf(extension.toLowerCase()) >= 0 && jpegLimit >= 0
  return createNewRecord({
    description: file.name,
    recordClass,
    recordFormat,
    files:[
      {
        file,
      },
    ],
    fileExtension:extension
  },{doNotHide: doNotHide,
    stateContext: {
      Submitted: {
        by: adminUserEmail && adminUserEmail.length > 0 ? adminUserEmail : ''
      }
    }
  })
}

const checkIsCCDA = ({file}) => readFileAsText(file).then(text => checkCCDAXSLT(text))

export const isPossibleCCDA = ({extension}) => ['xml', 'cda', 'ccda'].indexOf(extension.toLowerCase()) >= 0

export function* processNonDicomFiles(augmentedFiles,adminUserEmail) {
  const [filesToProcess,discardedIgnoredFiles] = _.partition(_.negate(isIgnoredFile),augmentedFiles)
  yield [[],[],discardedIgnoredFiles]

  const [nonDicom, possibleDicom] = _.partition(isNonDicomAndValid, filesToProcess)

  const [possibleCCDA, simpleNonDicoms] = _.partition(isPossibleCCDA, nonDicom)

  
  // count jpeg and jpg files among simpleNonDicoms
  const jpegCount = simpleNonDicoms.filter(({extension}) => ['jpg','jpeg'].indexOf(extension.toLowerCase()) >= 0).length
  const JPEG_SHOW_LIMIT =  window.JPEG_SHOW_LIMIT || 10;
  yield [simpleNonDicoms.map(file => createSimpleRecord(file, false, jpegCount, JPEG_SHOW_LIMIT, adminUserEmail)), [], simpleNonDicoms]

  /* eslint-disable no-await-in-loop, no-restricted-syntax */
  for (const file of possibleCCDA) {
    yield processQueue.next().then(({value:[unlock]}) =>
      checkIsCCDA(file)
        .then(isCCDA => [[createSimpleRecord(file, isCCDA, 0, 0, adminUserEmail)], [], [file]])
        .catch(e => [[createSimpleRecord(file)], [], [file]])
        .finally(() => unlock())
    )
  }

  yield [[], possibleDicom, []]
}
