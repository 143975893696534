import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { round } from 'lodash';

export const DonutChart = ({data, title}) => {

    const totalCount = data.reduce((sum, d) => sum + d.count, 0);
    // const centerTitle = `${totalCount} ${title}`;

    // Calculate the center position
    const centerX = 0; // Since you are using a viewBox centered on the SVG
    const centerY = -24; // Adjust this as needed to vertically align the text


    function extractTranslation(transformStr) {
        const regex = /translate\(([^,]+),\s*([^)]+)\)/;
        const match = regex.exec(transformStr);
        return match ? [parseFloat(match[1]), parseFloat(match[2])] : [0, 0];
    }

    function wrapText(text, width) {
        const words = text.split(/\s+/);
        let newLine = words[0];
        const lines = [];
    
        for (let i = 1; i < words.length; i++) {
            const word = words[i];
            const testLine = newLine + " " + word;
            // Measure text length here. In real implementation, use getComputedTextLength or similar.
            // This is just a placeholder:
            const testWidth = testLine.length * 10; // Replace with actual width calculation
    
            if (testWidth > width) {
                lines.push(newLine);
                newLine = word;
            } else {
                newLine = testLine;
            }
        }
        lines.push(newLine);
    
        return lines;
    }

    function getContrastTextFromRGBString(rgbString) {
        // Extract the RGB values from the string
        const match = rgbString.match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/);
        if (match) {
            const r = parseInt(match[1], 10);
            const g = parseInt(match[2], 10);
            const b = parseInt(match[3], 10);
    
            // Calculate the perceptive luminance of the color
            const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
            // Return black for bright colors, white for dark colors
            return luminance > 0.5 ? 'black' : 'white';
        }
        return 'white'; // Return null or any default value if the input string is not in the expected format
    }
    
    const chartRef = useRef();
    
    
    useEffect(() => {
        
        // Your existing Chart logic goes here
        const Chart = () => {

            function isTooClose(newPos, positions, margin) {
                return positions.some(pos => {
                    // Check if the first values are equal and the second values are within the margin
                    return newPos[0] === pos[0] && Math.abs(newPos[1] - pos[1]) <= margin;
                });
            }

            const width = 800;
            const height2 = 600;
            const height = Math.min(width, 388);
            const radius = Math.min(width, height) / 2;

            const arc = d3.arc()
                .innerRadius(radius * 0.55)
                .outerRadius(radius - 1);

            const pie = d3.pie()
                .padAngle(1 / radius)
                .sort(null)
                .value(d => d.count);

            const color = d3.scaleOrdinal()
                .domain(data.map(d => d.categoryName))
                // .range(["#016f84", "#68a52c", "#78ba66", "#82c677", "#5ec5b9"]);
                // .range(d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), data.length).reverse());
                // .range(d3.quantize(t => d3.interpolateBlues(t * 0.8 + 0.1) , data.length).reverse());
                // .range(d3.quantize(t => d3.interpolateBuGn(t * 0.8 + 0.1) , data.length).reverse());
                .range(d3.quantize(t => d3.interpolateGnBu(t * 0.8 + 0.1) , data.length).reverse());


            const svg = d3.create("svg")
                .attr("width", width)
                .attr("height", height2)
                .attr("viewBox", [-width / 2, -height / 2, width, height])
                .attr("style", "max-width: 100%; height: auto;");

            svg.append("g")
                .selectAll()
                .data(pie(data))
                .join("path")
                .attr("fill", d => color(d.data.categoryName))
                .attr("d", arc)
                .append("title")
                .text(d => `${d.data.categoryName}: ${d.data.count.toLocaleString()}`);

            svg.append("g")
                .attr("font-family", "sans-serif")
                .attr("font-size", 16)
                // .attr("fill", "white")
                .attr("text-anchor", "middle")
                .selectAll()
                .data(pie(data))
                .join("text")
                // .attr("transform", d => `translate(${arc.centroid(d)})`)
                .attr("transform", d => `translate(${arc.centroid(d)})`)
                // .call(text => text.append("tspan")
                //     .attr("y", "-0.4em")
                //     .attr("font-weight", "bold")
                //     .text(d => d.data.categoryName))
                .call(text => text.filter(d => (d.endAngle - d.startAngle) > 0.25).append("tspan")
                    .attr("x", 0)
                    .attr("y", "0.7em")
                    // .attr("fill-opacity", 0.7)
                    .attr("font-weight", "bold")
                    // .text(d => d.data.categoryName))
                    // .text(d => d.data.count.toLocaleString("en-US"))
                    .text(d => {
                        const roundedPercentage = round(d.data.percentage, 2);
                        return !isNaN(roundedPercentage) ? `${roundedPercentage}%` : '';
                      })
                    .attr("fill", d => {
                        return color(d.data.categoryName) ? getContrastTextFromRGBString(color(d.data.categoryName)) : "white"
                    })
                      
                    );

            // LINES AND LABELS
            const midAngle = d => d.startAngle + (d.endAngle - d.startAngle) / 2;

            svg.append("g")
                .attr("class", "labels");

            svg.append("g")
                .attr("class", "lines");


            const outerRadius = radius * 1.05; // Extend the line to the margin
            const labelRadius = radius * 1.1; // Where the label will be

            // Function to calculate the outer point for the label lines
            const outerArc = d3.arc()
                .innerRadius(outerRadius)
                .outerRadius(outerRadius);

            let positionsLines = []
            // Draw label lines
            const labelLine = svg.selectAll(".label-line")
                .data(pie(data))
                .enter().append("polyline")
                .attr("class", "label-line")
                .attr("points", d => {
                    var centroid = arc.centroid(d);
                    // get a point slgihtly outer from the centroid
                    var outer = outerArc.centroid(d);
                    const middlePos = [centroid[0] + (outer[0] - centroid[0]) / 2, centroid[1] + (outer[1] - centroid[1]) / 2];

                    let pos = outerArc.centroid(d);
                    pos[0] = labelRadius * (midAngle(d) < Math.PI ? 1 : -1);
                    // Define boundaries
                    const upperBound = 288;
                    const lowerBound = -288;
                    const margin = 16;

                    // Function to adjust position within bounds
                    function adjustPosition(pos) {
                        for (let adjustedMargin = margin; adjustedMargin <= upperBound; adjustedMargin += margin) {
                            // Try moving up within bounds
                            if (pos[1] - adjustedMargin >= lowerBound && !isTooClose([pos[0], pos[1] - adjustedMargin], positionsLines, margin)) {
                                return [pos[0], pos[1] - adjustedMargin];
                            }
                            // Try moving down within bounds
                            if (pos[1] + adjustedMargin <= upperBound && !isTooClose([pos[0], pos[1] + adjustedMargin], positionsLines, margin)) {
                                return [pos[0], pos[1] + adjustedMargin];
                            }
                        }
                        // If no adjustment is possible, return original position (might overlap)
                        return pos;
                    }

                    // Check if the new position is too close to any existing position
                    if (isTooClose(pos, positionsLines, margin)) {
                        pos = adjustPosition(pos); // Adjust the position if too close
                    }
                    positionsLines.push(pos); // Add the (adjusted) position to the array
                    
                    return [middlePos, outerArc.centroid(d), pos];
                    // return [arc.centroid(d), outerArc.centroid(d), pos];
                })
                .style("fill", "none")
                .style("stroke", "lightgray")
                .style("stroke-width", "1px");

            let positionsLabels = []
            // Draw labels for categoryName
            const categoryNameLabel = svg.selectAll(".categoryName-label")
                .data(pie(data))
                .enter().append("text")
                .attr("class", "categoryName-label")
                .attr("transform", (d,i) => {
                    let pos = outerArc.centroid(d);
                    pos[0] = labelRadius * (midAngle(d) < Math.PI ? 1 : -1)
                    // positions.push(pos);
                    // return `translate(${pos})`;
                    

                     // Define boundaries
                    const upperBound = 288;
                    const lowerBound = -288;
                    const margin = 16;

                    // Function to adjust position within bounds
                    function adjustPosition(pos) {
                        for (let adjustedMargin = margin; adjustedMargin <= upperBound; adjustedMargin += margin) {
                            // Try moving up within bounds
                            if (pos[1] - adjustedMargin >= lowerBound && !isTooClose([pos[0], pos[1] - adjustedMargin], positionsLabels, margin)) {
                                return [pos[0], pos[1] - adjustedMargin];
                            }
                            // Try moving down within bounds
                            if (pos[1] + adjustedMargin <= upperBound && !isTooClose([pos[0], pos[1] + adjustedMargin], positionsLabels, margin)) {
                                return [pos[0], pos[1] + adjustedMargin];
                            }
                        }
                        // If no adjustment is possible, return original position (might overlap)
                        return pos;
                    }

                    // Check if the new position is too close to any existing position
                    if (isTooClose(pos, positionsLabels, margin)) {
                        pos = adjustPosition(pos); // Adjust the position if too close
                    }

                    positionsLabels.push(pos); // Add the (adjusted) position to the array
                    return `translate(${pos})`;
                })
                .attr('text-anchor', d => midAngle(d) < Math.PI ? 'start' : 'end')
                .text(d => d.data.categoryName) // Add the categoryName text
                .attr("font-size", "14px")
                .attr("font-weight", "bold")
                .style("alignment-baseline", "middle");
                
            // const labels = svg.selectAll(".categoryName-label")
            //     resolveLabelCollisions(labels);    

            let positionsPercentage = []
            // Draw labels for percentage below the categoryName
            // const percentageLabel = svg.selectAll(".percentage-label")
            //     .data(pie(data))
            //     .enter().append("text")
            //     .attr("class", "percentage-label")
            //     .attr("transform", (d,i) => {
            //         let pos = positionsLabels[i]
            //         pos[1] += 12; // Offset the percentage label below the categoryName label
            //         return `translate(${pos})`;
            //         // let pos = outerArc.centroid(d);
            //         // pos[0] = labelRadius * (midAngle(d) < Math.PI ? 1 : -1);
            //         // // Check for collision and adjust position if necessary
            //         // // Define boundaries
            //         // const upperBound = 288;
            //         // const lowerBound = -288;
            //         // const margin = 20;

            //         // // Function to adjust position within bounds
            //         // function adjustPosition(pos) {
            //         //     for (let adjustedMargin = margin; adjustedMargin <= upperBound; adjustedMargin += margin) {
            //         //         // Try moving up within bounds
            //         //         if (pos[1] - adjustedMargin >= lowerBound && !isTooClose([pos[0], pos[1] - adjustedMargin], positionsPercentage, margin)) {
            //         //             return [pos[0], pos[1] - adjustedMargin];
            //         //         }
            //         //         // Try moving down within bounds
            //         //         if (pos[1] + adjustedMargin <= upperBound && !isTooClose([pos[0], pos[1] + adjustedMargin], positionsPercentage, margin)) {
            //         //             return [pos[0], pos[1] + adjustedMargin];
            //         //         }
            //         //     }
            //         //     // If no adjustment is possible, return original position (might overlap)
            //         //     return pos;
            //         // }

            //         // // Check if the new position is too close to any existing position
            //         // if (isTooClose(pos, positionsPercentage, margin)) {
            //         //     pos = adjustPosition(pos); // Adjust the position if too close
            //         // }
            //         // pos[1] += 14; // Offset the percentage label below the categoryName label

            //         // positionsPercentage.push(pos); // Add the (adjusted) position to the array
            //         // return `translate(${pos})`;
            //     })
            //     .attr('text-anchor', d => midAngle(d) < Math.PI ? 'start' : 'end')
            //     .text(d => {
            //         const roundedPercentage = round(d.data.percentage, 2);
            //         return !isNaN(roundedPercentage) ? `${roundedPercentage}%` : '';
            //       })
            //     .style("alignment-baseline", "middle");

            const labels = svg.selectAll(".categoryName-label")
            // resolveLabelCollisions(labels); 

            // Big Number count
            const bigNumber = svg.append("text")
                .attr("class", "chart-big-number")
                .attr("text-anchor", "middle")
                .attr("x", centerX)
                .attr("y", centerY-12)
                .attr("font-weight", "bold")
                .attr("font-size", "54px")
                .attr("fill", "#333333")
                .text(totalCount);

            // Title
            const wrappedText = wrapText(title, 140); // Assuming 200px is your max width

            // Now append each line of text as a separate tspan
            const textElement = svg.append("text")
                .attr("class", "chart-center-title")
                .attr("text-anchor", "middle")
                .attr("x", centerX)
                .attr("y", centerY)
                .attr("font-weight", "bold")
                .attr("font-size", "24px")
                .attr("fill", "#333333");

            wrappedText.forEach((line, index) => {
                textElement.append("tspan")
                    .attr("x", centerX)
                    .attr("y", (centerY+24) + index * 32) // Adjust line height as needed
                    .text(line);
            });

            // Append the SVG to the ref'd element
            d3.select(chartRef.current).append(() => svg.node());

            return svg.node();
            
        }    

        Chart();
    }, []);

    return (<>
        <div ref={chartRef}></div> {/* This div will contain the SVG chart */}
    </>)

}