import {Button } from '@startlibs/components';
import {useToggle} from '@startlibs/core'
import {DatePicker, Errors, WithForm} from '@startlibs/form';
import {_s, addQueryString, formatDate, isMobile, media } from '@startlibs/utils';
import React, {useState} from 'react'
import {useLocation} from 'react-router'
import _ from 'lodash/fp'
import styled from 'styled-components'
import { DateShortCuts} from './audit/AdvancedSearchPopup'
import {PurviewFooter} from '../../components/PurviewFooter'
import {Card, PageContainer} from '../../components/PageLayout';
import {getJwt} from '../../hooks/useJwt'
import {getDate, getDateValue} from '../../utils/utils';
import { DATE_FORMAT } from '../../config/DateFormat';
import { buildValidation, required } from '../../utils/validation';
import { downloadFile } from '../../utils/downloadFile';

const ContentComponent = styled.div`
  // margin-top: 2rem;
  display: inline-block;
`

const LineComponent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  ${Button}{
    margin-top: 13px;
    margin-left: 0.5rem;
  }
  
  div{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  div:first-of-type {
    /* Add your specific styles for the first div here */
    margin-left: 0rem;
    margin-right: 0.5rem;
  }
`

export const ExportCSV = () => {
  const location = useLocation()
  const loading = useToggle()
  const [params, setParams] = useState({
    from: NaN,
    to: NaN,
  })
  const [params2, setParams2] = useState({
    from: NaN,
    to: NaN,
  })


  return <>
    <PageContainer css="max-width: 100%;">
      <Card>
        <h3>Data Export</h3>
        <p>Export data within a specified time period to a CSV file</p>
        <hr css="margin-top:2rem;"/>
        <ExportHeader params={params} setParams={setParams} location={location} loading={loading} title={"Case data"}/>
        <ExportHeader params={params2} setParams={setParams2} location={location} loading={loading} title={"User feedback"}/>
        <p css="margin-top:2rem;color:rgba(0,0,0,0.5);">*Please be aware that the generated file contains Protected Health Information. It is the user's responsibility to use and store this data appropriately.</p>
      </Card>
    </PageContainer>
    <PurviewFooter/>
  </>
}


const ExportHeader = ({params, setParams, title}) => {

  const updateFields = useToggle()
  
  const exportCSV = () => {
    downloadFile(addQueryString('/api/admin/cases/csv', {
      csv: true,
      to: formatDate(params.to, "MM-dd-yyyy"),
      from: formatDate(params.from, "MM-dd-yyyy"),
      t: getJwt()
    }))
  }

  const exportFeedbackCSV = () => {
    downloadFile(addQueryString('/api/admin/feedback/csv', {
      csv: true,
      to: formatDate(params.to, "MM-dd-yyyy"),
      from: formatDate(params.from, "MM-dd-yyyy"),
      t: getJwt()
    }))
  }
 
  const dateRequiredOn = (key) => (d,k, props) => d === -1 && "Invalid date" || isNaN(d) && required()

  const transform = ({from,to,timeFrom,timeTo}) => ({
    from: _.isNumber(from) || _.isDate(from) ? formatDate(from, "MM-dd-yyyy") : "",
    to: _.isNumber(to) || _.isDate(to) ? formatDate(to, "MM-dd-yyyy") : "",
  })

  const preValidation = buildValidation({
    from:dateRequiredOn('from'),
    to:dateRequiredOn('to'),
  })

  const action = (values) => {
    // console.log(values)
    // exportCSV()
  }
  
  return <WithForm
    action={action}
    values={params}
    transform={transform}
    preValidation={preValidation}
    onChange={(v, v2) => setParams(v2)}
  >{form =>
    <>
    <h3 css="margin-top: 2rem">{title}</h3>
    <ContentComponent>
      <LineComponent >
        <DatePicker key={updateFields.isOpen+'init'} 
          label="From" placeholder={DATE_FORMAT().toUpperCase()} format={DATE_FORMAT()} path="from" />
        <DatePicker key={updateFields.isOpen+'end'} 
          label="To" placeholder={DATE_FORMAT().toUpperCase()} format={DATE_FORMAT()}  path="to" />
        <Button 
          highlight 
          type="submit"
          onClick={() => {
            form.willSubmitForm().then((res) => {
              title === "Case data" ? exportCSV() : exportFeedbackCSV()
              // exportCSV()
            }).catch((e) => {
              console.log(e)
            })
          }}
        >
          Export to CSV
        </Button>
      </LineComponent>
      <DateShortCuts>
        <a onClick={() => {form.setValue('from',getDate(-30)); form.setValue("to",getDate(0)); updateFields.openWith(Date.now())}}>Last 30 days</a>
        <a onClick={() => {form.setValue('from',getDate(-7)); form.setValue("to",getDate(0)); updateFields.openWith(Date.now())}}>Last 7 days</a>
        <a onClick={() => {form.setValue('from',getDate(0)); form.setValue("to",getDate(0)); updateFields.openWith(Date.now())}}>Today</a>
      </DateShortCuts>
      {/* <p css="margin-top:2rem;color:rgba(0,0,0,0.5);">*Please be aware that the generated file contains protected health information. It is the user's responsibility to use and store this data appropriately.</p> */}
      <Errors />
    </ContentComponent>
  </>
  }</WithForm>
}
