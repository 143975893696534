import {Icon} from '@startlibs/components';
import {useNavigate} from 'react-router'
import {preventDefault} from '@startlibs/utils'
import React from 'react'
import {
  CASE_ARCHIVED,
  CASE_CLOSED,
  PENDING_REQUEST,
  WAITING_MEDICAL_RECORDS
} from '../enums/CaseState';
import {
  CaseId,
  Sidebar,
  SidebarCard,
  SidebarContainer,
  SidebarDesc,
  SidebarSeparator,
  SidebarTitle,
  StepLink,
} from '../admin/steps/RequestSidebar';
import {ScrollToTop} from '../components/ScrollToTop'
import {confirmExitPage} from '../hooks/useConfirmExitPage'
import {isStateBefore} from '../request/utils';
import {IfLocale} from '../hocs/IfLocale'
import {FormattedDate, FormattedMessage} from 'react-intl'
import { START } from '../enums/UploaderManagement';
import {Match} from "../components/Match";


export const PatientRequestSidebar = ({caseRequest = {},providerInfo, isRefPhysician}) => {
  const navigate = useNavigate()

  const confirmRedirect = preventDefault((e) => {
    const href = e.target.pathname
    confirmExitPage().then(() => navigate(href))
  })

  //Only show the payment page if: The admin request payment or Any payment was already submitted (or marked as paid)
  const requestedPayment = caseRequest.caseFlags?.requestFlags?.askForPayments
  const showTerms = providerInfo?.serviceTerms && !isRefPhysician
  const hasPaid = caseRequest.payments.filter(payment => payment.paid).length > 0

  const isCurrentStateBefore = isStateBefore(caseRequest && caseRequest.state)
  const isArchived = caseRequest && caseRequest.state === CASE_ARCHIVED
  
  return <Match path="/:userType/:step">{({match}) => {
    const step = match?.params && match?.params.step

    return <SidebarContainer className="hideOnPrint">
      <ScrollToTop when={step}/>
      <Sidebar>
        <SidebarCard>
          <div className="sidebar-heading">
            <SidebarDesc>CASE DETAILS</SidebarDesc>
            <SidebarTitle className='fs-exclude'>{caseRequest.patientInfo.firstName} {caseRequest.patientInfo?.middleName} {caseRequest.patientInfo.lastName}</SidebarTitle>
            {caseRequest?.legalInfo?.caseNumber && <IfLocale contains="LEGAL">
                <CaseId><b>Case Number:</b> {caseRequest?.legalInfo?.caseNumber}</CaseId>
              </IfLocale>
            }
            {caseRequest.requestCode && <CaseId><b>Case Id:</b> {caseRequest.requestCode}</CaseId>}
          </div>
          <SidebarSeparator/>
          {
            (caseRequest.state !== CASE_CLOSED || requestedPayment) &&
            <>
              {/* <SidebarSeparator/> */}
              <StepLink
                onClick={confirmRedirect}
                to="status"
                selected={step === "status"}
              >
                <Icon icon="info"/> Status
              </StepLink>
              
            </>
          }
          <StepLink
            onClick={confirmRedirect}
            to="contact-institution"
            selected={step === "contact-institution"}
          >
            <Icon icon="email-line"/> Messaging
            {caseRequest?.collaborationMessagesToRead > 0 && <span className="notificationNumber">
                {caseRequest?.collaborationMessagesToRead}
            </span>}
          </StepLink>
          <SidebarSeparator/>
          <IfLocale not contains="LEGAL">{
            showTerms &&
            <StepLink
              to="agreements"
              onClick={confirmRedirect}
              disabled={caseRequest.state && isCurrentStateBefore(PENDING_REQUEST)}
              selected={step === "agreements"}
            ><Icon icon="sign-doc"/>{caseRequest.caseFlags.requestFlags.askForServiceTerms ? 'Agree to terms' : 'Agreements'}</StepLink>
          }</IfLocale>
          <StepLink
            to="details"
            onClick={confirmRedirect}
            disabled={caseRequest.state && isCurrentStateBefore(PENDING_REQUEST)}
            selected={step === "details" || step === "start"}
          ><Icon icon="user-line"/><FormattedMessage defaultMessage="Patient & contact details" description='RequestSidebar patient item label'/></StepLink>
          {/* <StepLink
            to="release"
            onClick={confirmRedirect}
            disabled={isCurrentStateBefore(PENDING_REQUEST)}
            selected={step === "release"}
          ><Icon icon="edit-box"/> Locate medical records</StepLink>
          <StepLink
            to="records"
            onClick={confirmRedirect}
            disabled={isCurrentStateBefore(PENDING_REQUEST)}
            selected={step === "records"}
          ><Icon icon="dicom-no-text"/> Upload medical records</StepLink> */}
           <StepLink
            to="records"
            onClick={confirmRedirect}
            disabled={isCurrentStateBefore(PENDING_REQUEST) && !isArchived}
            selected={step === "records"}
          ><Icon icon="dicom-no-text"/> Medical records</StepLink>
          {
            (requestedPayment || hasPaid || caseRequest.payments.length > 0) &&
            <StepLink
              to="payments"
              onClick={confirmRedirect}
              selected={step === "payments"}
            ><Icon icon="payment"/> Payment</StepLink>
          }
          {
            !isCurrentStateBefore(CASE_CLOSED) &&
            <StepLink
              to="report"
              onClick={confirmRedirect}
              selected={step === "report"}
            ><Icon icon="checked-report"/> Final report</StepLink>
          }
        </SidebarCard>
      </Sidebar>
    </SidebarContainer>
  }
  }</Match>
}
