
import React, {useEffect, useRef} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components'
import { useToggle } from '@startlibs/core'
import { DatePicker, Field } from '@startlibs/form'
import { SplitColumnsContainer } from '@startlibs/components'
import { DATE_FORMAT } from '../../../config/DateFormat'
import { getColor, media } from '@startlibs/utils'


const AdvancedPopupComponent = styled.div`
  position: absolute;
  background-color: #FFF;
  border: 1px solid ${getColor('gray210')};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 1.25rem;
  z-index: 999;
  // left: 0;
  top: 46px;
  // left: -161px;
  right: 0;
  width: 93vw;
  width: calc(100vw - 2rem);
  margin-top: -1px;
  .upload-dateshortcuts {
    margin-top: -0.25rem;
    margin-bottom: 1rem;
    a {
      color: ${getColor('gray150')};
      margin-right: 1rem;
      text-decoration: underline;
    }
  }
  .popup-buttons-container {
    text-align: right;
  }
  .close-icon {
    color: ${getColor('gray150')};
    font-size: 20px;
    position: absolute;
    top: 6px;
    right: 5px;
    cursor: pointer;
    width: 2rem;
    line-height: 2rem;
    text-align: center;
    border-radius: 50%;
    :hover {
      background: ${getColor('gray240')};
    }
  }
  ${media.desktop`
    // max-width: 30rem;
    max-width: 327.4px;
  `}
`

export const AdvancedSearchPopup = ({initialDate, setInitialDate, endDate, setEndDate, setSelectedPeriod, focusedInput, closePopup}) => {
  const popupRef = React.useRef()
  
  const initDate = useToggle(initialDate)
  const finalDate = useToggle(endDate)

  useEffect(() => {
    if(initDate.isOpen && initDate.isOpen != initialDate) {
      // set date from milliseconds
      var milliseconds = initDate.isOpen;

      // Create a date object in local time zone
      var localDate = new Date(milliseconds);
      
      // Convert to UTC components
      var year = localDate.getUTCFullYear();
      var month = localDate.getUTCMonth(); // Note: months are 0-indexed
      var day = localDate.getUTCDate();
      var hours = localDate.getUTCHours();

      // Create a new date object with UTC components to avoid timezone issues
      var newDate = new Date(year, month, day, hours, 0, 0, 0);
      
      localStorage.removeItem('initialDate')
      setInitialDate(newDate)
      setSelectedPeriod('Custom')
    }
    if(finalDate.isOpen && finalDate.isOpen != endDate) {
      // set date from milliseconds
      var milliseconds = finalDate.isOpen;

      // Create a date object in local time zone
      var localDate = new Date(milliseconds);
      
      // Convert to UTC components
      var year = localDate.getUTCFullYear();
      var month = localDate.getUTCMonth(); // Note: months are 0-indexed
      var day = localDate.getUTCDate();
      var hours = localDate.getUTCHours();

      // Create a new date object with UTC components to avoid timezone issues
      var newDate = new Date(year, month, day, hours, 0, 0, 0);
      
      localStorage.removeItem('endDate')
      setEndDate(newDate)
      setSelectedPeriod('Custom')
    }
    
  }, [initDate.isOpen, finalDate.isOpen])
  
  const focusRef = useRef()

  useEffect(() => {
    const tryClose = (e) => !(popupRef.current.contains(e.target) || e.target === popupRef.current || e.defaultPrevented) && closePopup()
    document.addEventListener("click",tryClose)
    return () => document.removeEventListener("click",tryClose)
  },[])

  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
  }, [])


  return <AdvancedPopupComponent ref={popupRef} onKeyDown={({key,defaultPrevented}) => key === "Enter" && !defaultPrevented}
                            onClick={(e) => e.stopPropagation()}>
      <DateRangeField
        initFocusRef={focusedInput === "initDate" ? focusRef : undefined}
        endFocusRef={focusedInput === "finalDate" ? focusRef : undefined}
        endToggle={finalDate}
        initToggle={initDate}
        // label="Study date between"
      />
    </AdvancedPopupComponent>

}

const DateRangeField = ({label, initFocusRef, endFocusRef, initToggle, endToggle}) => {
  const updateFields = useToggle()

  return <Field label={label}>
    <SplitColumnsContainer key={updateFields.isOpen}>
      <DatePicker ref={initFocusRef} label="From" placeholder={DATE_FORMAT().toUpperCase()} format={DATE_FORMAT()} raw
                  value={initToggle.isOpen} setValue={initToggle.openWith}/>
      <div id="datePickerRight">
        <DatePicker ref={endFocusRef} label="To" placeholder={DATE_FORMAT().toUpperCase()} format={DATE_FORMAT()} raw
                    value={endToggle.isOpen} setValue={endToggle.openWith}/>
      </div>
    </SplitColumnsContainer>
    {/* <div className="upload-dateshortcuts">
      <a onClick={() => {
        initToggle.openWith(getDate(-30));
        endToggle.openWith(getDate(0));
        updateFields.openWith(Date.now())
      }}>Last 30 days</a>
      <a onClick={() => {
        initToggle.openWith(getDate(-7));
        endToggle.openWith(getDate(0));
        updateFields.openWith(Date.now())
      }}>Last 7 days</a>
      <a onClick={() => {
        initToggle.openWith(getDate(0));
        endToggle.openWith(getDate(0));
        updateFields.openWith(Date.now())
      }}>Today</a>
    </div> */}
  </Field>
}