import { ContextMenu, Icon, Li, Tooltip } from '@startlibs/components';
import {getColor, isMobile} from '@startlibs/utils';
import {usePopupToggle} from '@startlibs/core';
import React from 'react'
import styled from 'styled-components';
import {ACTIVE, PENDING, SUSPENDED, USER_STATUS_LABELS} from '../../../enums/UserStatus'
import {AdminCaseCardCategories} from '../../dashboard/AdminCaseCardCategories'
import { DIVISION, ROLE_LABELS, SUPER } from '../../../enums/UserRole';
import {StaffManagementTableColumn, StaffManagementTableRow} from './Table';
import { TextButton } from '../../../components/StyledTable';
import {formatDateNoUTC} from '../../../utils/utils'

const STATUS_TO_COLOR = {
  'normal': 'success',
  'stat': 'alert',
  'time sensitive': 'warning',
}

const CaseStatus = styled.span`
  color: ${props => getColor(STATUS_TO_COLOR[(props.status || "").toLowerCase()] || "gray120")(props)};
  font-weight: 600;
`


export const StaffRow = ({record,editStaff,editCategories, confirmStaffDialogs}) => {

  const manageMenu = usePopupToggle()

  return <StaffManagementTableRow isMobile={isMobile()}>

    <StaffManagementTableColumn className="user">
      <div className="name">
        <Tooltip whenEllipsis content={record.firstName + ' ' + record.lastName} whenEllipsis={({target}) => target.offsetWidth < target.scrollWidth}>
          {record.firstName} {record.lastName}
        </Tooltip>
      </div>
      <div className="role">{ROLE_LABELS[record.role]}</div>
    </StaffManagementTableColumn>
    <StaffManagementTableColumn className="email">{record.email}</StaffManagementTableColumn>
    <StaffManagementTableColumn className="divisions">
      <AdminCaseCardCategories
        outline
        categories={record.categories}
        whenEmpty={<div className="empty-divisions">{record.role === DIVISION ? "Can't access any division." : "Can access all divisions."}</div>}
        editCategories={editCategories.willOpenWith(record)}
        allowDivisions={record.role !== SUPER}
      />
    </StaffManagementTableColumn>
    <StaffManagementTableColumn className="status">{USER_STATUS_LABELS[record.status]}</StaffManagementTableColumn>
    <StaffManagementTableColumn className="reviewer">{record.expertInfo ? 'Yes' : 'No'}</StaffManagementTableColumn>
    <StaffManagementTableColumn className="date">{formatDateNoUTC(new Date(record.whenCreatedEpochMilli), "MM/dd/yyyy")}</StaffManagementTableColumn>
    <StaffManagementTableColumn className="actions">
      <TextButton onClick={manageMenu.open}>
        <span css="white-space: nowrap; padding-right: .25rem;">Manage</span> <Icon icon="arrow-down"/>
        {manageMenu.isOpen &&
        <ContextMenu>
          <Li onClick={editStaff.willOpenWith(record)} label="Edit user details"/>
          {record.status === PENDING && <Li onClick={confirmStaffDialogs.willOpenWith(['resend',record])} label="Resend invitation"/>}
          {record.status === ACTIVE && <Li onClick={confirmStaffDialogs.willOpenWith(['suspend',record])} label="Suspend user"/>}
          {record.status === SUSPENDED && <Li onClick={confirmStaffDialogs.willOpenWith(['activate',record])} label="Activate user"/>}
          <Li onClick={confirmStaffDialogs.willOpenWith(['delete',record])} label="Delete user"/>
        </ContextMenu>
        }
      </TextButton>
    </StaffManagementTableColumn>

  </StaffManagementTableRow>
}
