import React from 'react'
import _ from 'lodash/fp'
import escapeHtml from 'escape-html'
import {jsx} from 'slate-hyperscript'
import {Editor, Node, Text} from 'slate'
import { v4 as uuid } from 'uuid';
import { PARAGRAPH_CLASSES } from './slateUtils'

const ELEMENT_TYPES = ['link','quote','div','list-item','numbered-list','bulleted-list','paragraph','image','table','thead','tfoot','table-row','th','td','tr','table-cell','table-content']
// const ELEMENT_TYPES = ['link','quote','div','list-item','numbered-list','bulleted-list','paragraph','image','table','thead','tfoot','table-row','th','td','tr','table-cell']
const ELEMENT_TAGS = {
  A: el => ({ type: 'link', url: el.getAttribute('href') }),
  BLOCKQUOTE: () => ({ type: 'quote' }),
  DIV: () => ({ type: 'div' }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  OL: el => ({ type: 'numbered-list', start: el.getAttribute('start') || 1  }),
  P: (el) => (el?.getAttribute('class') && PARAGRAPH_CLASSES.findIndex((item) => item == el?.getAttribute('class')) >= 0) ? ({ type: 'paragraph', class: el.getAttribute('class')}) : ({ type: 'paragraph'}),
  UL: () => ({ type: 'bulleted-list' }),
  TABLE: () => ({ type: 'table' }),
  THEAD: () => ({ type: 'thead' }),
  TFOOT: () => ({ type: 'tfoot' }),
  TR: el => ({ type: 'tr', key: el.getAttribute('key') || `row_${uuid()}`}),
  TD: el => {return ({ 
      type: 'td', 
      key: el.getAttribute('key') || `cell_${uuid()}`, 
      height: `22`, 
      width: `${el.getAttribute('width')}`, 
      rowspan: `${el.getAttribute('rowspan') || 1}`, 
      colspan: `${el.getAttribute('colspan') || 1}`
    })},
  TH: el => {return ({ 
    type: 'td', 
    key: el.getAttribute('key') || `cell_${uuid()}`, 
    height: `22`, 
    width: `${el.getAttribute('width')}`, 
    rowspan: `${el.getAttribute('rowspan') || 1}`, 
    colspan: `${el.getAttribute('colspan') || 1}`
  })},
  'TABLE-ROW': () => ({ type: 'tr' }),
  'TABLE-CONTENT': () => ({ type: 'table-content' }),
  'TABLE-CELL': () => ({ type: 'td' }),
  IMG: el => ({type: 'image', url: el.getAttribute('src'), size: el.getAttribute('class')}),
}

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  B: () => ({ bold: true }),
  U: () => ({ underline: true }),
  SPAN: () => ({ type: 'span' }), // Backwards compatibility with ExpertView RTF Editor
}
const STYLES = [
  {style:'fontWeight',toAttr: (fontWeight) => {
    const intFontWeight = parseInt(fontWeight)
    if (!isNaN(intFontWeight)) {
      return intFontWeight > 400 && { bold : true }
    }
    return (fontWeight+"").toLowerCase() === "bold" && { bold : true }
    }
  },
  { // Backwards compatibility with ExpertView RTF Editor
    style:'fontStyle',toAttr: (fontStyle) => {
      return (fontStyle+"").toLowerCase() === "italic" && { italic : true }
    }  
  },
  {style:'textDecoration',toAttr: (textDecoration) => {
      const values = textDecoration.split(' ').filter(v => v === 'underline' || v === 'line-through')
      if (values.indexOf('underline')>=0) {
        return {underline: true}
      }
      if (values.indexOf('line-through')>=0) {
        return {strikethrough: true}
      }
  }}
]


const fixTextInRoot = (nodeList) => nodeList.reduce((acc,node) => {
  if (node.children && node.type !== 'link') {
  // if (node.children) {
    return [...acc,node]
  } else {
    const last = _.last(acc)
    if (last?.rootFix) {
      return [...acc.slice(0,-1),_.update('children',_.concat(_,node),last)]
    } else {
      return [...acc,{type:'div',rootFix:true,children:[node]}]
    }
  }
},[])

// Backwards compatibility with ExpertView RTF Editor
const wrapTextInDiv = (nodeList, isRoot) => {

  nodeList.map((node,index) => {
    if(node.children && node.children.length > 0) {
      wrapTextInDiv(node.children, false)
      var everyOneIsInline = node.children.every(
        child => (
          (child.text || child.type==='link' || child.text === '')
        )
      )
      var everyOneIsBlock = node.children.every(
        child => (
          ((child.children && child.type !== 'link') || child.type==='div')
        )
      )
      var everyOneSameType = (everyOneIsInline == everyOneIsBlock == false)
      if (!everyOneSameType) {
        
        // perform the same as fixTextInRoot for node.children
        // node.children.map((child,childIndex) => {
        //   if (child.text || child.text === '' || child.type==='link') {
        //     node.children = [...node.children.slice(0,childIndex),{type:'div',children:[child]},...node.children.slice(childIndex+1)]
            var newNodeChildren = node.children.reduce((acc,item) => {
              if (item.children && item.type !== 'link') {
                return [...acc,item]
              } else {
                const last = _.last(acc)
                if (last?.nodeFix) {
                  return [...acc.slice(0,-1),_.update('children',_.concat(_,item),last)]
                } else {
                  return [...acc,{type:'div',nodeFix:true,children:[item]}]
                }
              }
            },[])
            node.children = newNodeChildren
        //   }
        // })
      }
    }
  })

  
  if(nodeList.length > 0 && isRoot) {
    var everyOneIsInlineRoot = nodeList.every(
      node => (
        (node.text || node.type==='link')
        // (node.text)        
      )
    )
    var everyOneIsBlockRoot = nodeList.every(
      node => (
        ((node.children && node.type !== 'link') || node.type==='div')
      )
    )
    var everyOneSameTypeRoot = (everyOneIsInlineRoot == everyOneIsBlockRoot == false)
    if (!everyOneSameTypeRoot) {
      nodeList = fixTextInRoot(nodeList)
    }else{
      // check if every node is inline and wrap it in a div
      if (everyOneIsInlineRoot) {
        var hasText = nodeList.some(
          node => (
            (node.text)
          )
        )
        var hasLink = nodeList.some(
          node => (
            (node.type==='link')
          )
        )
        if (hasText && hasLink) {
          nodeList = [{type:'div',children:nodeList}]
        }else{
          nodeList = fixTextInRoot(nodeList)
        }
      }
    }
  }
  
  return nodeList
}

// Ensure the text is correctly normailzed with rule 3. This function receives a copy by value of the nodeList
export const checkNormalization = (nodeList) => {

  nodeList.map((node,index) => {
    if(node.children && node.children.length > 0) {
      checkNormalization(node.children)
      var everyOneIsInline = node.children.every(
        child => (
          (child.text || child.type==='link' || child.text === '')
        )
      )
      var everyOneIsBlock = node.children.every(
        child => (
          ((child.children && child.type !== 'link') || child.type==='div')
        )
      )
      var everyOneSameType = (everyOneIsInline == everyOneIsBlock == false)    
      if(!everyOneSameType){
        node.children = [...node.children,{"type":"div","children":{"text":"    "}}]
      }
    }
  })
  return nodeList
}	

export const deserialize = (html, markAttributes = {},isPastingHtml = false) => {
  const el = new DOMParser().parseFromString(html, 'text/html').body
  // return wrapTextInDiv(fixTextInRoot(domDeserialize(el)),true) // ORIGINAL
  return wrapTextInDiv(domDeserialize(el,{},isPastingHtml),true) 
}
const domDeserialize = (el, markAttributes = {},isPastingHtml=false) => {
  if (el.nodeType === 3) {
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  } else if (el.nodeName === 'BR') {
    return '\n'
    // return ''
  }

  const { nodeName } = el
  let parent = el

  if (
    nodeName === 'PRE' &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === 'CODE'
  ) {
    parent = el.childNodes[0]
  }
  let children = Array.from(parent.childNodes)
    .map(domDeserialize)
    .flat()

  if (children.length === 0) {
    children = [{ text: '' }]
  }

  if (el.nodeName === 'BODY') {
    if(isPastingHtml){
      if(children && children[0]==='\n'){
        children[0] = null
      }
      if(children && children[children.length-1]==='\n\n'){
        children[children.length-1] = ''
      }
    }
    return jsx('fragment', {}, children)
  }

  const styleAttrs = {}
  STYLES.forEach(({style,toAttr}) => {
    const v = el.style[style]
    if (v) {
      const resultAttrs = toAttr(v,el)
      if (resultAttrs) {
        Object.assign(styleAttrs,resultAttrs)
      }
    }
  })

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el)
    return jsx('element', {...attrs,...styleAttrs}, children)
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el)
    return children.map(child => ELEMENT_TYPES.findIndex(item => item === child?.type) >= 0 ? child : jsx('text', {...attrs,...styleAttrs}, child))
    // return children.map(child => child?.type==='link' ? children : jsx('text', {...attrs,...styleAttrs}, child))
    // return children.map(child => jsx('text', {...styleAttrs}, child))
  }

  return children
}

const MARK_TO_TAG = Object.entries({
  bold: 'b',
  italic: 'i',
  strikethrough:'s',
  underline: 'u'
})
const ELEMENT_TYPE_TO_TAG = {
  link: (node) => (children) => `<a target="_blank" href="${escapeHtml(node.url)}">${children}</a>`,
  quote: 'blockquote',
  div: 'div',
  table: 'table',
  thead: 'thead',
  tfoot: 'tfoot',
  tr: (node) => {return (children) => `<tr key="${escapeHtml(node.key)}">${children}</tr>`},
  td: (node) => {return (children) => `<td height="${22}" width="${escapeHtml(node.width)}" ${(node.rowspan && node.rowspan !== "null") ? `rowspan="${node.rowspan}"`: null} ${(node.colspan && node.colspan !== "null") ? `colspan="${node.colspan}"`: null} key="${escapeHtml(node.key)}">${children}</td>`},
  th: 'th',
  'table-row': 'tr',
  'table-cell': 'td',
  'list-item':'li',
  // 'numbered-list':'ol',
  'numbered-list': (node) => {
    return (children) => `<ol start="${node.start}">${children}</ol>`},
  'bulleted-list':'ul',
  paragraph: (node) => {return (node.class && PARAGRAPH_CLASSES.findIndex((item) => item == node.class) >= 0) ? 
    (children) => `<p class="${node.class}">${children}</p>` : (children) => `<p>${children}</p>`},
  mention: (node) => () => `<span>${node.phrase}</span>`,
  image: (node) => () => `<img src="${escapeHtml(node.url)}" class="${node.size ? node.size : 'rtfImgMedium'}"/>`,
}
const wrapAs = (type) => (children) => type ? `<${type}>${children}</${type}>` : children

export const serialize = node => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text)
    for (const [mark,tag] of MARK_TO_TAG) {
      if (node[mark]) {
        string = `<${tag}>${string}</${tag}>`
      }
    }
    return string.replace(/\n/g, '<br>')
  }
  const children = node?.children?.map(n => serialize(n)).join('')

  const elementTypeToTag = ELEMENT_TYPE_TO_TAG[node.type]
  const formatter = _.isFunction(elementTypeToTag) ? elementTypeToTag(node) : wrapAs(elementTypeToTag || node.type)
  return formatter(children)
}

export const hasText = (nodeList) => {
  const hasSomeText = nodeList ? nodeList.some((node,index) => {
    if(node.type === 'image'){
      return true
    }
    if(node?.children && node?.children?.length > 0) {
      return hasText(node?.children)
    }else{
      if(node.text.length > 0){
        return true
      }
    }
    return
  }) : true

  return hasSomeText

}

export const hasContent = (value) => {
  
  const deserialized = deserialize(value)
  return hasText(deserialized)
  
}