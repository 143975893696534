import React, {useEffect} from 'react'
import styled from 'styled-components'
import _ from 'lodash/fp'
import {Slot, useConstant, usePopupToggle, useToggle} from '@startlibs/core'
import {addQueryString, getColor, isMobile, media} from '@startlibs/utils'
import {useUpdateParams, useUpdateUrl} from '../../hooks/useQueryParams'
import {RadioboxGroup, TabRadiobox, TextInput} from '@startlibs/form'
import {ContextMenu, Icon, Li} from '@startlibs/components'
import {AdvancedSearchPopup} from './AdvancedSearchPopup'
import {ActiveAdvancedFilters} from './ActiveAdvancedFilters'


const WorklistHeaderComponent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  position: relative;
  z-index: 30;
  .right-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    flex-basis: 560px;
  }
  &:after {
    content: '';
    position: absolute;
    left: -2rem;
    right: -2rem;
    bottom: -2rem;
    height: 1px;
    background: ${getColor('gray240')};
  }
  ${media.max(915)`
    flex-wrap: wrap;
    .right-wrapper {
      flex-grow: 1;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      align-items: flex-end;
      ${RadioboxGroup} {
        margin-bottom: 1rem;
      }
    }
  `}
  ${media.min(916)`
    ${RadioboxGroup} {
      position: sticky;
      right: 2.5rem;
      max-width: 400px;
      ${props => props.isExpertDashboard && `
        max-width: 400px;
      `}
      margin-left: 1rem;
    }
  `}
  ${media.max(915)`
    display: block;
    .right-wrapper {
      padding-top: 1.5rem;
      margin-bottom: -0.5rem;
      flex-wrap: nowrap;
      flex-direction: row;
      ${RadioboxGroup} {
        width: auto;
        flex-basis: 0;
        flex-grow: 1;
        margin-bottom: 0;
      }
    }
  `}
  ${media.max(515)`
    .right-wrapper {
      flex-basis: 100%;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: stretch;
      ${RadioboxGroup} {
        flex-basis: auto;
      }
    }
  `}
  ${media.mobile`
    padding: 0 1rem;
  `}
`

const LinkButton = styled.a`
  position: relative;
  display: inline-block;
  text-decoration: underline;
  user-select: none;
`

const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${getColor('gray120')};
  position: relative;
  ${media.max(450)`
    display: block;
  `}
`
const SearchInput = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 1rem;
  max-width: 30rem;
  width: 100%;
  ${media.max(450)`
    margin-right: 0;
    margin-bottom: .5rem;
    max-width: 100%;
  `}
  ${LinkButton} {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  > input {
    padding-right: 8.75rem;
  }
  ${props => props.expandedInput && `
    > input {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  `}
`
const ClearSearchButton = styled(Icon)`
  color: ${getColor('gray90')};
  font-size: 12px;
  position: absolute;
  right: 80px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  border-radius: 50%;
  background: rgba(0,0,0,0.1);
  :hover {
    background: #f7d3d4;
    color: ${getColor('alert')};
  }
`

const MaxResultsMenu = styled(ContextMenu)`
  li > * {
    padding: 11px 2rem;
  }
`


export const DashboardHeader = ({location, categories, params, setParams, skipPageSize, stateFilters, defaultMaxResults , isExpertDashboard, divisionsFilter, setDivisionsFilter}) => {

  const query = useToggle(params.search || '')
  const status = useToggle(params.state || '')
  const maxResults = useToggle(params.maxResults || '')

  const updateUrl = useUpdateUrl()

  useUpdateParams((params) => {
    query.openWith(params.search || "")
    status.openWith(params.state || "")
    maxResults.openWith(params.maxResults || "")
  })

  useEffect(() => {
    updateUrl(params)
  }, [params])


  const maxResultsMenu = usePopupToggle()
  const advancedPopup = useToggle()

  const updateSearch = (v) => {
    query.openWith(v)
    setParams(_.set('search', v), true)
  }

  const updateStatus = (v) => {
    status.openWith(v)
    setParams(_.set('state', v), true)
  }
  const updateMaxResult = (v) => {
    maxResults.openWith(v)
    setParams(_.set('maxResults', v), true)
  }

  return <WorklistHeaderComponent isExpertDashboard={isExpertDashboard}>
    <div css="flex-grow:1;">
      <SearchInputWrapper>
        <SearchInput expandedInput={advancedPopup.isOpen}>
          <TextInput
            raw
            value={query.isOpen}
            setValue={updateSearch}
            placeholder="Search cases"
          />
          {query.isOpen && <ClearSearchButton
            icon="x"
            onClick={() => updateSearch("")}
          />}
          {/* <LinkButton onClick={advancedPopup.open}> */}
          <LinkButton onClick={(e) => {advancedPopup.open(); e.preventDefault();}}>
            Advanced
          </LinkButton>
          {
            advancedPopup.isOpen &&
            <AdvancedSearchPopup
              categories={categories}
              focusedInput={advancedPopup.isOpen}
              closePopup={advancedPopup.close}
              setParams={setParams}
              params={params}
              withoutCreateDate={isExpertDashboard}
            />
          }
        </SearchInput>
        <div>
          <Slot name="QueryCount"/>
          {
            !skipPageSize &&
            <div className="nowrap">Showing <LinkButton onClick={maxResultsMenu.open}>{(maxResults.isOpen || defaultMaxResults)} per page
              {
                maxResultsMenu.isOpen &&
                <MaxResultsMenu offset="0 4">
                  <Li
                    label="10"
                    onClick={() => updateMaxResult(10)}
                  />
                  <Li
                    label="20"
                    onClick={() => updateMaxResult(20)}
                  />
                  <Li
                    label="50"
                    onClick={() => updateMaxResult(50)}
                  />
                </MaxResultsMenu>
              }
            </LinkButton>
            </div>
          }
        </div>
      </SearchInputWrapper>
      <React.Suspense fallback={null}>
      <ActiveAdvancedFilters
        divisionsFilter={divisionsFilter}
        setDivisionsFilter={setDivisionsFilter}
        lazyCategories={isExpertDashboard ? false : undefined}
        params={params}
        setParams={setParams}
        advancedPopup={advancedPopup}
        location={location}
      />
      </React.Suspense>
    </div>
    <div className="right-wrapper">
      <RadioboxGroup
        wide
        css="a{white-space:nowrap;padding-left:1rem;padding-right:1rem;}"
      >
        {stateFilters.map(({label, value}) =>
          <TabRadiobox
            key={label}
            raw
            label={label}
            fieldValue={value}
            value={status.isOpen}
            white={isMobile()}
            setValue={updateStatus}
          />
        )}
      </RadioboxGroup>
    </div>
  </WorklistHeaderComponent>
}
