import {AutoComplete, TextInput} from '@startlibs/form';
import {Button, ContextMenu, Icon, Li} from '@startlibs/components';
import {getColor, media} from '@startlibs/utils';
import {usePopupToggle} from '@startlibs/core'
import React, {useState} from 'react'
import _ from 'lodash/fp'
import styled, { css } from 'styled-components';
import {Card, PageContainer} from '../../components/PageLayout';
import {ConfirmDialog, useConfirmDialog, useConfirmDialogWithProps} from '../../hooks/useConfirmDialog'
import {InfoBox} from '../../components/InfoBox'
import {PurviewFooter} from '../../components/PurviewFooter'
import { SUPER } from '../../enums/UserRole';
import {buildValidation, required, responseFailure} from '../../utils/validation'
import {getJwt} from '../../hooks/useJwt'
import {jwtFormFetcher, jwtGetFetcher} from '../../utils/authFetch'
import {lazyUserInfo} from '../../components/WithProvider'
import {willUseSuspense} from '../../hooks/useSuspense'
import {lazyUserCategories} from '../experts/hooks/useEditCategories'

const DivisionsListHeading = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    margin: 0;
  }
`

const DivisionsList = styled.div`
  background: ${getColor('gray240')};
  border-radius: 6px;
  padding: .75rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  ${media.max(820)`
    flex-wrap: wrap;
  `}
`

const DivisionUnit = styled.div`
  flex-basis: calc(50% - .5rem);
  max-width: calc(50% - .5rem);
  flex-grow: 1;
  margin: .25rem;
  background: white;
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props => props.viewMode && css`
    margin: 1rem 0;
    width: 100%;
    max-width: 100%;
  `}
  .left-wrapper {
    flex-basis: 60%;
    flex-grow: 1;
    font-size: 13px;
    font-weight: 600;
    color: ${getColor('gray120')};
  }
  .counters-wrapper {
    min-width: 80px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 1rem;
    ${props => props.viewMode && css`
      margin-right: 0;
    `}
    .counter {
      color: ${getColor('gray150')};
      display: flex;
      align-items: center;
      ${Icon} {
        font-size: 16px;
        margin-right: .5rem;
      }
    }
    .counter ~ .counter {
      margin-left: 1rem;
    }
  }
  ${media.max(820)`
    flex-basis: 100%;
    margin: 0 0 .5rem;
    max-width: 100%;
  `}
`

const NoDivisions = styled.div`
  padding: 6rem 2rem;
  width: 100%;
  color: ${getColor('gray120')};
  font-size: 14px;
  text-align: center;
  font-weight: 600;
`

const transform = _.map(({category,adminsCount,casesCount}) => ({...category,adminsCount,casesCount}))

const useDivisions = willUseSuspense(() => jwtGetFetcher(getJwt())("/api/admin/countCategories"))

export const DivisionManagement = () => {

  const [divisions,setDivisions] = useState(transform(useDivisions()))


  // Split divisions by the middle of the array
  const midIndex = Math.ceil(divisions.length / 2);
  const leftColumn = divisions.slice(0, midIndex); // First half (left column)
  const rightColumn = divisions.slice(midIndex);   // Second half (right column)

  // Remerge the divisions in the desired column-first order
  const reorderedDivisions = [];
  const maxLength = Math.max(leftColumn.length, rightColumn.length);

  for (let i = 0; i < maxLength; i++) {
    if (i < leftColumn.length) reorderedDivisions.push(leftColumn[i]);
    if (i < rightColumn.length) reorderedDivisions.push(rightColumn[i]);
  }

  const userInfo = lazyUserInfo.read()

  const refreshDivisions = () => {
    lazyUserCategories.refresh()
    jwtGetFetcher(getJwt())("/api/admin/countCategories").then((v) => setDivisions(transform(v)))
  }

  const addDivisions = useConfirmDialog(
    <ConfirmDialog
      title="Add divisions"
      values={{categories:[]}}
      action={jwtFormFetcher(getJwt())("/api/admin/addCategories")}
      transform={_.flow(_.get('categories'),_.map(name => ({name})))}
      onSuccess={refreshDivisions}
      preValidation={buildValidation({
        categories:(list) =>!list.find(_.identity) && required()
      })}
      onFailure={responseFailure((n,{status}) => status === 400 && {name:"These divisions already exist."})}
      confirm={<Button highlight>Add divisions</Button>}
      notify="Divisions added successfully."
    >
      <p>Add institutional divisions for associating cases with.</p>
      <AutoComplete
        getLabel={_.identity}
        minQueryLength={1}
        defaultValue={[]}
        autoSelect
        path="categories"
        label="New divisions"
        placeholder="Type the new division(s) name(s) here"
        mandatory
      />
  </ConfirmDialog>
  )

  const editDivision = useConfirmDialogWithProps((props) =>
    <ConfirmDialog
      title="Edit division"
      values={props}
      action={jwtFormFetcher(getJwt())("/api/admin/updateCategory",{method:"PUT"})}
      onSuccess={refreshDivisions}
      onFailure={responseFailure((n,{status}) => status === 400 && {name:"There's already a division with that name."})}
      confirm={<Button highlight>Update division</Button>}
      preValidation={buildValidation({name:required})}
      notify="Division updated successfully."
    >
      <p>Edit this division's name.</p>
      <TextInput
        path="name"
        autoFocus
        mandatory
        label="Division name"
      />
  </ConfirmDialog>
  )

  const removeDivision = useConfirmDialogWithProps((division) =>
    <ConfirmDialog
      title="Remove division"
      values={division}
      action={jwtFormFetcher(getJwt())("/api/admin/removeCategory",{method:"PUT"})}
      onSuccess={refreshDivisions}
      confirm={<Button alert>Remove division</Button>}
      preValidation={buildValidation({name:required})}
      notify="Division successfully removed."
    >
      <p>You are about to remove the following division:</p>
      <DivisionItem division={division} viewMode />
      {
        (!!division.casesCount || !!division.adminsCount) &&
          <InfoBox lightYellow>There are active
            {[
              !!division.casesCount && " cases ",
              !!division.adminsCount && " users "
            ].filter(Boolean).join(" and ")}
            tied to this division being removed. It will automatically be dissociated from them upon removing the division.</InfoBox>
      }
      <p>Are you sure you want to remove?</p>
  </ConfirmDialog>
  )

  return <>
    <PageContainer css="max-width: 100%;">
      <Card>
        <h3>Division settings</h3>
        <p>Group users and cases by using divisions. You can add, edit and remove divisions below.</p>
        <DivisionsListHeading>
          <h4>Current divisions</h4>
          {userInfo.role === SUPER && <Button icon="plus-circle" onClick={addDivisions}>Add divisions</Button>}
        </DivisionsListHeading>
        <DivisionsList>
          {
            reorderedDivisions.map(division => <DivisionItem key={division.id} userInfo={userInfo} editDivision={editDivision} removeDivision={removeDivision} division={division}/>)
          }
          {
            !divisions.length &&
              <NoDivisions>
                No divisions added yet. {userInfo.role === SUPER && <span><a className="link" onClick={addDivisions}>Add a division</a> to assign users to.</span>}
              </NoDivisions>
          }
        </DivisionsList>
      </Card>
    </PageContainer>
    <PurviewFooter/>
  </>
}

const DivisionItem = ({division,editDivision,removeDivision, userInfo, viewMode}) => {

  const contextMenu = usePopupToggle()

  return <DivisionUnit viewMode={viewMode}>
    <div className="left-wrapper">
      {division.name}
    </div>
    <div className="counters-wrapper">
      <div className="counter"><Icon icon="note" /> {division.casesCount}</div>
      <div className="counter"><Icon icon="user-line" /> {division.adminsCount}</div>
    </div>
    {!viewMode && <Button onClick={contextMenu.open} small onlyDropdown>
      {
        contextMenu.isOpen &&
        <ContextMenu>
          <Li label="Manage cases" linkTo={`/admin?categories=${division.id}`}/>
          <Li label="Manage users" linkTo={`/admin/control/staff?categories=${division.id}`}/>
          <Li label="Edit name" onClick={editDivision.willOpenWith(division)}/>
          {userInfo.role === SUPER && <Li label="Remove" onClick={removeDivision.willOpenWith(division)}/>}
        </ContextMenu>
      }
    </Button>
    }
  </DivisionUnit>
}
