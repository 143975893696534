import {Button, Icon} from '@startlibs/components';
import {RichText, TextInput} from '@startlibs/form'
import {getColor} from '@startlibs/utils'
import React from 'react'
import styled, {css} from 'styled-components';
import { darken } from 'polished';
import _ from 'lodash/fp'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {useAutoScrollAndFocus} from '../../hooks/useAutoScrollAndFocus'
import { isRichText, RTF_TOOLBAR_OPTIONS, safeMultipleNewLineToBr } from '../../utils/utils';
import { useState } from 'react';
import { FormSlateEditor } from '../../components/slateEditor/FormSlateEditor';
import { RichTextDisplay } from '../../components/RichTextDisplay';
import { useEffect } from 'react';

const QuestionContainer = styled.div`
  position: relative;
  margin-bottom: 2rem;
  label {
    font-size: 13px;
    font-weight: 600;
  }
  ${props => props.submitted && css `
    textarea {
      color: #000000;
      }
  `}
  ${props => props.addedByExpert && css`
    position: relative;
    margin-bottom: 2rem;
    box-shadow: 0 0 0 1px ${getColor('gray210')};
    padding: 1.25rem;
    background: ${getColor('gray240')};
    border-radius: 6px;
  `}
`

export const RemoveIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  color: ${getColor('gray150')};
  cursor: pointer;
  ${props => props.addedByExpert && css`
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
    font-size: 18px;
    color: ${getColor('gray240')};
    background: ${getColor('gray120')};
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 50%;
    text-align: center;
    :hover {
      background: ${props => darken(0.05, getColor('gray120')(props))};
    }
    :active {
      background: ${props => darken(0.08, getColor('gray120')(props))};
    }
  `}
`

export const Answer = (
  {
    path, i, form, submitted,
    answerKey = "answer",
    questionKey = 'question',
    onBlur,
    requestId
  }
) => {

  const questionText = form.getValue([...path,i,questionKey]) || ""
  const answerText = form.getValue([...path,i,answerKey]) || ""
  const [focus, setFocus] = useState(false)

  const handleEditorFocus = (event, change) => {
    setFocus(true)
  };
  
  return <QuestionContainer submitted={submitted}>
    {/* {isRichText(answerText) ?  */}
      <div onBlur={onBlur}><FormSlateEditor 
        formatTools={RTF_TOOLBAR_OPTIONS}
        // label={questionText.charAt(0).toUpperCase() + questionText.substring(1)}
        label={<RichTextDisplay css="font-size: 14px; font-weight: 600;" content={questionText.charAt(0).toUpperCase() + questionText.substring(1)}/>}
        path={[...path,i, answerKey]}
        // value={answerText}
        placeholder="Your answer here"
        textarea
        autoResize
        autoHideToolbar
        locked={submitted}
        disabled={submitted}
        useHtml={true}
        onFocus={handleEditorFocus}
        focus={focus}
        requestId={requestId}
      />
      </div>
  </QuestionContainer>
}



export const ExpertAdditionalInfo = ({path, autoFocus, className, children, submitted, form, i, onMouseDown, onTouchStart, onBlur, additionalInfos, isAdmin = false, requestId}) => {
  const confirmRemove = () => form.setValue(path, _.pullAt([i]))

  const [value, setValue] = useState(additionalInfos ? additionalInfos[i].content : form.getValue([...path, i, "content"]))
  const [focus, setFocus] = useState(false)
  const handleEditorFocus = (event, change) => {
    setFocus(true)
  };

  useEffect(() => {
    // Remove div tags from Additional info title. This could be beacuse:
    // 1. A rollback from Updated Version or;
    // 2. Opening a case created with the Updated Version on a cached version (this one)
    // if(form.getValue([...path, i, "title"]).includes('<div>')){
    //   form.setValue([...path, i, "title"], form.getValue([...path, i, "title"]).replace(/<div>/g, "").replace(/<\/div>/g, ""))
    // }
  },[])

  const confirmDelete = useConfirmDialog(
    <ConfirmDialog
      title="Confirm field deletion"
      confirm={<Button alert>Delete</Button>}
      action={confirmRemove}
    >
      <p><b>Are you sure you want to delete this field?</b></p>
      <p>Once confirmed this cannot be undone.</p>
    </ConfirmDialog>
  )

  const removeQuestion = () => {
    const field = form.getValue([...path, i])
    if (field.content || field.title) {
      confirmDelete()
    } else {
      confirmRemove()
    }
  }

  return <QuestionContainer
    onMouseDown={onMouseDown}
    onTouchStart={onTouchStart}
    submitted={submitted}
    addedByExpert
    className={className}
    >
    <div data-drag={false}>
      <FormSlateEditor
        path={[...path,i, "title"]}
        placeholder="Provide title here"
        locked={submitted}
        disabled={submitted}
        css="margin-bottom: .5rem;"
        formatTools={[]}
        autoHideToolbar
        minHeight={30}
        // focus={autoFocus}	
        onFocus={handleEditorFocus}
        focus={autoFocus ? autoFocus : focus}
        singleLineSimpleInput={true}
      />
    </div>
    <div data-drag={false} onBlur={onBlur}>
      <FormSlateEditor
        path={[...path, i, "content"]}
        placeholder="Provide answer here"
        disabled={submitted}
        formatTools={RTF_TOOLBAR_OPTIONS}
        autoHideToolbar
        css="margin-top: .75rem;"
        useHtml={true}
        onFocus={handleEditorFocus}
        focus={focus}
        requestId={requestId}
      />
    </div>
    {!submitted && <RemoveIcon data-drag={false} icon='x' addedByExpert onClick={removeQuestion}/>}
    {children}
  </QuestionContainer>
}
