import React from 'react'

export const ACTIVE = 'ACTIVE'
export const FINISHED = 'FINISHED'
export const ARCHIVE = 'ARCHIVE'
export const ALL = 'ALL'
export const PENDING = 'PENDING'
export const EXPERT_OPEN = 'EXPERT_OPEN'
export const EXPERT_REVIEWED = 'EXPERT_REVIEWED'
export const EXPERT_CASES = 'EXPERT_CASES'

export const FILTER_LABEL_MAP = {
  [ACTIVE]:"Active",
  [ALL]:'All',
  [FINISHED]:'Completed',
  [ARCHIVE]:'Archived',
  [EXPERT_OPEN]:'Open',
  [EXPERT_REVIEWED]:'Reviewed',
  [EXPERT_CASES]:'All'
}