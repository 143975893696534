import {ContextMenu, Icon, Li, Loading, Button, Dialog} from '@startlibs/components';
import {RadioboxGroup, TextInput} from '@startlibs/form'
import {
  addQueryString, callIfFunction,
  getColor,
  media,
  isMobile
} from '@startlibs/utils'

import {jwtGetFetcher, jwtPostFetcher} from '../../utils/authFetch'
import {useConstant, Slot, usePopupToggle, useToggle, Fill, useRefState} from '@startlibs/core'
import React, {Suspense, useContext, useEffect, useState} from 'react'
import _ from 'lodash/fp'
import styled, {css} from 'styled-components'

import {Pagination} from '../Pagination'
import {SectionHeading} from '../PageLayout';

import {callUpdateParams, useUpdateParams} from '../hooks/useUpdateParams'

import {removeFiles} from '../../utils/AttachmentsUtils';
import {Item} from './Item';
import {DropdownIcon, TextButton} from '../AttachmentBoxStyles';

import {darken} from 'polished';
import {useUIDataSelector} from "../../service/hooks/useUIDataSelector";
import {useDoAction} from "../../service/hooks/useDoAction";
import {UploaderAction} from "../../service/UploaderAction";
import {UploaderConfigContext} from "../../service/UploaderConfigContext";
import {ADMIN, PATIENT} from "../../enums/UserRoles";
import {GroupAction} from "../../service/GroupAction";
import {getShortTokenUrl} from "../../service/utils/endpointUtils";
import { getFilenameFromRecord } from '../recordGroup/RecordRowActions';

let authenticated = process.env.NODE_ENV !== 'development'


const FilelistContainer = styled.div`
  overflow: auto;
  width: 100%;
  color: ${getColor('gray60')};
  background: white;
  border-spacing: 0;
  position: relative;
  ${props => props.isLoading && `
    pointer-events: none;
  `}
  ${media.desktop`
    border-radius: 5px;
  `}
  ${props => props.isMobile && css`
      overflow-x: auto;
  `}
`


const FilelistDialogFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.5rem;
  margin-top: auto;
`

const FilelistPageContainer = styled.div`
  position: relative;
  ${props => !props.isMobile && css`
    min-width: 100%;
    max-width: 100%;
  `}
  ${props => props.isMobile && css`
    min-width: 300px;
  `}
  p {
    font-size: 13px;
  }
`

const FilelistCard = styled.div`
  & ~ ${SectionHeading} {
    margin-top: 1rem;
  }
  ${media.desktop`
    background: white;
    margin: 0 auto;
  `}
`

const FilelistHeaderComponent = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 30;
  align-items: flex-end;
  .right-wrapper {
    position: relative;
    text-align: right;
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
  }
  ${Button} {
    margin-bottom: 0.75rem;
  }
  ${Button} ~ ${Button} {
    margin-left: 0.75rem;
  }
  ${media.mobile`
    padding: 0 1rem;
  `}
  .selectionLinks {
    margin-top: 0.5rem;
    * + * {
      margin-left: 0.75rem;
    }
  }
`

const LinkButton = styled.a`
  position: relative;
  display: inline-block;
  text-decoration: underline;
  user-select: none;
`
const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${getColor('gray120')};
  ${media.max(450)`
    display: block;
  `}
`

const SearchInput = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 1rem;
  
  
  ${media.max(450)`
    margin-right: 0;
    margin-bottom: .5rem;
    max-width: 100%;
  `}
  ${LinkButton} {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  > input {
    padding-right: 8.75rem;
  }
`
const ClearSearchButton = styled(Icon)`
  color: ${getColor('gray90')};
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  border-radius: 50%;
  background: rgba(0,0,0,0.1);
  :hover {
    background: #f7d3d4;
    color: ${getColor('alert')};
  }
`

const PaginationContainer = styled.div`
  text-align: left;
`

const MaxResultsMenu = styled(ContextMenu)`
  li > * {
    padding: 11px 2rem;
  }
`
const LoadingFilelist = styled.div`
  border-radius: 5px;
  height: 26rem;
  height: calc(100vh - 18.5rem);
`

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  font-size: 13px;
  flex-wrap: wrap;
  min-height: 34px;
`
const ExtensionButton = styled.div`
  border-radius: 100px;
  padding: 0.25rem 0.75rem;
  background: ${getColor('gray240')};
  margin: 0.5rem 0.5rem 0.5rem 0;
  cursor: pointer;
  font-size: 12px;
  flex-srink: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  :hover {
    background: ${props => darken(0.05, getColor('gray240')(props))};
  }
  :active {
    background: ${props => darken(0.08, getColor('gray240')(props))};
  }
  ${props => props.active && css`
    background: ${getColor('main')};
    color: white;
    :hover {
      background: ${props => darken(0.05, getColor('main')(props))};
    }
    :active {
      background: ${props => darken(0.08, getColor('main')(props))};
    }
  `}
`

const LoadingTableContent = styled.div`
  position: absolute;
  top: 0.75rem;
  bottom: 0;
  left: 0;
  right: 0;
  ${Loading} {
    box-shadow: 0 0 10px 0 white;
  }
`

const EmptyList = styled.div`
  background: ${getColor('gray240')};
  color: ${getColor('gray120')};
  width: 100%;
  min-height: 10rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  margin-top: 0.75rem;
`

export const Filelist = ({
                           filelist,
                           setFilelist,
                           selectedFiles,
                           setSelectedFiles,
                           extensions,
                           reloadExtensions,
                           location,
                         }) => {
  const sessionOnly = useUIDataSelector('sessionOnly')
  const doAction = useDoAction()

  const [count, setCount] = useState()
  const [selectedExtensions, setSelectedExtensions] = useState([])
  const loading = useToggle()
  const deleteDialog = useToggle()
  const [params, rawSetParams] = useState(() => _.set('', '', {
    page: 1,
    rows: 10,
    search: '',
    extension: '',
    sessionOnly
  }))
  const updateParams = (params) => {
    rawSetParams(prevParams => _.set('', '', params))
    callUpdateParams(params)
  }

  useEffect(() => {
    updateParams(params);
  }, [location])

  useEffect(() => {

  }, [params])


  const debounceSetParams = useConstant(_.debounce(500, rawSetParams))

  const setParams = (updater, debounce) => {
    const verifyPage = (updater) => (state) => {
      const updatedState = callIfFunction(updater, state)

      if (
        state.search !== updatedState.search
      ) {
        return _.set('page', 1, updatedState)
      } else if (state.rows !== updatedState.rows) {
        const firstRow = ((state.rows || 10) * (updatedState.page - 1) + 1)
        return _.set('page', Math.max(Math.floor(firstRow / (updatedState.rows || 10)) + 1, 1), updatedState)
      } else {
        return updatedState
      }
    }
    if (debounce) {
      debounceSetParams(verifyPage(updater))
    } else {
      setTimeout(() => rawSetParams(verifyPage(updater)), 0)
    }
    setTimeout(loading.open, 0)

  }

  const removeSelectedFiles = async () => {
    try {
      await doAction(UploaderAction.DeleteManyByUID, selectedFiles)
    } catch (e) {

    } finally {
      var selectdRecordsObj = []
      selectedFiles.map(item => selectdRecordsObj.push({'recordUID': item}))
      var newFileList = _.differenceBy(_.property('recordUID'), filelist, selectdRecordsObj)
      setSelectedFiles([])
      setFilelist(newFileList)
      setCount(newFileList.length)
      setParams(_.set('page', 1), true)
      // reloadExtensions()
      return deleteDialog.close()
    }
  }

  return <>
    <FilelistPageContainer isMobile={isMobile()}>
      <FilelistCard>
        <FilelistHeader
          filelist={filelist}
          setFilelist={setFilelist}
          extensions={extensions}
          reloadExtensions={reloadExtensions}
          selectedExtensions={selectedExtensions}
          setSelectedExtensions={setSelectedExtensions}
          count={count}
          setCount={setCount}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          params={params}
          setParams={setParams}
          location={location}
          loading={loading}
          deleteDialog={deleteDialog}
        />
        <Suspense fallback={null}>
          <FilelistTable
            filelist={filelist}
            setFilelist={setFilelist}
            reloadExtensions={reloadExtensions}
            count={count}
            setCount={setCount}
            updateParams={updateParams}
            loading={loading}
            params={params} setParams={setParams}
            location={location}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
        </Suspense>
      </FilelistCard>
      {deleteDialog.isOpen &&
        <Dialog
          closeDialog={deleteDialog.close}
          title={<>Delete {selectedFiles.length > 1 ? 'files' : 'file'}</>}
          footer={<>
            <Button onClick={deleteDialog.close}>Cancel</Button>
            <Button alert isLoading={loading.isOpen} onClick={loading.willWait(removeSelectedFiles)}>Delete</Button>
          </>}
        >
          <p css="font-size:15px!important"><b>This will permanently
            delete {selectedFiles.length} {selectedFiles.length > 1 ? 'files' : 'file'}</b>.</p>
          <p>Would you like to proceed?</p>
        </Dialog>
      }
    </FilelistPageContainer>

  </>
}

const FilelistHeader = ({
                          filelist,
                          setFilelist,
                          selectedFiles,
                          setSelectedFiles,
                          setCount,
                          selectedExtensions,
                          setSelectedExtensions,
                          extensions,
                          params,
                          setParams,
                          deleteDialog
                        }) => {

  const config = useContext(UploaderConfigContext)
  const {role,mode,requestId,apiEndpoints:{downloadFiles},canAddHiddenFileToCase,allowDownloadMedicalImages,worklistViewerJwt: expertViewJwt} = config
  const shortTokenUrl = getShortTokenUrl(config)
  const groups = useUIDataSelector('groups')
  const doAction = useDoAction()
  const canManageNotClassFiles =  ((role === PATIENT && mode && mode?.length > 0) || (role === ADMIN))
  const contextMenu = usePopupToggle()
  const groupItemsList = groups.find(g => g.id === 'notclass')
  const moveTo = (recordUID, groupId) => doAction(GroupAction.SimpleMoveByRecordUID, recordUID, 'notclass', groupId)

  const query = useToggle(params.search || '')
  const rows = useToggle(params.rows || '')

  useUpdateParams((params) => {
    query.openWith(params.search || "")
    rows.openWith(params.rows || "")
  })

  useEffect(() => {

  }, [params])


  const maxResultsMenu = usePopupToggle()

  const updateSearch = (v) => {
    query.openWith(v)
    setParams(_.set('search', v), true)
  }

  const updateExtension = (v) => {

    if (selectedExtensions.indexOf(v) >= 0) {
      // Already selected
      const extFilter = selectedExtensions.filter((item) => item !== v)
      setSelectedExtensions(extFilter)
      setParams(_.set('extension', extFilter.join('-')), true)
    } else {
      // Add to list
      const extFilter = selectedExtensions.concat(v)
      setSelectedExtensions(extFilter)
      setParams(
        _.flow(
          _.set('extension', extFilter.join('-')),
          _.set('page', 1)
        )
        , true)
    }
  }

  const clearExtensions = () => {
    setSelectedExtensions([])
    setParams(
      _.flow(
        _.set('extension', ''),
        _.set('page', 1)
      )
      , true)
  }

  const updateMaxResult = (v) => {
    rows.openWith(v)
    setParams(_.set('rows', v), true)
  }

  const handleDownload = () => {
    const filteredFileList = filelist.filter((item) => selectedFiles.indexOf(item.recordUID) >= 0);
    const descriptions = filteredFileList.map((record) => {
      try {
        return getFilenameFromRecord(record);
      } catch (e) {
        console.log(e);
        return null;
      }
    });
    try{
      jwtPostFetcher(expertViewJwt)(`/api/audit/study/downloadRequested?requestId=${requestId}`, {
        recordDescriptions: descriptions.filter(v => v)
      });
    }catch(e){
      console.log(e)
    }
    shortTokenUrl(requestId)
      .then((response) => {
        callIfFunction(downloadFiles(response.jwt, selectedFiles));
      })
      .catch((error) => {
        // Handle error if necessary
        console.log(error);
      });
  };

  return <FilelistHeaderComponent>
    <div css="flex-grow:2;">
      <SearchInputWrapper>
        <SearchInput>
          <TextInput raw value={query.isOpen} setValue={updateSearch} placeholder="Search files"/>
          {query.isOpen && <ClearSearchButton icon="x" onClick={() => updateSearch("")}/>}
        </SearchInput>
        <div>
          <Slot name="QueryCount"/>
          <div className="nowrap">Showing <LinkButton onClick={maxResultsMenu.open}>{(rows.isOpen || 10)} per page
            {
              maxResultsMenu.isOpen &&
              <MaxResultsMenu offset="0 4">
                <Li label="10" onClick={() => updateMaxResult(10)}/>
                <Li label="20" onClick={() => updateMaxResult(20)}/>
                <Li label="50" onClick={() => updateMaxResult(50)}/>
              </MaxResultsMenu>
            }
          </LinkButton>
          </div>
        </div>
      </SearchInputWrapper>
      <FiltersContainer>
        <div css="margin-right:0.5rem;">Filter by:</div>
        {extensions.map((e) => <ExtensionButton
          onClick={() => updateExtension(e)}
          active={selectedExtensions.indexOf(e) >= 0}
        >{e}</ExtensionButton>)
        }
      </FiltersContainer>
    </div>
    <div className="right-wrapper">
      {allowDownloadMedicalImages && selectedFiles.length > 0 && (
        <Button
          small
          icon="download"
          onClick={handleDownload}
        >
          Download
        </Button>
      )}
      {selectedFiles.length > 0 && canManageNotClassFiles &&
        <Button small icon="delete" onClick={() => deleteDialog.open()}>
          Delete
        </Button>}
      {selectedFiles.length > 0 && canAddHiddenFileToCase &&
        <Button highlight small withDropdown onClick={() => contextMenu.open()}>Add to case
          {
            contextMenu.isOpen &&
            <ContextMenu className="noIcons">
              {groups.map(group =>
                (group.name && group.id !== 'notclass')
                  ? <Li
                    key={group.id}
                    label={group.name}
                    onClick={() => {
                      selectedFiles.forEach(recordUID => {
                        moveTo(recordUID, group.id)
                      })
                      const listObj = selectedFiles.map((recordUID) => ({recordUID: recordUID}))
                      var newFileList = _.differenceBy(_.property('recordUID'), filelist, listObj)
                      setFilelist(newFileList)
                      setCount(newFileList.length)
                      setSelectedFiles([])
                      setParams(_.set('page', 1), true)
                    }}
                  />
                  : null
              )}
            </ContextMenu>
          }</Button>}
      <div className="selectionLinks">
        <a className="light-link" onClick={() => {
          setSelectedFiles(filelist.map(_.get('recordUID')))
        }}>Select all files</a>
        {selectedFiles.length > 0 && <a className="light-link" onClick={() => {
          setSelectedFiles([])
        }}>Clear selection</a>}
        {selectedFiles.length > 0 &&
          <span><b>{selectedFiles.length} file{selectedFiles.length > 1 ? 's' : ''} selected</b></span>}
      </div>
    </div>
  </FilelistHeaderComponent>
}

const FilelistTable = React.memo(({
                                    filelist,
                                    setFilelist,
                                    count,
                                    setCount,
                                    selectedFiles,
                                    setSelectedFiles,
                                    params,
                                    setParams,
                                    updateParams,
                                    loading,
                                    reloadExtensions
                                  }) => {
  const {jwt, caseId, apiEndpoints: {storageHost}} = useContext(UploaderConfigContext)
  const lastParams = useRefState(params)
  const lastSuccessParams = useRefState(params)
  const errorDialog = useToggle()

  const [isLoadingAction, setLoadingAction] = useState(false);

  const rows = Number(params.rows) || 10
  const currentPage = Number(params.page) || 1
  const config = useContext(UploaderConfigContext)
  const {requestId} = config
  const shortTokenUrl = getShortTokenUrl(config)
  const [imgToken, setImgToken] = useState('')

  const loadList = useConstant((currentParams, closeLoading, refreshing) => {

    lastParams.set(currentParams)
    if (lastParams.get() === lastSuccessParams.get() && !refreshing) {
      callIfFunction(closeLoading)
      return Promise.resolve()
    }

    return jwtGetFetcher(jwt)(addQueryString(storageHost() + "/notclass/" + caseId + '/',
      {
        rows: currentParams.rows,
        // search: currentParams.search,
        // extension: currentParams.extension,
        ..._.pickBy(_.identity, currentParams),
        // page: currentParams.page - 1
        first: (currentParams.page - 1) * currentParams.rows
      })
    ).then((response) => {
      if (lastParams.get() === currentParams) {
        lastSuccessParams.set(currentParams)
        setFilelist(response.list)
        setCount(response.count)
        reloadExtensions()
        callIfFunction(closeLoading)
      }
    })
      .catch((exception) => {
        if (lastParams.get() === currentParams) {
          callIfFunction(closeLoading)
          setFilelist([])
          setCount(0)
          // reloadExtensions()
          // errorDialog.open()
          // updateParams(lastSuccessParams.get())
        }
      })
  })

  useEffect(() => {
    loading.open()
    loadList(params, loading.close)
    shortTokenUrl(requestId).then((response) => {
      setImgToken(response.jwt)
    })
  }, [params])

  if (!filelist) {
    return <>
      <Fill name="QueryCount"><b>Loading...</b></Fill>
      <LoadingFilelist><Loading size={40} borderWidth={6} absolute/></LoadingFilelist>
    </>
  }


  return <>
    <Fill
      name="QueryCount"><b>{loading.isOpen ? 'Loading...' : <>{count} file{count !== 1 ? "s" : ""}{params.search && " found"}</>}</b></Fill>
    <Fill name="Pagination"><Pagination
      setPageParam={(page) => () => setParams(_.set('page', page))}
      currentPage={currentPage}
      totalPages={Math.ceil((Number(count)) / rows)}
    /></Fill>
    <FilelistContainer>
      {filelist?.length
        ? filelist.map(file =>
          <Item
            key={file.recordUID}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            file={file}
            filelist={filelist}
            setFilelist={setFilelist}
            setCount={setCount}
            isLoading={loading.isOpen}
            setParams={setParams}
            queryCount={Number(count)}
            setLoadingAction={setLoadingAction}
            isLoadingAction={isLoadingAction}
            imgToken={imgToken}
          />
        )
        :
        <EmptyList>{!loading.isOpen && 'No files found'}</EmptyList>
      }
      {loading.isOpen && <LoadingTableContent>
        <Loading absolute size={40} borderWidth={6}/>
      </LoadingTableContent>}
    </FilelistContainer>
    <FilelistDialogFooter>
      <PaginationContainer><Slot name="Pagination"/></PaginationContainer>
    </FilelistDialogFooter>
  </>
})
