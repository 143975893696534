import React, { useState } from 'react'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {Button} from '@startlibs/components'
import {jwtPostFetcher} from '../../utils/authFetch'
import {getJwt} from '../../hooks/useJwt'
import {CaseRequestCard} from '../CaseRequestCard'

export const useUnarchiveDialog = (caseRequest,onSuccess,fromDashboard,navigate) => useConfirmDialog((closeDialog) => {

  const nextState = caseRequest?.whenAcceptedEpochMilli ? "Accepted" : "Requested"

  return <ConfirmDialog
    title="Unarchiving case"
    confirm={<Button highlight 
      onClick={() => fromDashboard 
        ? setTimeout(() =>
            navigate(`/admin/request/${caseRequest.requestCode}`), 2000)
        : null}>
        {fromDashboard ? "Unarchive and go to case" : "Unarchive"}
      </Button>
    }
    alternativeButton={fromDashboard 
      ? <Button highlight type="submit">Unarchive</Button>
      : null
    }
    alternativeButtonWithLoading={fromDashboard}
    action={() => jwtPostFetcher(getJwt())("/api/admin/unarchiveCase?requestId="+(caseRequest.requestId || caseRequest.id))}
    notify="Case unarchived successfully."
    onSuccess={onSuccess}
  >
    {/* <p>By unarchiving the following case it will become a "Case draft" in "Active cases":</p> */}
    <CaseRequestCard viewOnly caseRequest={caseRequest} />
    {/* <p>You will no longer be able to access this case under "Archived cases", but it will be available in "Active cases" and "All cases".</p> */}
    {/* <p>Are you sure you want to unarchive this case?</p> */}
    <p>When unarchived, this case will return to the <b>"{nextState}"</b> state.</p>
  </ConfirmDialog>
})