import {Button} from '@startlibs/components'
import {FormValue, RadioboxGroup, SimpleRadiobox, TextInput, ToggleCheckbox} from '@startlibs/form'
import React from 'react'
import {CaseRequestCard} from '../CaseRequestCard'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {CASE_REJECTED, UNDER_REVIEW, WAITING_ACCEPTANCE} from '../../enums/CaseState'
import {buildValidation, required} from '../../utils/validation'
import {getJwt} from '../../hooks/useJwt'
import {jwtFormFetcher} from '../../utils/authFetch'
import styled from 'styled-components'
import { ADDITIONAL_MEDICAL_RECORDS_REQUIRED, ADDITIONAL_MEDICAL_RECORDS_REQUIRED_LABEL, EXPERT_NOT_AVAILABLE, EXPERT_NOT_AVAILABLE_LABEL,  MISTAKE, MISTAKE_LABEL, OTHER, OTHER_LABEL } from '../../enums/PredefinedReason'
import { InfoBox } from '../../components/InfoBox'

const preValidation = buildValidation({
  "predefinedReason": required,
  "details": [(v,k,values) => values.predefinedReason === OTHER && !v.length && required()],
})

const FullWidthRadioBox = styled(SimpleRadiobox)`
  width: 100%;
}`

const ReasonRadioBox = styled(SimpleRadiobox)`
  width: 48%;
}`

export const useSetAsAcceptedDialog = (caseRequest,onSuccess) => useConfirmDialog(
  <ConfirmDialog
    title={"Set case as Accepted"}
    action={jwtFormFetcher(getJwt())("/api/admin/setAsAccepted?requestId="+(caseRequest.requestId || caseRequest.id))}
    onSuccess={onSuccess}
    preValidation={preValidation}
    notify={"Case successfully set as Accepted."}
    confirm={<Button highlight>{'Set as Accepted'}</Button>}
    values={{
      "predefinedReason" : "",
      "details" : ""
    }}
  >
    {/* <p>{caseRequest.state === PENDING_REQUEST ? "The following case request " : "This case "} will no longer be visible under "Active cases":</p> */}
    {/* <p>This is case will be marked as "Reviewed"</p> */}
    <CaseRequestCard viewOnly caseRequest={caseRequest} isResults={true} />
    {(caseRequest.state === UNDER_REVIEW
      || caseRequest.state === WAITING_ACCEPTANCE
      || caseRequest.state === CASE_REJECTED
    ) && <RadioboxGroup path="predefinedReason" required mandatory framedBoxes highlightSelected horizontal css="margin-bottom:1rem;" 
      label="Select the reason for setting this case as Accepted"
      >
        <ReasonRadioBox
          fieldValue={MISTAKE}
          label={MISTAKE_LABEL}
        />
        <ReasonRadioBox
          fieldValue={EXPERT_NOT_AVAILABLE}
          label={EXPERT_NOT_AVAILABLE_LABEL}
        />
        <ReasonRadioBox
          fieldValue={ADDITIONAL_MEDICAL_RECORDS_REQUIRED}
          label={ADDITIONAL_MEDICAL_RECORDS_REQUIRED_LABEL}
        />
        <ReasonRadioBox
          fieldValue={OTHER}
          label={OTHER_LABEL}
        />
    </RadioboxGroup>}

    <FormValue path="predefinedReason">{reason =>
      <TextInput
        textarea
        autoResize
        mandatory={reason === OTHER}
        path="details"
        label={(reason === OTHER) ? "Please add more details" :"Details"}
      />
    }</FormValue>
    {((caseRequest.state === WAITING_ACCEPTANCE) || (caseRequest.state === UNDER_REVIEW)) && <>
      <InfoBox lightYellow>
        The expert will no longer be able to access or review this case.
      </InfoBox>
      <ToggleCheckbox
        label={<strong>Notify expert that the case was unassigned</strong>}
        path="sendEmail"
      />
    </>}
    {caseRequest.state === CASE_REJECTED && <ToggleCheckbox
      label={<strong>Notify expert that the case was unassigned</strong>}
      path="sendEmail"
    />}

  </ConfirmDialog>
)
