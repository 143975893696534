import { Button, Icon, Loading, Tooltip, TextButton, SplitColumnsContainer, setNotification} from '@startlibs/components';
import React, {useEffect, useState} from 'react'
import _ from 'lodash/fp'
import styled, {css} from 'styled-components'
import {Header} from '../../components/Header'
import {Card, PageContainer} from '../../components/PageLayout'
import {PurviewFooter} from '../../components/PurviewFooter'
import { useNavigate } from 'react-router';
import { Errors, TextInput, WithForm } from '@startlibs/form';
import { buildValidation, required } from '../../utils/validation';
import { CASE_CLOSED } from '../../enums/CaseState';
import { jwtPostFetcher } from '../../utils/authFetch';
import { getJwt } from '../../hooks/useJwt';

const preValidation = buildValidation({
  'caseInfo.ehrInfo.mrn': (v) => !v.length && required(),
})


export const ReconciliationStep = ({caseRequest, setCaseRequest}) => {

  const [isLoading, setLoading] = useState(false)

  const action = ({caseInfo}) =>  jwtPostFetcher(getJwt())("/api/caseInfo?requestId=" + caseRequest.requestId, caseInfo, {method: "PUT"})

  return <>
    <PageContainer>
      <Header title="Case to EHR"></Header>
      <Card>
        <h4>Insert the information below to export this case to your EHR system</h4>
        <br></br>
        <WithForm
          alwaysSave
          values={caseRequest}
          preValidation={preValidation}
          action={action}
          onSuccess={(values) => {
            setNotification("All changes saved")
            setCaseRequest(values)
          }}
        >{form => 
          <>
            <SplitColumnsContainer>
              <TextInput
                label="Medical Record Number"
                helpText="(Patient Identification Number)"
                css="min-width: 400px;"
                path="caseInfo.ehrInfo.mrn"
                mandatory
              />
              <TextInput
                label="Order Number"
                path="caseInfo.ehrInfo.orderNumber"
              />
            </SplitColumnsContainer>
            <p>If the order number is empty, the case report will be seen as unsolicited message on the EHR system.</p>  
            <Errors/>
            <div css="text-align: right; margin-top: .5rem;">
              {/* <Button onClick={() => form.confirm('discard-remote-log').then(cancel)}>Cancel</Button> */}
              <Button type='submit' highlight isLoading={form.isLoading} onClick={() => form.clearErrors()}>Save</Button>
            </div>
          </>
        }</WithForm>
      </Card>

      {caseRequest.state === CASE_CLOSED 
        ? <Card style={{display: "flex", justifyContent: "space-between", flexWrap: "nowrap", flexDirection: "row", alignItems: "center"}}>
          <h3 css="margin-bottom:0;font-size:17px;">
            <Icon icon="checked-report" css="font-size:30px;margin-right:0.75rem;vertical-align:-6px;"/>
            Case report is available
          </h3>
          <Button highlight isLoading={isLoading} 
            disabled={caseRequest.caseInfo.ehrInfo.mrn === null || caseRequest.caseInfo.ehrInfo.mrn.length === 0}
            onClick={() => {
              setLoading(true)
              return jwtPostFetcher(getJwt())("/api/admin/sendToEhr?requestId=" + caseRequest.requestId, {}, {method: "POST"})
                .then((response) => {
                  setLoading(false)
                  setNotification("Case report was sent to EHR")
                })
                .catch((err) => {
                  setLoading(false)
                  console.log(err)
                  setNotification({type: "alert", msg: (close) => <>Error sending to EHR</>})
                })
              }
            }
          >Send to EHR</Button>
        </Card>
        : null
      }
    </PageContainer>
    <PurviewFooter/>
  </>
}
