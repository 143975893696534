import React from 'react'
import {useToggle, willUseSharedCallback} from '@startlibs/core'
import {Button, Dialog} from '@startlibs/components'
import {Errors, WithForm} from '@startlibs/form'
import {useNotificationRef} from '../../../components/Notifications'
import {ExpertProfileCard} from '../components/ExpertProfileCard'
import {jwtPostFetcher} from '../../../utils/authFetch'
import {getJwt} from '../../../hooks/useJwt'
import {ConfirmDialog, useConfirmDialog} from '../../../hooks/useConfirmDialog'

export const [useRegisterExpertDeleted, triggerExportDeleted] = willUseSharedCallback()

export const useDeleteExpertDialog = (expert,onSuccess) => {

  const action = () => jwtPostFetcher(getJwt())(`/api/expert/${expert.id}`, '', {method: "DELETE"})

  const onFailure = (e,[resp,{status}],form) => {
    if (status === 403) {
      form.setError("","This expert is currently assigned to review a case. Revoke all case assignments before removing the expert.")
    }
    if(status === 564) {
      const errorMessage = resp.replace('This c','C')
      const displayMessage = errorMessage + ". Please unassign or complete the case before removing the expert."
      form.setError("",displayMessage)
    }
  }


  return useConfirmDialog(
    <ConfirmDialog
      title="Confirm expert removal"
      confirm={<Button alert>Yes, remove expert</Button>}
      action={action}
      onSuccess={onSuccess}
      onFailure={onFailure}
      notify="Expert removed successfully."
    >
      <p>By removing this expert you will no longer be able to share cases:</p>
      <ExpertProfileCard expert={expert}/>
      <p>The expert will no longer be able to access cases previously shared.</p>
      <p>Are you sure you want to remove this expert? This action cannot be undone.</p>
    </ConfirmDialog>
  )
}
