import { Button, Dialog } from '@startlibs/components';
import {Fill, useToggle} from '@startlibs/core';
import { getColor } from '@startlibs/utils';
import {useNavigate} from 'react-router'
import React, {useRef} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {Header} from '../components/Header';
import {LogoutButton} from '../components/Navbar';
import {MedicalRecords} from '../request/MedicalRecords'
import {
  CASE_CLOSED,
  CASE_REVIEWED,
  PENDING_ASSIGNMENT,
  WAITING_ACCEPTANCE,
  UNDER_REVIEW,
  WAITING_MEDICAL_RECORDS,
  CASE_ARCHIVED
} from '../enums/CaseState';
import {PageContainer, PageFooter} from '../components/PageLayout';
import {PurviewFooter} from '../components/PurviewFooter';
import {getJwt} from '../hooks/useJwt'
import { isStateBefore } from '../request/utils';
import {jwtPostFetcher} from '../utils/authFetch'
import { setNotification } from '../components/Notifications';
import {isPendingAskingMore} from './utils/patientUtils'
import { useState } from 'react';
import { DISPLAY, START } from '../enums/UploaderManagement';
import { FeedbackButton } from '../expert/ExpertRoutes';
import { PatientFeedback } from '../components/PatientFeedback';

const AutoSavedText = styled.div`
  color: ${getColor('gray150')};
  max-width: 240px;
`

export const PatientRecords = ({caseRequest, setCaseRequest, isRefPhysician, feedbackPopup}) => {

  const navigate = useNavigate()
  const uploaderRef = useRef()
  const loading = useToggle()
  const isForceStart = (
    (caseRequest?.caseFlags?.requestFlags?.askedRecords && !caseRequest?.caseFlags?.patientDoneFlags?.doneRecords  && caseRequest.state !== CASE_ARCHIVED) 
    || 
    (
      (caseRequest?.medicalRecords && caseRequest?.medicalRecords?.length === 0)
      && (caseRequest.state !== CASE_REVIEWED) 
      && (caseRequest.state !== CASE_CLOSED )
      && (caseRequest.state !== UNDER_REVIEW )
      && (caseRequest.state !== CASE_ARCHIVED)
      && (!caseRequest?.caseFlags?.patientDoneFlags?.doneMedicalReleases)
      && (!caseRequest?.caseFlags?.adminMedicalReleaseUpdated)
    )
  )
  
  const [mode, setMode] = useState(isForceStart ? START : DISPLAY)
  const [forceStart, setForceStart] = useState(isForceStart)
  const readOnly = caseRequest.state !== WAITING_MEDICAL_RECORDS
  const requestedPayment = caseRequest.caseFlags?.requestFlags?.askForPayments
  const isPaid = caseRequest.paymentInfo?.paid
  const goToPayment = (requestedPayment || isPaid)
  const isCurrentStateBefore = isStateBefore(caseRequest && caseRequest.state)
  const saveDoneRecords = () =>  (caseRequest.state === WAITING_MEDICAL_RECORDS || isPendingAskingMore(caseRequest)) 
    // ? loading.wait(confirmSaveAction().then(() => jwtPostFetcher(getJwt())("/api/doneRecords")).then(onSuccess))
    ? loading.wait(() => jwtPostFetcher(getJwt())("/api/doneRecords").then(onSuccess))
    : null

  const releasesActive = isStateBefore(caseRequest.state, WAITING_ACCEPTANCE) || caseRequest.state === CASE_REVIEWED
  
  const onSuccess = (values) => {
    setNotification("Changes saved successfully.")
    setCaseRequest(_.set('caseFlags.patientDoneFlags.doneRecords',true))
    navigate("/patient/status")
  }

  const confirmSaveAction = () => uploaderRef.current.confirm()

  return <>
    <Fill name="Navbar-Action">
      {!isCurrentStateBefore(CASE_CLOSED) && <FeedbackButton icon="feedback" textOnly onClick={()=>{feedbackPopup.open()}}>Give feedback</FeedbackButton>}
      <LogoutButton onClick={() => uploaderRef.current ? confirmSaveAction().then(() => navigate(goToPayment ? "/patient/payments" : "/access")) : navigate("/access")}>
        <span className="navbar-action no-padding">Exit and save for later</span>
      </LogoutButton>
    </Fill>
    <PageContainer>
      <Header
        title="Medical records"
      >
        {(mode === DISPLAY && releasesActive && caseRequest.state !== CASE_REVIEWED && caseRequest.state !== CASE_CLOSED && caseRequest.state !== PENDING_ASSIGNMENT && caseRequest.state !== CASE_ARCHIVED) && <Button highlight onClick={()=>setMode(START)}>Add medical records</Button>}
      </Header>
      <MedicalRecords
        setCaseRequest={setCaseRequest}
        values={caseRequest}
        alwaysSave
        isPatient
        optional
        uploaderRef={uploaderRef}
        caseRequest={caseRequest}
        readOnly={!isCurrentStateBefore(PENDING_ASSIGNMENT)}
        canOpenWorklist={false}
        autoGrouping={true}
        mode={mode}
        setMode={setMode}
        forceStart={forceStart}
        setForceStart={setForceStart}
        saveDoneRecords={saveDoneRecords}
        isRefPhysician={isRefPhysician}
      >
      </MedicalRecords>
    </PageContainer>
    <PurviewFooter/>
    {feedbackPopup.isOpen && <Dialog title="Share feedback" closeDialog={feedbackPopup.close}>
      <PatientFeedback caseRequest={caseRequest} feedbackPopup={feedbackPopup} isPopup />
      </Dialog>
    }
  </>
}
