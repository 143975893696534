import {Button} from '@startlibs/components';
import {FormValue, TextInput, ToggleCheckbox} from '@startlibs/form';
import React from 'react'
import {CaseRequestCard} from '../CaseRequestCard';
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {ExpertProfileCard} from '../experts/components/ExpertProfileCard';
import {getJwt} from '../../hooks/useJwt'
import {jwtPostFetcher} from '../../utils/authFetch'
import {lazyProviderInfo, lazyUserInfo} from '../../components/WithProvider'
import {responseFailure} from '../../utils/validation'
import {TransitionDiv} from '../../components/TransitionDiv'
import { REVIEWER_TYPE_ADMIN } from '../../enums/UserRole';


export const useUnshareDialog = (expertShare, caseRequest, onSuccess) => {

  const {expert} = expertShare
  const providerInfo = lazyProviderInfo.read()
  const formRef = React.useRef()
  const userInfo = lazyUserInfo.read()
  const isSelfAssignment = expert.id === userInfo.id && expert.type === REVIEWER_TYPE_ADMIN && userInfo?.canReviewCases
  
  return useConfirmDialog(
    <ConfirmDialog
      formRef={formRef}
      title="Unassign case"
      confirm={<Button alert>Unassign</Button>}
      action={(values) => jwtPostFetcher(getJwt())(`/api/reviewer/share/${expertShare.id}`, values, {method: 'DELETE'})}
      notify="Case unassigned successfully."
      values={{message: "", sendEmail: providerInfo.notifications.unassignExpert && !isSelfAssignment}}
      onSuccess={onSuccess}
      onFailure={responseFailure((n,{status}) => status === 566 && "This case is not shared with an expert")}
    >
      <p>Please review case details below:</p>
      <CaseRequestCard caseRequest={caseRequest}/>
      <p>Unassign case from:</p>
      <ExpertProfileCard expert={expert}/>
      {!isSelfAssignment &&  <>
        <ToggleCheckbox
          label={<strong>Notify expert that the case was unassigned</strong>}
          path="sendEmail"
        />
        <FormValue path="sendEmail">{sendEmail =>
          <TransitionDiv>{sendEmail &&
          <TextInput
            textarea
            autoResize
            label="Message"
            path="message"
            placeholder="Insert custom message here"
          />
          }</TransitionDiv>
        }</FormValue>
      </>}
      <p>The expert will no longer be able to access or review this case.</p>
    </ConfirmDialog>, [providerInfo]
  )
}
