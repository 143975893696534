import {useRef} from 'react'
import qq from 'fine-uploader'
import _ from 'lodash/fp'
import {FileState, getFileNameAndExtension, isDev, tryReadingFile} from './utils'
import {callIfFunction} from '@startlibs/utils'
import {useConstant} from '@startlibs/core'
import { syncControlLock, syncProcessLock, syncUploadLock } from './queues'

const createUploader = (fileInstanceToUpload, params, getJwt, incrementProgress) => {
  const { file: fileToUpload, params: fileParams } = fileInstanceToUpload
  const [filename, extension] = getFileNameAndExtension(fileToUpload)
  let isFirstProgress = true
  let lastProgress = 0
  let resolve = _.identity
  let reject = _.identity
  const promise = new Promise((res, rej) => {
    resolve = res
    reject = rej
  })

  const createNewChunksRequest = (responseJSON,getJwt,incrementProgress,progressDelta,resolve) => {
    
    const url = '/uploader/chunksdone';

    const chunksXhr = new XMLHttpRequest();
    chunksXhr.open('POST', url);
    chunksXhr.setRequestHeader('Authorization', `Bearer ${callIfFunction(getJwt)}`)
    chunksXhr.setRequestHeader('Accept', 'application/json');
    chunksXhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    setTimeout(() => {
      chunksXhr.send(`qquuid=${responseJSON.uuid}&qqfilename=${responseJSON.filename}&filename=${responseJSON.filename}&qqtotalfilesize=${responseJSON.totalFileSize}&qqtotalparts=${responseJSON.totalParts}`);
    }, 1000);
    // 4. This will be called after the response is received
    chunksXhr.onreadystatechange = () => { // Call a function when the state changes.
      if (chunksXhr.readyState === XMLHttpRequest.DONE && chunksXhr.status === 201) {
        incrementProgress(progressDelta)
        resolve(JSON.parse(chunksXhr.response))
        // setProgress(1)
        // onSuccess(JSON.parse(chunksXhr.response))
      }
      if (chunksXhr.readyState === XMLHttpRequest.DONE && chunksXhr.status === 206) {
        setTimeout(() => {
          createNewChunksRequest(responseJSON,getJwt,incrementProgress,progressDelta,resolve)
        }, 1000);
      }
    }

    chunksXhr.onerror = function () { 
      return 0;
    };
  };


  const uploader = new qq.FineUploaderBasic({
    autoUpload: true,
    maxConnections: 2,
    // debug: true,
    request: {
      requireSuccessJson: false,
      endpoint: '/uploader/uploads',
      filenameParam: 'filename',
      params: {
        ...params,
        ...fileParams,
        qqfilename: filename + (extension ? '.' : '') + extension,
      },
      customHeaders: {
        Authorization: `Bearer ${callIfFunction(getJwt)}`,
      },
    },
    retry: {
      enableAuto: true,
      maxAutoAttempts: isDev ? 1 : 5,
    },
    chunking: {
      enabled: true,
      concurrent: {
        enabled: true,
      },
      success: {
        endpoint: '/uploader/chunksdone',
        customHeaders: {
          Authorization: `Bearer ${callIfFunction(getJwt)}`,
        },
      },
      partSize: 2e6,
    },

    callbacks: {
      onAllComplete: (success, failed) => {
        if (failed.length) {
          console.log('Upload completed: ', fileToUpload.name)
        }
      },
      onProgress: (id, name, uploaded, total) => {
        uploader.setCustomHeaders({ Authorization: `Bearer ${callIfFunction(getJwt)}` })
        if (isFirstProgress) {
          isFirstProgress = false
        } else {
          const delta = uploaded - lastProgress
          lastProgress = uploaded
          incrementProgress(delta)
        }
      },
      onCancel: () => {
        reject({ error: FileState.Canceled, progressDelta: -lastProgress })
      },
      onComplete: (id, fileName, responseJSON, xhr) => {
        console.log("Upload completed: ",fileName," Response:",responseJSON,xhr.status)
        if (!responseJSON.success && xhr?.status !== 203) {
          const progressDelta = -lastProgress
          lastProgress = 0
          if (xhr.status > 0) {
            if (xhr.status === 406) {
              reject({ error: FileState.InvalidFilename, progressDelta })
            } else {
              reject({ error: FileState.ProcessingError, progressDelta })
            }
          } else {
            tryReadingFile(fileToUpload).then(
              () => reject({ error: FileState.ConnectionError, progressDelta }),
              () => reject({ error: FileState.FileNotFound, progressDelta })
            )
          }
        } else {
          const progressDelta = fileToUpload.size - lastProgress
          lastProgress = fileToUpload.size
          if (xhr?.status === 203) {
            // Custom response status - Virus found
            uploader.hasBeenQuarantined = true
            resolve({ ...responseJSON, quarantined: true})
          } else {
            if(xhr.status === 202){
              createNewChunksRequest(responseJSON,getJwt,incrementProgress,progressDelta,resolve)
            }else{
              incrementProgress(progressDelta)
              resolve(responseJSON)
            }
          }
        }
      },
      onError: (id,name,error) => {
        const progressDelta = -lastProgress
        if(error.indexOf(name + ' is empty') >= 0){
          reject({ error: FileState.EmptyFile, progressDelta })
          // onFailure(EmptyFile)
        }
        // if (xhr?.status === 201) { 
        //   manualUploader.hasBeenQuarantined = true
        //   onFailure(Quarantined)
        // }
      },
      onUpload: () => {
        if (uploader.hasBeenQuarantined) {
          return Promise.reject()
        }
        return Promise.resolve()
      },
      onAutoRetry: (id, name) => {
        console.log('Auto retry event: ', name)
        uploader.setCustomHeaders({ Authorization: `Bearer ${callIfFunction(getJwt)}` })
      },
      onManualRetry: (id, name) => {
        console.log('Manual retry event: ', name)
        uploader.setCustomHeaders({ Authorization: `Bearer ${callIfFunction(getJwt)}` })
      },
      onUploadChunk: () => {
        uploader.setCustomHeaders({ Authorization: `Bearer ${callIfFunction(getJwt)}` })
      },
    },
  })
  console.log('Upload starting: ', fileToUpload.name)

  uploader.addFiles([fileToUpload])

  return [promise, uploader]
}

export const useUploader = (getJwt, queue, recordManager, getData) => {
  const isActive = useRef()
  const isPaused = useRef(false)
  const smallFileActive = useRef()
  const activeUploads = useRef([])

  const slowMode = getData('slowMode')
  const uploadNextFile = async () => {

  
    if(getData('slowMode')){
      const {
        value: [unlock, hasSlots],
      } = await queue.next()
      
      // Wait for control lock release before processing files
      const {
        value: [unlockControl],
      } = await syncControlLock.next()
      if (isPaused.current == true){
        unlockControl()
        unlock()
        if (hasFile) {
          uploadNextFile()
        }
        return
      }
  
      const isSmallestFile = smallFileActive.current
      let hasFile = false
      try {
        const [fileInstance, recordKey, params] = await recordManager.consumeFile(isSmallestFile)
        if (!fileInstance) {
          recordManager.setIsUploading(false)
          isActive.current = false
          return
        }
        hasFile = true
        if (!isSmallestFile) {
          smallFileActive.current = true
        }
        const [uploaderPromise, uploaderInstance] = createUploader(
          fileInstance,
          params,
          getJwt,
          recordManager.incrementProgress(recordKey, _,fileInstance.file)
        )
        let resolveCompletedUpload = _.identity
        const completeUploadPromise = new Promise(res => {
          resolveCompletedUpload = res
        })
        activeUploads.current = activeUploads.current.concat([
          {
            file: fileInstance.file,
            recordKey,
            uploaderInstance,
            uploadPromise: completeUploadPromise,
          },
        ])
  
        if (hasSlots > 1) {
          uploadNextFile()
        }
        try {
          const response = await uploaderPromise
          const recordUID = await recordManager.uploadSuccess(recordKey, fileInstance, response)
          resolveCompletedUpload(recordUID)
        } catch (e) {
          console.log(e)
          await recordManager.uploadFailure(recordKey, fileInstance, e.error, e.progressDelta)
        } finally {
          resolveCompletedUpload()
          activeUploads.current = _.differenceBy('file', activeUploads.current, [fileInstance])
        }
      } finally {
        // release control lock
        unlockControl()
        // this code below deals with files to be uploaded queue
        unlock()
        if (hasFile) {
          uploadNextFile()
        }
      }

    }else{

      const {
        value: [unlock, hasSlots],
      } = await queue.next()
      if (isPaused.current == true) {
        unlock()
        if (hasFile) {
          uploadNextFile()
        }
        return
      }
  
      const isSmallestFile = smallFileActive.current
      let hasFile = false
      try {
        const [fileInstance, recordKey, params] = await recordManager.consumeFile(isSmallestFile)
        if (!fileInstance) {
          recordManager.setIsUploading(false)
          isActive.current = false
          return
        }
        hasFile = true
        if (!isSmallestFile) {
          smallFileActive.current = true
        }
        const [uploaderPromise, uploaderInstance] = createUploader(
          fileInstance,
          params,
          getJwt,
          recordManager.incrementProgress(recordKey, _,fileInstance.file)
        )
        let resolveCompletedUpload = _.identity
        const completeUploadPromise = new Promise(res => {
          resolveCompletedUpload = res
        })
        activeUploads.current = activeUploads.current.concat([
          {
            file: fileInstance.file,
            recordKey,
            uploaderInstance,
            uploadPromise: completeUploadPromise,
          },
        ])
  
        if (hasSlots) {
          uploadNextFile()
        }
        try {
          const response = await uploaderPromise
          const recordUID = await recordManager.uploadSuccess(recordKey, fileInstance, response)
          resolveCompletedUpload(recordUID)
        } catch (e) {
          console.log(e)
          await recordManager.uploadFailure(recordKey, fileInstance, e.error, e.progressDelta)
        } finally {
          resolveCompletedUpload()
          activeUploads.current = _.differenceBy('file', activeUploads.current, [fileInstance])
        }
      } finally {
        // this code below deals with files to be uploaded queue
        unlock()
        if (hasFile) {
          uploadNextFile()
        }
      }

    }
  }

  return useConstant({
    doWithoutCallingNext: async (cb) => {
      isPaused.current = true
      await cb()
      isPaused.current = false
      uploadNextFile()
    },
    start: () => {
      if (isActive.current) {
        return
      }
      recordManager.setIsUploading(true)
      isActive.current = true
      uploadNextFile()
    },
    isUploading: () => isActive.current,
    cancelFile: async (file) => {
      const [toCancel, toKeep] = _.partition(['file', file], activeUploads.current)
      activeUploads.current = toKeep
      const promises = toCancel.map(_.get('uploadPromise'))
      toCancel.forEach(({ uploaderInstance }) => uploaderInstance.cancelAll())
      const results = await Promise.all(promises)
      return results.find(Boolean)
    },
    cancelRecord: async record => {
      const [toCancel, toKeep] = _.partition(['recordKey', record.key], activeUploads.current)
      activeUploads.current = toKeep
      const promises = toCancel.map(_.get('uploadPromise'))
      toCancel.forEach(({ uploaderInstance }) => uploaderInstance.cancelAll())
      const results = await Promise.all(promises)
      return results.find(Boolean)
    },
    cancelQueue: async () => {
      const toCancel = activeUploads.current
      activeUploads.current = []
      const promises = toCancel.map(_.get('uploadPromise'))
      toCancel.forEach(({ uploaderInstance }) => uploaderInstance.cancelAll())
      const results = await Promise.all(promises)
      return results.find(Boolean)
    },
  })
}
