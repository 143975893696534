import React from 'react'
import { FormattedMessage } from 'react-intl'
import {CAREGIVER, EXECUTOR, LEGALGUARDIAN, OTHER, PARENT, PATIENT, REF_PHYSICIAN} from './ContactRelationType'
import { OTHER_RELATION } from './RemoteConsultationParticipantType'
import {EXPERT} from './UserRoles'

export const PRIMARY = 'PRIMARY'
export const REQUESTED = 'REQUESTED'


const REF_PHYSICIAN_LABEL = "Referring physician"
export const getRelationLabel = (intl, _relationType, otherRelationType) => {
  
  const relationType = [OTHER, OTHER_RELATION, REF_PHYSICIAN, CAREGIVER, PATIENT, EXPERT, LEGALGUARDIAN, EXECUTOR, PARENT].indexOf(_relationType) < 0 ? _relationType?.toLowerCase() : _relationType === OTHER || _relationType === OTHER_RELATION ? (otherRelationType?.toLowerCase() || OTHER) : _relationType
  return intl.formatMessage({
    // defaultMessage: '{relationType, select, REF_PHYSICIAN {Referring physician} CAREGIVER {Caregiver} PATIENT {Patient} EXPERT {Expert} other {{relationType}}}',
    defaultMessage: '{relationType, select, REF_PHYSICIAN {Referring physician} CAREGIVER {Caregiver} PATIENT {Patient} EXPERT {Expert} LEGAL_GUARDIAN {Legal Guardian} EXECUTOR {Executor} PARENT_OF_MINOR {Parent} other {{relationType}}}',
    description: 'contactRelationType'
  },{relationType})
}

export const fullNameToName = (fullName) => {
  if (!fullName || (!fullName.first && !fullName.last)) {
    return ""
  } else {
    return fullName.first + " " + fullName.last
  }
}

export const getContact = (intl, caseRequest, relationKind = PRIMARY) => {
  if (relationKind === PRIMARY) {
    return {
      name: caseRequest.contactDetails.contactRelationType === PATIENT
        ? (caseRequest.patientInfo.firstName + ' ' + (caseRequest.patientInfo?.middleName ? caseRequest.patientInfo?.middleName : '') + ' ' + caseRequest.patientInfo.lastName)
        : fullNameToName(caseRequest.contactDetails.fullName),
      relationType: getRelationLabel(intl, caseRequest.contactDetails.contactRelationType, caseRequest.contactDetails.otherRelationType),
      email: caseRequest.contactDetails.email,
      phone: caseRequest.contactDetails.phoneNumber
    }
  }

  if (relationKind === REQUESTED) {
    return {
      name: caseRequest.contactDetails.requestMedicalRecordsContactRelationType === PATIENT
        ? (caseRequest.patientInfo.firstName + ' ' + caseRequest.patientInfo?.middleName + ' ' + caseRequest.patientInfo.lastName)
        : (caseRequest.contactDetails.requestMedicalRecordsContactRelationType === REF_PHYSICIAN
            ? (fullNameToName(caseRequest.referringPhysician.fullName) || (caseRequest.contactDetails.contactRelationType === REF_PHYSICIAN ? fullNameToName(caseRequest.contactDetails.fullName) : ""))
            : fullNameToName(caseRequest.contactDetails.fullName).trim() != "" 
              ? fullNameToName(caseRequest.contactDetails.fullName)
              : caseRequest?.caseFlags?.requestFlags?.askForPayments && caseRequest.contactDetails.requestMedicalRecordsContactRelationType === null
                ? (caseRequest.patientInfo.firstName + ' ' + caseRequest.patientInfo?.middleName + ' ' + caseRequest.patientInfo.lastName)
                : fullNameToName(caseRequest.contactDetails.fullName)
        ),
      relationType: getRelationLabel(intl, caseRequest.contactDetails.requestMedicalRecordsContactRelationType, caseRequest.contactDetails.otherRelationType) != ''
      ? getRelationLabel(intl, caseRequest.contactDetails.requestMedicalRecordsContactRelationType, caseRequest.contactDetails.otherRelationType)
      : getRelationLabel(intl, caseRequest.contactDetails.contactRelationType, caseRequest.contactDetails.otherRelationType),
      email: caseRequest.contactDetails.requestMedicalRecordsContactRelationType === REF_PHYSICIAN
        ? (caseRequest.referringPhysician.email || (caseRequest.contactDetails.contactRelationType === REF_PHYSICIAN && caseRequest.contactDetails.email))
        : caseRequest.contactDetails.email,
      phone: caseRequest.contactDetails.requestMedicalRecordsContactRelationType === REF_PHYSICIAN
        ? (caseRequest.referringPhysician.phoneNumber || (caseRequest.contactDetails.contactRelationType === REF_PHYSICIAN && caseRequest.contactDetails.phoneNumber))
        : caseRequest.contactDetails.phoneNumber
    }
  }

  if (relationKind === REF_PHYSICIAN) {
    return {
      name: fullNameToName(caseRequest.referringPhysician.fullName),
      relationType: REF_PHYSICIAN_LABEL,
      email: caseRequest.referringPhysician.email,
      phone: caseRequest.referringPhysician.phoneNumber
    }
  }
}
