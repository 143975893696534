import {Button} from '@startlibs/components'
import { FormValue, RadioboxGroup, SimpleRadiobox, TextInput, ToggleCheckbox} from '@startlibs/form'
import React from 'react'
import {CaseRequestCard} from '../CaseRequestCard'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {CASE_DRAFT, CASE_REJECTED, PENDING_ASSIGNMENT, PENDING_REQUEST, UNDER_REVIEW, WAITING_ACCEPTANCE, WAITING_APPROVAL, WAITING_MEDICAL_RECORDS} from '../../enums/CaseState'
import {buildValidation, required} from '../../utils/validation'
import {getJwt} from '../../hooks/useJwt'
import {jwtFormFetcher} from '../../utils/authFetch'
import styled from 'styled-components'
import { EXPERT_FINISHED_CASE, EXPERT_FINISHED_CASE_LABEL, IN_PERSON_VISIT, IN_PERSON_VISIT_LABEL, OFFLINE_EXPERT_REVIEW, OFFLINE_EXPERT_REVIEW_LABEL, OTHER, OTHER_LABEL } from '../../enums/PredefinedReason'
import { InfoBox } from '../../components/InfoBox'
import { lazyProviderInfo } from '../../components/WithProvider'
import { PendingInfo } from '../steps/info/PendingInfo'

const preValidation = buildValidation({
  "predefinedReason": required,
  "details": [(v,k,values) => values.predefinedReason === OTHER && !v.length && required()]
})

const FullWidthRadioBox = styled(SimpleRadiobox)`
  width: 100%;
}`

const ReasonRadioBox = styled(SimpleRadiobox)`
  width: 48%;
}`


export const useSetAsCompletedDialog = (caseRequest,onSuccess) => {

  const providerInfo = lazyProviderInfo.read()

  return useConfirmDialog(
    <ConfirmDialog
      title={"Set case as Completed"}
      action={jwtFormFetcher(getJwt())("/api/admin/setAsCompleted?requestId="+(caseRequest.requestId || caseRequest.id))}
      onSuccess={onSuccess}
      preValidation={preValidation}
      notify={"Case successfully set as Completed."}
      confirm={<Button highlight>{'Set as Completed'}</Button>}
      values={{
        "predefinedReason" : "",
        "details" : ""
      }}
    >
      {/* <p>{caseRequest.state === PENDING_REQUEST ? "The following case request " : "This case "} will no longer be visible under "Active cases":</p> */}
      {/* <p>This is case will be marked as "Reviewed"</p> */}
      <CaseRequestCard viewOnly caseRequest={caseRequest} isResults={true} />
      {/* <p>You will still be able to assign this case to an expert if necessary.</p> */}
      <RadioboxGroup path="predefinedReason" required mandatory framedBoxes highlightSelected horizontal css="margin-bottom:1rem;" 
        label="Select the reason for setting this case as Completed"
        >
          <FullWidthRadioBox
            fieldValue={IN_PERSON_VISIT}
            label={IN_PERSON_VISIT_LABEL}
          />
          <FullWidthRadioBox
            fieldValue={OFFLINE_EXPERT_REVIEW}
            label={OFFLINE_EXPERT_REVIEW_LABEL}
          />
          <FullWidthRadioBox
            fieldValue={OTHER}
            label={OTHER_LABEL}
          />
      </RadioboxGroup>
      <FormValue path="predefinedReason">{reason =>
        <TextInput
          textarea
          autoResize
          mandatory={reason === OTHER}
          path="details"
          label={(reason === OTHER) ? "Please add more details" :"Details"}
        />
      }</FormValue>
      <p>This action will complete the case and move it to "Completed cases".</p>
      <InfoBox lightYellow>
        No report will be generated for this case.
      </InfoBox>
      
    </ConfirmDialog>
  )
}
