import {Button} from '@startlibs/components'
import {useNavigate} from 'react-router'
import {useToggle} from '@startlibs/core'
import React, { useEffect, useState } from 'react'
import _ from 'lodash/fp'
import {CASE_DRAFT, PENDING_ASSIGNMENT} from '../../enums/CaseState'
import {
  CardWithIcon,
  PageContainer,
  RequestStatusIcon
} from '../../components/PageLayout';
import { ExpertProfileCard } from '../experts/components/ExpertProfileCard';
import { Header } from '../../components/Header';
import { InfoBox } from '../../components/InfoBox';
import { PurviewFooter } from '../../components/PurviewFooter';
import {getJwt} from '../../hooks/useJwt'
import {jwtGetFetcher, jwtPostFetcher} from '../../utils/authFetch'
import {useArchiveDialog} from '../hooks/useArchiveDialog'
import {willUseSuspense} from '../../hooks/useSuspense'
import {will} from '@startlibs/utils'

const useAuthSuspense = willUseSuspense((requestId) =>
  jwtGetFetcher(getJwt())(`/api/experts/bycase/${requestId}`)
)

export const RejectedCase = ({caseRequest,setCaseRequest}) => {

  const [expertShare] = useAuthSuspense(caseRequest.requestId)
  const loadingEdit = useToggle()
  const navigate = useNavigate()
  const loadingReassign = useToggle()
  const [archiveReasons, setArchiveReasons] = useState([])
  
  useEffect(() => {
    jwtGetFetcher(getJwt())('/api/admin/archiveReasons')
      .then(response => {
        setArchiveReasons(response)
      })
      .catch(err => {
        console.log(err)
      })
  },[])

  const confirmReject = useArchiveDialog(caseRequest,will(navigate,"/admin"),archiveReasons)

  return <><PageContainer>
    <Header caseRequest={caseRequest} />
    <CardWithIcon>
      <RequestStatusIcon icon="unavailable"/>
      <h3>Action needed: the expert below has declined the case.</h3>
      <ExpertProfileCard expert={expertShare.expert}/>
      {expertShare.info.declineReason &&
      <>
        <p>The assigned expert is has declined the case due to the following reason:</p>
        <InfoBox>{expertShare.info.declineReason}</InfoBox>
      </>}
      <div css="margin-top:2rem">
        <p>How would you like to procceed?</p>
        <Button
          isLoading={loadingEdit.isOpen} highlight
          onClick={() => loadingEdit.wait(jwtPostFetcher(getJwt())("/api/admin/rejectedToDraft?requestId=" + caseRequest.requestId)
            .then(() => setCaseRequest(_.set('state',CASE_DRAFT)))
            .then(() => navigate("../records")))}
        >Edit case and reassign</Button>
        <Button
          isLoading={loadingReassign.isOpen}
          onClick={() => loadingReassign.wait(jwtPostFetcher(getJwt())("/api/admin/rejectedToPendingAssignement?requestId=" + caseRequest.requestId)
            .then(() => setCaseRequest(_.set('state',PENDING_ASSIGNMENT)))
            .then(() => navigate("../expert")))}
        >Reassign case without editing</Button>
        <Button onClick={confirmReject}>Archive case</Button>
      </div>
    </CardWithIcon>
  </PageContainer>
  <PurviewFooter />
  </>
}
