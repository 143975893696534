import { Button, Icon, Loading } from '@startlibs/components';
import { getColor } from '@startlibs/utils';
import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash/fp'
import styled, { css } from 'styled-components';
import {CASE_DRAFT, PENDING_REQUEST, WAITING_MEDICAL_RECORDS} from '../../enums/CaseState';
import { Card, PageContainer, PageFooter } from '../../components/PageLayout';
import {Header} from '../../components/Header'
import {InfoBox} from '../../components/InfoBox';
import {PurviewFooter} from '../../components/PurviewFooter'
import {isDigitalPaymentPending} from '../../request/utils'
import {useConfirmSubmitUpdate} from '../utils/useConfirmSubmitUpdate'
import { buildValidation, required } from '../../utils/validation';
import { getJwt } from '../../hooks/useJwt';
import { jwtGetFetcher, jwtPostFetcher } from '../../utils/authFetch';
import { ThreadCard } from '../../admin/steps/ThreadCard';
import { Errors, FormValue, TextInput, WithForm } from '@startlibs/form';
import { setNotification } from '../../components/Notifications';

const RequestCodeBox = styled.div`
  background: ${getColor('gray240')};
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 0.75rem;
  font-size: 16px;
  min-height: 4rem;
  width: 100%;
  font-size: 16px;
  max-width: 33rem;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    margin: 0.5rem 1rem 0.5rem 0;
  }
  ${Button} {
    margin: 0.5rem 0;
    justify-self: flex-end;
    flex-shrink: 0;
  }
`

const RequestSubmittedPage = styled(PageContainer)`
  @media print {
    ${Button} {
      display: none;
    }
    ${RequestCodeBox} {
      max-width: auto;
      display: inline-block;
      width: auto;
    }
  }
`

const UpdatesRequestedCard = styled(Card)`
  .buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.25rem;
    ${Button} {
      margin: 0.25rem;
      flex-basis: 32%;
      justify-content: left;
      min-width: 190px;
    }
  }
`

const ReturnButton = styled(Button) `
  box-shadow: none;
  color: ${getColor('gray60')};
  font-weight: bold;
  margin-bottom: 1.5rem;
  ${Icon} {
    font-size: 20px;
  }
`

const UpdateButton = styled(Button.Link)`
  ${props => props.checked && props.outline && css`
    color: ${getColor('gray150')};
    background: rgba(0,0,0,0.025);
    ${Icon} {
      color: ${getColor('gray150')};
    }
    :before {
      border: 1px solid rgba(0,0,0,0.15);
    }
  `}
`

export const PatientUpdatesRequested = ({caseRequest,setCaseRequest}) => {


  // NEW PARTS:
  
  const VIEW = 'VIEW';
  const NEW = 'NEW';
  const REPLY = 'REPLY';
  
  const [shouldReloadData, setShouldReloadData] = useState(false)
  const [threads, setThreads] = useState([])
  const [isLoadingCollab, setIsLoadingCollab] = useState(true)
  const [isSending, setIsSending] = useState(false)
  const [mode, setMode] = useState(VIEW)
  const [replyId, setReplyId] = useState(null)
  const formRef = useRef()
  const replyAction = (id) => {
    setReplyId(id)
    setMode(REPLY)
  }

  const preValidation = buildValidation({
    'message': [
      (v) => !v && required(),
      (v) => v?.length > 3000 && 'Message: Maximum character limit of 3000 reached.'
    ]
  })
  
  const getCollaborations = () => {
    setIsLoadingCollab(true)
    jwtGetFetcher(getJwt())('/api/collaborations',{requestId : caseRequest.requestId})
    .then((response) => {
      setThreads(response)
    })
    .catch((error) => {
      console.log(error)
    })  
    .finally(() => {
      setIsLoadingCollab(false)
    })
  }

  useEffect(() => {
    getCollaborations()
  },[])

  useEffect(() => {
    if(shouldReloadData){
      getCollaborations()
      setShouldReloadData(false)
    }
  },[shouldReloadData])
  
  const submit = useConfirmSubmitUpdate(
    caseRequest,
    setCaseRequest,
    () => {
      setCaseRequest(_.flow(
        _.set('state', CASE_DRAFT),
        _.set('recentlySubmitted', true),
        _.set('caseFlags.requestFlags',{}),
        _.set('caseFlags.patientDoneFlags',{})
      ))
    }
  )

  const {
    askedPatientDetails,
    askedMedicalRelease,
    askedRecords,
    askForPayments,
    askForServiceTerms
  } = caseRequest.caseFlags.requestFlags

  const donePayments = !caseRequest.payments.find(isDigitalPaymentPending)
  const doneTerms = caseRequest.acceptanceInfo.acceptedTerms
  const {
    doneRecords,
    donePatientDetails,
    doneMedicalReleases
  } = caseRequest.caseFlags.patientDoneFlags || {}

  const allCompleted =
    (!askedMedicalRelease || doneMedicalReleases) &&
    (!askedPatientDetails || donePatientDetails) &&
    (!askedRecords || doneRecords) &&
    (!askForServiceTerms || doneTerms) &&
    (!askForPayments || donePayments)

  const askedOnlyPayment = (!askedRecords && !askedMedicalRelease && !askedPatientDetails && !askForServiceTerms) && askForPayments

  const isPending = caseRequest.state === PENDING_REQUEST
  const isWaitingRecords = caseRequest.state === WAITING_MEDICAL_RECORDS

  const atLeastOneRequest = askForServiceTerms 
  || (askedPatientDetails && (isWaitingRecords || isPending)) 
  || askedMedicalRelease
  || (askedRecords && (isWaitingRecords || isPending))
  || askForPayments

  return <>
    <RequestSubmittedPage>
      <Header
        title="Status"
      />
      <UpdatesRequestedCard>
          <h3>
            Please take the following actions
          </h3>
          <p css="margin: -0.5rem 0 1.5rem;">{
            askedOnlyPayment
              ? "Please provide the requested payment. The institution will be automatically notified once it is done."
              // : "Once you are done, click 'Submit' to notify the institution."
              : 'When finished, press "All complete, submit updates" below'
          }</p>
          {
            !caseRequest?.requestCollaborationId && caseRequest.customMessages?.caseAcceptance &&
            <InfoBox css="margin-bottom:1.5rem;">{caseRequest.customMessages?.caseAcceptance}</InfoBox>
          }
          
          {isLoadingCollab && caseRequest?.requestCollaborationId && <Loading css="margin: 6rem auto" />}
          {mode === VIEW && !isLoadingCollab && threads && threads.length > 0 && caseRequest?.requestCollaborationId && 
            threads.filter((thread => thread.id === caseRequest.requestCollaborationId)).map((thread) => {
            return <ThreadCard 
              thread={thread} 
              key={thread.id}
              caseRequest={caseRequest} 
              replyAction={replyAction}
              isReplying={false}
            />
          })}
          { mode === REPLY && <>
            <ReturnButton 
              noShadow rounded color="gray240" icon="return"
              onClick={() => {
              setMode(VIEW)
              setReplyId(null)
            }}>View all messages</ReturnButton>

            {replyId && replyId > 0 && replyId != null && <ThreadCard 
              thread={threads.find((thread) => thread.id === replyId)} 
              caseRequest={caseRequest} 
              isReplying/>}
            <WithForm
              preValidation={preValidation}
              action={(values) => {
                setIsSending(true)
                return jwtPostFetcher(getJwt())(`/api/collaborations/${replyId}/messages?requestId=${caseRequest.requestId}`, {...values} ,{method: 'POST'})
              }}
              onSuccess={(values) => {
                setNotification("Message sent")
                setIsSending(false)
                setMode(VIEW)
                setShouldReloadData(true) 
              }}
              ref={formRef}
              onFailure={() => {
                setIsSending(false)
              }}
            >{form => 
              <>
                <TextInput
                  textarea
                  autoResize
                  path="message"
                  mandatory={true}
                  placeholder="Type your message here..."
                  label={'Reply message'}
                  onChange={(e) => {
                    if(e.length > 3000){
                      form.addErrors({message: 'Message: Maximum character limit of 3000 reached.'})
                    }else{
                      form.clearErrors('message')
                    }
                  }}
                  bellowDescText={
                    <FormValue path="message">{message => {
                      if(message){
                        return <span style={{ 
                          display: 'block',
                          width: '100%',
                          textAlign: 'right'
                        }}>{message?.length+"/3000"}</span>
                      }
                    }}</FormValue>
                  }
                />
                <Errors css="margin-bottom: 1rem;"/>
                <div css="text-align: right">
                  <Button
                    highlight
                    type="submit"
                    isLoading={isSending}
                    icon="email"
                  >
                    Send message
                  </Button>
                </div>
              </>
            }</WithForm>
          </>}
          {atLeastOneRequest && <p css="margin: 1.5rem 0 0.5rem;"><b>Actions requested:</b></p>}
          <div className="buttons-wrapper">
            {
              askForServiceTerms &&
              <UpdateButton to="/patient/agreements"
                            outline={doneTerms}
                            checked={doneTerms}
                            icon={doneTerms ? "check" : "sign-doc"}>
                Agree to terms
              </UpdateButton>
            }
            {
              askedPatientDetails && (isWaitingRecords || isPending) &&
              <UpdateButton to="/patient/details"
                outline={donePatientDetails}
                checked={donePatientDetails}
                icon={donePatientDetails ? "check" : "user-line"}>
                Edit contact details
              </UpdateButton>
            }
            {
              askedMedicalRelease &&
              <UpdateButton to="/patient/release"
                outline={doneMedicalReleases}
                checked={doneMedicalReleases}
                icon={doneMedicalReleases ? "check" : "edit-box"}>
                Locate medical records
              </UpdateButton>
            }
            {
              askedRecords && (isWaitingRecords || isPending) &&
              <UpdateButton to="/patient/records"
                outline={doneRecords}
                checked={doneRecords}
                icon={doneRecords ? "check" : "dicom-no-text"}>
                Medical records
              </UpdateButton>
            }
            {
              askForPayments &&
              <UpdateButton highlight={askedOnlyPayment}
                to="/patient/payments"
                outline={donePayments}
                checked={donePayments}
                icon={donePayments ? "check" : "payment"}>
                Provide payment
              </UpdateButton>
            }
          </div>
      </UpdatesRequestedCard>
      <PageFooter>
        <Button.Link to="/patient/overview">Go to overview</Button.Link>
        {
          !askedOnlyPayment &&
          <Button disabled={!allCompleted} onClick={submit} highlight>All complete, submit updates</Button>
        }
      </PageFooter>
    </RequestSubmittedPage>
    <PurviewFooter/>
  </>
}
