import { useToggle } from '@startlibs/core';
import _ from 'lodash/fp';
import React, { createContext, useEffect, useState } from 'react';

import { jwtPostFetcher } from './authFetch';
import { getJwt } from '../hooks/useJwt';


export const DicomRouterContext = createContext();

export const DicomRouterProvider = ({ children }) => {

    const [isSelectingStudiesToRoute, setIsSelectingStudiesToRoute] = useState(false)
    const [selectedStudiesToRoute, setSelectedStudiesToRoute] = useState([])
    const [routingDicomsList, setRoutingDicomsList] = useState([])
    const [numberOfSelectedStudies, setNumberOfSelectedStudies] = useState(0) // this ensures updates acrros table and panels
    const [dicomRouterJwt, setDicomRouterJwt] = useState(null)
    const dicomsRoutingPanelToggle = useToggle()

    return (
        <DicomRouterContext.Provider value={{ 
            isSelectingStudiesToRoute, setIsSelectingStudiesToRoute,
            selectedStudiesToRoute, setSelectedStudiesToRoute,
            routingDicomsList, setRoutingDicomsList,
            setNumberOfSelectedStudies,
            dicomsRoutingPanelToggle,
            dicomRouterJwt,
            setDicomRouterJwt
        }}>
            {children}
        </DicomRouterContext.Provider>
    );
};