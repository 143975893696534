import React, {useRef} from 'react'
import styled from 'styled-components/macro'
import {UploaderManager} from "../service/UploaderManager";
import {DropFilesBox} from "../DropFilesBox";
import {LinkVivaStudies} from "./dialogs/LinkVivaStudies";
import {UploaderConfigProvider} from "../service/UploaderConfigContext";
import {Button, Dialog, willUseIsSticky} from "@startlibs/components";
import {ActivityLog} from "./dialogs/ActivityLog";
import {UploaderHeader} from "./UploaderHeader";
import {Errors, useProvideErrors} from "@startlibs/form";
import {ProcessingBox} from "./ProcessingBox";
import {UploaderHeading} from "./styled/UploaderStyled";
import {FileNotFoundRetry} from "./FileNotFoundRetry";
import {UnidentifiedFilesWindow} from "./dialogs/UnidentifiedFilesWindow";
import {Records} from "./Records";
import {CancelUploadsConfirmation, CancelUploadsDialog} from "./dialogs/CancelUploadsDialog";
import {UploadingFeedback} from "./UploadingFeedback";
import {DeleteRecordDialog} from "./dialogs/DeleteRecordDialog";
import {PdfSplitPopup} from "./pdf/PdfSplitPopup";
import { useDoAction } from '../service/hooks/useDoAction';
import { UIAction } from '../service/UIAction';
import { CancelDicomDialog } from './dialogs/CancelDicomDialog';
import { DicomDetailsDialog } from './dialogs/DicomDetailsDialog';
import { ImportNewCareQualityDialog } from './dialogs/ImportNewCareQualityDialog';

styled

const [Sentinel, useIsSticky] = willUseIsSticky()

export const Uploader = React.forwardRef((
  {
    jwt,
    appJwt,

    allowDownload = true,
    allowDownloadMedicalImages = true,
    withViewAllButton,
    persistRecord,
    persistRecordInfo,
    persistGroups,
    removePersistedRecord,
    removeSelectedRecords,
    disabled,
    listMode,
    medicalRecords,
    setNotification,
    parallelFiles = 2,
    isApp,
    groups,
    withoutDelete,
    role,
    hasMRN,
    canMoveGroup = (group, groups) => groups.length > 2,
    autoGrouping,
    allowReorder,
    canAddGroup,
    apiEndpoints = {
      dicomViewer: undefined,
      nonDicomViewer: undefined,
      shortTokenUrl: undefined,
      downloadRecord: undefined,
      loadDownloadRecord: undefined,
      storageHost: undefined,
      pdfIframeSrc: '',
      downloadFiles: undefined,
      viewFileSrc: undefined,
    },
    patientName = '',
    linkedStudies,
    setLinkedStudies,
    canOpenWorklist,
    providerJwt,
    worklistViewerJwt,
    mode,
    setIsUploading = () => false,
    setToBeUploaded = () => 0,
    setTotal = () => 0,
    requestId,
    total,
    refreshUrl,
    caseId,
    canAddHiddenFileToCase = true,
    locations = [],
    adminUserEmail = '',
    careQualityPatient,
    carequalityEnabled = false,
    dicomRouterOrigin = '',
    canRouteDicoms = false,
    canCreatePacs = false,
    showHiddenRecords = true,
  },ref) => {
  const [isStickied] = useIsSticky()
  const [ErrorProvider, errors, {setError}] = useProvideErrors()
  const cancelUploadsConfirmationRef = useRef()

  React.useImperativeHandle(ref, () => ({
    confirm: () => cancelUploadsConfirmationRef.current.confirm()
  }), [])

  return <UploaderConfigProvider
    jwt={jwt}
    appJwt={appJwt}
    providerJwt={providerJwt}
    worklistViewerJwt={worklistViewerJwt}
    requestId={requestId}
    caseId={caseId}
    careQualityPatient={careQualityPatient}
    carequalityEnabled={carequalityEnabled}

    mode={mode}
    isApp={isApp}
    role={role}
    listMode={listMode}
    disabled={disabled}
    canOpenWorklist={canOpenWorklist}
    withViewAllButton={withViewAllButton}
    withoutDelete={withoutDelete}
    allowDownload={allowDownload}
    allowDownloadMedicalImages={allowDownloadMedicalImages}
    canAddHiddenFileToCase={canAddHiddenFileToCase}

    canMoveGroup={canMoveGroup}
    autoGrouping={canAddGroup}
    allowReorder={allowReorder}
    canAddGroup={canAddGroup}
    persistGroups={persistGroups}

    apiEndpoints={apiEndpoints}
    refreshUrl={refreshUrl}
    patientName={patientName}
    linkedStudies={linkedStudies}

    setNotification={setNotification}
    setLinkedStudies={setLinkedStudies}

    removePersistedRecord={removePersistedRecord}
    removeSelectedRecords={removeSelectedRecords}
    persistRecordInfo={persistRecordInfo}

    setToBeUploaded={setToBeUploaded}
    setIsUploading={setIsUploading}
    setTotal={setTotal}
    locations={locations}
    adminUserEmail={adminUserEmail}
    dicomRouterOrigin={dicomRouterOrigin}
    canRouteDicoms={canRouteDicoms}
    canCreatePacs={canCreatePacs}
    showHiddenRecords={showHiddenRecords}
  >
    <Sentinel css="margin-top:-7.25rem;"/>
    <UploaderManager setError={setError} groups={groups} medicalRecords={medicalRecords}>{(data) => {
      // console.log(data)
      return <>
        <div hidden={data.dialogs.activityLog || data.dialogs.importStudies}>
          <UploaderHeading
            isStickied={isStickied}
            listMode={listMode}
            uploaderDisabled={disabled}
          >
            <UploaderHeader/>
            {!disabled && <DropFilesBox/>}
            <div style={{marginTop: "10px"}}><ErrorProvider value={errors}><Errors/></ErrorProvider></div>
            <ProcessingBox/>
            {/* {data?.slowMode ? <p>Slow mode</p> : <p>Normal mode</p>} */}
            <FileNotFoundRetry/>
          </UploaderHeading>

          <Records />
          <UploadingFeedback/>
        </div>
        {
          data.dialogs.unidentifiedFiles && <UnidentifiedFilesWindow/>
        }
        {
          data.dialogs.activityLog && <ActivityLog/>
        }
        {
          data.dialogs.importStudies && <LinkVivaStudies/>
        }
        {
          data.dialogs.cancelUploads && <CancelUploadsDialog/>
        }
        {
          data.dialogs.deleteRecord && <DeleteRecordDialog/>
        }
        {
          data.dialogs.splitPDF && <PdfSplitPopup />
        }
        {
          data.dialogs.importNewCareQuality && <ImportNewCareQualityDialog data={data} setNotification={setNotification}/>
        }
        {
          data.dialogs.cancelDialog && <CancelDicomDialog data={data} />
        }
        {
          data.dialogs.dicomDetailsDialog && <DicomDetailsDialog data={data} />
        }
        <CancelUploadsConfirmation ref={cancelUploadsConfirmationRef} />
      </>}
    }</UploaderManager>
  </UploaderConfigProvider>
})