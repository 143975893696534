import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';


export const AreaChartMultiple = ({specialty, data, groupBy}) => {

    const chartRef = useRef();
    const MAX_MONTHS = 13;

    useEffect(() => {

        const parsedData = data.map(d => ({
            ...d,
            date: d3.utcParse("%Y/%m")(d.period)
        }));
        const width = 320;
        const height = 220;
        const marginTop = 20;
        const marginRight = 20;
        const marginBottom = 100;
        const marginLeft = 10;

        const x = groupBy == "MONTH" && data.length > MAX_MONTHS 
        ? d3.scaleUtc()
            .domain(d3.extent(parsedData, d => d.date)) // Get the min and max date
            .range([marginLeft, width - marginRight])
        : d3.scaleBand()
            .domain(data.map(d => d.period))
            .range([marginLeft, width - marginRight])
            .padding(0.1);
        
        const y = d3.scaleLinear()
            .domain([0, d3.max(data, d => Math.max(d.completed, d.archived, d.total))])
            .range([height - marginBottom, marginTop]);

        const startDate = d3.min(parsedData, d => d.date);
        const endDate = d3.max(parsedData, d => d.date);
        const intermediateDates = d3.timeMonths(startDate, endDate, 3);
        let tickValues = [startDate];
        for (let i = 0; i < intermediateDates.length; i++) {
            tickValues.push(intermediateDates[i]);
        }
        tickValues.push(endDate);
        
        const areaCompletedDate = d3.area()
            .x(d => x(d.date))
            .y0(y(0))
            .y1(d => y(d.completed))
            .curve(d3.curveMonotoneX);

        const areaArchivedDate = d3.area()
            .x(d => x(d.date))
            .y0(y(0))
            .y1(d => y(d.archived))
            .curve(d3.curveMonotoneX);
        
        const areaTotalDate = d3.area()
            .x(d => x(d.date))
            .y0(y(0))
            .y1(d => y(d.total))
            .curve(d3.curveMonotoneX);

        const areaCompleted = d3.area()
            .x(d => x(d.period) + x.bandwidth() / 2)
            .y0(y(0))
            .y1(d => y(d.completed))
            .curve(d3.curveMonotoneX);

        const areaArchived = d3.area()
            .x(d => x(d.period) + x.bandwidth() / 2)
            .y0(y(0))
            .y1(d => y(d.archived))
            .curve(d3.curveMonotoneX);

        const areaTotal = d3.area()
            .x(d => x(d.period) + x.bandwidth() / 2)
            .y0(y(0))
            .y1(d => y(d.total))
            .curve(d3.curveMonotoneX);
        
        const svg = d3.create("svg")
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, height]);

        svg.append("path")
            .datum(groupBy == "MONTH" && data.length > MAX_MONTHS  ? parsedData : data)
            // .attr("fill", "steelblue")
            .attr("fill", "#015587")
            .attr("fill-opacity", 0.75)
            .attr("d", groupBy == "MONTH" && data.length > MAX_MONTHS ? areaCompletedDate : areaCompleted(data));

        svg.append("path")
            .datum(groupBy == "MONTH" && data.length > MAX_MONTHS  ? parsedData : data)
            // .attr("fill", "lightblue")
            .attr("fill", "#53bce7")
            .attr("fill-opacity", 0.75)
            .attr("d", groupBy == "MONTH" && data.length > MAX_MONTHS ? areaArchivedDate : areaArchived(data));

        svg.append("path")
            .datum(groupBy == "MONTH" && data.length > MAX_MONTHS  ? parsedData : data)
            .attr("stroke", "lightgray")
            .attr("fill", "lightgray")
            .attr("fill-opacity", 0.25)
            // .attr("d", lineTotal(data));
            .attr("d", groupBy == "MONTH" && data.length > MAX_MONTHS ? areaTotalDate : areaTotal(data));

        svg.append("g")
            .attr("transform", `translate(0,${height - marginBottom})`)
            // .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0));
            .call(groupBy == "MONTH" && data.length > MAX_MONTHS
                ? d3.axisBottom(x).tickValues(tickValues).tickFormat(d3.utcFormat("%Y/%m"))
                : d3.axisBottom(x).ticks(data.lenght).tickSizeOuter(0)
            )
            .selectAll("text")
            .attr("transform", "rotate(60) translate(8,-6)")
            .attr("text-anchor", "start");

                // .attr("transform", `translate(8,${height})`)
                // .call(d3.axisBottom(x).tickFormat(d => d).tickSizeOuter(0))
                // // rotate x axis text 90 degrees
                // .selectAll("text")
                // .attr("transform", "rotate(60)")
                // .attr("text-anchor", "start");

        // Add the top x-axis and remove the axis line
        // svg.append("g")
        //     .attr("transform", `translate(0,${marginTop})`) // Position at the top
        //     .call(d3.axisTop(xTop).tickSizeOuter(0).tickSizeInner(0).ticks(2))
        //     .call(g => g.selectAll(".tick line").remove()) // Remove tick lines
        //     .call(g => g.select(".domain").remove()); // Remove the axis line (domain)

        // Add the top x-axis and adjust the position of tick labels
        // svg.append("g")
        //     .attr("transform", `translate(0,${marginTop})`) // Position at the top
        //     .call(d3.axisTop(xTop).tickSizeOuter(0).tickSizeInner(0).ticks(2))
        //     .call(g => g.selectAll(".tick line").remove()) // Remove tick lines
        //     .call(g => g.select(".domain").remove()) // Remove the axis line (domain)
            // .selectAll(".tick text")  // Select all tick labels
            // .attr("transform", "translate(0, -10)"); // Move the labels closer to the chart

        // if (xTop && Array.isArray(xTop)) {
        // const topAxisGroup = svg.append("g")
        //     .attr("transform", `translate(0,${marginTop})`);

        // topAxisGroup.selectAll("text")
        //     .data(xTop.domain())
        //     .enter()
        //     .append("text")
        //     .attr("x", d => xTop(d) + marginLeft)
        //     // .attr("x", marginLeft) // Align text to the left margin
        //     .attr("y", d => y(d) - 30) // Position based on y-scale, adjust -5 for vertical offset
        //     .text(d => d)
        //     .attr("text-anchor", "start")
        //     .attr("alignment-baseline", "middle");

        // Horizontal Lines
        // svg.append("g")
        //     .attr("transform", `translate(${marginLeft},0)`)
        //     .call(d3.axisLeft(y).ticks(height / 40))
        //     .call(g => g.select(".domain").remove())
        //     .call(g => g.selectAll(".tick line").clone()
        //         .attr("x2", width - marginLeft - marginRight)
        //         .attr("stroke-opacity", 0.1));

        // Append the SVG to the ref'd element
        d3.select(chartRef.current).append(() => svg.node());

        const labelYOffset = 8; // Adjust as needed to avoid labels cutting into the area path

        data.forEach(d => {
            const label = d.period;

            // const xPos = x(label) + x.bandwidth() / 2;
            const xPos = groupBy == "MONTH" && data.length > MAX_MONTHS
             ? x(d3.utcParse("%Y/%m")(d.period)) 
             : x(label) + x.bandwidth() / 2;
     
            const yPos = y(d.total) - labelYOffset;

            svg.append("text")
                .attr("x", xPos)
                .attr("y", yPos)
                .text(d.total) // Displaying the formatted fee as the label
                .attr("text-anchor", "middle") // This centers the label horizontally on its corresponding band
                .attr("alignment-baseline", "bottom") // Ensures the text is aligned such that its bottom edge is at yPos
                .style("fill", "#000") // Optional: Adjust the text color as needed
                .style("font-size", "12px"); // Adjust the font size as needed to fit the chart
        });

        // return svg.node();
        
    }, []);

    return (
        <div>
            <div style={{textAlign: 'center'}}><b>{specialty}</b></div>
            <div style={{textAlign: 'center'}}>{data.reduce((acc, curr) => acc + curr.total, 0)} Requests, {data.reduce((acc, curr) => acc + curr.completed, 0)} Completed, {data.reduce((acc, curr) => acc + curr.archived, 0)} Archived</div>
            <div ref={chartRef}></div>
        </div>
    )
}