import React from 'react'

export const Waiting = "Waiting"
export const Uploading = "Uploading"
export const Uploaded = "Uploaded"
export const ConnectionError = "ConnectionError"
export const FileNotFound = "FileNotFound"
export const InvalidFilename = "InvalidFilename"
export const ProcessingError = "ProcessingError"
export const Quarantined = "Quarantined"
export const Shortlisted = "Shortlisted"
export const EmptyFile  = "EmptyFile"
export const Canceled = "Canceled"
export const Deleted = "Deleted"
export const Linked = "Imported from worklist"

export const getFileStateLabel = (state) => ({
  [Quarantined]:"Harmful file",
  [ProcessingError]: "Processing error",
  [FileNotFound]: "File not found",
  [InvalidFilename]: "Invalid filename",
  [ConnectionError]: "Connection error",
  [EmptyFile]: "File is empty",
})[state] || state

const NO_RETRY_FAILURES = [InvalidFilename,Quarantined, EmptyFile]
export const IS_RETRY_POSSIBLE = ({failed,quarantined}) => NO_RETRY_FAILURES.indexOf(failed) <0 && !quarantined
export const IS_QUARANTINED = ({failed,quarantined}) => failed===Quarantined || quarantined

export const IS_FAILURE = (state) => [
  ConnectionError,
  FileNotFound,
  InvalidFilename,
  ProcessingError,
  Quarantined,
  Shortlisted,
  EmptyFile,
].indexOf(state) >= 0

