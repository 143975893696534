import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export const BarChartCasesByState = ({data, useFullName = false, maxLimit = 300, hoveredState, setHoveredState}) => {
    
    // Convert data into an array of objects
    // let processedData = data.map(([state, value]) => ({ state, value }));
    let processedData = data.map(([state, value, fullName]) => ({ state, value, fullName}));
    // Sort the data by value in descending order
    processedData.sort((a, b) => b.value - a.value)
    processedData = processedData.filter((d, i) => i < maxLimit);

    const chartRef = useRef();

    useEffect(() => {

        // Clear the existing chart before creating a new one
        d3.select(chartRef.current).selectAll("*").remove();

        const Chart = () => {
            const margin = { top: 20, right: 30, bottom: 92, left: 60 };
            const width = 620 - margin.left - margin.right;
            const height = 480 - margin.top - margin.bottom;

            const x = d3.scaleBand()
                .range([0, width])
                .padding(0.1)
                .domain(processedData.map(d => d.state));

            const y = d3.scaleLinear()
                .range([height, 0])
                .domain([0, d3.max(processedData, d => d.value)]);

            const svg = d3.select(chartRef.current)
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .attr("style", "max-width: 100%;")
                .append("g")
                .attr("viewBox", [0, 0, width + margin.left + margin.right, height + margin.top + margin.bottom])
                .attr("transform", `translate(${margin.left},40)`)
                
                
                // .attr("width", 975)
                // .attr("height", 800)
                // .attr("viewBox", [0, 0, 975, 800])
                // .attr("style", "max-width: 100%; height: auto;");
                // .append("g")

            svg.selectAll(".bar")
                .data(processedData)
                .join("rect")
                .attr("class", "bar")
                .attr("fill", d => d.state === hoveredState ? "#ff9900" : "steelblue") // Conditionally set initial fill based on hoveredState
                .attr("x", d => x(d.state))
                .attr("width", x.bandwidth())
                .attr("y", d => y(d.value))
                .attr("height", d => height - y(d.value))
                .on("" , function(event, d) {
                    
                    d => d.state === hoveredState 
                        ? svg.append("text")
                            .attr("class", "bar-text")
                            .attr("x", x(d.state) + x.bandwidth() / 2)
                            // .attr("y", y(d.value) - 20) // Adjust this value to position the text above the bar
                            .attr("y", height) // Position text at the bottom of the SVG
                            .attr("dy", "2.5em") // Slightly offset below the bar for readability
                            .attr("text-anchor", "middle")
                            .text(d.fullName)
                        : svg.selectAll(".bar-text").remove()
                })
                
                .on("mouseover", function(event, d) {
                    setHoveredState(hoveredState => {
                        return d.state
                    })
                    d3.select(this).attr("fill", "#ff9900");
                    svg.append("text")
                        .attr("class", "bar-text")
                        .attr("x", x(d.state) + x.bandwidth() / 2)
                        // .attr("y", y(d.value) - 20) // Adjust this value to position the text above the bar
                        .attr("y", height) // Position text at the bottom of the SVG
                        .attr("dy", "2.5em") // Slightly offset below the bar for readability
                        .attr("text-anchor", "middle")
                        .text(d.fullName);
                        
                })
                .on("mouseout", function(event, d) {
                    // On mouseout, revert fill color based on hoveredState
                    d3.select(this).attr("fill", d.state === hoveredState ? "#ff9900" : "steelblue");
                    svg.selectAll(".bar-text").remove();
                    setHoveredState(null)
                });
            // Append value text on top of each bar
            svg.selectAll(".text")
                .data(processedData)
                .join("text")
                .attr("class", "bar-value")
                .attr("x", d => x(d.state) + x.bandwidth() / 2)
                .attr("y", d => y(d.value) - 5) // Adjust this value to position the text above the bar
                .attr("text-anchor", "middle")
                .text(d => d.value);

            // Find the matching data entry for hoveredState
            const matchingData = processedData.find(d => d.state === hoveredState);

            // Append or update a text element to display the fullName
            // Ensure the container has enough space for this text
            // svg.select(".fullNameText").remove(); // Remove the existing text to avoid duplicates
            // svg.append("text")
            //     .attr("class", "fullNameText")
            //     .attr("x", width / 2) // Center the text
            //     .attr("y", height + margin.bottom) // Position below the chart
            //     .attr("text-anchor", "middle")
            //     .text(matchingData ? matchingData.fullName : ''); // Display fullName if hoveredState matches

            if(matchingData){
                svg.append("text")
                    .attr("class", "bar-text")
                    .attr("x", x(matchingData.state) + x.bandwidth() / 2)
                    // .attr("y", y(d.value) - 20) // Adjust this value to position the text above the bar
                    .attr("y", height) // Position text at the bottom of the SVG
                    .attr("dy", "2.5em") // Slightly offset below the bar for readability
                    .attr("text-anchor", "middle")
                    .text(matchingData.fullName);
            }

            svg.append("g")
                .call(d3.axisLeft(y));

            if (useFullName){
                svg.append("g")
                    .attr("transform", `translate(8,${height})`)
                    .call(d3.axisBottom(x).tickFormat(d => d).tickSizeOuter(0))
                    // rotate x axis text 90 degrees
                    .selectAll("text")
                    .attr("transform", "rotate(60)")
                    .attr("text-anchor", "start");
            }else{
                svg.append("g")
                    .attr("transform", `translate(0,${height})`)
                    .call(d3.axisBottom(x).tickFormat(d => d).tickSizeOuter(0))
            }
            
            

        };

        Chart();
    }, [hoveredState]);

    return (
        <>
            <div ref={chartRef}></div>
        </>
    );
}