import React, {useContext} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {UploaderConfigContext} from "../../service/UploaderConfigContext";
import {useDoAction} from "../../service/hooks/useDoAction";
import {usePopupToggle, useToggle} from "@startlibs/core";
import {useUIDataSelector} from "../../service/hooks/useUIDataSelector";
import {useIsUploading, useUploadQueueSelector} from "../../service/hooks/useUploadQueueSelector";
import {useRecordsSelector} from "../../service/hooks/useRecords";
import {isOtherKnowExtension} from "../../dicom/FileParser";
import {isOfRecordClass} from "../../service/utils/recordUtils";
import {Pathology, Radiology} from "../../enums/RecordClass";
import {useUngroup} from "../hooks/useUngroup";
import {GroupAction} from "../../service/GroupAction";
import {UIAction} from "../../service/UIAction";
import {isMinified} from "../FileinputBox2";
import {Button, Icon, Loading, Popup} from "@startlibs/components";
import {TextInput} from "@startlibs/form";
import {customTheme, getColor} from "@startlibs/utils";
import {css} from "styled-components";
import {DEVICE, DISK} from "../../enums/UploaderStepsManagement";

const GroupHeading = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  color: ${getColor('gray150')};;
  margin-bottom: 1.5rem;
  .nameWrapper {
    padding: 0.25rem 0.5rem 0.25rem 0;
    margin-right: 0.25rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    border-radius: 5px;
    ${Icon} {
      display: none;
      font-size: 15px;
      vertical-align: -3px;
    }
  }
  .line {
    flex-grow: 1;
    border-bottom: 1px solid ${getColor('gray210')};
    flex-grow: 1;
    min-width: 150px;
  }
  .collapseOption {
    margin-left: 0.75rem;
  }
  ${props => props.canEdit && css`
    .nameWrapper {
      padding: 0.25rem 0.5rem;
      :hover {
        cursor: pointer;
        background: ${getColor('gray240')};
      }
      ${Icon} {
        display: inline-block;
      }
    }
    .collapseOption {
      margin-right: 0.5rem;
    }
  `}
  ${Button} {
    margin-left: .5rem;
  }
  ${props => (props.mode === DEVICE || props.mode === DISK) && css`
    margin-bottom: 0px;
  `}
  ${customTheme("GroupHeading")};
`

const ViewAllButtonContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
  min-height: 2rem;
  color: ${getColor('gray90')};
  a {
    margin-left: 0.75rem;
  }
  ${Icon} {
    position: absolute;
    right: 0.5rem;
    top: 0;
    font-size: 24px;
    color: ${getColor('gray180')};
    cursor: pointer;
    :hover {
      color: ${getColor('gray150')};
    }
  }
  ${Loading} {
    margin-right: 0.75rem;
  }
`

const HelpPopup = styled(Popup)`
  max-width: 330px;
  font-size: 12px;
  border-radius: 6px;
  padding: 1.5rem;
`

export const GroupHeader = ({group, isCollapsed, snapshot, moveHandle}) => {
  const {
    mode,
    allowReorder,
    canAddGroup,
    persistGroups,
    setNotification,
  } = useContext(UploaderConfigContext)
  const doAction = useDoAction()

  const isRenaming = useToggle()
  const editingName = useToggle()

  const groups = useUIDataSelector('groups')
  const isUploading = useIsUploading()

  const notClassGroup = groups.filter(g => g.id === 'notclass')[0]?.items
  const unidentifiedFilesPatient = useRecordsSelector(_.filter((item) =>
    item.mode === mode
    && !isOtherKnowExtension(item)
    && !isOfRecordClass(Pathology)(item)
    && !isOfRecordClass(Radiology)(item)
    // && notClassGroup?.indexOf(item.recordUID + '') >= 0)
    && notClassGroup?.indexOf(item.key + '') >= 0),[notClassGroup]
  )
  const unidentifiedUploadingFilesPatient = useUploadQueueSelector(_.filter((item) => item.mode === mode && !isOtherKnowExtension(item) && !isOfRecordClass(Pathology)(item) && !isOfRecordClass(Radiology)(item)))

  const [_ungroup, ungroupRef] = useUngroup(group)
  const ungroup = () => {
    editingName.close()
    _ungroup()
  }
  const setGroups = (updater) => doAction(GroupAction.UpdateGroups, updater)
  const toggleCollapsed = (updater) => doAction(UIAction.ToggleCollapsed, group.id)

  const editingIsOpen = _.isString(editingName.isOpen)

  const confirmName = (e, groupName) => {
    const relatedTarget = e.relatedTarget
    const newGroupQtd = editingName.isOpen.includes('New group')
      ? groups.filter((g) => g.name === editingName.isOpen || g.name.includes(editingName.isOpen + ' (')).length
      : 0
    const groupNameQtd = groups.filter((g) => g.name === editingName.isOpen).length
    const groupIncludesNameQtd = groups.filter((g) => g.name.includes(editingName.isOpen + ' (')).length
    const totalGroupOther = groupNameQtd + groupIncludesNameQtd
    setTimeout(() => {
      if(groupName !== editingName.isOpen && relatedTarget !== ungroupRef.current) {
        if (newGroupQtd > 0) {
          if (newGroupQtd === 1) {
            setGroups(_.map((g) => g.id === group.id ? _.unset('transient', {...g, name: editingName.isOpen}) : g))
          } else {
            setGroups(_.map((g) => g.id === group.id ? _.unset('transient', {
              ...g,
              name: editingName.isOpen + ' (' + (newGroupQtd - 1) + ')'
            }) : g))
          }
        } else {
          if (groupNameQtd === 0) {
            setGroups(_.map((g) => g.id === group.id ? _.unset('transient', {...g, name: editingName.isOpen}) : g))
          } else {
            if (isRenaming.isOpen) { // Renaming existing group
              if (groupNameQtd === 1) {
                if (groups.find((g) => g.name === editingName.isOpen).id === group.id) {
                  setGroups(_.map((g) => g.id === group.id ? _.unset('transient', {...g, name: editingName.isOpen}) : g))
                } else {
                  groups.filter((g) => g.name.includes(editingName.isOpen + ' (')).find((g) => g.id === group.id)
                    ? setGroups(_.map((g) => g.id === group.id ? _.unset('transient', {
                      ...g,
                      name: editingName.isOpen + ' (' + (totalGroupOther - 1) + ')'
                    }) : g))
                    : setGroups(_.map((g) => g.id === group.id ? _.unset('transient', {
                      ...g,
                      name: editingName.isOpen + ' (' + totalGroupOther + ')'
                    }) : g))
                }
              }
            } else { // Creating a new group
              if (totalGroupOther > 0) {
                setGroups(_.map((g) => g.id === group.id ? _.unset('transient', {
                  ...g,
                  name: editingName.isOpen + ' (' + totalGroupOther + ')'
                }) : g))
              } else {
                setGroups(_.map((g) => g.id === group.id ? _.unset('transient', {...g, name: editingName.isOpen}) : g))
              }
            }
          }
        }
      }

      if (relatedTarget !== ungroupRef.current) {
        editingName.close()
        isRenaming.close()
      }
    }, 100)
  }

  const tryConfirmName = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (editingName.isOpen.trim().length > 0) {
        confirmName(e)
      } else {
        editingName.close()
      }
    } else if (e.key === 'Escape') {
      e.preventDefault()
      editingName.close()
    }
  }

  if (group.id === 'ungrouped') {
    return <span></span>
  }

  const editGroupName = (name, e) => {
    isRenaming.open()
    return editingName.openWith(name)
  }

  const refreshGroups = (groups) => {
    const clearTemps = _.map(_.update('items', _.filter(item => item.indexOf("temp-") < 0)))
    const removeSize = _.map(_.unset('size'))

    const retry = () => persistGroups(clearTemps(removeSize(groups)))
      .catch(() =>{
        setNotification({
          type: "alert", timeout: 0, msg: (close) => <span>Could not refresh groups <a onClick={() => {
            close();
            retry()
          }}>Retry</a></span>
        })
      })
    retry()

  }

  const helpPopup = usePopupToggle()

  if (group.id === 'ungrouped') {
    return <span></span>
  }

  return <GroupHeading canEdit={allowReorder && canAddGroup && group.id !== 'notclass'}
                       isDragging={snapshot?.isDragging} mode={mode}>
    {isMinified(mode) || group.id === 'notclass'
      ?
      isMinified(mode) && group.id === 'notclass'
        ?
        <ViewAllButtonContent>
          {isMinified(mode) && unidentifiedUploadingFilesPatient.length > 0 && <><Loading size={18}
                                                                                          borderWidth={3}></Loading><b>Uploading
            files</b></>}
          {isMinified(mode) && unidentifiedUploadingFilesPatient.length == 0 && unidentifiedFilesPatient.length > 0 &&
            <>
              <b>{unidentifiedFilesPatient.length} other {unidentifiedFilesPatient.length > 1 ? 'files' : 'file'}</b>
              {!isUploading && <a className='light-link' onClick={() => {
                // refreshGroups(groups);
                doAction(GroupAction.RefreshGroups)
                doAction(UIAction.ToggleSessionOnly, true)
                doAction(UIAction.ToggleUnidentifiedFilesDialog)
              }
              }>
                View all
              </a>}
            </>
          }
          <Icon onMouseEnter={helpPopup.open} onMouseLeave={helpPopup.close} icon="help">
            {helpPopup.isOpen &&
              <HelpPopup>These are files that we did not automatically identify as medical records. They are still being
                uploaded to your case and can be reviewed at any time, but will remain hidden unless classified as a
                medical record by you or a case manager.</HelpPopup>}
          </Icon>
        </ViewAllButtonContent>
        : null
      :
      <>
        {moveHandle}
        {editingIsOpen
          ? <TextInput
            onFocus={e => e.target.select()}
            raw value={editingName.isOpen} setValue={_.unary(editingName.openWith)}
            autoFocus
            onKeyUp={tryConfirmName}
            onKeyPress={e => e.key === "Enter" && e.preventDefault()}
            onBlur={e => confirmName(e, group.name)}
            maxLength={255}
            css="max-width:150px; height:2rem; font-size:13px; margin-right:0.5rem;"
          />
          : <div className="nameWrapper"
                  disabled={group.id === 'Carequality'}
                 onClick={allowReorder && canAddGroup ? (e) => editGroupName(group.name, e) : null}>
            <span>{group.name} {canAddGroup &&
              <Icon icon="edit" className="edit" onClick={(e) => editGroupName(group.name, e)}/>}</span>
          </div>
        }
        <div className="line"/>
        {
          editingIsOpen ? <Button small ref={ungroupRef} onClick={ungroup}>Ungroup</Button>
            :
            <span className="collapseOption">
            {isCollapsed ?
              <><b>{group.items.length} record{(group.items.length > 1 || group.items.length === 0) ? 's' : ''}</b>
                <span className="light-link gray" css="margin-left:0.25rem;" onClick={toggleCollapsed}>Expand</span></>
              :
              <span className="light-link gray" onClick={toggleCollapsed}>Collapse</span>
            }
          </span>
        }
      </>
    }
  </GroupHeading>

}