import React from 'react'
import styled from 'styled-components'

export const MALE = 'M'
export const FEMALE = 'F'
export const NONBINARY = 'N'
export const OTHER = 'O'

export const GENDER_LABELS = {
  [MALE]: 'Male',
  [FEMALE]: 'Female',
  [NONBINARY]: 'Non-binary',
  [OTHER]: 'Other'
}