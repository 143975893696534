import React, {useContext, useEffect, useState} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {
  AttachmentActions, AttachmentBox,
  AttachmentDescription,
  AttachmentDetails,
  AttachmentIcon,
  AttachmentInfoContainer, TextButton
} from "../AttachmentBoxStyles";
import {UIAction} from "../../service/UIAction";
import {Icon, Loading} from "@startlibs/components";
import {useDoAction} from "../../service/hooks/useDoAction";
import {EXPERT, PROVIDER} from "../../enums/UserRoles";
import {DEVICE, DISK} from "../../enums/UploaderStepsManagement";
import {UploaderConfigContext} from "../../service/UploaderConfigContext";
import {useIsUploading, useUploadQueueSelector} from "../../service/hooks/useUploadQueueSelector";
import { GroupAction } from '../../service/GroupAction';
import { Other } from '../../enums/RecordFormat';
import { isOtherKnowExtension } from '../../dicom/FileParser';
import { useUIDataSelector } from '../../service/hooks/useUIDataSelector';

styled

export const UnclassifiedRecordRow = ({group, isCollapsed}) => {
  const doAction = useDoAction()
  const {role,mode,config} = useContext(UploaderConfigContext)
  const isUploading = useIsUploading()
  const uploadQueue = useUploadQueueSelector()
  const data = useUIDataSelector()
  const hiddenFilesUploadingCount = uploadQueue.map(item => {
    return item.recordClass === Other && item.recordFormat === Other && !(isOtherKnowExtension(item) || config?.role === PROVIDER || item.doNotHide)
  }).filter(Boolean).length
  const completedHidden = data?.completedHidden?.length
  
  const showUnclassifiedRow = role !== EXPERT && role !== PROVIDER &&  !isCollapsed && mode !== DEVICE && mode !== DISK && group.items.length > 0

  if (!showUnclassifiedRow) {
    return null
  }

  return <AttachmentBox css="margin-bottom:1rem; background:white;" >
    <AttachmentIcon icon="files"/>
    <AttachmentInfoContainer>
      <AttachmentDescription><b>{group.items.length} hidden files</b></AttachmentDescription>
      <AttachmentDetails>These files do not appear to be medical records and have been hidden from the case. If you believe some may be relevant, you may review them and add them to the case.</AttachmentDetails>
    </AttachmentInfoContainer>
    <AttachmentActions>
      <div className="buttons-wrapper">
        {
          <TextButton disabled={isUploading}
                      onClick={() => { doAction(GroupAction.RefreshGroups); doAction(UIAction.ToggleSessionOnly,false); doAction(UIAction.ToggleUnidentifiedFilesDialog);}}
          >

            { isUploading && hiddenFilesUploadingCount > 0 ?
              <>
                {/* <Loading size={16} borderWidth={3}/>{hiddenUploading} Uploading */}
                <Loading size={16} borderWidth={3}/> {completedHidden}/{completedHidden+hiddenFilesUploadingCount} uploaded
                {/* Uploading  */}
                {/* {maxUploadingHidden - hiddenFilesUploadingCount}/{maxUploadingHidden} */}
                

              </>
              :
              <>
                <Icon icon="check-files"/>
                Review hidden files
              </>
            }
          </TextButton>
        }
      </div>
    </AttachmentActions>
  </AttachmentBox>
}