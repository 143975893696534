import {Button, Dialog, DialogFooter} from '@startlibs/components'
import {Errors, Field, TextInput, useProvideErrors, WithForm} from '@startlibs/form'
import React, { useRef, useState } from 'react'
import _ from 'lodash/fp'
import {getJwt} from '../../hooks/useJwt'
import {jwtPostFetcher} from '../../utils/authFetch'
import { useToggle } from '@startlibs/core'
import { StarRating } from '../../components/StarRating'
import { setNotification } from '../../components/Notifications'

export const FeedbackDialog = ({feedbackPopup, requestId = 0}) => {

  const loadingFeedback = useToggle(false)
  const formRef = useRef()
  const [formValues, setFormValues] = useState({review: ""})
  const [ErrorProvider, errors, {setError}] = useProvideErrors()
  const [rating, setRating] = useState(0);
  const [selection, setSelection] = useState(0);
  
  const saveRating = (value) => {
    if(value < 1 || value > 5){
      return
    }
    jwtPostFetcher(getJwt())('/api/' + ( requestId > 0 ? 'expert/feedback?requestId='+requestId+'&presave=true' : 'feedback?presave=true'),{stars: value, message: ""},{method:"PUT"})
      .then((response) => {})
    setRating(value)
  }

  return <Dialog 
    title="Give feedback"
    closeDialog={feedbackPopup.close}
    >
    <WithForm
      values={formValues}
      ref={formRef}
      action={(values)=> {}}
      onSuccess={(e) => {
        if(!rating){
          return setError("Error", 'Please rate your experience from 1 to 5 stars.')
        }
        loadingFeedback.open();
        jwtPostFetcher(getJwt())('/api/' + ( requestId > 0 ? 'expert/feedback?requestId='+requestId : 'feedback'),{stars: rating, message: e.review},{method:"PUT"})
          .then((response) => {
            setNotification({type:"success", timeout: 4000, msg: 'Thank you for your feedback!'})
            loadingFeedback.close();
            feedbackPopup.close();
          })
          .catch((error) => {
            setError("Error", 'It was not possible to send your feedback. Please try again.')
            loadingFeedback.close();
          })
        
      }}
    >
      {form => <>
        <p css="font-size: 14px !important;">Your feedback is invaluable to us as we work to improve this platform.</p>
        <Field label="How would you rate your experience?">
          <StarRating value={0} size={60} quantity={5} rating={rating} setRating={saveRating} selection={selection} setSelection={setSelection} />
        </Field>
        <TextInput
          label="Give more details"
          path="review"
          values={null}
          textarea
          autoResize
          placeholder="Please include as much information as you would like"
        />
        <ErrorProvider value={errors}>
          <Errors/>
        </ErrorProvider>
        <DialogFooter>
          <Button onClick={feedbackPopup.close}>Maybe later</Button>
          <Button highlight isLoading={loadingFeedback.isOpen} type="submit">Submit feedback</Button>
        </DialogFooter>
      </>
    }</WithForm>
  </Dialog> 
}
