import React, {useEffect} from 'react'
import {useToggle} from '@startlibs/core'
import _ from 'lodash/fp'
import {jwtGetFetcher, jwtPostFetcher} from '../../../utils/authFetch'
import {getJwt} from '../../../hooks/useJwt'
import {ConfirmDialog, useConfirmDialog} from '../../../hooks/useConfirmDialog'
import {getColor} from '@startlibs/utils'
import {UNDER_REVIEW, WAITING_MORE_INFORMATION} from '../../../enums/CaseState'
import {useNavigate} from 'react-router'
import {Button} from '@startlibs/components'
import {CaseRequestCard} from '../../CaseRequestCard'
import {ExpertProfileCard} from '../../experts/components/ExpertProfileCard'
import {CheckboxGroup, FormValue, Radiobox, SimpleRadiobox, TextInput, ToggleCheckbox} from '@startlibs/form'
import styled from 'styled-components'
import {SimpleContactCard} from '../../../components/ContactCard'
import {buildValidation, required, responseFailure} from '../../../utils/validation'
import {lazyProviderInfo, lazyUserInfo} from '../../../components/WithProvider'
import {isNotRevoked} from '../expertReview/utils'
import {TransitionDiv} from '../../../components/TransitionDiv'
import { REVIEWER_TYPE_ADMIN } from '../../../enums/UserRole'

const ContactCheckboxGroup = styled(CheckboxGroup)`
  margin-bottom: 1.5rem;
  border: 1px solid ${getColor('gray210')};
  border-radius: 5px;
  overflow: hidden;
  > label:first-child > div {
    border-top: 0;
  }
`

export const useAskVariableExpertMoreInformation = (caseRequest, setCaseRequest, setSharedExperts) => {
  const [dialog, sharedExperts] = useInternalAskExpertMoreInformation(caseRequest, setCaseRequest, [], setSharedExperts)
  return (expert) => {
    sharedExperts.openWith([expert])
    dialog()
  }
}

export const useAskExpertMoreInformation = (caseRequest, setCaseRequest, experts, setSharedExperts) => {
  const [dialog, sharedExperts] = useInternalAskExpertMoreInformation(caseRequest, setCaseRequest, experts, setSharedExperts)
  useEffect(() => {
    if (!sharedExperts.isOpen) {
      jwtGetFetcher(getJwt())(`/api/experts/bycase/${caseRequest.requestId}`)
        .then((experts) => sharedExperts.openWith(experts.filter(isNotRevoked(caseRequest))))
    }
  }, [])

  return dialog
}
const useInternalAskExpertMoreInformation = (caseRequest, setCaseRequest, experts, setSharedExperts) => {

  const sharedExperts = useToggle(experts)
  const navigate = useNavigate()
  const providerInfo = lazyProviderInfo.read()
  const userInfo = lazyUserInfo.read()

  return [useConfirmDialog(
    <ConfirmDialog
      confirmChanges={false}
      isLoading={!sharedExperts.isOpen}
      action={({selectedExpert, ...values}) => jwtPostFetcher(getJwt())(`/api/reviewer/requestMoreInformation/${selectedExpert.id}?requestId=` + caseRequest.requestId, values)}
      navigate="waitingAdditional"
      transform={(v) => sharedExperts.length > 1 ? v : {...v, selectedExpert: sharedExperts.isOpen[0]}}
      preValidation={buildValidation({selectedExpert: (v) => !v && sharedExperts.length > 1 && required()})}
      onSuccess={({selectedExpert}, {id}) => {
        const isSelfAssignment = selectedExpert?.expert?.type === REVIEWER_TYPE_ADMIN && selectedExpert?.expert?.id === userInfo.id
        const type = selectedExpert?.expert?.type
        
        setCaseRequest(_.flow(
          _.set('state', isSelfAssignment ? UNDER_REVIEW : WAITING_MORE_INFORMATION),
          _.set('activeCaseExpertId', id || selectedExpert.id),
          _.set('activeCaseExpertType', type),
          _.set('isAssignedToLoggedAdmin', isSelfAssignment)
        ))
        return setSharedExperts
          ? jwtGetFetcher(getJwt())(`/api/experts/bycase/${caseRequest.requestId}`).then(setSharedExperts)
          : navigate(`/admin/request/${caseRequest.requestCode}/expert`)
      }}
      onFailure={responseFailure((n, {status}) => status === 565 && "This case is shared with another expert")}
      values={{sendEmail: providerInfo?.notifications?.assignExpert}}
      title="Requesting additional information from expert"
      confirm={<Button highlight disabled={!sharedExperts.isOpen}>Request information</Button>}
    >
      <p>{
        sharedExperts.isOpen?.length < 1 ? "You are about to request additional information from this case's assigned expert:"
        : "Please select the expert you would like to request more information from."
      }</p>
      <CaseRequestCard caseRequest={caseRequest}/>
      <p>Sending request to:</p>
      {
        (sharedExperts.isOpen && _.uniqBy('expert.id',sharedExperts.isOpen).length > 1)
          ? <ContactCheckboxGroup path="emails">{
            _.uniqBy('expert.id',sharedExperts.isOpen).filter(expert => expert.expert.id !== userInfo.id || expert.expert.type !== REVIEWER_TYPE_ADMIN).map(sharedExpert => <ExpertSelectInput
              path="selectedExpert"
              fieldValue={sharedExpert}
              expert={sharedExpert.expert}
            />)
          }</ContactCheckboxGroup>
          : <ExpertProfileCard expert={_.get('[0].expert', sharedExperts.isOpen) || {}}/>
      }
      <ToggleCheckbox
        label={<strong>Send an email notifying the expert that the case was assigned</strong>}
        path="sendEmail"
      />
      <FormValue path="sendEmail">{sendEmail =>
        <TransitionDiv>{sendEmail && <TextInput
          textarea
          autoResize
          label="Message"
          path="message"
          descText="This will be included in the email sent to the expert for requesting more information."
          placeholder="Insert custom message here"
        />
        }</TransitionDiv>
      }</FormValue>
      <p>You will be notified by email as soon as the expert updates the case.</p>
    </ConfirmDialog>, [providerInfo, sharedExperts.isOpen]
  ), sharedExperts]
}


export const ExpertSelectInput = styled(({highlightSelected, expert, viewOnly, children, className, ...props}) =>
  <Radiobox {...props}>{({disabled, checked, hasErrors, focused, tabIndex, framedBoxes}) =>
    <SimpleContactCard
      selectable
      className={className}
      name={expert.firstName + " " + expert.lastName}
      email={expert.email}
      checked={checked}
    >
      <SimpleRadiobox raw fieldValue value={checked} setValue={_.identity}/>
    </SimpleContactCard>
  }</Radiobox>)`
  padding-right: 3.75rem;
  margin-bottom: 0;
  border: none;
  border-radius: 0;
  border-top: 1px solid ${getColor('gray210')};
  ${SimpleRadiobox} {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
  }
`
