import React from 'react'
import _ from 'lodash/fp'
import {Button} from '@startlibs/components'
import {ConfirmDialog, useConfirmDialog} from '@startlibs/form'
import {PRIMARY} from '../../../../enums/ContactRelationKind'
import {CaseRequestCard} from '../../../CaseRequestCard'
import {jwtPostFetcher} from '../../../../utils/authFetch'
import {getJwt} from '../../../../hooks/useJwt'
import {getTimezoneOffset} from '../../../../utils/utils'

export const useMarkAsAgreed = (terms, caseRequest,setCaseRequest, intl) => {

  const onFailure = (e,[resp,{status}],form) => {
    form.setError("",<>
      <p><b>Something went wrong. Please reload the page and try again.</b></p>
      <p>{status ? `Error code: ${status}. ` : null}{resp ? `Message: ${resp}. `:null}If the error persists please contact <a href="mailto:support@purview.net"
                                                target="_blank">support@purview.net</a> or <span
        className="nowrap">call <b>+1 800-501-1537</b>.</span></p>
    </>)
  }

  return useConfirmDialog((closeDialog) => <ConfirmDialog
    title="Confirm agreement"
    action={() => jwtPostFetcher(getJwt())(`/api/admin/markAsAgreed?requestId=${caseRequest.requestId}`,{timezoneOffset:getTimezoneOffset()})}
    notify="Document marked as agreed."
    confirm={<Button highlight>Yes, confirm agreement</Button>}
    onSuccess={(values,acceptanceInfo) => {
      setCaseRequest(
        _.set("acceptanceInfo",acceptanceInfo)
      )
    }}
    onFailure={onFailure}
  >
    <p>Confirm the {intl.formatMessage({
      defaultMessage:"patient contact",
      description:"patient contact label",
    })}’s agreement to all unsigned <a className="link" onClick={closeDialog}>agreements</a>?</p>
    {/* <p>Confirm the case contact’s agreement to all unsigned <a className="link" onClick={closeDialog}>agreements</a>?</p> */}
    <CaseRequestCard caseRequest={caseRequest} relationKind={PRIMARY}/>
    <p>This is recommended if the agreement was given offline.</p>
    <p>Are you sure you want to proceed?</p>
  </ConfirmDialog>)
}
