import React from 'react'

export const IN_PERSON_VISIT_LABEL = "In-person visit"
export const PATIENT_DECLINED_CASE_LABEL = "Patient declined case"
export const INSTITUTION_DECLINED_CASE_LABEL = "Institution declined case"
export const EXPERT_DECLINED_CASE_LABEL = "Expert declined case"
export const EXPERT_FINISHED_CASE_LABEL = "Expert finished case"
export const DATA_MISSING_LABEL = "Data missing"
export const TEST_CASE_LABEL = "Test case"
export const OTHER_LABEL = "Other"
export const OFFLINE_EXPERT_REVIEW_LABEL = "Offline expert review"
export const MISTAKE_LABEL = "Mistake"
export const EXPERT_NOT_AVAILABLE_LABEL = "Expert not available"
export const PATIENT_NOT_RESPOND_LABEL = "Patient did not respond"
export const TRANSFERRED_INTERNALLY_LABEL = "Transferred internally"
export const ADDITIONAL_MEDICAL_RECORDS_REQUIRED_LABEL = "Additional medical records required"

export const IN_PERSON_VISIT = "IN_PERSON_VISIT"
export const PATIENT_DECLINED_CASE = "PATIENT_DECLINED_CASE"
export const INSTITUTION_DECLINED_CASE = "INSTITUTION_DECLINED_CASE"
export const EXPERT_DECLINED_CASE = "EXPERT_DECLINED_CASE"
export const EXPERT_FINISHED_CASE = "EXPERT_FINISHED_CASE"
export const DATA_MISSING = "DATA_MISSING"
export const TEST_CASE = "TEST_CASE"
export const OTHER = "OTHER"
export const OFFLINE_EXPERT_REVIEW = "OFFLINE_EXPERT_REVIEW"
export const MISTAKE = "MISTAKE"
export const EXPERT_NOT_AVAILABLE = "EXPERT_NOT_AVAILABLE"
export const PATIENT_NOT_RESPOND = "PATIENT_NOT_RESPOND"
export const TRANSFERRED_INTERNALLY = "TRANSFERRED_INTERNALLY"
export const ADDITIONAL_MEDICAL_RECORDS_REQUIRED = "ADDITIONAL_MEDICAL_RECORDS_REQUIRED"

export const getPredefinedReasonLabel = (reason) => {
    switch (reason) {
        case IN_PERSON_VISIT:
            return IN_PERSON_VISIT_LABEL
        case PATIENT_DECLINED_CASE:
            return PATIENT_DECLINED_CASE_LABEL
        case INSTITUTION_DECLINED_CASE:
            return INSTITUTION_DECLINED_CASE_LABEL
        case EXPERT_DECLINED_CASE:
            return EXPERT_DECLINED_CASE_LABEL
        case EXPERT_FINISHED_CASE:
            return EXPERT_FINISHED_CASE_LABEL
        case DATA_MISSING:
            return DATA_MISSING_LABEL
        case TEST_CASE:
            return TEST_CASE_LABEL
        case OTHER:
            return OTHER_LABEL
        case OFFLINE_EXPERT_REVIEW:
            return OFFLINE_EXPERT_REVIEW_LABEL
        case MISTAKE:
            return MISTAKE_LABEL
        case EXPERT_NOT_AVAILABLE:
            return EXPERT_NOT_AVAILABLE_LABEL
        case PATIENT_NOT_RESPOND:
            return PATIENT_NOT_RESPOND_LABEL
        case TRANSFERRED_INTERNALLY:
            return TRANSFERRED_INTERNALLY_LABEL
        case ADDITIONAL_MEDICAL_RECORDS_REQUIRED:
            return ADDITIONAL_MEDICAL_RECORDS_REQUIRED_LABEL
        default:
            return reason
    }
}

