import { wrapLazy } from '@startlibs/utils'
import { jwtGetFetcher } from '../../../../utils/authFetch'
import { getJwt } from '../../../../hooks/useJwt'
import { REVIEWER_TYPE_ADMIN } from '../../../../enums/UserRole'

export const lazyExperts = wrapLazy((experts) => Promise.all(experts.map(({ expertId, reviewerType }) => {
    return expertId
        ? reviewerType && reviewerType === REVIEWER_TYPE_ADMIN
            ? jwtGetFetcher(getJwt())("/api/admin/user/" + expertId)
            : jwtGetFetcher(getJwt())("/api/expert/" + expertId)
        : jwtGetFetcher(getJwt())("/api/expert")
})))
