import {AutoComplete, Checkbox, CheckboxGroup, ConfirmDialog, Errors, Field, FieldDescription, FormValue, SimpleCheckbox, TextInput, ToggleCheckbox, WithForm, useConfirmDialog} from '@startlibs/form';
import {useToggle} from '@startlibs/core'
import _ from 'lodash/fp'
import React, {useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {CopyButton, CopyLinkComponent} from 'sharecomponents/src/pages/ShareDialog'
import {ExpirationSelect} from 'sharecomponents/src/components/ExpirationSelect'
import {CaseRequestCard} from '../../CaseRequestCard'
import {getJwt} from '../../../hooks/useJwt';
import {jwtPostFetcher} from '../../../utils/authFetch';
import {setNotification} from '../../../components/Notifications'
import { getColor } from '@startlibs/utils';
import styled from 'styled-components';
import { Button, Dialog, TransitionDiv } from '@startlibs/components';
import { buildValidation, required } from '../../../utils/validation';

const HelpText = styled.span`
  font-weight: 400;
  color: ${getColor('gray120')};
  font-size: 12px;
`

const EmailConfirmationListContainer = styled.div`
  background-color: rgba(0,0,0,.07);
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 5px;
  margin-bottom: 1rem;
  font-size: 13px;
  padding: 1rem;
  p {
    margin-bottom: 0;
  }
  ul {
    list-style-type: disc;
    margin-top: .5rem;
    margin-left: 2rem;
  }
`

const AdditionalText = styled.p`
  margin-top: .5rem;
`

const SecurityDataWarning = styled.div`
  color: rgba(0,0,0,0.5);
  h4 {
    font-size: 13px;
    margin-bottom: 0.25rem;
  }
  p {
    font-size: 12px;
    margin-bottom: 0;
  }
`

export const useShareCompleted = (caseRequest) => {

  const shareLink = useToggle()
  const autocompleteRef = useRef()
  const shareData = useToggle()
  const formRef = useRef()
  // const [message, setMessage] = useState('')
  
  // useEffect(() => {
  //   if(message.length >= 128){
  //     formRef.current.setError('customMessage', 'Maximum character limit of 128 reached.')
  //   }
  // }, [message])
  
  const validateEmail = (email) => {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    return re.test(email)
  }
  
  const emailValidation = (v = '') => {
    return validateEmail(v.trim().replace(/\u200b/g, '')) ? {success: v.trim().replace(/\u200b/g, '')} : {errors: [`"${v}" is not a valid e-mail address.`]}
  }

  const preValidation = ({emails}) => {
    const hasEmails = emails.find(e => !!e)
    const invalidEmails = emails.filter(e => e && !validateEmail(e))
    if (!hasEmails)
      return {emails: [`At least one valid e-mail address is needed.`]}
    if (invalidEmails.length) {
      return {}
    }
  }


  const request = useConfirmDialog(<ConfirmDialog
          values={{expirationTime: 0, emails: [], caseOverview: true, medicalRecords: true, caseReport: true, allowDownload: false}}
          title="Share case"
          className="JS-copy-focus-container"
          cancelLabel="Cancel"
          action={(values) => {
            shareData.openWith(values)
            setTimeout(confirm, 200)
          }}
          formRef={formRef}
          confirmChanges={false}
          preValidation={preValidation}
          confirm={<Button highlight>Send email</Button>}
        >{form => <>
            {/* <p>Review the details below for sharing the case in read only mode:</p> */}
            <CaseRequestCard caseRequest={caseRequest}/>
          
            <CheckboxGroup horizontal label="Select case information to share" framedBoxes highlightSelected wide>
              <SimpleCheckbox
                css="margin-top: .5rem;" 
                path="caseOverview" 
                label="Case overview"
              />
              <SimpleCheckbox
                css="margin-top: .5rem;" 
                path="medicalRecords" 
                label="Medical records"
              />
              <SimpleCheckbox
                css="margin-top: .5rem;" 
                path="caseReport" 
                label="Case report"
              />
            </CheckboxGroup>
            <FormValue path="medicalRecords">{medicalRecords => 
              <>
                <TransitionDiv>{medicalRecords && <ToggleCheckbox
                  label="Allow download of medical records"
                  path="allowDownload"
                  css="font-weight:600"
                />}
                </TransitionDiv>
              </>
            }</FormValue>
            <Field label="Security">
              <div css="margin-top: .25rem;">
                <ExpirationSelect />
              </div>
              <HelpText>
                <FormattedMessage defaultMessage="The recipient will be asked to provide the patient's last name and date of birth." description='useReadOnlyShare share dialog security message' />
              </HelpText>
            </Field>
            <div>
              <AutoComplete
                ref={autocompleteRef}
                form={form}
                getLabel={_.identity}
                minQueryLength={1}
                confirmIfNewKeys={[' ']}
                defaultValue={[]}
                autoSelect
                validation={emailValidation}
                path="emails"
                label="Enter the recipients email address"
                placeholder="example@email.com"
                bellowDescText="Separate multiple email addresses using comma or space."
              />
            </div>
            {/* <TextInput
              textarea
              autoResize
              label="Message"
              path="customMessage"
              descText = "Describe what you need the recipient to review and contribute."
              maxLength={128}
              onChange={setMessage}
              bellowDescText={
                <span style={{ 
                  display: 'block',
                  width: '100%',
                  textAlign: 'right'
                }}>{message.length}/128</span>
              }
        
            /> */}
            {/* <Errors/> */}
            
        </>}</ConfirmDialog>, [shareData.isOpen])
  
  const confirm = useConfirmDialog(<ConfirmDialog
      title="Share case"
      action={
        (values) => {
          const formatedValues = {
            expirationTime: values.expirationTime,
            customMessage: '',
            emails: values.emails,
            permission: {
              caseOverview: values.caseOverview,
              medicalRecords: values.medicalRecords,
              report: values.caseReport,
              allowDownload: values.medicalRecords && values.allowDownload,
              addNote: false
            }
      
          }
          jwtPostFetcher(getJwt())("/api/admin/ro/share?requestId="+caseRequest.requestId,formatedValues)
          .then(response => {
          })
          .catch(error => console.log('error', error))
          
        }
      }
      confirm={<Button highlight>Send email</Button>}
      values={shareData.get()}
      cancelLabel="Cancel"
      closeButton={(closeDialog) => <Button
        onClick={() => {
          closeDialog();
          setTimeout(request, 150)
        }}
      >Cancel</Button>}
      // onSuccess={() => setRelease(_.set('suspend',true))}
      notify="Case shared successfully."
    >
      <EmailConfirmationListContainer>
        <p><b>Share case with:</b></p>
        <ul className="sending-email-list">
          <FormValue path="emails">{emails =>
            emails.map(email => email != null &&  email != '' && <li key={email}>{email}</li>)
          }</FormValue>
        </ul>
        {/* <br></br>
        <p><b>Message:</b></p>
        <FormValue path="customMessage">{msg =>
            <p>{msg}</p>
        }</FormValue> */}
      </EmailConfirmationListContainer>
      <SecurityDataWarning>
        <h4>This email may contain confidential and protected health care information.</h4>
        <p>
          Please be sure that the email of the recipient has been entered
          correctly and that you are using appropriately confidential mechanisms
          for this communication.
        </p>
      </SecurityDataWarning>
    </ConfirmDialog>, [shareData.isOpen]
  )


  return [request]
}

{/* <EmailConfirmationListContainer>
      <p><b>{labels.sendingText || (caseRequest ? "Sending Expert View report to:" : "Sending record(s) to:")} </b></p>
      <ul className="sending-email-list">
        <FormValue path="emails">{emails =>
          emails.map(email => <li key={email}>{email}</li>)
        }</FormValue>
      </ul>
    </EmailConfirmationListContainer>
    <AdditionalText>{labels.additionalInfo}</AdditionalText>
    <SecurityDataWarning>
      <h4>This email may contain confidential and protected health care information.</h4>
      <p>
        Please be sure that the email of the recipient has been entered
        correctly and that you are using appropriately confidential mechanisms
        for this communication.
      </p>
    </SecurityDataWarning> */}

const Link = ({expirationTime, shareLink, caseRequest, form}) => {

  const loadingShareableLink = useToggle()

  useEffect(() => updateShareableLink(), [expirationTime])

  const updateShareableLink = () => {
    shareLink.close()
    form.clearErrors()
    loadingShareableLink.wait(
      jwtPostFetcher(getJwt())( `/api/admin/readOnlyAccess?requestId=${caseRequest.requestId}`,{expirationInHours:expirationTime})
        .then(({url}) => shareLink.openWith(url))
        .catch(() => {
          form.setError('shareLink', 'Link failed to load')
          shareLink.close()
        })
    )
  }

  return <>
    <CopyLinkComponent url={shareLink.isOpen} label='Copy the link below to share this case:' loadingShareableLink={loadingShareableLink.isOpen}/>
    </>
}
