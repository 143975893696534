import {useRefState} from '@startlibs/core'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {enums, Uploader2} from 'uploader-frontend'
import {authPostFetcher, jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import {getJwt} from '../hooks/useJwt'
import {getProviderJwt, setProviderJwt} from '../hooks/useProviderJwt'
import {useNotification} from '../components/Notifications'
import { getUploaderJwt, setUploaderJwt } from '../hooks/useUploaderJwt';
import { getStorageHost } from '../hooks/useStorageHost';
import {getFetcher, postFetcher, using} from '@startlibs/utils'
import { downloadFiles, viewFileSrc } from './utils';
import { ADMIN, PATIENT } from '../enums/UserRoles';
import { DISK } from '../enums/UploaderManagement';
import { Loading } from '@startlibs/components';

const apiEndpoints = {
  dicomViewer:(studies) => Promise.resolve({viewerURL:getStorageHost()+`/view/records/${studies.filter(_.get('recordUID')).map(_.get("recordUID")).join("|")}?t=${getUploaderJwt()}`}),
  nonDicomViewer: (record) => getStorageHost()+`/view/record/${record.recordUID}?t=${getUploaderJwt()}`,
  shortTokenUrl: (requestId) => jwtGetFetcher(getJwt())(`/api/shortDownloaderToken`,{requestId: requestId}),
  downloadRecord: (record) => getStorageHost()+`/download/record/${record.recordUID}?t=${getUploaderJwt()}`,
  storageHost: () => getStorageHost(),
  loadDownloadRecord: (record,filename) => record.nonCompliantFiles
  ? jwtGetFetcher(getUploaderJwt())(getStorageHost()+`/download/async/nonDicoms/${record.nonCompliantFiles.map(_.get("recordUID")).join("-")}`).then(({fileUID})=> ({url:() => getStorageHost()+`/download/file/${fileUID}?t=${getUploaderJwt()}${filename ? '&originalFilename='+encodeURIComponent(filename) : ""}`}))
  : jwtGetFetcher(getUploaderJwt())(getStorageHost()+`/download/async/${record.recordUID}`).then(({fileUID})=> ({url:() => getStorageHost()+`/download/file/${fileUID}?t=${getUploaderJwt()}${filename ? '&originalFilename='+encodeURIComponent(filename) : ""}`}))
  ,
  downloadFiles: (shortJwt, records) => {downloadFiles(shortJwt, records)},
  viewFileSrc: (shortJwt, recordId) => viewFileSrc(shortJwt, recordId),
  
}

const fileFields = ['fileName','fileExtension','fileType','fileSize','uid','description','docType','locationFormId','uploadDate']

export const RecordsManagerPatient = ({caseRequest = {}, autoGrouping, uploaderRef, listMode, caseId, allowReorder, disabled, setMedicalRecords, setCaseRequest, patientInfo, canOpenWorklist, isPatient, role, mode, setIsUploading, setToBeUploaded, setTotal, total, canAddToCase = true, ...rest}) => {
  
  const [, setNotification] = useNotification()

  const medicalRecordsStore = useRefState(() => (caseRequest.medicalRecords||[]).map((file) => ({...file,...file.info})), (prev,current) => setMedicalRecords(current))
  var studyUIDList = []
  caseRequest?.medicalRecords?.map((item) => item?.info?.studyUID ? studyUIDList.push(item?.info?.studyUID) :  null)
  const [linkedStudies, setLinkedStudiesb] = useState(studyUIDList);
  const [uploaderDiskJwt, setUploaderDiskJwt] = useState(getUploaderJwt())
  
  useEffect(() => {
    
    let refreshTimeout = -1
    if(canOpenWorklist && !isPatient){
      //let url = 'https://purviewimage.vivacloud.io/worklist/api/authenticate';
      jwtGetFetcher(getJwt())('/api/worklistToken/').then((response) => {
        setProviderJwt(response.jwt);
        refreshTimeout = setInterval(() => {
          jwtGetFetcher(getJwt())('/api/worklistToken/')
            .then((response) => {
              setProviderJwt(response.jwt)})
        },1*60*1000)

      })
    }

    if(mode===DISK && caseRequest.requestId){
      // To separate Unidentified Files between Device and Disk
      jwtGetFetcher(getJwt())(`/api/storageToken`,{requestId: caseRequest.requestId})
        .then((response) => {
          let responseJson = JSON.parse(response.jwt);
          setUploaderDiskJwt(responseJson.jwt)
        })
    }
    return  () => {
      clearInterval(refreshTimeout)
      setCaseRequest(_.unset("medicalRecords"));
    }
    
  }, [])

  const setLinkedStudies = (lStudies,type,newStudy) =>{
    
    setLinkedStudiesb(lStudies);
    
    if(type == 'Add'){
      medicalRecordsStore.set(medicalRecordsStore.get().concat(newStudy));
      setMedicalRecords(medicalRecordsStore.get());
    }
  }

  const persistRecord = (record) => {
    medicalRecordsStore.set(_.unionBy(_.property('recordUID'), [record]))
    return Promise.resolve()
  }

 const persistGroups = useMemo(() => {
    let lastUpdateGroups = Promise.resolve()
    let isUpdatingGroups = false
    let nextUpdateGroups = null

    const syncUpdateGroup = () => {
      if (isUpdatingGroups) {
        return lastUpdateGroups
      } else {
        const next = () => {
          isUpdatingGroups = true
          const currentGroups = nextUpdateGroups
          const updateGroups = (groups) => jwtPostFetcher(getUploaderJwt())(getStorageHost()+"/groups/case/"+caseId,{info:groups.filter(({transient})=>!transient)},{method:"PUT"}).then((a) => {
            // setMedicalRecords(_.set('groups.list',groups));
            setCaseRequest(_.set('group.info', groups));
          })
          return updateGroups(nextUpdateGroups)
            .catch(e => {
              isUpdatingGroups = false
              return Promise.reject(e)
            })
            .then(() => {
              isUpdatingGroups = false
              if (currentGroups !== nextUpdateGroups) {
                syncUpdateGroup()
              }
            })

        }
        lastUpdateGroups = next()
        return lastUpdateGroups
      }
    }
    return (groups) => {
      nextUpdateGroups = groups
      return syncUpdateGroup()
    }
  },[])

  const persistRecordInfo = (updatedRecord,currentInfo) => {
    
    const notesPromise = updatedRecord?.notes?.note !== currentInfo?.notes?.note
      ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+`/record/${updatedRecord.recordUID}/notes`,{note:updatedRecord?.notes?.note}, {method: 'PUT'})
      : Promise.resolve()
    const infoPromise = (!currentInfo || currentInfo.docType !== updatedRecord.docType || currentInfo.description !== updatedRecord.description)
      ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+"/medicalRecord/info/"+updatedRecord.recordUID,{..._.pick(fileFields,updatedRecord),type:'NonDicomFile'},{method:"PUT"})
      : Promise.resolve()

    const metaPromise = !_.isEqual(updatedRecord?.metadata,currentInfo?.metadata)
      ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+`/record/${updatedRecord.recordUID}/metadata`,updatedRecord?.metadata, {method: 'PUT'})
      : Promise.resolve()

    const statePromise = (updatedRecord?.state !== currentInfo?.state && currentInfo?.state)
      ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+`/record/${updatedRecord.recordUID}/state`,updatedRecord?.state, {method: 'PUT'})
      : Promise.resolve()

    return Promise.all([notesPromise,infoPromise,metaPromise,statePromise])
      .then(() =>
        medicalRecordsStore.set(_.unionBy(_.property('recordUID'), [updatedRecord]))
      )
  }

  const removePersistedRecord = (recordUID) => {
    const removePromise =
      recordUID === enums.MetaRecordType.ALL_NON_COMPLIANT_DICOM
      ? using(medicalRecordsStore.get().filter(record => record.format === enums.RecordFormat.NonCompliantDicom))(nonCompliants =>
        nonCompliants.length
          ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+'/record/'+nonCompliants.map(_.get('recordUID')).join("-"),undefined,{method:"DELETE"})
          : Promise.resolve({})
        )
      : medicalRecordsStore.get().find(record => record.recordUID === recordUID)
        ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+'/record/'+recordUID,undefined,{method:"DELETE"})
        : Promise.resolve(false)

     return removePromise.then(() =>
       medicalRecordsStore.set(_.differenceBy(_.property('recordUID'), _ , [{recordUID}]))
     )
  }

  const removeSelectedRecords = (selectedRecords) => {
    
    const selectdRecordsObj = []
    _.map((item) => selectdRecordsObj.push({'recordUID': item}),selectedRecords)
    
    const removePromise =
      selectedRecords.length
        ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+'/record/'+selectedRecords.map((item) => item).join("-"),undefined,{method:"DELETE"})
        : Promise.resolve(false)

      return removePromise.then(() => //console.log('test')
        medicalRecordsStore.set(_.differenceBy(_.property('recordUID'), medicalRecordsStore.get(),  selectdRecordsObj ))
      )
  }

  if(mode===DISK && uploaderDiskJwt === getUploaderJwt()) {
    return <Loading style={{position: 'relative', margin: 'auto', marginTop: '1.5rem'}}
      size="24"
      borderWidth="4"
    />
  }

  return <Uploader2
      listMode={listMode}
      downloadEnabled={caseRequest}
      disabled={disabled}
      jwt={mode === DISK ? uploaderDiskJwt : getUploaderJwt}
      apiEndpoints={apiEndpoints}
      ref={uploaderRef}
      groups={caseRequest.group?.info}
      appJwt={mode === DISK ? uploaderDiskJwt : getUploaderJwt}
      persistRecord={persistRecord}
      persistRecordInfo={persistRecordInfo}
      removePersistedRecord={removePersistedRecord}
      removeSelectedRecords={removeSelectedRecords}
      persistGroups={persistGroups}
      setNotification={setNotification}
      medicalRecords={medicalRecordsStore.get()}
      role={isPatient ? PATIENT : role ? role : ADMIN}
      autoGrouping={autoGrouping}
      allowReorder={role === ADMIN ? true : false} 
      canMoveGroup={role === ADMIN ? true : false} 
      canAddGroup={role === ADMIN ? true : false} 
      // Those are to open Radiology Worklist
      patientName={patientInfo?.firstName+' '+patientInfo?.lastName}
      linkedStudies={linkedStudies}
      setLinkedStudies={setLinkedStudies}
      canOpenWorklist={canOpenWorklist}
      worklistViewerJwt={getJwt}
      providerJwt={getProviderJwt}
      withViewAllButton
      downloadFiles={downloadFiles}
      mode={mode}
      setIsUploading={setIsUploading}
      setToBeUploaded={setToBeUploaded}
      setTotal={setTotal}
      total={total}
      requestId={caseRequest.requestId}
      caseId={caseId}
      canAddToCase={canAddToCase}
    />
}