import { FlipList, Icon, Loading } from '@startlibs/components';
import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro';

import {
  PanelButton,
  WidgetBody,
  WidgetHeader,
  WidgetPanelComponent
} from './PanelStyles';

import { touchClick } from './utils'
import { usePanelUtils2 } from './usePanelUtils2'
import { CREATED, DOWNLOADED, RECEIVED, RETRY, ROUTING_FAILED } from '../../utils/utils'
import { DicomsRoutingItem } from './DicomsRoutingItem';
// import { DicomRouterContext } from '../../utils/DicomRouterContext';
import { getFetcher, postFetcher } from '@startlibs/utils';
import { DicomRouterContext } from '../../utils/DicomRouterContext';
import { jwtPostFetcher } from '../../utils/authFetch';
import { getJwt } from '../../hooks/useJwt';

const FailedWarning = styled.div`
  background: #f9eaea;
  color: #c3282d;
  padding: 1rem;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const WidgetIcon = styled(Icon)`
  font-size: 33px !important;
  line-height: 21px !important;
  margin-right: 1.25rem !important;
`

export const DicomsRoutingPanel = ({ close }) => {

  const { routingDicomsList, setRoutingDicomsList, dicomRouterJwt, setDicomRouterJwt } = useContext(DicomRouterContext)
  const [{ refs }, maximized, toggleMaximized] = usePanelUtils2()
  const panelRef = refs.panel
  const scrollContainerRef = refs.scrollContainer
  const timeoutIdsRef = useRef({});
  const routing = routingDicomsList.filter(({ status }) => status === CREATED || status === RECEIVED || status === DOWNLOADED || status === RETRY);
  const failed = routingDicomsList.filter(({ status }) => status === ROUTING_FAILED)
  const isRouting = routing.length > 0
  const [isRetryingAll, setIsRetryingAll] = useState(false)

  const retryAll = () => {
    setIsRetryingAll(true);
    // get failed ones and retry
    for (let index = 0; index < routingDicomsList.length; index++) {
      const item = routingDicomsList[index];
      if(item.status === ROUTING_FAILED){
        retryOne(index);
      }
    }
    setIsRetryingAll(false);
  }
  
  const retryOne = (index) => {
    return new Promise((resolve, reject) => {
      let itemId = routingDicomsList[index].id;
      postFetcher(
        (window.DICOM_ROUTER_ORIGIN || "")  +`/dicom-routing?jwt=${dicomRouterJwt}`,
        {routingId: itemId },{
          method: 'PUT'  
        })
        .then((response) => {
          setRoutingDicomsList(prevState => {
            return prevState.map((item, idx) => {
              if (idx === index) {
                return {...item, status: RETRY};
              }
              return item;
            });
          });
          resolve(); // Resolve the promise once the update is done
        })
        .catch((err) => {
          console.log(err);
          reject(err); // Reject the promise in case of error
        })
    });
  }

  useEffect(() => {

    // Clear previous timeouts
    Object.values(timeoutIdsRef.current).forEach(clearTimeout);
    timeoutIdsRef.current = {};
    // setIsUnarchiving(true);  // Reset unarchiving flag

    if (routingDicomsList && routingDicomsList.length > 0) {
      routingDicomsList.forEach((item) => {
        if (item.status === CREATED || item.status === RECEIVED || item.status === DOWNLOADED || item.status === RETRY) {
          const itemId = item.id;

          const checkRoutingStatus = () => {
            getFetcher((window.DICOM_ROUTER_ORIGIN || "") +`/dicom-routing/${itemId}?jwt=${dicomRouterJwt}`)
              .then((response) => {

                // If we need to check again, we store the timeoutId
                if (response.status === CREATED || response.status === RECEIVED || response.status === DOWNLOADED) {
                  timeoutIdsRef.current[itemId] = setTimeout(
                    checkRoutingStatus,
                    8000
                  );
                } else {
                  setRoutingDicomsList((prev) =>
                    prev.map((dcm) => {
                      if (dcm.id === itemId) {
                        return {
                          ...dcm,
                          status: response.status,
                        };
                      }
                      return dcm;
                    })
                  );
                  // setInvalidateCache(true);
                  // We clear the timeoutId when no longer needed
                  delete timeoutIdsRef.current[itemId];
                }

              })
              .catch((e) => {
                console.log('error', e);
              });
          };

          // // We store the initial timeoutId if it has not been stored yet
          if (!timeoutIdsRef.current[itemId]) {
            timeoutIdsRef.current[itemId] = setTimeout(
              checkRoutingStatus,
              8000
            );
          }
        }
      });
    }

    
    return () => {
      // On cleanup, we clear all timeouts
      Object.values(timeoutIdsRef.current).forEach(clearTimeout);
    };
  }, [routingDicomsList]); // Add maximized to the dependencies array

  useEffect(() => {
    // Function to refresh the token
    const refreshDicomRouterToken = () => {
      jwtPostFetcher(getJwt())("/api/admin/dicomRouterToken")
        .then(r => {
          setDicomRouterJwt(r.jwt);
        })
        .catch(error => {
          console.error("Error refreshing token:", error);
        });
    };

    // Refresh the token immediately on component mount
    // refreshDicomRouterToken();

    // Set up the interval to refresh the token every 5 minutes 
    const intervalId = setInterval(refreshDicomRouterToken, 1000 * 60 * 4 );

    // Clear the interval when the component is unmounted or dependencies change
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    toggleMaximized(true)
  }, [])

  return <WidgetPanelComponent
    className="JS-fix-dialog-scroll" maximized={maximized} ref={panelRef}
  >
    <WidgetHeader onClick={toggleMaximized} onTouchStart={touchClick(toggleMaximized)}>
      {isRouting && <Loading white />}
      <div css="margin-right:auto;">
        {
          isRouting
            ? <div>
                <h4>Routing</h4>
                <span css="opacity:0.6;">{routingDicomsList.length-routing.length}/{routingDicomsList.length} completed</span>
              </div>
            : <div css="margin-right:auto;display:flex;align-itens:center;">
                <WidgetIcon icon="route" />
                <h4>Routing completed</h4>
              </div>
        }
      </div>
      {maximized && <>
        <PanelButton icon="arrow-down-line" />
        {!isRouting && <PanelButton icon="x" onClick={() => {
          setRoutingDicomsList([])
          close()
          }} />}
      </>
      }
    </WidgetHeader>
    {failed && failed.length > 0 && <FailedWarning>
      <span><b>{failed.length} study routing{failed.length > 1 ? 's' : ''} failed</b></span>
      <a className='link' onClick={retryAll} disabled={isRetryingAll}>Retry all</a>
    </FailedWarning>}
    <WidgetBody ref={scrollContainerRef}>

      {routingDicomsList && routingDicomsList.length > 0
        ? <FlipList>
          {routingDicomsList && routingDicomsList.map((routeItem, idx) =>
            <DicomsRoutingItem key={idx} index={idx} study={routeItem?.study} pacsNode={routeItem?.pacsNode} status={routeItem?.status} retry={retryOne} />)
          }
        </FlipList>
        : <div css="text-align:center; padding: 1rem;">
          <p>No studies being routed</p>
        </div>
      }
    </WidgetBody>
  </WidgetPanelComponent>
}