import {TextInput} from '@startlibs/form'
import React, { useRef} from 'react'
import {Card, SectionHeading} from '../../components/PageLayout';
import { SplitColumnsContainer } from '@startlibs/components';

export const CaseReconciliation = () => {

  const headerRef = useRef()

  return <>
    <SectionHeading ref={headerRef}><h3>Case to EHR reconciliation</h3></SectionHeading>
    <Card css="padding-bottom:1.5rem;">
      <p>Insert the information below to export this case to your EHR system</p>
      <SplitColumnsContainer>
          <TextInput
            label="Medical Record Number"
            helpText="(Patient Identification Number)"
            css="min-width: 400px;"
            path="caseInfo.ehrInfo.mrn"
          />
          <TextInput
            label="Order Number"
            path="caseInfo.ehrInfo.orderNumber"
          />
      </SplitColumnsContainer>
    </Card>
  </>
}