import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {getColor} from "@startlibs/utils";
import {createGlobalStyle, css} from "styled-components";
import {Button} from "@startlibs/components";


export const ToolsContainer = styled.div`
  background: ${getColor('gray240')};
  padding: 0 0.125rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: 0.225s;
  max-height: 0;
  pointer-events: none;
  overflow: hidden;
`

export const RichTextContainer = styled.div`
  position:relative;
  width: 100%;
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  background: white;
  margin-bottom: 1rem;
  ${props => props.hasErrors && `border-color: ${getColor(props)('alert')}`};
  ${props => props.showToolbar && `
    ${ToolsContainer} {
      transition: 0.5s;
      max-height: 7.5rem;
      transition: 0.6s;
      pointer-events: auto;
    }
  `};
  ${props => props.focus && `
    border-color: ${getColor('gray180')};
    box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.1);
  `}
  ${props => props.locked && css`
    padding-right: 2.25rem;
    background: rgba(0,0,0,0.075);
  `}
  ${props => props.readOnly && css`
    padding-right: 2.25rem;
    background: rgba(0,0,0,0.075) !important;
  `}
  ul {
    list-style-type: disc;
  }
  ul, ol {
    padding-inline-start: 25px;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }
`


export const ToolButton = styled(Button)`
    min-height: 2rem;
    min-width: 2rem;
    background: transparent;
    box-shadow: none;
    margin: 0.25rem 0.125rem;
    & ~ & {
      margin-left: 0.125rem;
    }
    .icon {
      font-size: 23px;
      color: ${getColor('gray90')};
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%,-50%);
              transform: translate(-50%,-50%);
    }
    &:hover, &.activated:hover {
      background: rgba(0,0,0,0.13);
    }
    &.activated {
      background: rgba(0,0,0,0.1);
    }
    &:focus:after {
      content: none;
    }
`

export const ToolSeparator = styled.span`
    border-left: 1px solid ${getColor('gray180')};
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    line-height: 2rem;
    vertical-align: -6px;
`
export const RichTextInput = styled.div`
  width: 100%;
  font-size: 14px;
  outline: 0;
  outline: none;
  color: ${getColor('gray60')};
  background: white;
  min-height: ${props=>props.minHeight || 78}px !important;
  outline: 0;
  padding: 0.7rem;
  overflow: hidden;
  border-radius: 5px;
  p {
    font-size: 14px;
  }
  td {
    font-size: 14px;
  }
  td div{
    font-size: 14px;
  }
  table-content{
    font-size: 14px;
  }
  table-content div{
    font-size: 14px;
  }
  b, strong {
    font-weight: 600;
  }
  &[contenteditable=true]:empty:before {
    content: attr(placeholder);
    color: rgba(0,0,0,0.3);
  }
  ${props => props.disabled && `
    &[disabled] {
      background: rgba(0, 0, 0, 0.075) !important;
      opacity: 0.75 !important;
    }
  `}
  ${props => props.readOnly && `
    &[readOnly] {
      background: rgba(0, 0, 0, 0.075) !important;
      opacity: 0.75 !important;
    }
  `}
`

export const CustomSafariStyle = createGlobalStyle`
  span[data-slate-placeholder='true'] {
    margin-top: -19px;
  }
`

export const TableSlateStyle = createGlobalStyle`
td div{
  font-size: 12px;
  vertical-align: initial;
}
table-content{
  display: block;
  font-size: 12px;
  min-height: 16px;
  vertical-align: initial;
}
table-content div{
  font-size: 12px;
  vertical-align: initial;
}
td p{
  min-height: 16px;
}
table{
  margin: 10px 0;
  border-collapse:collapse;
  min-width: 100%;
}
.table-horizontal-toolbar {
  user-select: none;
  white-space: nowrap;
  z-index: 1;
  margin: 0px;
  height: 4px;
}
.table-horizontal-toolbar .table-dragger-item {
  display: inline-block;
  height: 0px;
  background: blue;
  position: relative;
}
.table-horizontal-toolbar .table-dragger-item .table-trigger {
  position: absolute;
  right: -4px;
  top: -1px;
  width: 7px;
  height: 0px;
  z-index: 10;
  cursor: col-resize;
}
.table-horizontal-toolbar .table-dragger-item .table-trigger:hover {
  background: #0589f3;
}
.table-horizontal-toolbar .table-dragger-item .table-trigger:active {
  background: transparent;
}

table p {
  margin: 0;
}
.table {
  // margin: 4px 0;
  overflow: auto;
  // border: solid 1px #000;
  border-spacing: 0px !important;
}
.table-tr {
  border: none;
}
.table-td {
  padding: 2px;
  box-sizing: border-box;
  border: solid 1px #5a5a5a;
  vertical-align: baseline;
  min-width: 0;
}
.table-td ::selection {
  color: white;
  background: #3390FF;
}
.table-td.selectedCell {
  background: #b9d3fc !important;;
}
td{
  padding: 2px;
  box-sizing: border-box;
  border: solid 1px #000;
  vertical-align: baseline;
  min-width: 32px;
}, 
td ::selection {
  color: white;
  background: #3390FF;
}
td.selectedCell {
  background: #b9d3fc !important;
}
.table-content {
  overflow: auto;
}
`

export const ImageSlateStyle = createGlobalStyle`
  .rtfImgSmall{
    max-width: 33%;
    max-height: 240px;
    margin: 10px 0;
    transition: max-width 0.3s ease-in, max-height 0.3s ease-in;
    pointer-events: all !important;
  }
  .rtfImgMedium{
    max-width: 50%;
    max-height: 360px;
    margin: 10px 0;
    transition: max-width 0.3s ease-in, max-height 0.3s ease-in;
    pointer-events: all !important;
  }
  .rtfImgLarge{
    max-width: 80%;
    max-height: 576px;
    margin: 10px 0;
    transition: max-width 0.3s ease-in, max-height 0.3s ease-in;
    pointer-events: all !important;
  }
  .rtfImgFull{
    max-width: 100%;
    max-height: 720px;
    margin: 10px 0;
    transition: max-width 0.3s ease-in, max-height 0.3s ease-in;
    pointer-events: all !important;
  }
`