import { Icon, Loading } from '@startlibs/components';
import { css } from 'styled-components';
import { formatDate, getColor, media } from '@startlibs/utils';
import React from 'react'
import styled from 'styled-components/macro';
import { dicomDateToDate } from '../../utils';
import { CREATED, DOWNLOADED, RECEIVED, RETRY, ROUTING_COMPLETED, ROUTING_FAILED } from '../../utils/utils';

export const RecordItemStyled = styled.div`
  background: #f0f6f8;
  border-radius: 6px 6px 0px 0px;
  position: relative;
  padding: 1rem;
  padding-bottom: 8px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  & ~ & {
    margin-top: 0.75rem;
  }
  ${props => !props.isOnPanel && css`
    padding: 1.5rem;
    margin-top: 1rem;
    ${media.desktop `
      border: 1px solid #cddee3;
      padding: 0.75rem 1.5rem 0.75rem 7rem;
      min-height: 78px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}
  `}
  hr{
    background: rgba(0, 0, 0, 0.06);
    width: 100%;
    padding: 0;
    margin-top: 2px;
    margin-bottom: 2px;
    height: 0px !important;
  }
`
export const DicomIcon = styled(Icon).attrs({icon:'dicom'})`
  font-size: 62px;
  position: absolute;
  left: 0;
  width: 7rem;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  ${media.mobile `
    display: none;
  `}
`
const PatientName = styled.div `
  font-weight: bold;
  font-size: 13px;
`

const PacsName =  styled.div`
  color: rgba(0, 0, 0, 0.50);
  font-size: 12px;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  border-radius: 0px 0px 6px 6px;
  border-top: 1px solid rgba(0,0,0,0.1);
  background: #f0f6f8;
`
export const RecordDescription = styled.div `
  margin-top: 3px;
  font-size: 13px;
  ${props => !props.isOnPanel && `
    font-size: 12px;
    margin-top: 1px;
  `}
  ${props => props.small && `
    color: rgba(0,0,0,0.5);
    font-size: 11px;
  `}
`
export const Progress = styled.div`
  width: ${props => props.progress || 0}%;
  height: 100%;
  min-width: 4px;
  display: block;
  border-radius: 30px;
  background-color: ${getColor('main')};
  transition: 0.25s linear;
  ${props => props.completed && css`
    background-color: ${getColor('success')};
    width: 100%;
  `}
  ${props => props.failed && css`
    background-color: ${getColor('alert')};
  `}
`

export const UploadedIcon = styled(Icon)`
  font-size: 20px;
  width: 28px;
  height: 28px;
  line-height: 29px;
  text-align: center;
  color: #39B54A;
  background-color: #cae7d5;
  display: inline-block;
  border-radius: 50%;
`
const WarningIcon = styled(Icon)`
  text-align: center;
  color: ${getColor('alert')};
  display: inline-block;
  font-size: 24px;
  width: 28px;
  border-radius: 50%;
  height: 28px;
  line-height: 25px;
  background: #ebe0e3;
  padding-top: 1px;
` 

export const DicomsRoutingItem = ({index, study, pacsNode, status, retry}) => {

  const studyDate = dicomDateToDate(study.info.studyDate)
  const formattedDate = !isNaN(studyDate) ? formatDate(studyDate, 'MM-dd-yyyy') : ''
  
  return <>
  <RecordItemStyled isOnPanel>
      <div style={{maxWidth: '223px'}}>
        <PatientName>Patient: {study?.info?.patientName?.trim()}</PatientName>
        <RecordDescription isOnPanel>{
            study?.info?.modalities + 
            (study?.info?.modalities && " - ") + 
            (study?.description || "DICOM Study") + " " +
            (study.info.studyDate 
              ? `(${formattedDate})`
              : ""
            )
          }
          {/* study?.study?.modalities}{study?.study?.modalities && " - "}{study?.study?.studyDescription} {study?.study?.studyDate && <span className='nowrap'>({formatDate(dicomDateToDate(study?.study?.studyDate), 'yyyy-MM-dd')})</span>} */}
        </RecordDescription>
      </div>
      {status === ROUTING_COMPLETED && <UploadedIcon icon="check"/>}
      {(status === CREATED || status === RECEIVED || status === DOWNLOADED || status === RETRY) && <Loading size={26} borderWidth={4}/>}
      {status === ROUTING_FAILED && <WarningIcon icon="warning"/>}
  </RecordItemStyled>
  <PacsName>
    <span>Destination: {pacsNode?.name}</span>
    {status === ROUTING_FAILED && <a className="link" onClick={() => retry(index)}>Retry</a>}
  </PacsName>
  </>
}