export { formatRecord, createNewRecord, isMultiFile, isDicom } from './recordUtils'
export { lazyAuth } from './lazyAuth'
export { lazyRecords } from './lazyRecords'
export { getFileNameAndExtension, getUUID } from './common'
export { RecordClass, RecordFormat } from './recordEnums'
export { readFileAsText, readFile, tryReadingFile } from './readFile'
export { checkCCDAXSLT } from './checkXSLT'
export { BlackListedError, EmptyFileError } from './ProcessingErrors'
export { getDicomInstanceJson } from './dicomInstance'
export { viewRecord } from './viewRecord.jsx'
export {
  PATHOLOGY_EXTENSIONS,
  IGNORED_FILE_PATTERNS,
  IGNORED_FILES,
  VALID_EXTENSIONS,
  EXECUTABLES_EXTENSIONS,
  SETTINGS_EXTENSIONS,
  SYSTEM_EXTENSIONS,
} from './fileExtensionLists'
export { FileState } from './FileStateEnum'
export const isDev = process.env.NODE_ENV === 'development'

