import React, { useEffect } from 'react'
import {
  CASE_ARCHIVED,
  CASE_CLOSED, CASE_DRAFT, PENDING_ASSIGNMENT, PENDING_REQUEST,
  REQUEST_REJECTED,
  WAITING_APPROVAL,
  WAITING_MEDICAL_RECORDS
} from '../enums/CaseState'
import {PatientApprovalSuccess} from './status/PatientApprovalSuccess'
import {PatientWaiting} from './status/PatientWaiting'
import {PatientRejected} from './status/PatientRejected'
import {PatientUpdatesRequested} from './status/PatientUpdatesRequested'
import {PatientPendingApproval} from './status/PatientPendingApproval'
import {isDigitalPaymentPending} from '../request/utils'
import {isPendingAskingMore} from './utils/patientUtils'
import { lazyProviderInfo, lazyUserInfo } from '../components/WithProvider'

export const PatientStatus = ({caseRequest,setCaseRequest}) => {

  const providerInfo = lazyProviderInfo.read()

  // Fullstory disabled for now
  // useEffect(() => {
  //   window.FS.setUserVars({
  //     displayName: caseRequest.requestCode,
  //     requestCode : caseRequest.requestCode,
  //     requestId: caseRequest.requestId,
  //     type: "PATIENT",
  //     customer: providerInfo.name
  //   })
  //   var now = new Date()
  //   var eventProperties = {
  //     customer_str: providerInfo.name,
  //     dateTime_date: now,
  //     requestId: caseRequest.requestId,
  //     caseId_str: caseRequest.requestCode,
  //     caseState_str: caseRequest.state
  //   }
  //   window.FS.event('Patient Case Accessed',eventProperties)
  // },[])

  if (caseRequest.recentlySubmitted) {
    return <PatientWaiting path="processing" caseRequest={caseRequest}/>
  }

  const requestedPayment = caseRequest.caseFlags?.requestFlags?.askForPayments && !!caseRequest.payments.find(isDigitalPaymentPending)
  const requestedSigning = caseRequest.caseFlags?.requestFlags?.askForServiceTerms && !caseRequest.acceptanceInfo?.acceptedTerms
  
  if (caseRequest.state === WAITING_MEDICAL_RECORDS || requestedPayment || requestedSigning || isPendingAskingMore(caseRequest)) {
    return <PatientUpdatesRequested caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>
  }  else if (caseRequest.caseFlags?.requestFlags?.askedMedicalRelease && caseRequest.state === CASE_DRAFT) {
    return <PatientUpdatesRequested caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>
  } if (caseRequest.state === WAITING_APPROVAL) {
    return <PatientPendingApproval caseRequest={caseRequest}/>
  } else if (caseRequest.state === CASE_ARCHIVED || caseRequest.state === REQUEST_REJECTED) {
    return <PatientRejected caseRequest={caseRequest}/>
  } else {
    return <PatientWaiting caseRequest={caseRequest}/>
  }
}
