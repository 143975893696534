import {Button, Icon, Loading} from '@startlibs/components';
import {Errors, FormValue, TextInput, WithForm} from '@startlibs/form';
import _ from 'lodash/fp'
import React, { useEffect, useRef, useState } from 'react'
import {Card, CardWithIcon, PageContainer, RequestStatusIcon,} from '../../components/PageLayout';
import {CaseRequestCard} from '../CaseRequestCard';
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog';
import {ContactCard} from '../../components/ContactCard';
import {ContactSelectBoxes} from './RecordsStep';
import {ContactSelectInput} from '../../components/ContactSelectInput';
import {Header} from '../../components/Header';
import {PurviewFooter} from '../../components/PurviewFooter';
import {getJwt} from '../../hooks/useJwt';
import {jwtPostFetcher} from '../../utils/authFetch';
import {getColor, media, will} from '@startlibs/utils'
import {CASE_DRAFT} from '../../enums/CaseState'
import {REQUESTED} from '../../enums/ContactRelationKind'
import {isPendingAskingMore} from '../../patient/utils/patientUtils'
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { index } from 'd3';
import { formatDateLogs } from './ContactPatientStep';
import { ThreadCard } from './ThreadCard';
import { buildValidation, required } from '../../utils/validation';
import { setNotification } from '../../components/Notifications';


const MessageCard = styled.div`
  width: 100%;
  background: #f0f0f0;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  position: relative;
  margin: 0 0 1.5rem;
`

const CardHeader = styled.div`  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #e6e6e6;
  padding: 1rem;
  align-items: center;
`

const CardBody = styled.div`  
  padding: 1rem;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleLine = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  align-items: center;
  display: flex;
`

const AuthorLine = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  align-items: center;
  display: flex;
  margin-bottom: .5rem;
`

const DateSpan = styled.span`
  font-size: 11px;
  font-weight: 400;
  align-items: center;
  display: flex;
  margin-top: .25rem;
`

const InfoBadge = styled.span`
    border-radius: 20px;    
    font-size: 11px;
    font-weight: 500;
    padding: 0 0.75rem;
    margin: 0px 8px;
    ${props => props.type === 'Admin' && `
      background: #e2e2e2;
      color: #555555;
    `}
    ${props => props.type === 'Patient' && `
      background: #cce4d4;
      color: #009900;
    `}
    ${props => props.type === 'request' && `
      margin: 0px 4px;
      background: transparent; 
      color: #555555;
      border: 1px solid #555555;
    `}

`

const PaddedContainer = styled.div`
  padding-right: 13rem;
  position: relative;
  ${media.max(520)`
    padding-right: 13rem;
  `}
`

const SizedIcon = styled(Icon)`
  font-size: 18px;
  font-weight: 500;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  margin-right: .5rem;
`

const ReturnButton = styled(Button) `
  box-shadow: none;
  color: ${getColor('gray60')};
  font-weight: bold;
  margin-bottom: 1.5rem;
  ${Icon} {
    font-size: 20px;
  }
`

export const WaitingPatientUpdates = ({caseRequest,setCaseRequest, thread, setShouldReloadData, isLoadingCollab}) => {
  // const paymentIsPending = isOfflinePaymentPending(caseRequest)
  const isAccepted = _.get('acceptanceInfo.accepted',caseRequest) && _.get('medicalRecordsReleaseLicense',caseRequest)

  const alreadyPaid = useConfirmDialog(<ConfirmDialog
    title="It is longer possible to revoke acceptance"
    closeButton={<Button onClick={() => window.location.reload()}>Close</Button>}
  >
    <p>This case has already been accepted. Click below to refresh the page and see the case.</p>

  </ConfirmDialog>)

  const revokeConfirm = useConfirmDialog(
    <ConfirmDialog
      title="Revoke request"
      confirm={<Button alert>Revoke</Button>}
      action={(v) => jwtPostFetcher(getJwt())("/api/admin/revokeRequestMedicalRecords?requestId="+caseRequest.requestId,v)}
      onSuccess={will(setCaseRequest,_.flow(isPendingAskingMore(caseRequest) ? _.identity : _.set("state", CASE_DRAFT),_.set('caseFlags.requestFlags',{})))}
    >
      <p>The contacted party will no longer have editing permissions.</p>
      <CaseRequestCard caseRequest={caseRequest} viewOnly />
      <p>Do you wish to proceed?</p>
    </ConfirmDialog>
  )

  const reminderConfirm = useConfirmDialog(
    <ConfirmDialog
      title="Send reminder"
      confirm={<Button highlight icon="email">Send</Button>}
      values={caseRequest.caseFlags}
      action={(v) => jwtPostFetcher(getJwt())("/api/admin/sendRequestMedicalRecordsReminder?requestId="+caseRequest.requestId,v)}
      notify="Reminder sent successfully."
    >
      <p>Remind the listed party to complete requested actions on the following case:</p>
      <CaseRequestCard caseRequest={caseRequest} viewOnly />
      <p>The reminder will be sent to:</p>
      <ContactSelectBoxes path="contactRelationType">
        <ContactSelectInput
          raw
          value={true}
          fieldValue={true}
          caseRequest={caseRequest}
          relationKind={REQUESTED}
          viewOnly
        >
        </ContactSelectInput>
      </ContactSelectBoxes>
      <TextInput
        textarea
        autoResize
        label="Message"
        path="message"
        descText="This will be included in the reminder email."
        placeholder="Insert custom message here."
      />
    </ConfirmDialog>
  )

  const VIEW = 'VIEW';
  const NEW = 'NEW';
  const REPLY = 'REPLY';
  const [isSending, setIsSending] = useState(false)
  const [mode, setMode] = useState(VIEW)
  const [replyId, setReplyId] = useState(null)
  const msgFormRef = useRef()
  const msgFieldRef = useRef()
  const preValidationMsg = buildValidation({
   'message': [
      (v) => !v && required(),
      (v) => v?.length > 3000 && 'Message: Maximum character limit of 3000 reached.'
    ]
  })
  const replyAction = (id) => {
    setReplyId(id)
    setMode(REPLY)
  }

  useEffect(() => {
    if (replyId) {
      msgFieldRef.current.focus()
    }
  }, [replyId])

  return <><PageContainer css="padding-bottom: 1rem; min-height: 600px;">
      <Header title="Message case contact">
      </Header>
      <Card>
        {mode === VIEW && <PaddedContainer>
          <RequestStatusIcon icon="clock"/>
          <h3>
            Waiting for updates from the case contact
          </h3>
          <ContactCard
            caseRequest={caseRequest}
            relationKind={REQUESTED}
          />          
          <p css="margin-bottom:1.5rem;">If the contact takes too long to provide the updates, you can send them a new message or revoke this update request, allowing you to edit the related steps again.</p>
        </PaddedContainer>}
        {isLoadingCollab && <Loading css="margin: 6rem auto" />}
        {!isLoadingCollab && caseRequest.requestCollaborationId && mode === VIEW &&
          <ThreadCard 
            thread={thread} 
            caseRequest={caseRequest} 
            setShouldReloadData={setShouldReloadData}
            replyAction={replyAction}
          />
        }
        
        {(mode === REPLY) && <>
          <ReturnButton 
            noShadow rounded color="gray240" icon="return"
            onClick={() => {
            setMode(VIEW)
            setReplyId(null)
          }}>Go back</ReturnButton>
          {replyId && replyId > 0 && replyId != null && <ThreadCard thread={thread} caseRequest={caseRequest} isReplying/>}
          <WithForm
            preValidation={preValidationMsg}
            action={(values) => {
              setIsSending(true)
              return jwtPostFetcher(getJwt())(`/api/collaborations/${replyId}/messages?requestId=${caseRequest.requestId}`, {...values} ,{method: 'POST'})
            }}
            onSuccess={(values) => {
              if(setShouldReloadData){
                setShouldReloadData(true)
              }
              setNotification("Message sent")
              setIsSending(false)
            }}
            ref={msgFormRef}
            onFailure={() => {
              setIsSending(false)
            }}
          >
            {form => <>
              <TextInput
                textarea
                ref={msgFieldRef}
                autoResize
                placeholder="Type your message here..."
                path="message"
                mandatory={true}
                label={mode === NEW ? 'Write your message' : 'Reply message'}
                onChange={(e) => {
                  if(e.length > 3000){
                    form.addErrors({message: 'Message: Maximum character limit of 3000 reached.'})
                  }else{
                    form.clearErrors('message')
                  }
                }}
                bellowDescText={
                  <FormValue path="message">{message => {
                    if(message){
                      return <span style={{ 
                        display: 'block',
                        width: '100%',
                        textAlign: 'right'
                      }}>{message?.length+"/3000"}</span>
                    }
                  }}</FormValue>
                }
              />
              <Errors css="margin-bottom: 1rem;"/>
              <div css="text-align: right">
                <Button
                  highlight
                  type="submit"
                  icon="email"
                  isLoading={isSending}
                >
                  Send message
                </Button>
              </div>
            </>}
          </WithForm>
        </>}
        {/* WITHOUT COLLAB ID - PREVIOUS REQUEST EXISTING - WITHOUT THREAD */}
        {!isLoadingCollab && !caseRequest.requestCollaborationId && <ThreadCard caseRequest={caseRequest}/>}
        <div css="margin-top:2rem; text-align: end;">
          {!caseRequest.requestCollaborationId && <Button highlight icon="email" onClick={reminderConfirm}>Send a reminder</Button>}
          {mode === VIEW  && <Button onClick={revokeConfirm} hover="alert">Revoke request</Button>}
        </div>
      </Card>
  </PageContainer>
  {/* <PurviewFooter/> */}
</>
}
