import React from 'react'


export const SUPER = 'SUPER'
export const DIVISION = 'DIVISION'
export const OPERATOR = 'OPERATOR'

export const ROLE_LABELS = {
  [SUPER]: 'Super admin',
  [DIVISION]: 'Division admin',
  [OPERATOR]: 'Operator'
}

export const REVIEWER_TYPE_ADMIN = 'ADMIN'
export const REVIEWER_TYPE_EXPERT = 'EXPERT'