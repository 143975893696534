import {callIfFunction, getColor, media} from '@startlibs/utils';
import React from 'react';
import styled, {css} from 'styled-components';
import {getContact} from '../enums/ContactRelationKind'
import {CheckboxGroup, SimpleCheckbox, useFormValue} from '@startlibs/form'
import _ from 'lodash/fp'
import {useIntl} from 'react-intl'

export const SimpleContactCard = styled(({name,email,phone,type,className,children,onClick}) => <div className={className} onClick={onClick}>
  <h3>{name ?
    <span><span className='fs-exclude'>{name}</span>{type && <span className="contact-type"> ({type})</span>}</span>
    : <span>{type && <span className="contact-type">{type}</span>}</span>
  }
  </h3>
  <div className="contact-info">
    {email && <div className='fs-exclude'><strong>Email:</strong> {email}</div>}
    {phone && <div className='fs-exclude'><strong>Phone:</strong> {phone}</div>}
  </div>
  {children}
</div>
)`
  background: ${getColor('gray240')};
  border: 1px solid ${getColor('gray210')};
  border-radius: 5px;
  padding: 1rem;
  position: relative;
  margin: 0 0 1rem;
  h3 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: .25rem;
  }
  a {
    position: absolute;
    top: .5rem;
    right: .5rem;
    text-align: right;
  }
  .contact-type {
    text-transform: capitalize;
  }
  .contact-info {
    display: flex;
    flex-wrap: wrap;
    > div {
      flex-grow: 1;
      white-space: nowrap;
      ${media.max(420)`
        white-space: normal;
      `}
      :first-child {
        margin-right: 1rem;
      }
    }
  }
  ${props => props.selectable && css`
    cursor: pointer;
  `}
  ${props => props.selectable && props.checked && css`
    background: ${getColor('main')};
    color: white;
  `}
  ${props => props.selectable && props.isRadioBox && css`
    padding-left: 3.75rem;
    :before {
      border-radius: 50%;
      content: '';
      border: 1px solid ${getColor('gray210')};
      width: 18px;
      height: 18px;
      background-color: white;
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  `}
  ${props => props.selectable && props.isRadioBox && props.checked && css`
    :after {
      content: '';
      width: 8px;
      height: 8px;
      top: 50%;
      transform: translateY(-50%);
      left: 17px;
      border-radius: 50%;
      position: absolute;
      background-color: ${getColor('main')};
    }
  `}
  ${props => props.selectable && props.focused && css`
    :before {
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
      border-color: ${getColor('gray180')};
    }
  `}
`


export const ContactCard = ({caseRequest, relationKind, ...props}) => {
  const intl = useIntl()
  const contact = getContact(intl, caseRequest, relationKind)

  return <SimpleContactCard
    name={contact.name}
    email={contact.email}
    phone={contact.phone}
    type={contact.relationType}
    {...props}
  />
}



export const ContactCheckbox = styled(({email, name, type, isFirst, isLast, className, path,setValue=(v) => !v,getValue = _.identity}) => {
  const [rawChecked, setChecked] = useFormValue(path)

  const toggleEmail = () => setChecked(setValue)

  const checked=  callIfFunction(getValue,rawChecked)

  return <SimpleContactCard
    className={className}
    onClick={toggleEmail}
    name={name}
    email={email}
    type={type}
    checked={checked}
    selectable={!(isFirst && isLast)}
  >
    {!(isFirst && isLast) && <SimpleCheckbox raw value={checked} setValue={_.identity}/>}
  </SimpleContactCard>
})`
  padding-right: 3.75rem;
  margin-bottom: 0;
  border: none;
  border-radius: 0;
  & ~ & {
    border-top: 1px solid ${getColor('gray210')};
  }
  ${SimpleCheckbox} {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const ContactCheckboxGroup = styled(CheckboxGroup)`
  margin-bottom: 1.5rem;
  border: 1px solid ${getColor('gray210')};
  border-radius: 5px;
  overflow: hidden;
`