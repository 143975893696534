import {getColor, isMobile} from '@startlibs/utils';
import { useToggle } from '@startlibs/core';
import React from 'react'
import styled from 'styled-components';
import { AuditLogTableColumn, AuditLogTableRow, DetailContainer } from './Table';
import {formatDateNoUTC} from '../../../utils/utils'
import {TransitionDiv} from '@startlibs/components';
import { IfLocale } from '../../../hocs/IfLocale';

const STATUS_TO_COLOR = {
  'normal': 'success',
  'stat': 'alert',
  'time sensitive': 'warning',
}

const CaseStatus = styled.span`
  color: ${props => getColor(STATUS_TO_COLOR[(props.status || "").toLowerCase()] || "gray120")(props)};
  font-weight: 600;
`

export const shouldShowLogDetails = (event) => {
  return !(event?.info?.message?.length == 0 && Object.keys(event?.info).length == 1 && event?.info?.message !== null) 
  && (event?.message?.length > 0 || Object.keys(event?.info).length > 0) 
  && !(Object.keys(event?.info).length == 3 && event?.info?.askedMedicalRelease !== undefined && event?.info?.askedPatientDetails !== undefined && event?.info?.askedRecords !== undefined) // "Case Update Requested"
}

export const AuditRow = ({record}) => {
  // const uploadDate = strToDate(study.study.uploadDate)
  // const formattedUploadDate = isNaN(uploadDate.getTime()) ? "" : formatDate(uploadDate, DATE_FORMAT())
  //caseCode: "REW3R"
  // createdEpochMili: 1593725999778
  // event: "CASE_REVIEW_UPDATED_REQUESTED"
  // firstName: "Guga"
  // id: 1996
  // lastName: "111"
  // who: "admin@purview"
  const showDetails = useToggle()

  const formatPermissionKey = (key) => {
    // Insert spaces before uppercase letters and capitalize the first letter
    const formattedKey = key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
    return formattedKey;
  };
  
  return <AuditLogTableRow isMobile={isMobile()}>
    <AuditLogTableColumn className="date">
      <div>{formatDateNoUTC(new Date(record.createdEpochMili),"MM/dd/yyyy")} <span className="time">- {formatDateNoUTC(new Date(record.createdEpochMili),"hh:mm")}</span></div>
    </AuditLogTableColumn>
    <AuditLogTableColumn className="event">
      <div className="event-name">
        <strong>{record.event}</strong> <span className="nowrap id">(ID: {record.id})</span> {
        // !(record?.info?.message?.length == 0 && Object.keys(record?.info).length == 1 && record?.info?.message !== null) 
        // && (record?.message?.length > 0 || Object.keys(record?.info).length > 0) 
        shouldShowLogDetails(record)
          ? <a className="link" onClick={showDetails.toggle}>Details</a> 
          : null}
      </div>
      <TransitionDiv>
        {showDetails.isOpen && record?.info?.reason?.length > 0 && <DetailContainer><strong>Reason: </strong>{record?.info?.reason}</DetailContainer>}
        {showDetails.isOpen && record?.info?.REASON?.length > 0 && <DetailContainer><strong>Reason: </strong>{record?.info?.REASON}</DetailContainer>}
        {showDetails.isOpen && record?.info?.emails != null && <DetailContainer><strong>Emails: </strong>{record?.info?.emails?.split(',').join(', ')}</DetailContainer>}
        {showDetails.isOpen && record?.message?.length > 0 && <DetailContainer><strong>Message: </strong>{record?.message}</DetailContainer>}
        {showDetails.isOpen && record?.info?.recordDescription?.length > 0 && <DetailContainer><strong>Medical Record{record?.info?.recordDescription?.includes(',') ? 's' : null}: </strong>{record?.info?.recordDescription}</DetailContainer>}
        {showDetails.isOpen && record?.info?.expert?.length > 0 && <DetailContainer><strong>Expert: </strong>{record?.info?.expert}</DetailContainer>}
        {showDetails.isOpen && record?.info?.recipients?.length > 0 && <DetailContainer><strong>Recipients: </strong>{record?.info?.recipients.replaceAll(',',', ')}</DetailContainer>}
        {showDetails.isOpen && record?.info?.permission && (
          <DetailContainer>
            <strong>Permissions:</strong>
            <ul>
              {Object.entries(JSON.parse(record.info.permission))
                .filter(([key, value]) => record.event == 'Case Shared' && key != 'addNote')
                .map(([key, value]) => (
                  <li key={key}>
                    {`${formatPermissionKey(key)}: ${value ? 'Allowed' : 'Not allowed'}`}
                  </li>
                ))
              }
            </ul>
          </DetailContainer>
        )}
        {showDetails.isOpen && record?.info?.sendSecurely != null && <DetailContainer><strong>Send Securely: </strong>{record?.info?.sendSecurely ? 'Yes' : 'No'}</DetailContainer>}
        {showDetails.isOpen && record?.info?.expiration != null && <DetailContainer><strong>Expiration: </strong>{record?.info?.expiration == 0 ? 'Never' : record?.info?.expiration == 48 ? '48h' : record?.info?.expiration == 168 ? '1w' : ''}</DetailContainer>}
        {showDetails.isOpen && record?.info?.login != null && <DetailContainer><strong>Login: </strong>{record?.info?.login}</DetailContainer>}
        {showDetails.isOpen && record?.info?.subjectOrganization != null && <DetailContainer><strong>Organization: </strong>{record?.info?.subjectOrganization}</DetailContainer>}
        {showDetails.isOpen && record?.info?.subjectOrgId != null && <DetailContainer><strong>Org ID: </strong>{record?.info?.subjectOrgId}</DetailContainer>}
        {showDetails.isOpen && record?.info?.purposeOfUseCode != null && <DetailContainer><strong>Purpose of Use Code: </strong>{record?.info?.purposeOfUseCode}</DetailContainer>}
        {showDetails.isOpen && record?.info?.alert != null && <DetailContainer><strong>Alert: </strong>{record?.info?.alert}</DetailContainer>}
        {showDetails.isOpen && record?.info?.routingId != null && <DetailContainer><strong>Routing ID: </strong>{record?.info?.routingId}</DetailContainer>}
        {showDetails.isOpen && record?.info?.studyDescription != null && <DetailContainer><strong>Study Description: </strong>{record?.info?.studyDescription}</DetailContainer>}
        {/* {showDetails.isOpen && record?.info?.pacsNodeId != null && <DetailContainer><strong>PACS Node ID: </strong>{record?.info?.pacsNodeId}</DetailContainer>} */}
        {showDetails.isOpen && record?.info?.pacsNodeName != null && <DetailContainer><strong>PACS: </strong>{record?.info?.pacsNodeName}</DetailContainer>}
        {showDetails.isOpen && record?.info?.pacsNodeIp != null && <DetailContainer><strong>Host: </strong>{record?.info?.pacsNodeIp}</DetailContainer>}
        {showDetails.isOpen && record?.info?.pacsNodePort != null && <DetailContainer><strong>Port: </strong>{record?.info?.pacsNodePort}</DetailContainer>}
        {showDetails.isOpen && record?.info?.pacsNodeTitle != null && <DetailContainer><strong>Title: </strong>{record?.info?.pacsNodeTitle}</DetailContainer>}
        {/* {showDetails.isOpen && record?.info?.studyUid != null && <DetailContainer><strong>Study UID: </strong>{record?.info?.studyUid}</DetailContainer>} */}
        {/* {showDetails.isOpen && record?.info?.patientName != null && <DetailContainer><strong>Patient Name: </strong>{record?.info?.patientName}</DetailContainer>} */}
      </TransitionDiv>
    </AuditLogTableColumn>
    <IfLocale not contains="LEGAL">
      <AuditLogTableColumn className="case-id">{record.caseCode ? <a className="link" href={`/admin/request/${record.caseCode}`}>{record.caseCode}</a> : <span className="not-case">----</span>}</AuditLogTableColumn>
      <AuditLogTableColumn className="case-patient">{(record.firstName || record.lastName) ? <span>{record.firstName} {record.lastName}</span> : <span className="not-case">----</span>}</AuditLogTableColumn>
    </IfLocale>
    <IfLocale contains="LEGAL">
      <AuditLogTableColumn className="case-id-legal">{record.caseCode ? <a className="link" href={`/admin/request/${record.caseCode}`}>{record.caseCode}</a> : <span className="not-case">----</span>}</AuditLogTableColumn>
      <AuditLogTableColumn className="case-patient-legal">{(record.firstName || record.lastName) ? <span>{record.firstName} {record.lastName}</span> : <span className="not-case">----</span>}</AuditLogTableColumn>
    </IfLocale>
    
    <AuditLogTableColumn className="user">
      <div>{record.who}</div>
    </AuditLogTableColumn>
  </AuditLogTableRow>
}
