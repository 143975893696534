import React, {useContext} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {UploaderConfigContext} from "../../service/UploaderConfigContext";
import {useDoAction} from "../../service/hooks/useDoAction";
import {usePopupToggle, useToggle} from "@startlibs/core";
import {useUIDataSelector} from "../../service/hooks/useUIDataSelector";
import {useIsUploading, useUploadQueueSelector} from "../../service/hooks/useUploadQueueSelector";
import {useRecordsSelector} from "../../service/hooks/useRecords";
import {isOtherKnowExtension} from "../../dicom/FileParser";
import {isOfRecordClass} from "../../service/utils/recordUtils";
import {Pathology, Radiology} from "../../enums/RecordClass";
import {useUngroup} from "../hooks/useUngroup";
import {GroupAction} from "../../service/GroupAction";
import {UIAction} from "../../service/UIAction";
import {isMinified} from "../FileinputBox2";
import {Button, Icon, Loading, Popup} from "@startlibs/components";
import {TextInput} from "@startlibs/form";
import {customTheme, getColor} from "@startlibs/utils";
import {css} from "styled-components";
import {DEVICE, DISK} from "../../enums/UploaderStepsManagement";

const GroupHeading = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  color: ${getColor('gray150')};;
  margin-bottom: 1.5rem;
  .nameWrapper {
    padding: 0.25rem 0.5rem 0.25rem 0;
    margin-right: 0.25rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    border-radius: 5px;
    ${Icon} {
      display: none;
      font-size: 15px;
      vertical-align: -3px;
    }
  }
  .line {
    flex-grow: 1;
    border-bottom: 1px solid ${getColor('gray210')};
    flex-grow: 1;
    min-width: 150px;
  }
  .collapseOption {
    margin-left: 0.75rem;
  }
  ${props => props.canEdit && css`
    .nameWrapper {
      padding: 0.25rem 0.5rem;
      :hover {
        cursor: pointer;
        background: ${getColor('gray240')};
      }
      ${Icon} {
        display: inline-block;
      }
    }
    .collapseOption {
      margin-right: 0.5rem;
    }
  `}
  ${Button} {
    margin-left: .5rem;
  }
  ${props => (props.mode === DEVICE || props.mode === DISK) && css`
    margin-bottom: 0px;
  `}
  ${customTheme("GroupHeading")};
`

const ViewAllButtonContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
  min-height: 2rem;
  color: ${getColor('gray90')};
  a {
    margin-left: 0.75rem;
  }
  ${Icon} {
    position: absolute;
    right: 0.5rem;
    top: 0;
    font-size: 24px;
    color: ${getColor('gray180')};
    cursor: pointer;
    :hover {
      color: ${getColor('gray150')};
    }
  }
  ${Loading} {
    margin-right: 0.75rem;
  }
`

const HelpPopup = styled(Popup)`
  max-width: 330px;
  font-size: 12px;
  border-radius: 6px;
  padding: 1.5rem;
`

export const GroupHeaderSimple = ({group, isCollapsed, className}) => {
  const {
    mode
  } = useContext(UploaderConfigContext)
  const doAction = useDoAction()

  const toggleCollapsed = (updater) => doAction(UIAction.ToggleCollapsed, group.id)


  if (group.id === 'ungrouped') {
    return <span></span>
  }

  return <GroupHeading canEdit={false} isDragging={false} mode={mode} className={className}>
    
      <>
        {
          <div className="nameWrapper">
            <span>{group.name}</span>
          </div>
        }
        <div className="line"/>
        {
          <span className="collapseOption">
            {isCollapsed ?
              <><b>{group.items.length} record{(group.items.length > 1 || group.items.length === 0) ? 's' : ''}</b>
                <span className="light-link gray" css="margin-left:0.25rem;" onClick={toggleCollapsed}>Expand</span></>
              :
              <span className="light-link gray" onClick={toggleCollapsed}>Collapse</span>
            }
          </span>
        }
      </>
    
  </GroupHeading>

}