import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';


export const AreaChartFees = ({data, period}) => {

    const parsedData = data.map(d => ({
        ...d,
        date: d3.utcParse("%Y/%m")(d.month)
    }));
    const chartRef = useRef();
    
    const legendXTopOffset = data.length == 2 ? 0 : data.length == 3 ? -24 : -32

    function formatNumberAsCurrency(number) {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0, // Avoid showing any decimal places
        }).format(number);
      }

    useEffect(() => {
        const width = 600;
        const height = 360;
        const marginTop = 20;
        const marginRight = 20;
        const marginBottom = 60;
        const marginLeft = 80;

        const x = period == "MONTH" && data.length > 12 
        ? d3.scaleUtc()
            .domain(d3.extent(parsedData, d => d.date)) // Get the min and max date
            .range([marginLeft, width - marginRight])
        : d3.scaleBand()
            .domain(data.map(d => 
                    period == "QUARTER" ? d.quarter 
                    : period == "MONTH" 
                        // ? d.month && d.month.split(' ')[0].slice(0,3)+' '+d.month.split(' ')[1].slice(2,4)
                        ? d.month
                        : d.year
                    ))
                .range([marginLeft, width - marginRight])
                .padding(0.1);


        const y = d3.scaleLinear()
            .domain([0, d3.max(data, d => Math.max(d.totalFees))])
            .range([height - marginBottom, marginTop]);
        
        const startDate = d3.min(parsedData, d => d.date);
        const endDate = d3.max(parsedData, d => d.date);
        const intermediateDates = d3.timeMonths(startDate, endDate, 3);
        let tickValues = [startDate];
        for (let i = 0; i < intermediateDates.length; i++) {
            tickValues.push(intermediateDates[i]);
        }
        tickValues.push(endDate);

        const areaGenerator = d3.area()
            .x(d => x(d.date))
            .y0(height - marginBottom)
            .y1(d => y(d.totalFees))
            .curve(d3.curveMonotoneX);

        const areaTotal = d3.area()
            .x(d => 
                period == "QUARTER" 
                    ? x(d.quarter) + x.bandwidth() / 2
                    : period == "MONTH" 
                        ? x(d.month) + x.bandwidth() / 2
                        : x(d.year) + x.bandwidth() / 2
            )
            .y0(y(0))
            .y1(d => y(d.totalFees))
            .curve(d3.curveMonotoneX);

        const svg = d3.create("svg")
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, height])
            .attr("style", "max-width: 100%; height: auto;");

        svg.append("path")
            .datum(
                period == "MONTH" && data.length > 12 
                ? parsedData
                : data
            )
            // .attr("stroke", "lightgray")
            .attr("fill", "#015587")
            .attr("fill-opacity", 0.75)
            // .attr("d", lineTotal(data));
            .attr("d", period == "MONTH" && data.length > 12 
                ?   areaGenerator
                : areaTotal(data)
            );
            
        svg.append("g")
            .attr("transform", `translate(0,${height - marginBottom})`)

            .call(
                period == "MONTH" && data.length > 12 
                ? // d3.axisBottom(x).ticks(d3.timeMonth.every(3)).tickFormat(d3.utcFormat("%Y/%m"))
                 d3.axisBottom(x).tickValues(tickValues).tickFormat(d3.utcFormat("%Y/%m"))
                : d3.axisBottom(x).ticks(data.lenght).tickSizeOuter(0)
                // d3.axisBottom(x)
                // .tickValues(x.domain()) // Explicitly set tick values for "MONTH" period
                // .tickFormat(d => d) // You can further customize this if needed
                // .tickSizeOuter(0)
            )

            // .call(
            //     d3.axisBottom(x)
            //     .tickValues(x.domain()) // Explicitly set tick values for "MONTH" period
            //     .tickFormat(d => d) // You can further customize this if needed
            //     .tickSizeOuter(0)
            //     )
            .selectAll("text")
            .attr("transform", "rotate(60) translate(8,-6)")
            .attr("text-anchor", "start");

        // Horizontal lines
        svg.append("g")
            .attr("transform", `translate(${marginLeft},0)`)
            .call(d3.axisLeft(y).ticks(height / 40))
            .call(g => g.select(".domain").remove())
            .call(g => g.selectAll(".tick line").clone()
                .attr("x2", width - marginLeft - marginRight)
                .attr("stroke-opacity", 0.1));

        // Append the SVG to the ref'd element
        d3.select(chartRef.current).append(() => svg.node());

        const labelYOffset = 8; // Adjust as needed to avoid labels cutting into the area path

        // NUMBER ON TOP
        data.forEach(d => {
            const label = period === "QUARTER"
                ? d.quarter
                : period === "MONTH" 
                    ? d.month
                    : d.year;
            

                const xPos = period == "MONTH" && data.length > 12 
                    ? x(d3.utcParse("%Y/%m")(d.month)) 
                    : x(label) + x.bandwidth() / 2;
                const yPos = y(d.totalFees) - labelYOffset;

                svg.append("text")
                    .attr("x", xPos)
                    .attr("y", yPos)
                    .text(formatNumberAsCurrency(d.totalFees)) // Displaying the formatted fee as the label
                    .attr("text-anchor", "middle") // This centers the label horizontally on its corresponding band
                    .attr("alignment-baseline", "bottom") // Ensures the text is aligned such that its bottom edge is at yPos
                    .style("fill", "#000") // Optional: Adjust the text color as needed
                    .style("font-size", "12px"); // Adjust the font size as needed to fit the chart
            
        });
        
    }, []);

    return (
        <div>
            <div ref={chartRef}></div>
        </div>
    )
}