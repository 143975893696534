import {Button, ContextMenu, Icon, Li} from '@startlibs/components';
import {Slot, useConstant, usePopupToggle, useToggle, useUpdateEffect} from '@startlibs/core'
import {TextInput, ToggleCheckbox} from '@startlibs/form';
import {addQueryString, callIfFunction, getColor, isMobile, media, smoothScroll} from '@startlibs/utils';
import React, {useEffect, useRef, useState} from 'react'
import _ from 'lodash/fp'
import {useLocation} from 'react-router'
import styled, {css} from 'styled-components';
import {ActiveAdvancedFilters} from './staff/ActiveAdvancedFilters'
import {AdvancedSearchPopup} from './staff/AdvancedSearchPopup'
import {DATE, NAME, ROLE, STATUS} from '../../enums/StaffOrderBy'
import {DashboardLoader} from '../../components/asyncQueryList/DashboardLoader'
import {PurviewFooter} from '../../components/PurviewFooter'
import {SectionHeading} from '../../components/PageLayout';
import {Table} from './staff/Table'
import {callUpdateParams, useUpdateParams, useUpdateUrl} from '../../hooks/useQueryParams'
import {formatDateParams} from './staff/utils'
import {getJwt} from '../../hooks/useJwt'
import {jwtGetFetcher} from '../../utils/authFetch'
import {searchToParams} from '../../utils/utils';
import {useEditStaff} from './staff/useEditStaff'
import {lazyUserInfo} from '../../components/WithProvider'
import {lazyUserCategories} from '../experts/hooks/useEditCategories'
import {FormattedMessage} from 'react-intl'

const StaffManagementPageContainer = styled.div `
  padding: 8rem 1.5rem 90px;
  margin: 0 auto;
  position: relative;
  min-width: 300px;
  max-width: 100%;
  min-height: 100vh;
  p {
    font-size: 13px;
  }
  ${media.max(739)`
    max-width: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  `}
  ${media.max(520)`
    padding-top: 6.25rem;
  `}
`

const StaffManagementCard = styled.div`
  & ~ ${SectionHeading} {
    margin-top: 3rem;
  }
  padding: 2.5rem;
  background: white;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  ${media.max(520)`
    padding: 1.5rem;
  `}
`

const PastYearCheckbox = styled(ToggleCheckbox)`
  margin-bottom: 0;
  margin-left: 1rem;
  white-space: nowrap;
  min-width: 225px;
  ${props => props.disabled && css `
    color: ${getColor('gray150')};
  `}
  ${media.max(601)`
    padding: 13px 1rem;
  `}
`


const StaffManagementHeaderComponent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
  z-index: 30;
  position:relative;
  h3 {
    margin: 0 0 .5rem 0;
    font-weight: 600;
    width: 100%;
  }
  .left-wrapper {
    min-width: 300px;
    margin-right: 1rem;
    flex-grow: 1;
    .flex-wrapper {
      display: flex;
      align-items: center;
    }
  }
  .right-wrapper {
    ${Button} ~ ${Button} {
      margin-left: .5rem;
    }
  }
  ${media.max(1179)`
    .right-wrapper {
      margin-top: .5rem;
    }
  `}
  ${media.max(910)`
    .left-wrapper {
      margin-right: 0;
      width: 100%;
      .flex-wrapper {
        flex-wrap: wrap;
        h3 {
          flex-basis: 100%;
          flex-shrink: 0;
          margin-bottom: .5rem;
          position: relative;
          top: -.5rem;
        }
      }
    }
    .right-wrapper {
      display: flex;
      flex-grow: 1;
      ${Button} {
        flex-basis: 50%;
      }
    }
  `}
  ${media.max(625)`
    display: block;
    .right-wrapper {
      padding-top: 1rem;
      width: 100%;
      > div {
        width: 100%;
        label {
          margin-left: 0;
        }
      }
    }
  `}
  /* IE11 Adjustments */
  @media (max-width: 810px) and (-ms-high-contrast:none) {

    .left-wrapper { width: 100%; } /* IE10 */
     *::-ms-backdrop, .left-wrapper { width: 100%; } /* IE11 */

   }
`

const LinkButton = styled.a`
  position: relative;
  display: inline-block;
  text-decoration: underline;
  user-select: none;
`

const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${getColor('gray120')};
  flex-grow: 1;
  .query-count {
    b {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 10rem;
      width: 100%;
      display: block;
    }
  }
  ${media.max(910)`
    display: block;
    .query-count {
      position: absolute;
      top: -1rem;
      right: 0;
    }
  `}
`
const SearchInput = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 1rem;
  width: 29rem;
  ${media.max(910)`
    margin-right: 0;
    margin-bottom: .5rem;
    max-width: 100%;
    width: 100%;
  `}
  ${LinkButton} {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  > input {
    padding-right: 8.75rem;
  }
  ${props => props.expandedInput && `
    > input {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  `}
`
const ClearSearchButton = styled(Icon)`
  color: ${getColor('gray90')};
  font-size: 12px;
  position: absolute;
  right: 80px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  border-radius: 50%;
  background: rgba(0,0,0,0.1);
  :hover {
    background: #f7d3d4;
    color: ${getColor('alert')};
  }
`

const MaxResultsMenu = styled(ContextMenu)`
  li > * {
    padding: 11px 2rem;
  }
`



export const StaffManagement = () => {
  const location = useLocation()
  const loading = useToggle()
  const [params, rawSetParams] = useState(() => searchToParams(location.search))
  const userInfo = lazyUserInfo.read()
  const refreshUserInfo = () => lazyUserInfo.refresh()
  const categories = lazyUserCategories.read()

  const updateParams = (params) => {
    rawSetParams(params)
    callUpdateParams(params)
  }

  useEffect(() => {
    updateParams(searchToParams(location.search))
  },[location])


  const debounceSetParams = useConstant(_.debounce(200, rawSetParams))

  const setParams = (updater, debounce) => {
    const verifyPage = (updater) => (state) => {
      const updatedState = callIfFunction(updater,state)
      if (
        state.search !== updatedState.search ||
        state.events !== updatedState.events ||
        state.from !== updatedState.from ||
        state.timeFrom !== updatedState.timeFrom ||
        state.to !== updatedState.to ||
        state.timeTo !== updatedState.timeTo
      ) {
        return _.set('page', 1, updatedState)
      } else if (state.maxResults !== updatedState.maxResults) {
        const firstRow = ((state.maxResults||10) * (updatedState.page-1)+1)
        return _.set('page',Math.max(Math.floor(firstRow/(updatedState.maxResults||10))+1,1),updatedState)
      } else {
        return updatedState
      }
    }
    if (debounce) {
      debounceSetParams(verifyPage(updater))
    } else {
      setTimeout(() => rawSetParams(verifyPage(updater)),0)
    }
    setTimeout(loading.open,0)
  }

  const loaderRef = useRef()

  const editStaff = useEditStaff(userInfo.role, userInfo, () => {refreshUserInfo();loaderRef.current.refreshList()})

  const fetcher = (url,params,_,translateResults) => jwtGetFetcher(getJwt())(url,formatDateParams(translateResults(params)))
      .then((v) => {window.scroll(0, window.pageYOffset);smoothScroll(0); return v})

  return <>
    <StaffManagementPageContainer isMobile={isMobile()}>
      <StaffManagementCard>
        <WorklistHeader categories={categories} params={params} setParams={setParams} editStaff={editStaff} location={location} loading={loading}/>
        <DashboardLoader
          ref={loaderRef}
          defaultMaxResults={10}
          url='/api/admin/searchUsers'
          params={params}
          queryCount={(i) => i + (i > 1 ? " users" : " user")}
          setParams={setParams}
          location={location}
          fetcher={fetcher}
          defaultOrderBy={DATE}
          loading={loading}
          updateParams={updateParams}
        >{({key, refreshList, queryCount, list, setList, params: lodadedParams}) =>
             <Table
              // isFiltering={isFiltering}
              key={key}
              params={params}
              setList={setList}
              editStaff={editStaff}
              refreshList={refreshList}
              isLoading={loading.isOpen}
              setParams={setParams}
              queryCount={queryCount}
              list={list}
            />
        }</DashboardLoader>
      </StaffManagementCard>
    </StaffManagementPageContainer>
    <PurviewFooter/>
  </>
}


export const ORDER_BY_LABEL = {
  [ROLE]:"role",
  [STATUS]:"status",
  [NAME]:"user name",
}

const WorklistHeader = ({location, loading, categories, params, editStaff, setParams}) => {

  const query = useToggle(params.search || '')
  const maxResults = useToggle(params.maxResults || '')
  const contextMenu = usePopupToggle()

  const updateUrl = useUpdateUrl()

  useUpdateParams((params) => {
    query.openWith(params.search || "")
    maxResults.openWith(params.maxResults || "")
  })

  useEffect(() => {
    updateUrl(params)
  }, [params])


  const maxResultsMenu = usePopupToggle()
  const advancedPopup = useToggle()

  const updateSearch = (v) => {
    query.openWith(v)
    setParams(_.set('search', v), true)
  }

  const updateMaxResult = (v) => {
    maxResults.openWith(v)
    setParams(_.set('maxResults', v), true)
  }

  return <StaffManagementHeaderComponent>
    <h3><FormattedMessage description="Staff management label" defaultMessage="Staff management"/></h3>
    <div className="left-wrapper">
      <div className="flex-wrapper">
        <SearchInputWrapper>
          <SearchInput expandedInput={advancedPopup.isOpen}>
            <TextInput raw value={query.isOpen} setValue={updateSearch} placeholder="Search users"/>
            {query.isOpen && <ClearSearchButton icon="x" onClick={() => updateSearch("")}/>}
            {/* <LinkButton onClick={advancedPopup.open}> */}
            <LinkButton onClick={(e) => {advancedPopup.open(); e.preventDefault();}}>
              Advanced
            </LinkButton>
            {
              advancedPopup.isOpen &&
              <AdvancedSearchPopup
                categories={categories}
                focusedInput={advancedPopup.isOpen}
                closePopup={advancedPopup.close}
                setParams={setParams}
                params={params}
              />
            }
          </SearchInput>
          <div className="query-count">
            <Slot name="QueryCount"/>
            <div className="nowrap">Showing <LinkButton onClick={maxResultsMenu.open}>{(maxResults.isOpen || 10)} per page
              {
                maxResultsMenu.isOpen &&
                <MaxResultsMenu offset="0 4">
                  <Li label="10" onClick={() => updateMaxResult('')}/>
                  <Li label="25" onClick={() => updateMaxResult(25)}/>
                  <Li label="50" onClick={() => updateMaxResult(50)}/>
                </MaxResultsMenu>
              }
            </LinkButton>
            </div>
          </div>
        </SearchInputWrapper>
      </div>
    </div>
    <div className="right-wrapper">
      <Button outline withDropdown onClick={contextMenu.open}>
        Sorting by {ORDER_BY_LABEL[params.orderBy] || "date"}
        {
          contextMenu.isOpen &&
          <ContextMenu>
            <Li label="User name" icon={params.orderBy === NAME ? "check" : undefined} onClick={() => setParams(_.set('orderBy',NAME))}/>
            <Li label="Status" icon={params.orderBy === STATUS ? "check" : undefined} onClick={() => setParams(_.set('orderBy',STATUS))}/>
            <Li label="Role" icon={params.orderBy === ROLE ? "check" : undefined} onClick={() => setParams(_.set('orderBy',ROLE))}/>
            <Li label="Date added" icon={!params.orderBy? "check" : undefined} onClick={() => setParams(_.unset('orderBy'))}/>
          </ContextMenu>
        }
      </Button>
      <Button highlight onClick={editStaff.willOpenWith({categories:[], notification: true})}>Add new user</Button>
    </div>
    <React.Suspense fallback={null}><ActiveAdvancedFilters params={params} setParams={setParams} advancedPopup={advancedPopup} location={location}/></React.Suspense>
  </StaffManagementHeaderComponent>
}
