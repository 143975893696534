import {Button, Dialog, DialogFooter, Icon, Link, Loading, setNotification, SplitColumnsContainer} from '@startlibs/components';
import {Errors, TextInput, WithForm} from '@startlibs/form'
import {useNavigate} from 'react-router';
import {useToggle} from '@startlibs/core'
import React, { useState } from 'react';
import _ from 'lodash/fp'
import styled from 'styled-components';
import {CASE_CLOSED, CASE_REVIEWED} from '../../enums/CaseState'
import {Card, HeaderButtons, PageContainer, PageFooter} from '../../components/PageLayout';
import {CustomReportInfoBox} from '../../expert/customReport/CustomReportInfoBox'
import {ExternalReportUpload} from '../../request/components/ExternalReportUpload'
import {FinalReportEdit} from './finalReport/FinalReportEdit'
import {Header} from '../../components/Header';
import { PageLoadingSuspense } from '../../components/PageLoading';
import {PdfReportFrame, PdfReportFrameWrapper} from '../../request/components/PdfReportFrame';
import {Preview} from '../../request/reviewedRequest/Preview'
import {PreviewForCaseRequest} from '../../request/reviewedRequest/PreviewForCaseRequest'
import {PurviewFooter} from '../../components/PurviewFooter';
import {SuccessBox} from '../../components/InfoBox';
import {getJwt} from '../../hooks/useJwt'
import {hasAnsweredAll} from '../../request/utils'
import {lazyProviderInfo} from '../../components/WithProvider'
import {useConfirmAnswers} from '../../expert/customReport/useConfirmAnswers'
import {useConfirmFinishCase} from './hooks/useConfirmFinishCase'
import {useIframeIsLoading} from './hooks/useIframeIsLoading'
import {useNotifyClosedCase} from './hooks/useNotifyClosedCase'
import {useShareDialog} from '../../hooks/useShareDialog'
import {useUploadCustomReport, useUploadDialogIsOpen} from '../../expert/customReport/useUploadCustomReport'
import { buildValidation, required } from '../../utils/validation';
import { IfLocale } from '../../hocs/IfLocale';
import { jwtPostFetcher } from '../../utils/authFetch';
import { AssignmentStatusBox } from './expertReview/ExpertReviewList';

const ActionsWrapper = styled.div`
  display: flex;
  ${Button} {
    margin-left: 1rem;
  }
`

export const PrintPdfPreview = ({caseRequest}) => {
  const providerInfo = lazyProviderInfo.read()

  return <Preview
    caseRequest={caseRequest}
    organization={{...providerInfo, ...providerInfo.address}}
  />
}

export const FinalReportStep = ({caseRequest, setCaseRequest, uploadPDF}) => {

  const navigate = useNavigate()
  const [openShareDialog, shareDialog] = useShareDialog(caseRequest)
  const sendToEHRDialog = useToggle()

  const providerInfo = lazyProviderInfo.read()

  const finish = useConfirmFinishCase(caseRequest, setCaseRequest)
  const notify = useNotifyClosedCase(caseRequest)

  const uploadCustomReport = useUploadCustomReport(caseRequest, setCaseRequest)

  const iframeIsLoading = useIframeIsLoading(caseRequest.report?.useCustomReport)

  const confirmAnswers = useConfirmAnswers(caseRequest, setCaseRequest)
  const editing = useToggle(caseRequest.state === CASE_REVIEWED)

  const [isUploadDialogOpen] = useUploadDialogIsOpen()

  const caseCompletedWithoutReport = caseRequest.state === CASE_CLOSED && !caseRequest.report?.customReport && !caseRequest.report?.useCustomReport && !caseRequest.report
  const showUpload = (uploadPDF || (!hasAnsweredAll(caseRequest.caseInfo.questionsConsultant) && !caseRequest.report?.customReport)) && !caseRequest.report?.useCustomReport

  const tryToFinish = () => {
    if (!caseRequest.report?.useCustomReport) {
      confirmAnswers().then(finish)
    } else {
      finish()
    }
  }

  React.useEffect(() => {
    if (caseRequest.state === CASE_CLOSED)
    editing.close()
  },[caseRequest.state])


  const reviewedNavigation = caseRequest.state === CASE_REVIEWED &&
    <>
      <Button onClick={() => navigate(`/admin/request/${caseRequest.requestCode}/review`)}>Edit expert review</Button>
      {
        (!caseRequest.report?.useCustomReport && !uploadPDF && hasAnsweredAll(caseRequest.caseInfo.questionsConsultant) != 0) &&
        <Button icon={editing.isOpen ? "view" : "edit"} onClick={editing.toggle}>{editing.isOpen ? "Preview" : "Edit report"}</Button>
      }
      <Button highlight onClick={tryToFinish} disabled={showUpload && !caseRequest.report?.customReport}>Complete report</Button>
    </>


  return <><PageContainer>
    <Header title="Case report">
      {reviewedNavigation}
      {
        caseRequest.state === CASE_CLOSED &&
        <ActionsWrapper>
          <SuccessBox>
            <Icon icon="check"/><span>{caseCompletedWithoutReport ? 'Case' : 'Report'} completed.</span>
          </SuccessBox>
          <Button icon="email" onClick={notify}>Notify contact</Button>
        </ActionsWrapper>
      }
    </Header>
    {caseRequest.state === CASE_CLOSED &&
    <HeaderButtons>
      <div className="left-wrapper">
        <Button onClick={() => navigate(`/admin/request/${caseRequest.requestCode}/overview`)}>Go to overview</Button>
      </div>
      {!caseCompletedWithoutReport &&
        <div className="right-wrapper">
          <IfLocale not contains="LEGAL">
            {providerInfo?.ehrHl7Enabled === true && <Button.a
              onClick={() => sendToEHRDialog.open()}
              // href={`/api/report/download?token=${getJwt()}&requestId=${caseRequest.requestId}`}
              icon="external-report"
            >Send to EHR</Button.a>}
          </IfLocale>
          <Button.a
            href={`/api/report/download?token=${getJwt()}&requestId=${caseRequest.requestId}`}
            icon="download"
          >Save PDF</Button.a>
          <Button.a
            target="_blank"
            href={`/api/report/preview?token=${getJwt()}&requestId=${caseRequest.requestId}`}
            icon="print"
          >Print</Button.a>
          {/* <Button highlight onClick={openShareDialog} icon="send">Share</Button> */}
        </div>
      }
    </HeaderButtons>
    }
    {
      caseRequest.report?.customReport && caseRequest.state === CASE_REVIEWED &&
      <CustomReportInfoBox setCaseRequest={setCaseRequest} caseRequest={caseRequest}/>
    }
    {
      !caseRequest.report?.customReport && caseRequest.state === CASE_REVIEWED && !showUpload &&
      <HeaderButtons>
        <div className="left-wrapper">
          <p css="margin-bottom:  0;">{editing.isOpen ? "Editing the default case report." : "Previewing default case report."}</p>
        </div>
        <div className="right-wrapper">
          <Button onClick={uploadCustomReport} small icon="upload">Upload custom PDF report</Button>
        </div>
      </HeaderButtons>
    }
    <PageLoadingSuspense>
    {
      caseCompletedWithoutReport 
        ? <AssignmentStatusBox reviewed={caseRequest.state === CASE_CLOSED}>
            <div className="waitingBoxContent">
              <Icon icon={'check'}/>
              <h4>
                {'This case is already completed.'}
              </h4>
              <p>The case has been marked as completed, but no report was generated.</p>
            </div>
          </AssignmentStatusBox> 
        : (showUpload)
            ? <UploadCustomReport caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>
            : caseRequest.report?.useCustomReport
              ? <PdfReportFrameWrapper>
                  <PdfReportFrame
                    pdfReport
                    isUploadDialogOpen={isUploadDialogOpen}
                    onLoad={iframeIsLoading.close}
                    src={`/api/report/preview?token=${getJwt()}&requestId=${caseRequest.requestId}&refreshPdf=${caseRequest.pdfKey}#toolbar=0&navpanes=0`}
                  />
                    {iframeIsLoading.isOpen && <Loading absolute/>}
                </PdfReportFrameWrapper>
              : (
                editing.isOpen
                  ? <FinalReportEdit
                      caseRequest={caseRequest}
                      editing={editing}
                      setCaseRequest={setCaseRequest}
                      providerInfo={providerInfo}
                    />
                  : <PreviewForCaseRequest caseRequest={caseRequest}/>
                  /*
                  <Load value={lazyPoviderInfo}>{ info =>
                    <PreviewForReport info={info} report={caseRequest.report}/>
                  }</Load>
                  */
              )
    }

    {reviewedNavigation && <PageFooter>{reviewedNavigation}</PageFooter>}

    {caseRequest.state === CASE_CLOSED &&
    <HeaderButtons>
      <div className="left-wrapper">
        <Button onClick={() => navigate(`/admin/request/${caseRequest.requestCode}/overview`)}>Go to overview</Button>
      </div>
      {!caseCompletedWithoutReport && <div className="right-wrapper">
        <Button.a
          href={`/api/report/download?token=${getJwt()}&requestId=${caseRequest.requestId}`}
          icon="download"
        >Save PDF</Button.a>
        <Button.a
          target="_blank"
          href={`/api/report/preview?token=${getJwt()}&requestId=${caseRequest.requestId}`}
          icon="print"
        >Print</Button.a>
        {/* <Button highlight onClick={openShareDialog} icon="send">Share</Button> */}
      </div>}
    </HeaderButtons>
    }
    </PageLoadingSuspense>
  </PageContainer>
    {shareDialog}
    {sendToEHRDialog.isOpen && <SendToEHRDialog closeDialog={sendToEHRDialog.close} caseRequest={caseRequest} setCaseRequest={setCaseRequest} />}
    <PurviewFooter/>
  </>
}


const UploadCustomReport = ({caseRequest, setCaseRequest}) => {
  const isUploading = useToggle()

  return <>
    <p><strong>There are missing fields in the Expert Review step.</strong> Would you like to upload a custom PDF report with the review?</p>
    <Card>
      {isUploading.isOpen && "Uploading..."}
      <WithForm>
        <ExternalReportUpload
          url={"/api/customReport?requestId=" + caseRequest.requestId}
          onSuccess={() => setCaseRequest(_.flow(_.set('report.useCustomReport', true), _.set('pdfKey', Date.now()), _.set('report.customReport', true)))}
        />
        <Errors/>
      </WithForm>
    </Card>
    <p>You can also <Link
      className="link"
      to={`/admin/request/${caseRequest.requestCode}/review`}
    >insert your answers</Link> for generating a pre-formatted Expert View report.</p>
  </>
}

const SendToEHRDialog = ({closeDialog, caseRequest, setCaseRequest }) => {

  const [isLoading, setLoading] = useState(false)

  const preValidation = buildValidation({
    'caseInfo.ehrInfo.mrn': (v) => (!v?.length || v === null) && required(),
  })

  const action = ({caseInfo}) => jwtPostFetcher(getJwt())("/api/caseInfo?requestId=" + caseRequest.requestId, caseInfo, {method: "PUT"})

  return <Dialog
    title="Send to EHR"
    closeDialog={closeDialog}
    isLoading={false}
  >
    <p css="font-size:14px !important;margin-bottom:1.5rem !important;">
      Insert the information below to export this case to your EHR system
    </p>
    <WithForm
      alwaysSave
      values={caseRequest}
      preValidation={preValidation}
      action={action}
      onSuccess={(values) => {
        setCaseRequest(values)
        setLoading(true)
        return jwtPostFetcher(getJwt())("/api/admin/sendToEhr?requestId=" + caseRequest.requestId, {}, {method: "POST"})
          .then((response) => {
            setLoading(false)
            setNotification("Case report was sent to EHR")
            closeDialog()
          })
          .catch((err) => {
            setLoading(false)
            setNotification({type: "alert", msg: (close) => <>Error sending to EHR</>})
          })
        }
      }
    >{form => 
      <>
        <SplitColumnsContainer>
          <TextInput
            label="Medical Record Number"
            helpText="(Patient Identification Number)"
            css="min-width: 400px;"
            path="caseInfo.ehrInfo.mrn"
            mandatory
          />
          <TextInput
            label="Order Number"
            path="caseInfo.ehrInfo.orderNumber"
          />
        </SplitColumnsContainer>
        <p>If the order number is empty, the case report will be seen as unsolicited message on the EHR system.</p>  
        <Errors/>
        <DialogFooter>
          <Button type='button' 
            onClick={closeDialog}
          >Cancel</Button>
          <Button 
            type='submit' 
            // disabled={form.getValue('caseInfo.ehrInfo.mrn') == null || form.getValue('caseInfo.ehrInfo.mrn').length === 0} 
            highlight 
            isLoading={form.isLoading} 
            // onClick={() => form.clearErrors()}
          >Send</Button>
        </DialogFooter>
      </>
    }</WithForm>
  </Dialog>
}