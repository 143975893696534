import {AutoComplete, DatePicker, Field, WithForm} from '@startlibs/form'
import {Button, Dialog, Icon, Link, SplitColumnsContainer} from '@startlibs/components'
import {
  _s,
  addQueryString,
  formatDate,
  getColor,
  media
} from '@startlibs/utils';
import {useLazyConstant, useToggle} from '@startlibs/core'
import React, {useEffect, useRef} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components'

import {DATE_FORMAT} from './config/DateFormat'
import {fetchSuggestionsTags, getTagsFromParam} from '../../utils/tagFetch'
import {getDate, getDateValue, getDateYear} from '../../utils/utils'
import {getLabel} from './StudyCell'
import {AutocompleteBox} from './AutocompleteBox'

const AdvancedPopupComponent = styled.div`
  position: absolute;
  background-color: #FFF;
  border: 1px solid ${getColor('gray210')};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 1.25rem;
  z-index: 999;
  left: 0;
  width: 93vw;
  width: calc(100vw - 2rem);
  margin-top: -1px;
  .upload-dateshortcuts {
    margin-top: -0.25rem;
    margin-bottom: 1rem;
    a {
      color: ${getColor('gray150')};
      margin-right: 1rem;
      text-decoration: underline;
    }
  }
  .popup-buttons-container {
    text-align: right;
  }
  .close-icon {
    color: ${getColor('gray150')};
    font-size: 20px;
    position: absolute;
    top: 6px;
    right: 5px;
    cursor: pointer;
    width: 2rem;
    line-height: 2rem;
    text-align: center;
    border-radius: 50%;
    :hover {
      background: ${getColor('gray240')};
    }
  }
  ${media.desktop`
    max-width: 30rem;
  `}
`
const validateTag = (v) => ({errors: true})
export const AdvancedSearchPopup = ({params, focusedInput, setParams, closePopup}) => {
  const popupRef = React.useRef()

  const patBirthdate = useToggle(getDateValue('patBirthdate', params))
  const studyDatetimeInit = useToggle(getDateValue('studyDatetimeInit', params))
  const studyDatetimeEnd = useToggle(getDateValue('studyDatetimeEnd', params))
  const updatedTimeInit = useToggle(getDateValue('updatedTimeInit', params))
  const updatedTimeEnd = useToggle(getDateValue('updatedTimeEnd', params))
  const focusRef = useRef()
  const formRef = useRef()

  useEffect(() => {
    // const tryClose = (e) => !(popupRef.current.contains(e.target) || e.target === popupRef.current || e.defaultPrevented) && closePopup()
    // document.addEventListener("click", tryClose)
    // return () => document.removeEventListener("click", tryClose)
  }, [])

  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
  }, [])

  React.useEffect(() => {
    fetchSuggestionsTags()
  }, [])

  const confirmAdvancedSearch = () => {
    const tags = formRef.current.getValues().tags
    setParams(_s.assign({
      patBirthdate: _.isNumber(patBirthdate.isOpen) || _.isDate(patBirthdate.isOpen) ? formatDate(patBirthdate.isOpen, "yyyy-MM-dd") : "",
      idsTagsArray: tags.length ? tags.map(({idTag}) => idTag).join(",") : undefined,
      updatedTimeInit: _.isNumber(updatedTimeInit.isOpen) || _.isDate(updatedTimeInit.isOpen) ? formatDate(updatedTimeInit.isOpen, "yyyy-MM-dd") : "",
      updatedTimeEnd: _.isNumber(updatedTimeEnd.isOpen) || _.isDate(updatedTimeEnd.isOpen) ? formatDate(updatedTimeEnd.isOpen, "yyyy-MM-dd") : "",
      studyDatetimeInit: _.isNumber(studyDatetimeInit.isOpen) || _.isDate(studyDatetimeInit.isOpen) ? formatDate(studyDatetimeInit.isOpen, "yyyy-MM-dd") : "",
      studyDatetimeEnd: _.isNumber(studyDatetimeEnd.isOpen) || _.isDate(studyDatetimeEnd.isOpen) ? formatDate(studyDatetimeEnd.isOpen, "yyyy-MM-dd") : ""
    }))
    closePopup()
  }

  return <WithForm ref={formRef} values={{tags: getTagsFromParam(params.idsTagsArray)}}>
    <AdvancedPopupComponent 
      ref={popupRef} 
      // onKeyDown={({key}) => key === "Enter" && confirmAdvancedSearch()}
      onKeyDown={({key,defaultPrevented}) => key === "Enter" && !defaultPrevented && confirmAdvancedSearch()}
      onClick={(e) => e.stopPropagation()}>
      <DatePicker ref={focusedInput === "patBirthdate" ? focusRef : undefined} label="Patient date of birth"
                  placeholder={DATE_FORMAT().toUpperCase()} format={DATE_FORMAT()} raw value={patBirthdate.isOpen}
                  setValue={patBirthdate.openWith}/>
      <DateRangeField
        initFocusRef={focusedInput === "studyDatetimeInit" ? focusRef : undefined}
        endFocusRef={focusedInput === "studyDatetimeEnd" ? focusRef : undefined}
        endToggle={studyDatetimeEnd}
        initToggle={studyDatetimeInit}
        label="Study date between"
      />
      <DateRangeField
        initFocusRef={focusedInput === "updatedTimeInit" ? focusRef : undefined}
        endFocusRef={focusedInput === "updatedTimeEnd" ? focusRef : undefined}
        endToggle={updatedTimeEnd}
        initToggle={updatedTimeInit}
        label="Upload date between"
      />
      <div 
        onClick={e => e.preventDefault()}
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
      >
        <AutoComplete
          CompletedBox={AutocompleteBox}
          minQueryLength={1}
          autoFocus={focusedInput === "tags"}
          label="Tags:"
          confirmIfValueKeys={['Tab']}
          path="tags"
          value={[]}
          autoSelect
          fetchSuggestions={fetchSuggestionsTags()}
          validation={validateTag}
          getLabel={getLabel}
          withDropdown
          bellowDescText="Use comma, enter or tab to add multiple tags."
          placeholder="Search for existing tags"
        />
      </div>
      <div className="popup-buttons-container">
        <Button onClick={closePopup} tabIndex={2}>Cancel</Button>
        <Button highlight onClick={confirmAdvancedSearch}>Search</Button>
      </div>
    </AdvancedPopupComponent>
  </WithForm>
}

const DateRangeField = ({label, initFocusRef, endFocusRef, initToggle, endToggle}) => {
  const updateFields = useToggle()

  return <Field label={label}>
    <SplitColumnsContainer key={updateFields.isOpen}>
      <DatePicker ref={initFocusRef} label="" placeholder={DATE_FORMAT().toUpperCase()} format={DATE_FORMAT()} raw
                  value={initToggle.isOpen} setValue={initToggle.openWith}/>
      <DatePicker ref={endFocusRef} label="" placeholder={DATE_FORMAT().toUpperCase()} format={DATE_FORMAT()} raw
                  value={endToggle.isOpen} setValue={endToggle.openWith}/>
    </SplitColumnsContainer>
    <div className="upload-dateshortcuts">
      <a onClick={() => {
        initToggle.openWith(getDate(-30));
        endToggle.openWith(getDate(0));
        updateFields.openWith(Date.now())
      }}>Last 30 days</a>
      <a onClick={() => {
        initToggle.openWith(getDate(-7));
        endToggle.openWith(getDate(0));
        updateFields.openWith(Date.now())
      }}>Last 7 days</a>
      <a onClick={() => {
        initToggle.openWith(getDate(0));
        endToggle.openWith(getDate(0));
        updateFields.openWith(Date.now())
      }}>Today</a>
    </div>
  </Field>
}