import {Button, ContextMenu, Icon, Li, Tooltip, useNotification} from '@startlibs/components';
import {Errors, RichText, TextInput, WithForm} from '@startlibs/form'
import {getColor} from '@startlibs/utils'
import React, { useEffect, useState } from 'react'
import styled, {css} from 'styled-components';
import { darken, desaturate, lighten } from 'polished';
import _ from 'lodash/fp'
import { WithLazyResource } from '../../hooks/useSuspense';
import { lazyUserInfo } from '../WithProvider';
import { RichTextDisplay } from '../RichTextDisplay';
import { jwtPostFetcher } from '../../utils/authFetch';
import { getJwt } from '../../hooks/useJwt';
import { ConfirmDialog, useConfirmDialog } from '../../hooks/useConfirmDialog';
import { usePopupToggle } from '@startlibs/core';
import { isRichText, RichTextLineBreaksToNewLine, RichTextRemoveTags, RTF_TOOLBAR_OPTIONS, safeMultipleNewLineToBr, safeMultipleNewLineToBrOnly } from '../../utils/utils';
import { SUPER } from '../../enums/UserRole';
import { useRef } from 'react';
import { FormSlateEditor } from '../slateEditor/FormSlateEditor';
import { hasContent } from '../slateEditor/slateSerializer';
import { useAutoScroll } from '../../hooks/useAutoScroll';

const NoteContainer = styled.div`
  position: relative;
  margin-bottom: 0.75rem;
  position: relative;
  padding: 1rem;
  background: ${props => desaturate(0.6, lighten(0.525, getColor("main")(props)))};
  border-radius: 8px;
  overflow: auto;
`

const NoteHeader = styled.div `
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-size: 12px;
  .title {
    margin-right: 5px;
    font-weight: 600;
    font-size: 14px;
  }
  .externalLabel {
    margin-right: 0.5rem;
    display: inline-block;
    color: rgba(0,0,0,0.4);
  }
  .timestamp {
    color: rgba(0,0,0,0.4);
    margin-top: 2px;
  }
`
const HeaderIcon = styled(Icon)`
  color: ${getColor('gray60')};
  font-size: 21px;
  margin-right: 0.5rem;
  vertical-align: -4px;
`

const LastEditTimestamp = styled.div `
  color: rgba(0,0,0,0.4);
  margin-top: 0.5rem;
  font-size: 11px;
`

const NoteActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`
const NoteFooter = styled.div `
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: auto;
  margin-top: 1rem;
`

const NoteActionButton = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  line-height: 0;
  border-radius: 50%;
  background: ${props => desaturate(0.5, lighten(0.45, getColor("main")(props)))};
  text-align: center;
  font-size: 20px;
  color: ${getColor('main')};
  position: relative;
  margin-left: 0.5rem;
  cursor: pointer;
  :hover {
    background: ${props => desaturate(0.5, lighten(0.425, getColor("main")(props)))};
  }
  :active {
    background: ${props => desaturate(0.5, lighten(0.4, getColor("main")(props)))};
  }
  .dropdown {
    font-size: 14px;
  }
  ${Icon} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
`


export const NoteInfoPanel = ({caseRequest, caseNotes, setCaseNotes, note, className, children,  formRef, i, onMouseDown, onTouchStart}) => {
  
  const inputRef = useAutoScroll(caseNotes[i]?.createdBy ? false : true)
  
  const contextMenu = usePopupToggle()
  const rtRef = useRef()
  const [focus, setFocus] = useState(false)
  const [,setNotification] = useNotification()
  
  const confirmRemove = () => {
    
    if(note.id){
      setIsLoadingDelete(true)
      jwtPostFetcher(getJwt())(`/api/case/note?requestId=${caseRequest.requestId}&caseNoteId=${caseNotes[i].id}`, '', {method: "DELETE"})
        .then(response => {
          setCaseNotes(_.differenceBy('id', caseNotes, [{ 'id': caseNotes[i].id }]))
          setIsLoadingDelete(false)  
        })
    }else{
      var pulled = _.pullAt([i], caseNotes);
      setCaseNotes(pulled)  
    }
  }
  const [isReadOnly, setReadOnly] = useState(caseNotes[i]?.createdBy ? true : false)
  const [isNew, setIsNew] = useState(caseNotes[i]?.createdBy ? false : true)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const parse = _.flow(
    _.update('note', (note) => {
      return note && note.includes("\n") 
      ? isRichText(note) ? safeMultipleNewLineToBrOnly(note) : safeMultipleNewLineToBr(note)
      : note 
    }))
  

  useEffect(() => {
    if(isNew || !isReadOnly){
      setFocus(caseNotes[i].id+''+Date.now())
    }
  },[isNew, isReadOnly])

  const confirmDelete = useConfirmDialog(
    <ConfirmDialog
      title="Delete note"
      confirm={<Button alert>Delete</Button>}
      action={confirmRemove}
    >
      <p><b>Are you sure you want to delete this note?</b></p>
      <p>This cannot be undone.</p>
    </ConfirmDialog>
  )

  const removeQuestion = () => {
    if (note.id) {
      confirmDelete()
    } else {
      confirmRemove()
    }
  }

  return <WithLazyResource value={lazyUserInfo}>{userInfo =>
    <NoteContainer
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      submitted={isReadOnly}
      canRemove
      className={className}
    >
      {(isReadOnly || !isNew) && <>
        <NoteHeader>
          <div>
            <HeaderIcon icon={caseNotes[i]?.external ? "external-user": "user-line"}/>
            <span className='title'>{caseNotes[i]?.createdBy}</span>
            {caseNotes[i]?.external && <span className='externalLabel'>(external)</span>}
            {caseNotes[i]?.external && caseNotes[i]?.info?.email && <span className='externalLabel'>{caseNotes[i]?.info?.email}</span>}
            <div className='timestamp'>{caseNotes[i]?.creationDateEpochMilli && <Tooltip 
              content={caseNotes[i]?.creationDateEpochMilli !== caseNotes[i]?.lastUpdatedEpochMilli && <>Last edit on: {new Date(caseNotes[i]?.lastUpdatedEpochMilli).toLocaleString('en-us', { month: 'short', day: '2-digit', year: 'numeric' })} - {new Date(caseNotes[i]?.lastUpdatedEpochMilli).toLocaleString('en-us', { hour: '2-digit', minute:'2-digit' })}</>}>
                {new Date(caseNotes[i]?.creationDateEpochMilli).toLocaleString('en-us', { month: 'short', day: '2-digit', year: 'numeric' })} - {new Date(caseNotes[i]?.creationDateEpochMilli).toLocaleString('en-us', { hour: '2-digit', minute:'2-digit' })}
                </Tooltip>
              }
            </div>
          </div>
          <NoteActions>
            <NoteActionButton 
              title="Copy note to clipboard" 
              onClick={() => {
                // var stripedHtml = RichTextRemoveTags(RichTextLineBreaksToNewLine(caseNotes[i]?.info.note));
                // navigator.clipboard.writeText(stripedHtml)
                // This ensures copied content will bring links and formatted text when possible
                // while not bringing tags when copying it to other fields;
                var content = caseNotes[i]?.info.note;
                function listener(e) {
                    e.clipboardData.setData("text/html", content );
                    e.clipboardData.setData("text/plain", RichTextRemoveTags(RichTextLineBreaksToNewLine(content)));
                    e.preventDefault();
                }
                document.addEventListener("copy", listener);
                document.execCommand("copy");
                document.removeEventListener("copy", listener);
                setNotification("Note copied to clipboard");
              }}>
              <Icon icon="copy"/>
            </NoteActionButton>
            <NoteActionButton onClick={contextMenu.open}>
              <Icon icon="arrow-down" className="dropdown"/>
              {
                contextMenu.isOpen &&
                <ContextMenu>
                  <Li label="Edit" icon="edit" disabled={(userInfo.id !== caseNotes[i]?.creatorId && userInfo.role !== SUPER) || !isReadOnly} onClick={() => setReadOnly(false)}/>
                  <Li label="Delete" icon="delete" disabled={userInfo.id !== caseNotes[i]?.creatorId && userInfo.role !== SUPER} onClick={removeQuestion}/>
                </ContextMenu>
              }
            </NoteActionButton>
          </NoteActions>
        </NoteHeader>
      </>}
      {/* {isReadOnly && <RichTextDisplay content={caseNotes[i]?.info.note} /> } */}
      {isReadOnly && <RichTextDisplay className='fs-exclude' content={caseNotes[i]?.info.note} useSafeNewLine={!isRichText(caseNotes[i]?.info.note)} />}
      {isReadOnly && caseNotes[i]?.creationDateEpochMilli !== caseNotes[i]?.lastUpdatedEpochMilli && 
          <LastEditTimestamp>Last edit on {
            new Date(caseNotes[i]?.lastUpdatedEpochMilli).toLocaleString('en-us', { month: 'short', day: '2-digit', year: 'numeric' })
          } at {
            new Date(caseNotes[i]?.lastUpdatedEpochMilli).toLocaleString('en-us', { hour: '2-digit', minute:'2-digit' })
          } by {caseNotes[i]?.updatedBy}
        </LastEditTimestamp>
      }
      {!isReadOnly && 
        <WithForm
          ref={formRef}
          values={parse(caseNotes[i]?.info)}
        >{form => <>
          <div ref={inputRef}>
            <FormSlateEditor
              formatTools={RTF_TOOLBAR_OPTIONS}
              path="note"
              inputRef={rtRef}
              placeholder="Write your note here"
              disabled={isReadOnly}
              focus={focus}
              useHtml={true}
              requestId={caseRequest.requestId}
            />
          </div>
          <Errors css="margin-top: 1rem"/>
          <NoteFooter>
            <Button small onClick={() => {
              return isNew ? removeQuestion() : setReadOnly(true)
            }}>Cancel</Button>
            <Button small highlight isLoading={isLoading} onClick={() => {
              if(!hasContent(form.getValues('note')) || !form.getValues('note')){
                setFocus(caseNotes[i].id+''+Date.now())
                return form.setError('note','Notes can not be empty')
              }
              form.clearErrors()
              setIsLoading(true)
              setIsNew(false)
              if(note.id){
                const editObj = {
                  id: note.id,
                  note: form.getValues('note')
                }
                jwtPostFetcher(getJwt())('/api/case/note?requestId=' + caseRequest.requestId, editObj,{method: "PUT"})
                  .then((response) => {
                    setCaseNotes(_.set('['+i+']',response,caseNotes))
                    setIsLoading(false)
                    setReadOnly(true)
                  })
              }else{
                const newObj = {
                  note: form.getValues('note'),
                  external: false
                }
                jwtPostFetcher(getJwt())('/api/case/note?requestId=' + caseRequest.requestId, newObj)
                  .then((response) => {
                    setCaseNotes(_.set('['+i+']',response,caseNotes))
                    setIsLoading(false)
                    setReadOnly(true)
                  })
              }
            }}>Save note</Button>
          </NoteFooter>
        </>}</WithForm> 
      }
      {children}
    </NoteContainer>
  }</WithLazyResource> 
}
