import { Button, Loading } from '@startlibs/components'
import { parseDate } from '@startlibs/utils'
import _ from 'lodash/fp'
import { useRefState, useToggle } from '@startlibs/core'
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { enums, Uploader, Uploader2 } from 'uploader-frontend'
// import {Uploader} from 'uploader-frontend-legacy'
import { Uploader as UploaderLegacy } from 'uploader-frontend-legacy'
import { CASE_CLOSED, CASE_REVIEWED } from '../enums/CaseState'
import { Card, PageContainer, SectionHeading } from '../components/PageLayout'
import { EMPTY_MEDICAL_RECORDS, EMPTY_MEDICAL_RECORDS_LOCATION_FORM } from './MedicalRecords'
import { NotProvided } from '../admin/steps/ExpertAssignmentStep'
import { authPostFetcher, jwtGetFetcher, jwtPostFetcher } from '../utils/authFetch'
import { getJwt } from '../hooks/useJwt'
import { downloadFiles, persistMedicalRecord, updateMedicalRecordsInfo, viewFileSrc } from './utils'
import { calculateAge, isRichText, safeMultipleNewLineToBr } from '../utils/utils'
import { fullNameToName, getRelationLabel } from '../enums/ContactRelationKind'
import { getUploaderJwt, setUploaderJwt } from '../hooks/useUploaderJwt'
import { useNotification } from '../components/Notifications'
import { PageLoadingSuspense } from '../components/PageLoading'
import { callIfFunction } from '@startlibs/utils'
import { LoadingPage } from 'sharecomponents/src/components/PageLoading'
import { getStorageHost, setStorageHost } from '../hooks/useStorageHost'
import {using} from '@startlibs/utils'
import { EXPERT } from '../enums/UserRoles'
import {IfLocale} from '../hocs/IfLocale'
import {FormattedMessage, useIntl} from 'react-intl'
import { PATIENT } from '../enums/ContactRelationType'
import { RichTextDisplay } from '../components/RichTextDisplay'
import { QuestionsAndAdditionalOverview } from './QuestionsAndAdditionalOverview'
import { Header } from '../components/Header'
import { PurviewFooter } from '../components/PurviewFooter'
import { RequestOverviewNew } from './RequestOverview'


export const FieldsList = styled.div`
  margin: -0.5rem;
  ${props => props.twoColumns && `
    display: flex;
    flex-wrap: wrap;
    > * {
      flex-basis: 46%;
      flex-grow: 1;
      &.full-width {
        flex-basis: 100%;
      }
    }
    ${ReadOnlyField} {
      margin: 0.5rem 0.5rem 1.5rem 0.5rem;
    }
  `}
  .contact-relationship {
    text-transform: capitalize;
    :before {
      content: ' ';
    }
  }
  .address-concat {
    span ~ span:before {
      content: ', '
    }
  }
  .physician-contacts {
    span ~ span:before {
      content: ' | ';
    }
  }
`
const ReadOnlyQuestion = styled.div`
  font-size: 14px;
  font-weight: 600;
  label {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    font-weight: 400;
  }
  & ~ & {
    margin-top: 1rem;
  }
  .answer {
    font-weight: 400;
    margin-top: .25rem;
  }
`

export const ReadOnlyField = styled.div`
  font-size: 14px;
  margin-bottom: 1.5rem;
  label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }
`
export const FieldDetail = styled.span`
  color: rgba(0,0,0,0.4);
  font-size: 13px;
`

export const EmptyMedicalRecordsList = styled.div`
  text-align: center;
  color: rgba(0,0,0,0.4);
  padding: 1rem 0;
  font-size: 14px;
 `

export const SpacedDiv = styled.div`
  display: block;
  height: 460px;
`

const GENDER_LABELS = { M: "Male", F: "Female", O: "Gender: other", N: 'Gender: Non-binary' }
const IdentityFn = (({ children }) => children())

const apiEndpoints = {
  dicomViewer: (studies) => Promise.resolve({ viewerURL: getStorageHost()+`/view/records/${studies.filter(_.get('recordUID')).map(_.get("recordUID")).join("|")}?t=${getUploaderJwt()}` }),
  nonDicomViewer: (record) => getStorageHost()+`/view/record/${record.recordUID}?t=${getUploaderJwt()}`,
  shortTokenUrl: (requestId) => jwtGetFetcher(getJwt())(`/api/shortDownloaderToken`,{requestId: requestId}),
  downloadRecord: (record) => getStorageHost()+`/download/record/${record.recordUID}?t=${getUploaderJwt()}`,
  storageHost: () => getStorageHost(),
  loadDownloadRecord: (record, filename) => record.nonCompliantFiles
    ? jwtGetFetcher(getUploaderJwt())(getStorageHost()+`/download/async/nonDicoms/${record.nonCompliantFiles.map(_.get("recordUID")).join("-")}`).then(({ fileUID }) => ({ url: () => getStorageHost()+`/download/file/${fileUID}?t=${getUploaderJwt()}${filename ? '&originalFilename=' + encodeURIComponent(filename) : ""}` }))
    : jwtGetFetcher(getUploaderJwt())(getStorageHost()+`/download/async/${record.recordUID}`).then(({ fileUID }) => ({ url: () => getStorageHost()+`/download/file/${fileUID}?t=${getUploaderJwt()}${filename ? '&originalFilename=' + encodeURIComponent(filename) : ""}` }))
    ,
  downloadFiles: (shortJwt, records) => {downloadFiles(shortJwt, records)},
  viewFileSrc: (shortJwt, recordId) => viewFileSrc(shortJwt, recordId),
    
}

const fileFields = ['fileName', 'fileExtension', 'fileType', 'fileSize', 'uid', 'description', 'docType', 'locationFormId', 'uploadDate']


export const RequestOverviewMedicalRecordsOnly = ({ caseRequest, setCaseRequest, withoutRecords, allowUpload, allowDownload, allowDownloadMedicalImages, uploaderRef, setMedicalRecords, role, isReadOnlyShare = false, showHiddenRecords = true }) => {

  const unauthorizedRefresh = useToggle()
  const { medicalRecordsInfo } = caseRequest
  const [medicalRecordsNewUploader, setMedicalRecordsNewUploader] = useState()

  const hasMedicalRecords = medicalRecordsInfo && (
    medicalRecordsInfo.dicomStudies.length ||
    medicalRecordsInfo.nonCompliantDicom.length ||
    medicalRecordsInfo.nonDicomFiles.length
  )
  const dobDate = parseDate(caseRequest.patientInfo.dob, new Date(), "MM-dd-yyyy")

  // Ask for colaboration and new share will make use of permissions
  const showCaseOverview =  (!isReadOnlyShare || (isReadOnlyShare && caseRequest.permission.caseOverview))
  const showMedicalRecords =  (!isReadOnlyShare || (isReadOnlyShare && caseRequest.permission.medicalRecords))
    
  var caseUIDJson = ''
  const [caseUID, setCaseUID] = useState('')

  var listModeRes = ''
  var patientUID = ''
  var userRole = 0
  var storageHost = ''

  var allowReorder = false
  var autoGrouping = true
  const intl = useIntl()

  useEffect(() => {
    if (!hasMedicalRecords) {

      let refreshTimeout = -1
      jwtGetFetcher(getJwt())(`/api/storageToken`, caseRequest.requestId ? {requestId: caseRequest.requestId} : null)
        .then((response) => {

          let responseJson = JSON.parse(response.jwt);
          setStorageHost(response.storageHost)
          setUploaderJwt(responseJson.jwt)
          setCaseUID(responseJson.caseUID)
          caseUIDJson = responseJson.caseUID
          listModeRes = responseJson.listMode
          patientUID = responseJson.patientUID
          userRole = responseJson.userRole

          refreshTimeout = setInterval(() => {
            jwtGetFetcher(getUploaderJwt())(getStorageHost()+"/auth/refresh")
              .then(({ jwt }) => setUploaderJwt(jwt))
              .catch(([v, { status }]) => status === 401 && unauthorizedRefresh.open())
          }, 10 * 60 * 1000)

          return caseUIDJson
            ? jwtGetFetcher(getUploaderJwt())(getStorageHost()+"/case/" + caseUIDJson + (patientUID ? "?patientUID=" + patientUID : ""))
            : jwtGetFetcher(getUploaderJwt())(getStorageHost()+"/record/patient/" + patientUID).then(medicalRecords => ({ medicalRecords }))
        })
        .then((response) => {
          setCaseRequest(caseRequest => ({ ...caseRequest, medicalRecords: response.medicalRecords, group: response.group }));
        })

      return () => {
        clearInterval(refreshTimeout)
      }
    }
  }, [])

  const referringPhysician = caseRequest.referringPhysician

  const hasReferringPhyisicianAsSecondary = referringPhysician && (
    (fullNameToName(referringPhysician.fullName) || "").length > 0 ||
    (referringPhysician.email || "").length > 0 ||
    (referringPhysician.phoneNumber || "").length > 0
  )
  const medicalRecordsStore = useRefState(caseRequest.medicalRecordsInfo || EMPTY_MEDICAL_RECORDS)

  if (!hasMedicalRecords) {
    
    if (!caseRequest.medicalRecords) {
      return <SpacedDiv><LoadingPage /></SpacedDiv>
    }

    return <>
      <PageContainer>
        <Header title="Medical record files" />
        <PageLoadingSuspense>
          {
            !withoutRecords && showMedicalRecords && <>
              <Card>
                {
                ((caseRequest.medicalRecords.length > 0 && role !== EXPERT) || (role === EXPERT && caseRequest.medicalRecords.filter((item) => item.state !== "Rejected").length > 0) || allowUpload) 
                  ?
                    <RequestOverviewNew
                      caseRequest={caseRequest}
                      setCaseRequest={setCaseRequest}
                      records={medicalRecordsNewUploader}
                      uploaderRef={uploaderRef}
                      allowReorder={allowReorder}
                      allowUpload={allowUpload}
                      allowDownload={allowDownload}
                      allowDownloadMedicalImages={allowDownloadMedicalImages}
                      setMedicalRecords={(updateRecords) => setCaseRequest(_.update('medicalRecords', (prevRecords) => callIfFunction(updateRecords, prevRecords)))}
                      autoGrouping={autoGrouping}
                      storageHost={storageHost}
                      userRole={userRole}
                      role={role}
                      unauthorizedRefresh={unauthorizedRefresh}
                      caseUID={caseUID}
                      showHiddenRecords={showHiddenRecords}
                    />
                  : 
                    <EmptyMedicalRecordsList>No medical records provided.</EmptyMedicalRecordsList> 
                } 
              </Card>
            </>
          }
        </PageLoadingSuspense>
      </PageContainer>
      <PurviewFooter/>
    </>
  }

  const GetUploaderToken = allowUpload || IdentityFn
  const medicalRecordsUpdater = updateMedicalRecordsInfo(authPostFetcher)(medicalRecordsStore, setMedicalRecords, caseRequest.requestId)
  const persistRecord = persistMedicalRecord(medicalRecordsUpdater)
  // const dobDate = parseDate(caseRequest.patientInfo.dob, new Date(), "MM-dd-yyyy")

  return <>
    {
      !withoutRecords && <>
        <SectionHeading>
          <h3>Medical record files</h3>
        </SectionHeading>
        <Card>
          {(hasMedicalRecords || allowUpload) ?
            <Suspense fallback={<Loading size={36} borderWidth={5} css="margin:3rem auto;" />}>
              <GetUploaderToken requestId={caseRequest.requestId}>{(jwt) =>
                <UploaderLegacy
                  withViewAllButton
                  jwt={jwt}
                  disabled={!allowUpload}
                  allowDownload={allowDownload && allowDownloadMedicalImages}
                  withoutDelete={!allowUpload}
                  ref={uploaderRef}
                  medicalRecords={medicalRecordsInfo || EMPTY_MEDICAL_RECORDS}
                  persistRecord={persistRecord}
                  appJwt={getJwt()}
                  requestId={caseRequest.requestId}
                />
              }</GetUploaderToken>
            </Suspense>
            :
            <EmptyMedicalRecordsList>No medical records provided.</EmptyMedicalRecordsList>
          }
        </Card>
      </>
    }
  </>

}