import React from 'react'
import {useFill, useToggle} from '@startlibs/core'
import {Button, Dialog} from '@startlibs/components'
import {Errors, WithForm} from '@startlibs/form'
import {callIfFunction, stopPropagation} from '@startlibs/utils'
import {setNotification} from '../components/Notifications'
import {useLocation, useNavigate} from 'react-router'

const addExtraProp = (element, extra, override = true) => React.cloneElement(
  element,
  override ? {...element.props, ...extra} : {...extra, ...element.props},
  element.props.children
)

export const useDialog = (dialog, args = []) => {
  const toggle = useToggle()
  useFill("Dialog", toggle.isOpen && dialog(toggle.close), [toggle.isOpen, ...args])
  return toggle.open
}

export const useDialogWithToggle = (dialog, args = []) => {
  const toggle = useToggle()
  useFill("Dialog", toggle.isOpen && dialog(toggle), [toggle.isOpen, ...args])
  return toggle
}

export const useConfirmDialog = (dialog, args) => {
  return useDialog((closeDialog) => addExtraProp(callIfFunction(dialog, closeDialog), {closeDialog}), args)
}


export const useConfirmDialogWithProps = (dialog, args) => {
  return useDialogWithToggle((toggle) => addExtraProp(callIfFunction(dialog, toggle.isOpen), {closeDialog: toggle.close}), args)
}

export const ConfirmDialog = (
  {
    isLoading, formRef,
    className, confirmChanges, onChange,
    action = () => {
    },
    onSuccess,
    onFailure,
    title,
    keepOnSuccess,
    closeDialog,
    closeLabel = "Cancel",
    closeButton = <Button>{closeLabel}</Button>,
    auxiliarActions,
    confirm, children, preValidation, transform, values, alternativeButton, alternativeButtonWithLoading, notify, navigate: navigateTo
  }) => {
  const navigate = useNavigate()
  return <WithForm
    alwaysSave
    ref={formRef}
    preValidation={preValidation}
    values={values}
    onChange={onChange}
    action={action}
    transform={transform}
    onFailure={(...props) => callIfFunction(onFailure, ...props, closeDialog)}
    onSuccess={(...props) =>
      Promise.resolve(callIfFunction(onSuccess, ...props))
        .then((v) => {
          if (!keepOnSuccess) {
            closeDialog()
          }
          if (navigateTo) {
            navigate(navigateTo,{replace: true,relative: "path"})
          }
          if (notify) {
            setNotification(notify)
          }
          return v
        })
    }
  >{form =>
    <Dialog
      title={title}
      closeDialog={closeDialog}
      form={form}
      isLoading={isLoading}
      confirmChanges={confirmChanges}
      className={className}
      auxiliarActions={auxiliarActions}
      footer={<>
        {closeButton && addExtraProp(callIfFunction(closeButton, closeDialog), {onClick: closeDialog}, false)}
        {alternativeButtonWithLoading 
          ? addExtraProp(alternativeButton, {isLoading: form.isLoading, type: "submit"})
          : callIfFunction(alternativeButton, closeDialog)
        }
        {/* {callIfFunction(alternativeButton, closeDialog)} */}
        {confirm && addExtraProp(confirm, {isLoading: form.isLoading, type: "submit"})}
      </>}
    >
      {callIfFunction(children, form)}
      <Errors/>
    </Dialog>
  }</WithForm>
}
