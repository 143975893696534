import React, { useEffect } from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {RecordRowContainer} from "./RecordRowContainer";
import {DescriptionField} from "./DescriptionField";
import {AttachmentDescription, AttachmentDetails} from "../AttachmentBoxStyles";
import {Pathology} from "../../enums/RecordClass";
import {CCDA, FormatToLabel} from "../../enums/RecordFormat";
import {formatDate} from "@startlibs/utils";
import {dicomDateToDate} from "../../utils";
import {useToggle} from "@startlibs/core";
import {RecordDetailsDialog} from "./RecordDetailsDialog";
import {Button, Dialog} from "@startlibs/components";
import {useDoAction} from "../../service/hooks/useDoAction";
import {UploaderAction} from "../../service/UploaderAction";
import { UIAction } from '../../service/UIAction';
import { useUIDataSelector } from '../../service/hooks/useUIDataSelector';

styled

export const DicomRecordRow = ({record, group, dragProvided}) => {
  const studyDate = dicomDateToDate(record.info?.studyDate)
  const doAction = useDoAction()

  const openCancelDialog = () => {
    doAction(UIAction.SetCancelDialog, record)
  }

  const openDicomDetailsDialog = () => {
    doAction(UIAction.SetDicomDetailsDialog, record)
  }
  const closeCancelDialog = () => {
    doAction(UIAction.SetCancelDialog, false)
  }

  return <RecordRowContainer
    group={group}
    record={record}
    dragProvided={dragProvided}
    // cancelUpload={cancelDialog.open}
    cancelUpload={openCancelDialog}
    // openDetails={recordDetailsDialog.open}
    openDetails={openDicomDetailsDialog}
    isMultiFile
  >{isMinified =>
    <>
      <AttachmentDescription className="fs-exclude">
        <b>{record.description || "DICOM Study"} {!isNaN(studyDate) && `(${formatDate(studyDate, 'MM-dd-yyyy')})`} </b>
        {
          !isMinified && <a className="light-link" 
            onClick={openDicomDetailsDialog}
            >Details</a>
        }
      </AttachmentDescription>
      {
        !isMinified &&
        <>
          <AttachmentDetails className="fs-exclude">
            <b>Modalities: </b>{record.info?.modalities?.join(", ")} ({record.info?.seriesUIDs?.length} series)
          </AttachmentDetails>
          <AttachmentDetails className="fs-exclude">
            <b>Patient: </b>{record.info?.patientName?.replace(/\^/g, ' ') || null}
          </AttachmentDetails>
        </>
      }
    </>
  }</RecordRowContainer>
}

export const DicomDetailsInfoBox = ({record}) => {
  const studyDate = dicomDateToDate(record.info?.studyDate)
  return <>
    <AttachmentDescription className="fs-exclude" css="font-size:14px;">
      <b>{record.info?.studyDescription} {!isNaN(studyDate) && `(${formatDate(studyDate, 'MM-dd-yyyy')})`}</b>
    </AttachmentDescription>
    <AttachmentDetails className="fs-exclude">
      <b>Patient:</b> {record.info?.patientName ? record.info?.patientName.replace(/\^/g, ' ') : null}
    </AttachmentDetails>
    <AttachmentDetails className="fs-exclude">
      <b>Modalities: </b>{record.info?.modalities.join(", ")} ({record.info?.seriesUIDs.length} series)
    </AttachmentDetails>
    <AttachmentDetails className="fs-exclude">
      <b>Referring
        physician:</b> {record.info?.referringPhysician ? record.info?.referringPhysician.replace(/\^/g, ' ') : null}
    </AttachmentDetails>
    <AttachmentDetails>
      <b>Study UID:</b> {record.studyUID}
    </AttachmentDetails>
  </>
}