import {Button, Dialog, Icon} from '@startlibs/components'
import {Errors, SimpleCheckbox, WithForm} from '@startlibs/form'
import {formatDate, getColor} from '@startlibs/utils';
import {lighten} from 'polished';
import {useToggle} from '@startlibs/core'
import React, {useState, useRef, useEffect, useContext} from 'react'
import _ from 'lodash/fp'
import styled, {css} from 'styled-components'

import {dicomDateToDate} from '../utils'
import {useViewRecords, useViewStudy} from '../service/utils/downloadFile'
import {UploaderConfigContext} from "../service/UploaderConfigContext";
import {RecordFormat} from "../service/utils";
import {DicomStudy} from "../enums/RecordFormat";
import {useRecordsSelector} from "../service/hooks/useRecords";
import { useUIDataSelector } from '../service/hooks/useUIDataSelector';
import { getFilenameFromRecord } from './recordGroup/RecordRowActions';
import { jwtPostFetcher } from '../utils/authFetch';
import { PROVIDER } from '../enums/UserRoles';
import { group } from 'd3';
import { GroupContainer } from './RecordGroup';
import { GroupHeader } from './recordGroup/GroupHeader';
import { GroupHeaderSimple } from './recordGroup/GroupHeaderSimple';
import { AttachmentBox, AttachmentCheckbox, AttachmentDescription, AttachmentDetails, AttachmentIcon } from './AttachmentBoxStyles';
import { getRecordIcon } from '../service/utils/recordUtils';
import { useDoAction } from '../service/hooks/useDoAction';
import { UIAction } from '../service/UIAction';
import { DicomRouteDialog } from './dialogs/DicomRouteDialog';


const ExportDICOMButton = styled(Button)`
  margin-left: auto;
  flex-shrink: 0;
  ${props => props.isGroupButton && css`
    margin-top: 0;
    margin-bottom: .25rem;
    border: 0;
    :hover, :focus, :active {
      border: 0;
      box-shadow: none;
      :after {
        display: none;
      }
    }
  `}
  span{
    margin: 0px;
    margin-left: -4px !important;
    margin-right: -4px !important;
    padding: 0px;
  }
`

const StudyItemStyled = styled.div`
  position: relative;
  background: ${getColor('gray240')};
  border-bottom: 1px solid ${getColor('gray210')};
  padding: 0.75rem 4rem 0.75rem 1.25rem;
  cursor: pointer;
  :last-child {
    border-bottom: none;
  }
  :hover {
    background: rgba(0,139,210,0.15);
  }
  ${props => props.isChecked && css`
    background: ${getColor('main')};
    color: white;
    .authorizationAlert {
      color: #ffb9b9 !important;
    }
    :hover {
      background: ${getColor('main')};
    }
  `}
  ${SimpleCheckbox} {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }
`
const StudiesListHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  background: ${getColor('gray240')};
  padding: 1rem;
  font-size: 13px;
  border-radius: 6px;
`
const StudiesList = styled.div`
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 1.5rem;
  > div {
    max-height: 16rem;
    overflow: auto;
  }
`

const StudyTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0.25rem;
`

const StudyDetails = styled.div`
  margin-top: 2px;
  font-size: 11px;
  span + span {
    margin-left: 1rem;
  }
`

export const InfoBox = styled.div`
  background: ${getColor('gray240')};
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 0.75rem;
  width: 100%;
  color: ${getColor('gray120')};
  ${props => props.lightBlue && css`
    background: #f0f6f8;
    border: 1px solid rgba(41, 122, 163, 0.25);
    color: ${getColor('main')};
  `}
  ${props => props.error && css`
    background: ${props => lighten(0.43, props.theme.colors.alert)};
    border-color: ${props => lighten(0.32, props.theme.colors.alert)};
    color: ${getColor('alert')};
  `}
  ${props => props.withIcon && css`
    display: flex;
    align-items: center;
    ${Icon} {
      font-size: 16px;
      margin-right: .5rem;
      font-weight: 400;
    }
  `}
`

const RecordsContainer = styled.div`
    max-height: 450px;
    height: calc(100vh - 25rem);
    overflow: auto;
    ::-webkit-scrollbar {
        width: 17px;
        height: 17px;
        border-radius: 100px;
        &:hover {
          background: rgba(0,0,0,0.025);
        }
      }
      ::-webkit-scrollbar-thumb {
        background: rgba(0,0,0,0.15);
        border-radius: 100px;
        background-clip: padding-box;
        border: 4px solid transparent;
        min-height: 30px;
        min-width: 30px;
        &:hover {
          background: rgba(0,0,0,0.25);
          background-clip: padding-box;
          border: 4px solid transparent;
        }
      }
      ::-webkit-scrollbar-corner {
        background: #f3f5f7;
      }
`

export const DicomRoute = ({isGroupButton}) => {
  
  const groups = useUIDataSelector('groups')
  const notClassGroup = groups.find(group => group.id === 'notclass')
  // prevent this error item.includes is not a function from happen
  const dicomsInNotClassGroup = notClassGroup?.items?.filter(item => item && item != null && item?.length > 0 && item?.includes('.'))
  // const dicomRecords = useRecordsSelector(_.filter(_.matchesProperty('recordFormat',DicomStudy))).filter(dicom => !dicomsInNotClassGroup.includes(dicom.key))
  const dicomRecords = useRecordsSelector(_.filter(dicom => {
    return (dicom.recordFormat === DicomStudy || dicom?.info?.type === DicomStudy) && !dicomsInNotClassGroup?.includes(dicom.key)
  }));
  const [selectedAttachments, setSelectedAttachments] = useState([])
  const [groupsWithDicoms, setGroupsWithDicoms] = useState([])
  const config = useContext(UploaderConfigContext)
  const [isLoadingToken, setIsLoadingToken] = useState(false)

  const {
    requestId,
    role,
    canRouteDicoms,
    canCreatePacs,
    worklistViewerJwt: expertViewJwt,
  } = config

  const collapsedGroups = useUIDataSelector('collapsed')
  const selectPacsDialog = useToggle()
  const dialog = useToggle()
  const infoBox = useToggle()
  const [firstDicomRouterJwt, setFirstDicomRouterJwt] = useState(null)

  useEffect(() => {
    if (selectedAttachments.length < 4 && infoBox.isOpen) {
      infoBox.close()
    }
  },[selectedAttachments])

  useEffect(() => {
    setSelectedAttachments(_.filter(attachment => dicomRecords.indexOf(attachment)>=0))
    const filteredGroups = filterGroupsByRecordKeys(groups, dicomRecords);
    setGroupsWithDicoms(filteredGroups.filter(group => group.items.length > 0));
  },[dicomRecords, groups])

  const filterGroupsByRecordKeys = (groups, records) => {
    // Extract keys from records
    const recordKeys = new Set(records.map(record => record.key));

    // Iterate over groups to filter items
    return groups.map(group => {
        const filteredItems = group.items.filter(item => recordKeys.has(item));
        return {
            ...group,
            items: filteredItems,
            size: filteredItems.length
        };
    });
};


  return dicomRecords?.length >= 1
    ? <>
      <ExportDICOMButton
        onClick={dialog.open}
        small
        outline
        icon="route"
        isGroupButton={isGroupButton}
      />
      {/* Export DICOM records</LaunchViewerButton> */}
      {
        dialog.isOpen &&
        <WithForm
          alwaysSave
          action={() => {
            setIsLoadingToken(true)
            jwtPostFetcher(expertViewJwt)("/api/admin/dicomRouterToken")
            .then(r => {
              setFirstDicomRouterJwt(r.jwt)

            })
            .catch(e => console.log(e))
            .finally(() => {
              setIsLoadingToken(false)
              selectPacsDialog.open()
              dialog.close()
            })
            // openViewerAndLog()
          }}
          onSuccess={() => {
            // setSelectedAttachments([]);
            // dialog.close()
          }}
          preValidation={() => selectedAttachments.length < 1 && {"":"You must select at least one DICOM study to route"}}
        >{form =>
          <Dialog
            title="DICOM records export"
            closeDialog={() => {
              setSelectedAttachments([]);
              dialog.close();
            }}
            footer={<>
              <Button 
                onClick={() => {
                  setSelectedAttachments([])
                  dialog.close()
                }}
              >Cancel</Button>
              <Button isLoading={isLoadingToken} onClick={form.submitForm} highlight>Next</Button>
            </>}
          >
            <p><strong>Select the DICOM records to export:</strong></p>
             <StudiesListHeader>
              <b>{selectedAttachments.length} selected</b>
              <a className='light-link' onClick={() => setSelectedAttachments(dicomRecords)}>Select all</a>
              {selectedAttachments.length > 0 && <a className='light-link' onClick={() => setSelectedAttachments([])}>Clear</a>}
            </StudiesListHeader>
            {/* <StudiesList> */}
              <RecordsContainer>
                {groupsWithDicoms.map((group, index) => (
                  <GroupContainer
                    key={group.id}
                    index={index}
                    group={group}
                    // mode={mode}
                    isNotClassMinified={false}
                    isNotClassAndNotMinifiedAndEmpty={group.id === 'notclass' && !group.items.length > 0}
                  >
                    <div style={{position: 'relative'}}>
                      <GroupHeaderSimple css="margin: 1rem 0 0.5rem !important;" group={group} isCollapsed={collapsedGroups.includes(group.id)} snapshot={null} moveHandle={null}/>
                      {!collapsedGroups.includes(group.id) && dicomRecords.filter(record => group.items.indexOf(record.key) >= 0).map(attachment => <AttachmentItem
                        attachment={attachment}
                        isChecked={selectedAttachments?.indexOf(attachment) >= 0}
                        toggle={() => setSelectedAttachments(attachments =>
                          attachments.indexOf(attachment) >= 0
                          ? attachments.filter(a => a !== attachment)
                          : attachments.concat(attachment)
                        )}
                        />)}
                    </div>
                  </GroupContainer>
                ))}
                
              </RecordsContainer>
            {/* </StudiesList> */}
            <Errors css="margin-top:1rem;"/>
          </Dialog>
        }</WithForm>
      }
      {
        selectPacsDialog.isOpen &&
        <DicomRouteDialog
          studyList={selectedAttachments}
          closeDialog={
            () => {
              selectPacsDialog.close()
              setSelectedAttachments([])
            }
          }
          firstDicomRouterJwt={firstDicomRouterJwt}
          previousDialog={dialog}
        />

      }
    </>
    : null
}


const AttachmentItem = ({attachment, isChecked, toggle}) => {
  // const isChecked = values.indexOf(release.id) >= 0

  const ref = useRef()
  useEffect(() => {
    if (isChecked && ref.current) {
      ref.current.scrollIntoView({block: "center"})
    }
  }, [])

  
  const studyDate = dicomDateToDate(attachment.info.studyDate)
  const studyDescription = attachment.studyDescription || attachment.info.studyDescription
  const modalities = attachment.modalities || attachment.info.modalities
  const patientName = attachment.patientName || attachment.info.patientName
  const doAction = useDoAction()

  const openDicomDetailsDialog = () => {
    doAction(UIAction.SetDicomDetailsDialog, attachment)
  }
  

  return <AttachmentBox isSelected={isChecked} ref={ref} 
    onClick={(e) => {
      e.stopPropagation()
      toggle()
    }} 
    isSelectMode={true}>
    <AttachmentIcon icon={getRecordIcon(attachment)} />
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <AttachmentDescription className="fs-exclude">
        <b>{studyDescription || "DICOM Study"} {!isNaN(studyDate) && `(${formatDate(studyDate, 'MM-dd-yyyy')})`} </b>
        <a className="light-link" style={{pointerEvents: 'all'}}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            openDicomDetailsDialog()
          }}
        >
          Details
        </a>
      </AttachmentDescription>
      {
        <>
          <AttachmentDetails className="fs-exclude">
            <b>Modalities: </b>{modalities?.join(", ")} ({attachment.info?.seriesUIDs?.length} series)
          </AttachmentDetails>
          <AttachmentDetails className="fs-exclude">
            <b>Patient: </b>{attachment.info?.patientName?.replace(/\^/g, ' ') || null}
          </AttachmentDetails>
        </>
      }
    </div>
    <div onClick={e => e.stopPropagation()}>
      <AttachmentCheckbox
        raw
        setValue={toggle}
        value={isChecked}
      />
    </div>
  {/* </StudyItemStyled> */}
  </AttachmentBox>
}
