import React from 'react'
import styled from 'styled-components'

export const MALE = 'male'
export const FEMALE = 'female'
export const OTHER = 'other'
export const UNKNOWN = 'unknown'

export const GENDER_LABELS = {
  [MALE]: 'Male',
  [FEMALE]: 'Female',
  [OTHER]: 'Other',
  [UNKNOWN]: 'Unknown'
}

export const convertToCareQualityGender = (gender) => {
  switch (gender) {
    case 'M':
      return 'male'
    case 'F':
      return 'female'
    case 'O':
      return 'other'
    default:
      return 'unknown'
  }
}
