import React from 'react'
import {isStateBefore} from '../../request/utils'
import {
  CaseId,
  Sidebar,
  SidebarCard,
  SidebarContainer,
  SidebarDesc, SidebarSeparator,
  SidebarTitle, StepLink
} from '../../admin/steps/RequestSidebar'
import {ScrollToTop} from '../../components/ScrollToTop'
import {CASE_CLOSED, PENDING_REQUEST} from '../../enums/CaseState'
import {Icon} from '@startlibs/components'
import {Match} from "../../components/Match";

export const ReadonlyRequestSidebar = ({caseRequest = {}}) => {



  return <Match path="/:userType/:step">{({match}) => {
    const step = match?.params && match?.params.step

    return <SidebarContainer className="hideOnPrint">
      <ScrollToTop when={step}/>
      <Sidebar>
        <SidebarCard>
          <div className="sidebar-heading">
            <SidebarDesc>CASE DETAILS</SidebarDesc>
            <SidebarTitle>{caseRequest.patientInfo.firstName} {caseRequest.patientInfo?.middleName} {caseRequest.patientInfo.lastName}</SidebarTitle>
            {caseRequest.requestCode && <CaseId><b>Case Id:</b> {caseRequest.requestCode}</CaseId>}
          </div>

          <StepLink
            to="overview"
            selected={step === "overview"}
          ><Icon icon="user-line"/> Case Overview</StepLink>

          {/* // If can add note, show all on the same page */}
          {!caseRequest?.permission?.addNote && <StepLink
            to="medical-records"
            selected={step === "medical-records"}
            disabled={caseRequest?.permission?.medicalRecords === false}
          ><Icon icon="dicom-no-text"/> Medical Records</StepLink>}


          {caseRequest.permission.report && <StepLink
            to="report"
            selected={step === "report"}
            disabled={caseRequest.state != CASE_CLOSED}
          ><Icon icon="report"/> Report</StepLink>}

        </SidebarCard>
      </Sidebar>
    </SidebarContainer>
  }
  }</Match>
}
