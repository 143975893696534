import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {Editor, Node, Transforms} from "slate";
import {useSlatePopups} from "./popups/SlatePopups";
import { CellComponent, TableElement } from './table';
import {DefaultElement, ReactEditor, useFocused, useSelected, useSlateStatic} from "slate-react";
import {  Icon, Tooltip } from '@startlibs/components';
import { RTF_IMAGE_CLASSES } from './slateUtils';
import { getColor } from '@startlibs/utils';

const ButtonsContainer = styled.div`
  position: absolute;
  left: 3px;
  top: 3px;
  background: white;
  z-index: 10;
  padding: 0.25rem;
  border-radius: 7px;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.10), 0px 0px 2px 0px rgba(0,0,0,0.25);
  user-select: none;
  display: flex;
  align-items: center;
  ${Icon} {
    font-size: 22px;
    width: 2.5rem;
    line-height: 2.5rem;
    height: 2.5rem;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    :hover {
      cursor: pointer;
      background: rgba(0,0,0,0.05);
    }
  }
`

const ImageToolSeparator = styled.div`
  border-left: 1px solid ${getColor('gray230')};
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  height: 1.5rem;
  user-select: none;
`


// Define a React component to render leaves with bold text.
const Leaf = ({attributes, children, leaf}) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.linkHighlight) {
    children = <span style={{backgroundColor:"rgba(200,200,200,0.5)"}}>{children}</span>
  }

  if (leaf.strikethrough) {
    children = <del>{children}</del>
  }

  return <span {...attributes}>{children}</span>
}

const InlineChromiumBugfix = styled(({className}) => (
  <span
    contentEditable={false}
    className={className}
  >
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
))`font-size: 0;`;

const Paragraph = styled.p`
  margin: 0;
`

const LinkStyle = styled.a`
  ${props => props.selected ? 'box-shadow: 0 0 0 3px #ddd;' : ''}
`
const LinkComponent = ({attributes, children, element}) => {
  const selected = useSelected()
  const editor = useSlateStatic()
  const popups = useSlatePopups()

  const getLinkRange = (e) => {
    const path = ReactEditor.findEventRange(editor, e)?.anchor?.path
    if (!path) { return }
    const [link, linkPath] = Editor.above(editor, {
      at: path,
      match: (node) => node.type === 'link'
    }) || []
    if (!linkPath) { return }
    const [anchor,focus] = Editor.edges(editor, linkPath)
    return {anchor,focus}
  }

  return (
    <LinkStyle
      onClick={(e) => popups.open('link', {
        isActive: true,
        values: {text: Node.string(element), url: element.url},
        range: getLinkRange(e)
      })}
      {...attributes}
      href={element.url}
      selected={selected}
    >
      <InlineChromiumBugfix/>
      {children}
      <InlineChromiumBugfix/>
    </LinkStyle>
  )
}

const Image = ({ attributes, children, element, disabled }) => {
  const editor = useSlateStatic()
  const path = ReactEditor.findPath(editor, element)

  const selected = useSelected()
  const focused = useFocused()
  const [keepShowing, setKeepShowing] = React.useState(!disabled)
  var size = RTF_IMAGE_CLASSES.findIndex((item) => item == element.size)
  React.useEffect(() => {
    if(selected && focused && !disabled) {
      setKeepShowing(true)
      return
    }
    const timer = setTimeout(() => {
      setKeepShowing(false)
    }, 2000);
    return () => clearTimeout(timer);
  }, [focused, selected, keepShowing, disabled])
  return (
    <div {...attributes}>
      {children}
      <div
        contentEditable={false}
        css={"position: relative;"}
      >
        <img
          src={element.url}
          className={`${element.size} ${selected && focused && keepShowing ? 'imageSelected':''}`}
          onDragStart={(e) => {e.preventDefault(); e.stopPropagation();}}
        />
        {/* {((selected && focused) || keepShowing) && */}
        {(selected && focused && keepShowing) &&
        <ButtonsContainer data="slatebtcontainer">
          <Tooltip content="Increase size">
            <Icon 
              icon="plus"
              onClick={() => {
                setKeepShowing(true)
                size = (size + 1) % RTF_IMAGE_CLASSES.length
                Transforms.setNodes(editor, { size: RTF_IMAGE_CLASSES[size] }, { at: path }) 
              }}
              disabled={size == RTF_IMAGE_CLASSES.length - 1}
            />
          </Tooltip>
          <Tooltip content="Decrease size">
            <Icon
              icon="minus"
              onClick={() => {
                setKeepShowing(true)
                size = (size - 1) % RTF_IMAGE_CLASSES.length
                if (size < 0){size = 0}
                Transforms.setNodes(editor, { size: RTF_IMAGE_CLASSES[size] }, { at: path })
              }}
              disabled={size == 0}
            />
          </Tooltip>
          <ImageToolSeparator/>
          <Tooltip content="Delete image">
            <Icon 
              icon="delete"
              onClick={(e) => {setKeepShowing(false); 
                Transforms.removeNodes(editor, { at: path })
                const { selection } = editor;
                const isInTable = Editor.above(editor, {
                  match: n => n.type === 'table',
                });
          
                if (isInTable) {
                  const start = Editor.start(editor, selection);
                  const isStart = Editor.isStart(editor, start, selection);
          
                  const currCell = Editor.above(editor, {
                    match: n => n.type === 'td',
                  });
                    
                  Transforms.setNodes(
                    editor,
                    {
                      height: 22
                    },
                    {
                      at: [],
                      match: n => n.type === 'td'
                    },
                  );
                }
                
              }}
            />
          </Tooltip>
        </ButtonsContainer>
        }
      </div>
    </div>
  )
}

export const renderElement = ({attributes, children, element, ...props}, disabled, formatTools) => {
  switch (element.type) {
    case 'quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'code':
      return (
        <pre>
          <code {...attributes}>{children}</code>
        </pre>
      )
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>
    case 'heading-four':
      return <h4 {...attributes}>{children}</h4>
    case 'heading-five':
      return <h5 {...attributes}>{children}</h5>
    case 'heading-six':
      return <h6 {...attributes}>{children}</h6>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol start={element.start}{...attributes}>{children}</ol>
    case 'link':
      return <LinkComponent attributes={attributes} children={children} element={element} {...props} />
    case 'div':
      return <div {...attributes}>{children}</div>
    case 'table':
    case 'tbody':
    case 'tfoot':
    case 'thead':
      return <TableElement attributes={attributes} children={children} element={element} disabled={disabled} {...props} />;
    case 'table-content': {
      return (
        <div slate-table-element="content" className="table-content">{children}</div>
      );
    }  
    case 'table-row':
    case 'tr': {
      return (
        <tr
          {...attributes}
          className="table-tr"
          slate-table-element="tr"
          data-key={element.key}
          onDrag={e => e.preventDefault()}
        >
          {children}
        </tr>
      );
    }
    case 'table-cell':
    case 'th':
    case 'td': {
      return (
        <CellComponent
          {...props}
          dataKey={element.key}
          node={element}
        >
          {children}
        </CellComponent>
      );
    }
    case 'quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'image':
      return formatTools.findIndex(x => x === 'image') >= 0 ? <Image attributes={attributes} children={children} element={element} disabled={disabled} /> : <DefaultElement attributes={attributes} element={element} children={children}/>
    case 'paragraph':
      return <Paragraph {...attributes}>{children}</Paragraph>
    case 'mention':
      return <Mention attributes={attributes} children={children} element={element} {...props}/>
    default:
      return <DefaultElement attributes={attributes} element={element} children={children}/>
  }
}
// Define a leaf rendering function that is memoized with `useCallback`.
export const renderLeaf = props => {
  return <Leaf {...props} />
}


const Mention = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  const style = {
    padding: '3px 3px 2px',
    margin: '0 1px',
    verticalAlign: 'baseline',
    display: 'inline-block',
    borderRadius: '4px',
    backgroundColor: '#eee',
    fontSize: '0.9em',
    boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
  }
  // See if our empty text child has any styling marks applied and apply those
  if (element.children[0].bold) {
    style.fontWeight = 'bold'
  }
  if (element.children[0].italic) {
    style.fontStyle = 'italic'
  }
  return (
    <span
      {...attributes}
      contentEditable={false}
      // data-cy={`mention-${element.character.replace(' ', '-')}`}
      style={style}
    >
      {children}{element.phrase}
    </span>
  )
}
