import {Button, DraggableItem, Icon, Loading} from '@startlibs/components';
import {Errors, FormValue, WithForm} from '@startlibs/form';
import {_s, getColor} from '@startlibs/utils';
import {useNavigate, useLocation} from 'react-router'
import {useToggle} from '@startlibs/core'
import React, {useRef, useState} from 'react';
import _ from 'lodash/fp'
import styled from 'styled-components';
import {AssignmentStatusBox} from './expertReview/ExpertReviewList'
import {CASE_CLOSED, CASE_REVIEWED, UNDER_REVIEW, WAITING_MORE_INFORMATION} from '../../enums/CaseState'
import {Card, PageContainer, PageFooter, SectionHeading} from '../../components/PageLayout';
import {Header} from '../../components/Header';
import {InfoBox} from '../../components/InfoBox'
import {PurviewFooter} from '../../components/PurviewFooter';
import {QuestionAndAnswer} from '../../request/forms/QuestionAndAnswer'
import {QuestionList} from '../../components/QuestionList'
import {getJwt} from '../../hooks/useJwt'
import {hasAFieldsFilled} from '../../request/utils'
import {jwtGetFetcher, jwtPostFetcher} from '../../utils/authFetch'
import {useAskExpertMoreInformation} from './hooks/useAskExpertMoreInformation'
import {useConfirmExit} from './hooks/useConfirmExit'
import {ExpertAdditionalInfo} from '../../request/forms/Answer'
import {ExpertSignatureManagement} from '../../expert/ExpertSignatureManagement'
import {buildValidation} from '../../utils/validation'
import {isStrEmpty} from '../../utils'
import { getTimezoneOffset, isRichText, safeMultipleNewLineToBr, safeMultipleNewLineToBrOnly } from '../../utils/utils';
import { useSetConfirmExitPage } from '../../hooks/useConfirmExitPage';
import { useIntl } from 'react-intl';
import { lazyUserInfo } from '../../components/WithProvider';
import { useSubmitCase } from '../../expert/dialogs/useSubmitCase';
import { REVIEWER_TYPE_ADMIN } from '../../enums/UserRole';

export const DragDropHelp = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  height: auto;
  span {
    color: rgba(0,0,0,0.3);
    font-weight: 600;
    font-size: 11px;
    margin-right: .5rem;
  }
  :after {
    flex-grow: 1;
    content: '';
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }
`

const QuestionsWrapper = styled(Card)`
  position: relative;
`

const LoadingExpert = styled.div `
  position: relative;
  border-radius: 6px;
  height: 5rem;
  background: ${getColor('gray240')};
`

const GET_EMPTY_QUESTION = () => ({question: '', answer: '', id: Date.now() + ''})
const verifyQuestions = ({caseInfo}) => (!caseInfo || !caseInfo.questionsConsultant || !caseInfo.questionsConsultant.length || !caseInfo.questionsConsultant.find(({question}) => !!question)) && {"caseInfo.questionsConsultant.0.question":"At least one question is required."}

const transform = _.update('caseInfo.questionsConsultant',_.filter(({question,answer})=> question || answer))

const GET_EMPTY_ADDITIONAL_INFO = () => ({title: '', content: '', id: Date.now().toString(36) + ''})

export const ExpertReviewStep = ({caseRequest, setCaseRequest, ...props}) => {

  const userInfo = lazyUserInfo.read()
  const adminIsReviewingThisCase = userInfo?.canReviewCases && caseRequest?.isAssignedToLoggedAdmin && (caseRequest?.state === UNDER_REVIEW || caseRequest?.state === WAITING_MORE_INFORMATION)
  // const readOnly = caseRequest.state === WAITING_MORE_INFORMATION || caseRequest.state === CASE_CLOSED
  const readOnly = caseRequest.state === CASE_CLOSED || (caseRequest.state === WAITING_MORE_INFORMATION && !adminIsReviewingThisCase)
  const [isSaving, setIsSaving] = useState(false)

  const hideAskForMoreInfo = (adminIsReviewingThisCase && caseRequest?.reportExpertReviews?.length === 0) || (caseRequest?.reportExpertReviews?.length === 1 && caseRequest?.reportExpertReviews[0]?.reviewerType === REVIEWER_TYPE_ADMIN && caseRequest?.reportExpertReviews[0]?.expertId === userInfo.id)
  
  const onlySaveAction = ({  caseInfo }) => {
    return jwtPostFetcher(getJwt())("/api/caseInfo?requestId=" + caseRequest.requestId, caseInfo, { method: "PUT" })
      .then(() => {
        setCaseRequest(_.set('caseInfo', caseInfo))
      })
      .catch((err) => {
        console.error(err)
      })
  }
  
  const action = ({caseInfo}) => {
    if(adminIsReviewingThisCase){
      setIsSaving(true)
      return jwtPostFetcher(getJwt())(`/api/reviewer/submitReview?requestId=${caseRequest.requestId}&useCustomReport=${false}`, 
        _.update('additionalFields',(additionalFields) => additionalFields || [],caseInfo)
      ).then(() => {
        jwtGetFetcher(getJwt())(`/api/viewCaseRequest?requestCode=${caseRequest.requestCode}&timezoneOffset=${getTimezoneOffset()}`)
          .then((caseRequest) => {
            setCaseRequest(caseRequest)
            setIsSaving(false)
            if (skipAndUpload.isOpen) {
              navigate("../upload-report")
            } else {
              navigate("../report")  
            }
          })
          .catch((err) => {
            console.error(err)
            setIsSaving(false)
          })
      })
      .catch((err) => {
        console.error(err)
        setIsSaving(false)
      })
    }else{
      return jwtPostFetcher(getJwt())("/api/caseInfo?requestId=" + caseRequest.requestId, caseInfo, {method: "PUT"})
    }
  }

  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const formRef = useRef()
  const footerRef = useRef()
  const skipAndUpload = useToggle(!caseRequest.report?.customReport && !hasAFieldsFilled(caseRequest.caseInfo))
  
  const caseCompletedWithoutReport = caseRequest.state === CASE_CLOSED && !caseRequest.report?.customReport && !caseRequest.report?.useCustomReport && !caseRequest.report
  
  const parseAdditionalFieldContent = _.update('content',  (content) => isRichText(content) ? safeMultipleNewLineToBrOnly(content) :safeMultipleNewLineToBr(content))
  const parseAnswer = _.update('answer',  (answer) => isRichText(answer) ? safeMultipleNewLineToBrOnly(answer) : safeMultipleNewLineToBr(answer))
  const parse = _.flow(
    _.update('caseInfo.additionalFields',_.map(item => item.content.includes('\n') ? parseAdditionalFieldContent(item) : item)),
    _.update('caseInfo.questionsConsultant',_.map(item => item.answer.includes('\n') ? parseAnswer(item) : item))
  )
  
  const confirmExitPage = () => () => {
    if (!formRef.current) {
      return false
    }
    
    if (formRef && formRef.current && formRef.current.hasChanged && !readOnly) {
      return adminIsReviewingThisCase 
        ? new Promise((exit) => {
            onlySaveAction(formRef.current.getTransformedValues())
            .then(() => {exit()})
          })
        : new Promise((exit) => {
            formRef.current.willSubmitForm()
            .then(() => {exit()})
          })
      // return new Promise((exit) => {
      //   formRef.current.willSubmitForm()
      //   .then(() => {exit()})
      // })
    }
  }

  // useExitSave(({state,...values}) => {
  //   setCaseRequest(_.assign(_,values))
  //   return action(values)
  // },formRef)
  // This works on default browser close tab/window
  useConfirmExit(formRef)

  // This works on navigating away from the page on SPA (react-router)
  useSetConfirmExitPage(confirmExitPage(false))
  
  const confirmValid = (fn) => () => {
    const errors = verifyQuestions(formRef.current.getValues())
    if (errors) {
      formRef.current.addErrors(errors)
    } else {
      fn()
    }
  }
  const requestMoreinfo = useAskExpertMoreInformation(caseRequest,setCaseRequest)

  const navigationButtons = (form, isSaving) => <>
    {!hideAskForMoreInfo && <Button onClick={confirmValid(requestMoreinfo)} icon="email">Ask for additional information</Button>}
    {
      skipAndUpload.isOpen
        ? <Button highlight isLoading={form.isLoading} type="submit">Skip and upload PDF report</Button>
        : <>
          <Button highlight isLoading={form.isLoading || isSaving} type="submit">{'Save and advance'}</Button>
        </>
    }
  </>

  return <><WithForm
    // values={caseRequest}
    values={parse(caseRequest)}
    action={action}
    alwaysSave
    transform={transform}
    ref={formRef}
    preValidation={buildValidation({
      'caseInfo.additionalFields':(fields = [],k,values) => {
        if (caseRequest?.report?.useCustomReport || !hasAFieldsFilled(values.caseInfo)) { return }
        if (fields.find(({title,content}) => !isStrEmpty(content.replace(/<div>|<\/div>/g, '').trim()) && isStrEmpty(title.replace(/<div>|<\/div>/g, '').trim()))) {
          footerRef.current.scrollIntoView({behavior:'smooth'})
          return "Filled-in fields require a title, please review before proceeding."
        }
      },
      'caseInfo.questionsConsultant': (fields = [],k,values) => {
        if (caseRequest?.report?.useCustomReport || !hasAFieldsFilled(values.caseInfo)) { return }
        if (fields.find(({question,answer}) => !isStrEmpty(answer.replace(/<div>|<\/div>/g, '').trim()) && (isStrEmpty(question.replace(/<div>|<\/div>/g, '').trim())))) {
          footerRef.current.scrollIntoView({behavior:'smooth'})
          return "Filled-in answer require a question, please review before proceeding."
        }
      }
    })}
    onChange={(_,caseRequest) => skipAndUpload.openWith(!caseRequest.report?.customReport && !hasAFieldsFilled(caseRequest.caseInfo))}
    onSuccess={
      adminIsReviewingThisCase 
        ? () => setIsSaving(false)
        : _.flow(
            (values) => setCaseRequest(_.set('caseInfo',values.caseInfo)),
            () => skipAndUpload.isOpen
                ? navigate("../upload-report")
                : navigate("../report")
          )
    }
  >{form => <>
    <PageContainer>
      <Header title="Expert review">
        {!readOnly && navigationButtons(form, isSaving)}
      </Header>
      {readOnly &&
        <AssignmentStatusBox reviewed={caseRequest.state === CASE_CLOSED} css="margin-bottom: 1rem;">
          <div className="waitingBoxContent">
            <Icon icon={caseRequest.state === CASE_CLOSED ? 'check' : 'clock'}/>
            <h4>
              {caseRequest.state === CASE_CLOSED ? 'This case is already completed.' : 'Pending review'}
            </h4>
            <p>{caseRequest.state === CASE_CLOSED
              ? caseCompletedWithoutReport 
                ? 'The case has been marked as completed, but no report was generated.'
                : intl.formatMessage({
                    defaultMessage:"The report is already concluded and available for the patient contact.",
                    description:"Report concluded warning available to contact label"
                  })
              : 'There is an expert currently reviewing this case.'}</p>
          </div>
        </AssignmentStatusBox>
      }
      {
        caseRequest.report?.customReport &&
        <InfoBox lightBlue>
          {caseRequest.report?.useCustomReport
            ? 'A custom PDF was uploaded and is serving as the case report.'
            : 'Using the default report, but a custom PDF is also available.'
          }
        </InfoBox>
      }
      <SectionHeading>
        <h3>Questions & answers</h3>
      </SectionHeading>
      <Questions
        form={form}
        location={location}
        readOnly={readOnly}
        requestId={caseRequest.requestId}
      />
      <SectionHeading>
        <h3>Additional information</h3>
      </SectionHeading>
      <AdditionalInfo
        form={form}
        location={location}
        readOnly={readOnly}
        requestId={caseRequest.requestId}
      />

      <SectionHeading>
        <h3>Signatures</h3>
        <p>To sign your report, please add a signature</p>
      </SectionHeading>
      <Card css="position:relative;">
        <FormValue path='caseInfo.signatures'>{ signatures =>
          signatures?.length > 1 && <DragDropHelp><span>Drag and drop items to reorder</span></DragDropHelp>
        }</FormValue>
        <React.Suspense fallback={<Loading absolute />}>
          <ExpertSignatureManagement disabled={readOnly} location={location} path='caseInfo.signatures' apiType="admin" caseRequest={caseRequest} form={form}/>
        </React.Suspense>
      </Card>

      <Errors/>
      {!readOnly &&
        <PageFooter ref={footerRef}>
          {!readOnly && navigationButtons(form, isSaving)}
        </PageFooter>
      }
    </PageContainer>
    <PurviewFooter/>
  </>
  }</WithForm>
  </>
}


const Questions = ({form,readOnly,location,requestId}) => {

  const setItemOrder = (from, to) => {
    const path = "caseInfo.questionsConsultant"
    const oldOrder = form.getValue(path)
    const newOrder = _s.moveElement(from, to, oldOrder)
    form.setValue(path,newOrder)
  }

  return  <Card css={readOnly && "padding-bottom: 0;"}>
    <FormValue path="caseInfo.questionsConsultant">{questionsConsultant =>
      questionsConsultant?.length > 1 && <DragDropHelp><span>Drag and drop items to reorder</span></DragDropHelp>
    }</FormValue>
     <FormValue path="caseInfo.questionsConsultant">{questionsConsultant =>
      <QuestionList
        disabled={readOnly}
        setItemOrder={setItemOrder}
        limit={readOnly ? 0 : 500}
        path="caseInfo.questionsConsultant"
        addLabel="Add question"
        getEmpty={GET_EMPTY_QUESTION}
      >{(question, i) =>
        <DraggableItem
          DragTag={QuestionAndAnswer}
          disabled={readOnly}
          i={i}
          key={question.id}
          testkey={question.id}
          questionsConsultant={questionsConsultant}
          form={form}
          isAdmin
          path={["caseInfo", "questionsConsultant"]}
          autoFocus={location?.state?.question === question.id}
          requestId={requestId}
        />
      }</QuestionList>
  }</FormValue>
  </Card>
}

const AdditionalInfo = ({form,readOnly, location, requestId}) => {

  const setItemOrder = (from, to) => {
    const path = "caseInfo.additionalFields"
    const oldOrder = form.getValue(path)
    const newOrder = _s.moveElement(from, to, oldOrder)
    form.setValue(path,newOrder)
  }

  return <QuestionsWrapper>
    <FormValue path="caseInfo.additionalFields">{additionalInfos =>
      additionalInfos?.length > 1 && <DragDropHelp><span>Drag and drop items to reorder</span></DragDropHelp>
    }</FormValue>
    <FormValue path="caseInfo.additionalFields">{additionalInfos =>
      <QuestionList
        disabled={readOnly}
        path="caseInfo.additionalFields"
        addLabel="Add a new field"
        getEmpty={GET_EMPTY_ADDITIONAL_INFO }
        setItemOrder={setItemOrder}
      >{(field, i) =>
        <DraggableItem
          DragTag={ExpertAdditionalInfo}
          submitted={readOnly}
          i={i} key={field.id}
          additionalInfos={additionalInfos}
          form={form}
          isAdmin
          autoFocus={location?.state?.additionalField === field.id}
          path={['caseInfo', 'additionalFields']}
          requestId={requestId}
        />
      }</QuestionList>
    }</FormValue>
  </QuestionsWrapper>
}
