import {Button, Icon} from '@startlibs/components';
import _ from 'lodash/fp'
import React, { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl';
import styled, {css} from 'styled-components';
import { formatDateLogs } from './ContactPatientStep';
import { Errors, FormValue, TextInput, WithForm } from '@startlibs/form';
import { buildValidation, required } from '../../utils/validation';
import { setNotification } from '../../components/Notifications';
import { jwtPostFetcher } from '../../utils/authFetch';
import { getJwt } from '../../hooks/useJwt';
import { safeMultipleNewLineToBr } from '../../utils/utils';
import { useIsLocale } from '../../hocs/IfLocale';
import { getColor } from '@startlibs/utils';
import {transparentize, darken} from "polished";

const MessageCard = styled.div`
  width: 100%;
  background: #f0f0f0;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  position: relative;
  margin-bottom: 1rem;
`

const CardHeader = styled.div`  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #e6e6e6;
  padding: 1.25rem;
  align-items: center;
`

const CardBody = styled.div`  
  padding: 1.25rem;
  hr {
    padding: 0;
    margin: 1rem 0;
  }
`

const MessageContent = styled.div`
  font-size: 13px;
  color: ${getColor('gray60')};
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const SizedIcon = styled(Icon)`
  font-size: 20px;
  font-weight: 500;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  margin-right: .5rem;
`

const TitleLine = styled.span`
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  align-items: center;
  display: flex;
  color: ${getColor('gray60')};
`

const AuthorLine = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  align-items: center;
  display: flex;
  margin-bottom: .5rem;
`

const DateSpan = styled.span`
  font-size: 12px;
  font-weight: 400;
  margin-top: .25rem;
  color: ${getColor('gray120')};
`

const RequestsList = styled.div`
  font-size: 13px;
  color: ${getColor('gray90')};
  margin: 0.75rem 0 0.5rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
const RequestItem = styled.div`
  border-radius: 6px;    
  font-size: 12px;
  font-weight: 500;
  padding: 0 0.5rem;
  color: ${getColor('gray120')};
  border: 1px solid ${getColor('gray180')};
  margin: 0.25rem 0.5rem 0.25rem 0;
  flex-shrink: 0;
`

const InfoBadge = styled.span`
    border-radius: 100px;    
    font-size: 12px;
    font-weight: 500;
    padding: 0 8px;
    margin: 0 0.75rem;
    text-transform: capitalize;
    ${props => props.type === 'ADMIN' && css`
      background: rgba(0,0,0,0.075);
      color: ${getColor('gray120')};
    `}
    ${props => props.type === 'PATIENT' && css`
      color: ${props => darken(0.05, getColor('success')(props))};
      background: ${props => transparentize(0.85, getColor('success')(props))};
    `}
`

export const ThreadCard = ({caseRequest, setCaseRequest, thread, replyAction, isReplying=false, setShouldReloadData = false}) => {
 
  const isNotLegal = useIsLocale({not:true,contains:"LEGAL"})
  const [singleReplying, setSingleReplying] = useState(false)
  const internalReply = () => {
    setSingleReplying(true)
  }
  const cancelInternalReply = () => {
    setSingleReplying(false)
  }
  const [isSending, setIsSending] = useState(false)
  const msgFormRef = useRef()
  const preValidationMsg = buildValidation({
    'message': [
      (v) => !v && required(),
      (v) => v?.length > 3000 && 'Message: Maximum character limit of 3000 reached.'
    ]
  })

  const hasRequestFlags = thread?.requestFlags && 
  (thread?.requestFlags?.askedMedicalRelease 
    || thread?.requestFlags?.askForPayments 
    || thread?.requestFlags?.askForServiceTerms
    || thread?.requestFlags?.askedPatientDetails
    || thread?.requestFlags?.askedRecords
  )

  return <>
    {/* THREAD WITH MESSAGES */}
    {thread &&
      <MessageCard> 
        {/* CARD HEADER */}
        <CardHeader>
          {/* Two lines */}
          <FlexColumn>
            {isNotLegal 
              ? <TitleLine><SizedIcon icon="email-line"/> {thread.title} </TitleLine>
              : <TitleLine><SizedIcon icon="email-line"/> {thread.title.replace('Patient', 'Case Contact')} </TitleLine>
            }
            {thread.creationDate && <DateSpan>{formatDateLogs(thread.creationDate)}</DateSpan>}
          </FlexColumn>
          {!isReplying && <Button small icon="send" 
            onClick={() => {
              replyAction ? replyAction(thread.id) : internalReply()
            }}
          >Reply</Button>}
        </CardHeader>
        {/* CARD BODY */}
        <CardBody>
          {thread?.collabMessages.map((m,i) => 
            <>
              <div key={i}>
                <AuthorLine>
                  <SizedIcon icon="user-line"/><b>{m.name}</b> <InfoBadge type={m.role}>{
                    m.role == 'PATIENT' 
                      // ? <FormattedMessage defaultMessage="Patient" description="Patient Message Patient Role"/>
                      ? 'Case contact'
                      : m.role.toLowerCase()
                  }</InfoBadge> <DateSpan>{formatDateLogs(m.date)}</DateSpan>
                </AuthorLine>
                {hasRequestFlags && i == 0 && <RequestsList>
                  <b css="margin-right:0.5rem;">Requested:</b>
                  {thread?.requestFlags?.askForServiceTerms && <RequestItem type={'request'}>Agree to terms</RequestItem>}
                  {thread?.requestFlags?.askedMedicalRelease && <RequestItem type={'request'}>Medical records</RequestItem>}
                  {thread?.requestFlags?.askedRecords && <RequestItem type={'request'}>Medical records</RequestItem>}
                  {thread?.requestFlags?.askedPatientDetails && <RequestItem type={'request'}>Edit contact details</RequestItem>}
                  {thread?.requestFlags?.askForPayments && <RequestItem type={'request'}>Provide payment</RequestItem>}
                </RequestsList>}
                <MessageContent
                  dangerouslySetInnerHTML={{ __html: safeMultipleNewLineToBr(m.message) }}
                />
              </div>
              {(i != thread.collabMessages.length-1) && <hr/>}
            </>
          )}
        </CardBody>
      </MessageCard>
    }
    {/* WITHOUT THREAD WILL HAPPEN IN OLD REQUESTS */}
    {!thread && <MessageCard> 
        {/* CARD HEADER */}
        <CardHeader>
          {/* Two lines */}
          <FlexColumn>
            <TitleLine><SizedIcon icon="email-line"/>Update requested to patient</TitleLine>
          </FlexColumn>
        </CardHeader>
        {/* CARD BODY */}
        <CardBody>
          <RequestsList>
            <b css="margin-right:0.5rem;">Requested:</b>
            {caseRequest?.caseFlags?.requestFlags?.askForPayments && <RequestItem type={'request'}>Provide payment</RequestItem>}
            {caseRequest?.caseFlags?.requestFlags?.askForServiceTerms && <RequestItem type={'request'}>Agree to terms</RequestItem>}
            {caseRequest?.caseFlags?.requestFlags?.askedPatientDetails && <RequestItem type={'request'}>Edit contact details
              {/* <FormattedMessage defaultMessage="Patient and contact details" description="ContactPatientStep askPatientDetails request badge label"/> */}
            </RequestItem>}
            {caseRequest?.caseFlags?.requestFlags?.askedRecords && <RequestItem type={'request'}>Medical records</RequestItem>}
            {caseRequest?.caseFlags?.requestFlags?.askedMedicalRelease && <RequestItem type={'request'}>Medical records</RequestItem>}
          </RequestsList>
        </CardBody>
      </MessageCard>
    }
    {singleReplying && <WithForm
      preValidation={preValidationMsg}
      action={(values) => {
        setIsSending(true)
        return jwtPostFetcher(getJwt())(`/api/collaborations/${thread.id}/messages?requestId=${caseRequest.requestId}`, {...values} ,{method: 'POST'})
      }}
      onSuccess={(values) => {
        if(setShouldReloadData){
          setShouldReloadData(true)
        }
        setNotification("Message sent")
        setIsSending(false)
      }}
      ref={msgFormRef}
      onFailure={() => {
        setIsSending(false)
      }}
    >
      {form => <>
        <TextInput
          textarea
          autoResize
          placeholder="Type your message here..."
          path="message"
          mandatory={true}
          label={'Reply message'}
          onChange={(e) => {
            if(e.length > 3000){
              form.addErrors({message: 'Message: Maximum character limit of 3000 reached.'})
            }else{
              form.clearErrors('message')
            }
          }}
          bellowDescText={
            <FormValue path="message">{message => {
              if(message){
                return <span style={{ 
                  display: 'block',
                  width: '100%',
                  textAlign: 'right'
                }}>{message?.length+"/3000"}</span>
              }
            }}</FormValue>
          }
        />
        <Errors css="margin-bottom: 1rem;"/>
        <div css="padding-top: 1rem; text-align: right">
          <Button
            type="button"
            onClick={cancelInternalReply}
          >
            Cancel
          </Button>
          <Button
            highlight
            type="submit"
            isLoading={isSending}
            icon="email"
          >
            Send message
          </Button>
        </div>
      </>}
    </WithForm>}
  </>
}
