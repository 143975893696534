import React from 'react'
import {Routes, Route} from 'react-router'
import {AuditLog} from './controlPanel/AuditLog'
import {ControlPanelSidebar} from './controlPanel/ControlPanelSidebar'
import {PageContent, PageWrapper} from './steps/RequestSidebar'
import {NotificationSettings} from './controlPanel/NotificationSettings'
import {PageLoadingSuspense} from '../components/PageLoading'
import {StaffManagement} from './controlPanel/StaffManagement'
import {DivisionManagement} from './controlPanel/DivisionManagement'
import {SuspensePage} from '../components/SuspensePage'
import { ExportCSV } from './controlPanel/ExportCSV'
import { OPERATOR, SUPER } from '../enums/UserRole'
import { lazyUserInfo } from '../components/WithProvider'
import { Jurisdiction } from './controlPanel/Jurisdiction'
import { PaymentFees } from './controlPanel/PaymentFees'
import { Analytics } from './controlPanel/Analytics'

export const AdminControlPanel = () => {

  const userInfo = lazyUserInfo.read()

  return <PageWrapper responsiveWorkaround controlPanel>
    <ControlPanelSidebar/>
    <PageContent controlPanel>
      <PageLoadingSuspense>
        <Routes>
          <Route path="audit" element={<AuditLog />}/>
          {userInfo.role === SUPER && <Route path="analytics" element={<Analytics />}/>}
          {userInfo.role === SUPER && <Route path="export" element={<ExportCSV />}/>}
          {userInfo.role === SUPER && <Route path="jurisdictions" element={<Jurisdiction />}/>}
          {userInfo.role === SUPER && <Route path="paymentfees" element={<PaymentFees />}/>}
          <Route path="staff" element={<SuspensePage Component={StaffManagement}/>}/>
          <Route path="divisions" element={<SuspensePage Component={DivisionManagement} />}/>
          <Route path="notifications" element={<NotificationSettings />}/>
        </Routes>
      </PageLoadingSuspense>
    </PageContent>
  </PageWrapper>
}
