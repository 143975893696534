import { Button } from '@startlibs/components';
import { useNavigate } from 'react-router';
import React from 'react'
import { CASE_ARCHIVED, CASE_CLOSED } from '../enums/CaseState';
import {Header} from '../components/Header';
import { InfoBox } from '../components/InfoBox';
import {PageContainer, PageFooter} from '../components/PageLayout';
import {PurviewFooter} from '../components/PurviewFooter';
import {RequestOverview} from '../request/RequestOverview'
import { PATIENT } from '../enums/UserRoles';
import { getPredefinedReasonLabel } from '../enums/PredefinedReason';

export const PatientOverview = ({caseRequest, setCaseRequest, allowUpload=false, allowDownload=true}) => {
  
  const hasPredefinedReason = caseRequest?.caseInfo?.declinePredefinedReason ? true : false
  const navigate = useNavigate()
  return <> <PageContainer>
      <Header title="Case overview">
        {caseRequest.state === CASE_CLOSED &&
          <Button highlight onClick={() => navigate(`/patient/report`)}>View expert report</Button>
        }
        {caseRequest.state === CASE_ARCHIVED &&
          <Button onClick={() => navigate(`/patient/status`)}>Return</Button>
        }
      </Header>
    {(caseRequest.state === CASE_ARCHIVED && caseRequest.caseInfo.declineReason) &&
      <InfoBox lightBlue css="margin-top: -1rem; margin-bottom: 2rem;">
        <div>
          <strong>The reason provided by the institution not to advance with this case was</strong>
          {hasPredefinedReason ? <b>: {getPredefinedReasonLabel(caseRequest?.caseInfo?.declinePredefinedReason)}</b> : ':'} 
        </div>
        <div>{caseRequest.caseInfo.declineReason}</div>
      </InfoBox>
    }
    <RequestOverview caseRequest={caseRequest} setCaseRequest={setCaseRequest} allowUpload={allowUpload} allowDownload={allowDownload} role={PATIENT}/>
      <PageFooter>
        {caseRequest.state === CASE_CLOSED ?
          <Button highlight onClick={() => navigate(`/patient/report`)}>View expert report</Button>
          : <Button onClick={() => navigate(`/patient/status`)}>Return to case status</Button>
        }
      </PageFooter>
    </PageContainer>
    <PurviewFooter />
  </>
}
