import React from 'react'
import styled from 'styled-components'
import purviewLogo from '../../../public/favicon.png'

export const PurviewFooter = styled(({className}) => {
  return (
    <div className={className}>
      <a href="https://www.purview.net/" target="_blank" rel="noopener noreferrer"><img src={purviewLogo} alt="Purview Logo"/></a>
      <div>
        <div><span className="main-text">Powered by <a href="https://www.purview.net/" rel="noopener noreferrer" target="_blank">Purview</a></span> © <span>{new Date().getFullYear()}</span>. All Rights Reserved.</div>
      </div>
    </div>
  )
})`
  color: rgba(0,0,0,0.4);
  font-size: 12px;
  padding: 0.5rem 1rem;
  position: relative;
  bottom: .25rem;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  margin: -40px auto 0px;
  img {
    width: 25px;
    height: 25px;
    margin-right: 0.5rem;
  }
  .main-text {
    color: rgba(0,0,0,0.5);
    font-weight: 600;
    a {
      color: rgba(0,0,0,0.5);
      :hover {
        color: rgba(0,0,0,0.6);
      }
    }
  }
  @media print {
    display: none !important;
  }
`
