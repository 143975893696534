import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import dicomParser from 'dicom-parser'
import {readFile} from "../utils";
import {
  DicomTextValue,
  getInstancePropertiesFromDataset,
  getPatientPropertiesFromDataset,
  getSeriesPropertiesFromDataset,
  getStudyPropertiesFromDataset,
  isDicomPropertiesCompliant
} from "../utils/dicomInstance";
import {createCompliantDicomRecord} from "../utils/recordUtils";
import {augmentFile} from "../utils/common";
import {createSimpleRecord} from "./processNonDicomFiles";

styled

const DICOM_DIR_TAG = 'x00041220' //Directory Record Sequence
const FileIdTag = 'x00041500' //Referenced File ID
const RECORD_TYPE_TAG = 'x00041430' //Directory Record Type

const isDicomDir = (instance) => instance.filename === 'DICOMDIR' && instance.extension === ''
const getFileFromDirFileId = (instance,augmentedFiles) => {
  const dicomDirFilePath = instance.dicomDirRootPath + "/" + instance.dicomDirFileId
  return augmentedFiles.find(file => file.key === dicomDirFilePath)
}


export const processDicomDirFiles = async (augmentedFiles, adminUserEmail) => {
  const dicomDirs = augmentedFiles.filter(isDicomDir)
  const [records,processedFiles] = await dicomDirs.reduce(async (acc,dicomDir) => {
    try {
      const [records, processedFiles] = await acc
      const bufferArray = await readFile(dicomDir.file)
      const dataSet = dicomParser.parseDicom(new Uint8Array(bufferArray))

      const dicomSeries = createInstancesFromDicomDir(dataSet,dicomDir)

      let currentlyProcessedFiles = []
      const currentRecords = dicomSeries
        .filter(s => s.length)
        .map(dicomSeries =>
          !dicomSeries[0].nonCompliant
          //compliant
          ? createCompliantDicomRecord(dicomSeries[0],
            dicomSeries.flatMap(instance => {
              const file = getFileFromDirFileId(instance,augmentedFiles)
              if (file) {
                currentlyProcessedFiles = currentlyProcessedFiles.concat(file)
                return [{
                  ...file,
                  params: {
                    instanceUID: instance.instanceUID
                  }
                }]
              } else {
                return []
              }
            })
            , adminUserEmail)
            // non compliant
            : dicomSeries.flatMap(instance => {
              const file = getFileFromDirFileId(instance,augmentedFiles)
              const augmentedFile = augmentFile(file,instance.dicomDirRootPath + "/" + instance.dicomDirFileId)
              return createSimpleRecord(augmentedFile)
            })
        )
        .filter(_.get("files.length"))


      return [[...records, ...currentRecords], [...processedFiles, ...currentlyProcessedFiles]]
    } catch (e) {
      return acc
    }
  },Promise.resolve([[],[]]))
  return [records,_.difference(augmentedFiles,[...processedFiles,...dicomDirs]),[...processedFiles,...dicomDirs]]
}

const dumpDicomDir = (dataSet) => {
  console.log(Object.keys(dataSet.elements).map(key =>
    [key,DicomTextValue(key, dataSet)]
  ))

  const instances = dataSet.elements['x00041220'].items.map(item => item.dataSet)
  console.log(instances.map( instance =>
    Object.fromEntries(Object.keys(instance.elements).map(key =>
      [key, DicomTextValue(key, instance)]
    ))
  ))
  console.log(instances.map(instance => DicomTextValue('x00041500', instance)).filter(Boolean))
}


const createInstancesFromDicomDir = (dataSet,dicomDir) => {
  const dirRecordInstances = dataSet.elements[DICOM_DIR_TAG].items.map(item => item.dataSet)
  let patients = []
  let currentPatient = null
  let currentStudy = null
  let currentSeries = null
  for (const record of dirRecordInstances) {
    const recordType = record.string(RECORD_TYPE_TAG)
    if (recordType === 'PATIENT') {
      currentPatient = {patient: record, studies: []}
      patients.push(currentPatient)
    } else if (recordType === 'STUDY') {
      currentStudy = {study: record, series: []}
      currentPatient.studies.push(currentStudy)
    } else if (recordType === 'SERIES') {
      currentSeries = {series: record, instances: []}
      currentStudy.series.push(currentSeries)
    } else if (recordType === 'IMAGE' || record.string(FileIdTag)) {
      currentSeries.instances.push(record)
    }
  }
  const series = patients.flatMap(patientMap => {
    const patientProperties = getPatientPropertiesFromDataset(patientMap.patient)
    return patientMap.studies.flatMap(studyMap => {
      const studyProperties = getStudyPropertiesFromDataset(studyMap.study)
      return studyMap.series.map(seriesMap => {
        const seriesProperties = getSeriesPropertiesFromDataset(seriesMap.series)
        return seriesMap.instances.flatMap(instance => {
          try {

            const instanceProperties = getInstancePropertiesFromDataset(instance)
            const dicomProperties = {
              ...patientProperties,
              ...studyProperties,
              ...seriesProperties,
              ...instanceProperties,
              dicomDirFileId: instance.string(FileIdTag)?.replace(/\\/g,"/"),
              dicomDirRootPath: dicomDir.path,
              isDicomDir: false
            }
            return { ...dicomProperties, isCompliant:isDicomPropertiesCompliant(dicomProperties) }
          } catch (e) {
            return {
              nonCompliant: true,
              dicomDirFileId: instance.string(FileIdTag)?.replace(/\\/g,"/"),
              dicomDirRootPath: dicomDir.path,
              isDicomDir: false
            }
          }
        })
      })
    })
  })
  return series
}