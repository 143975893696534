import {Button} from '@startlibs/components'
import { FormValue, RadioboxGroup, SimpleRadiobox, TextInput, ToggleCheckbox} from '@startlibs/form'
import React from 'react'
import {CaseRequestCard} from '../CaseRequestCard'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {CASE_DRAFT, CASE_REJECTED, PENDING_ASSIGNMENT, PENDING_REQUEST, UNDER_REVIEW, WAITING_ACCEPTANCE, WAITING_APPROVAL, WAITING_MEDICAL_RECORDS} from '../../enums/CaseState'
import {buildValidation, required} from '../../utils/validation'
import {getJwt} from '../../hooks/useJwt'
import {jwtFormFetcher} from '../../utils/authFetch'
import styled from 'styled-components'
import { EXPERT_FINISHED_CASE, EXPERT_FINISHED_CASE_LABEL, IN_PERSON_VISIT, IN_PERSON_VISIT_LABEL, OFFLINE_EXPERT_REVIEW, OFFLINE_EXPERT_REVIEW_LABEL, OTHER, OTHER_LABEL } from '../../enums/PredefinedReason'
import { InfoBox } from '../../components/InfoBox'

const preValidation = buildValidation({
  "predefinedReason": required,
  "details": [(v,k,values) => values.predefinedReason === OTHER && !v.length && required()]
})

const FullWidthRadioBox = styled(SimpleRadiobox)`
  width: 100%;
}`

const ReasonRadioBox = styled(SimpleRadiobox)`
  width: 48%;
}`

export const useSetAsReviewedDialog = (caseRequest,onSuccess) => useConfirmDialog(
  <ConfirmDialog
    title={"Set case as Reviewed"}
    action={jwtFormFetcher(getJwt())("/api/admin/setAsReviewed?requestId="+(caseRequest.requestId || caseRequest.id))}
    onSuccess={onSuccess}
    preValidation={preValidation}
    notify={"Case successfully set as Reviewed."}
    confirm={<Button highlight>{'Set as Reviewed'}</Button>}
    values={{
      "predefinedReason" : "",
      "details" : ""
    }}
  >
    {/* <p>{caseRequest.state === PENDING_REQUEST ? "The following case request " : "This case "} will no longer be visible under "Active cases":</p> */}
    {/* <p>This is case will be marked as "Reviewed"</p> */}
    <CaseRequestCard viewOnly caseRequest={caseRequest} isResults={true} />
    {/* <p>You will still be able to assign this case to an expert if necessary.</p> */}
    {(caseRequest.state === PENDING_REQUEST 
      || caseRequest.state === CASE_DRAFT
      || caseRequest.state === WAITING_MEDICAL_RECORDS
      || caseRequest.state === WAITING_APPROVAL
      || caseRequest.state === PENDING_ASSIGNMENT
      || caseRequest.state === WAITING_ACCEPTANCE
      || caseRequest.state === CASE_REJECTED
    ) && <RadioboxGroup path="predefinedReason" required mandatory framedBoxes highlightSelected horizontal css="margin-bottom:1rem;" 
      label="Select the reason for setting this case as Reviewed"
      >
        <FullWidthRadioBox
          fieldValue={IN_PERSON_VISIT}
          label={IN_PERSON_VISIT_LABEL}
        />
        <FullWidthRadioBox
          fieldValue={OFFLINE_EXPERT_REVIEW}
          label={OFFLINE_EXPERT_REVIEW_LABEL}
        />
        <FullWidthRadioBox
          fieldValue={OTHER}
          label={OTHER_LABEL}
        />
    </RadioboxGroup>}

    {(caseRequest.state === UNDER_REVIEW) && <RadioboxGroup path="predefinedReason" required mandatory framedBoxes highlightSelected horizontal css="margin-bottom:1rem;" 
      label="Select the reason for setting this case as Reviewed"
      >
        <ReasonRadioBox
          fieldValue={IN_PERSON_VISIT}
          label={IN_PERSON_VISIT_LABEL}
        />
        <ReasonRadioBox
          fieldValue={OFFLINE_EXPERT_REVIEW}
          label={OFFLINE_EXPERT_REVIEW_LABEL}
        />
        <ReasonRadioBox
          fieldValue={EXPERT_FINISHED_CASE}
          label={EXPERT_FINISHED_CASE_LABEL}
        />
        <ReasonRadioBox
          fieldValue={OTHER}
          label={OTHER_LABEL}
        />
    </RadioboxGroup>}
    <FormValue path="predefinedReason">{reason =>
      <TextInput
        textarea
        autoResize
        mandatory={reason === OTHER}
        path="details"
        label={(reason === OTHER) ? "Please add more details" :"Details"}
      />
    }</FormValue>
    {(caseRequest.state === PENDING_REQUEST 
      || caseRequest.state === CASE_DRAFT
      || caseRequest.state === WAITING_MEDICAL_RECORDS
      || caseRequest.state === WAITING_APPROVAL
      || caseRequest.state === PENDING_ASSIGNMENT
      || caseRequest.state === CASE_REJECTED
    ) && <InfoBox lightYellow>
      This action will bypass assigning the case to an expert.
    </InfoBox>}
    {/* {caseRequest.state === CASE_REJECTED && <ToggleCheckbox
      label={<strong>Notify expert that the case was unassigned</strong>}
      path="sendEmail"
    />} */}

    {(caseRequest.state === WAITING_ACCEPTANCE) && <>
      <InfoBox lightYellow>
        The expert will no longer be able to edit this case.
        {/* This action will bypass assigning the case to an expert */}
      </InfoBox>
      {/* <ToggleCheckbox
        label={<strong>Notify expert that the case was unassigned</strong>}
        path="sendEmail"
      /> */}
    </>}

    {(caseRequest.state === UNDER_REVIEW) && <>
      <InfoBox lightYellow>
        The expert will no longer be able to edit this case.
      </InfoBox>
      {/* <ToggleCheckbox
        label={<strong>Notify expert that the case was unassigned</strong>}
        path="sendEmail"
      /> */}
    </>}
  </ConfirmDialog>
)
