import React, {useContext, useEffect} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {isMinified} from "./FileinputBox2";
// import {Draggable, Droppable} from "react-beautiful-dnd";
import {Draggable, Droppable} from "@hello-pangea/dnd";
import {DEVICE, DISK} from "../enums/UploaderStepsManagement";
import {css} from "styled-components";
import {customTheme, getColor} from "@startlibs/utils";
import {AttachmentBox} from "./AttachmentBoxStyles";
import {Button, Icon, Loading, Popup} from "@startlibs/components";
import {UploaderConfigContext} from "../service/UploaderConfigContext";
import {closeNotes} from "./recordGroup/NotesField";
import {TransitionDiv} from "../utils/TransitionDiv";
import {useRecordsSelector} from "../service/hooks/useRecords";
import {useUIDataSelector} from "../service/hooks/useUIDataSelector";
import {useUngroup} from "./hooks/useUngroup";
import {getItemPersistedUID} from "../service/utils/recordUtils";
import {useIsUploading} from "../service/hooks/useUploadQueueSelector";
import {RecordRow} from "./recordGroup/RecordRow";
import {UnclassifiedRecordRow} from "./recordGroup/UnclassifiedRecordRow";
import {GroupHeader} from "./recordGroup/GroupHeader";
import {NonCompliantDicom} from "../enums/RecordFormat";

export const GroupContainer = styled.div`
  ${props => (props.mode === DEVICE || props.mode === DISK) && css`
    :last-child {
      margin-bottom: -1px;
    }
  `}
  ${props => props.isNotClassMinified === true && css`
    background: white;
    margin-bottom: 1px !important;
    border-radius: 8px;
    border: 1px solid ${getColor('gray210')};
    padding: 0.5rem 0;
    ${AttachmentBox} {
      :first-child {
        border-radius: 6px 6px 0 0;
        margin-top: 0.5rem;
      }
      :last-child {
        border-radius: 0 0 6px 6px;
        border-bottom: none;
      }
    }
  `}
  ${props => props.isNotClassAndNotMinifiedAndEmpty === true && css`
    height: 0px;
  `}
`

const GroupContent = styled.div`
  position: relative;
`

const GroupWrapper = styled.div`
  position: relative;
  border: 0.5rem solid transparent;
  border-top-width: 1rem;
  ${props => (props.mode === DEVICE || props.mode === DISK) && css`
    margin: 0rem 0.5rem;
    border: 0px;
  `
}
  ${props => props.isDragging && css`
    &:before {
      content: '';
      position: absolute;
      top: -.5rem;
      right: -.5rem;
      left: -.5rem;
      bottom: -.5rem;
      background: white;
      border-radius: 6px;
      box-shadow: 0 0 4px 0 rgba(0,0,0,0.25);
      padding: .5rem .5rem 1rem;
      z-index: 1;
    }
    ${GroupContent} {
      z-index: 5;
    }
  `}
  ${props => props.emptyGroup && css`
    border-top: none;
    border-bottom: none;
  `}
`


const EmptyGroupArea = styled.div`
  min-height: 2rem;
  color: rgba(0,0,0,0.25);
  text-align: center;
  margin-top: 1rem;
  a {
    text-decoration: underline;
    color: rgba(0,0,0,0.4);
    :hover, :active {
      color: ${getColor('alert')};
    }
  }
`

const DraggableIcon = styled(Icon)`
  padding: 4px 0.25rem 0.5rem 4px;
  font-size: 16px;
  color: rgba(0,0,0,0.3);
  cursor: grab;
  vertical-align: -3px;
  outline: none;
  :hover {
    color: rgba(0,0,0,0.5);
  }
`

export const RecordGroup = ({group, index}) => {
  const {
    mode,
    canMoveGroup,
    allowReorder,
    showHiddenRecords
  } = useContext(UploaderConfigContext)
  const recordKeys = useRecordsSelector(_.flow(
    _.filter(_.negate(_.matchesProperty('recordFormat',NonCompliantDicom))),
    _.map(_.get('key'))
  )).concat(NonCompliantDicom)
  const availableItems = group.items.filter((item) => recordKeys.includes(item))
  const isCollapsed = useUIDataSelector('collapsed').includes(group.id)
  const groupsLength = useUIDataSelector('groups.length')
  const [ungroup] = useUngroup(group)

  const DraggableMemoized = React.useMemo(() => Draggable, [])

  const droppable = (snapshot, moveHandle) =>
    <Droppable
      droppableId={group.id}
      isCombineEnabled={isCollapsed}
    >
      {(dropProvided, dropSnapshot) => <GroupContent>
        <GroupHeader group={group} isCollapsed={isCollapsed} snapshot={snapshot} moveHandle={moveHandle}/>
        <div
          css={isMinified(mode) ? "position:relative;margin-top:0rem;" : "position:relative;margin-top:-0.75rem;"}
          onMouseDown={
            (e) =>
              !document.activeElement?.closest(".JS-blur-container")?.contains(e.target)
              // && document.activeElement.blur()
              && closeNotes()
          }

          {...dropProvided.droppableProps}
          ref={dropProvided.innerRef}
        >
          {
            group.id === 'notclass' && showHiddenRecords &&
            <UnclassifiedRecordRow group={group} isCollapsed={isCollapsed}/>
          }
          <TransitionDiv>{
            !isCollapsed && availableItems.map((key, index) => {
              const isDragDisabled = !allowReorder || (availableItems.length < 2 && groupsLength === 1)
              return <DraggableMemoized
                isDragDisabled={isDragDisabled}
                key={key} draggableId={key + ""} index={index}
              >
                {(dragProvided, dragSnapshot) => <>
                  <RecordRow
                    group={group}
                    dragProvided={dragProvided}
                    dragSnapshot={dragSnapshot}
                    index={index}
                    isDragDisabled={isDragDisabled}
                    itemKey={key}
                  />
                </>}
              </DraggableMemoized>
            })
          }</TransitionDiv>
          <div>
            <TransitionDiv>{
              group.id !== 'ungrouped' && group.id !== 'notclass' && !group.items.length && allowReorder && !isCollapsed &&
              <EmptyGroupArea>This group is empty. <a onClick={ungroup}>Remove group</a></EmptyGroupArea>
            }</TransitionDiv>
          </div>
          {dropProvided.placeholder}
        </div>
      </GroupContent>}
    </Droppable>

  if (!allowReorder && !availableItems.length) {
    return null
  }

  return <GroupContainer
    key={group.id}
    index={index}
    group={group}
    mode={mode}
    isNotClassMinified={group.id === 'notclass' && isMinified(mode)}
    isNotClassAndNotMinifiedAndEmpty={group.id === 'notclass' && !isMinified(mode) && !group.items.length > 0}
  >
    {
      group.id === 'ungrouped' || !canMoveGroup
        ? <GroupWrapper emptyGroup={group.items.length === 0} mode={mode}>
          {droppable()}
        </GroupWrapper>
        : <DraggableMemoized draggableId={group.id} index={index} key={group.id}
                     isDragDisabled={!canMoveGroup || !allowReorder || group.id === 'notclass'}>
          {(provided, snapshot) =><>
            <GroupWrapper ref={provided.innerRef} {...provided.draggableProps} isDragging={snapshot.isDragging}
              // isNotClassMinified={group.id === 'notclass' && isMinified(mode)}
            >
              {droppable(snapshot, canMoveGroup && allowReorder &&
                <div {...provided.dragHandleProps}><DraggableIcon icon="draggable"/></div>)}
            </GroupWrapper>
            </>
          }
        </DraggableMemoized>
    }
  </GroupContainer>

}