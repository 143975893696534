import React, { forwardRef, useEffect, useRef, useState } from 'react';
import IntlTelInput from 'intl-tel-input/reactWithUtils';
import styled from 'styled-components';
import { useFormValue } from '@startlibs/form';


// Styled component for IntlTelInput
const StyledIntlTelInput = styled(IntlTelInput)`  
    width: 100%;
    div {
      width: 100%;
    }
`;

const HelpTextSpan = styled.span`
    color: rgba(0, 0, 0, 0.4);
    font-weight: 400;
    font-size: 14px;
    padding-left: 0.25rem;
`;

const ErrorTextSpan = styled.span`
    color: #c3282d;
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 0.5rem;
`;

// Floating label container styled component
const FloatLabelIntlTelInput = styled.div`
  position: relative;
  width: 100%;
  input {
    width: 100%;
    font-size: 14px;
    border: 1px solid #d2d2d2;
    ${props => props.hasError ? 'border-color: #c3282d;' : ''}
    border-radius: 6px;
    outline: 0;
    outline: none;
    color: #3c3c3c;
    background: white;
    position: relative;
    height: 3rem;
    padding: 0 0.7rem;
    overflow: auto;
    border-radius: 6px;
    ::-webkit-input-placeholder {
      color: rgba(0,0,0,0.3);
    }
    ::-moz-placeholder {
      color: rgba(0,0,0,0.3);
    }
    :-moz-placeholder {
      color: rgba(0,0,0,0.3);
    }
    :-ms-input-placeholder {
      color: rgba(0,0,0,0.3);
    }
    :focus {
      border-color: #b4b4b4;
      box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.1);
    }

  }
  label {
    display: inline-block;
    font-size: 14px;
    margin-bottom: .5rem;
    font-weight: 600;
    color: #3C3C3C;
  }
`;

const Mandatory = styled.span`
    color: #c3282d;
    margin-left: 1px;
    vertical-align: top;
    line-height: 100%;
    margin-top: -1px;
`;

export const StyledTelInput = forwardRef(({
  label,
  path,
  placeholder,
  initOptions,
  tabIndex = 0,
  disabled = false,
  helpText = '',
  mandatory = false,
  hasErrors = false,
  setIsValid = false,
  isValid = true,
  usePreciseValidation = false,
  ...props
}, ref) => {

  // Use withForms' hook to get and set the form value by path
  const [value, setValue] = useFormValue(path); // Adapted to use withForms' path

  const intlTelInputRef = useRef(null); // Ref to access the IntlTelInput DOM
  const [hasUserTyped, setHasUserTyped] = useState(false); // Track if the user has typed
  // const [errorMessage, setErrorMessage] = useState('');

  // Conditions for showing the errors must be set here as well.
  const hasError = hasErrors && (
    ((!value && mandatory) || (value === '' && mandatory))
    ||
    (value && !isValid)
  );

  // const [isValid, setIsValid] = useState(true);
  
  // Apply the tabIndex directly to the input element after mounting
  useEffect(() => {
    if (intlTelInputRef.current) {
      const inputElement = intlTelInputRef.current.querySelector('input.iti__tel-input');
      if (inputElement) {
        inputElement.setAttribute('tabindex', tabIndex);

        // Attach keyup event listener
        const handleKeyUp = () => setHasUserTyped(true);
        inputElement.addEventListener('keyup', handleKeyUp);

        // Add listener for country change
        inputElement.addEventListener('countrychange', handleOnCountryChange);

        return () => {
          inputElement.removeEventListener('keyup', handleKeyUp); // Clean up
          inputElement.removeEventListener('countrychange', handleOnCountryChange); // Clean up countrychange event
        };
      }
    }
  }, [tabIndex]);

  // Only update value if the input is not disabled
  const handleOnChangeNumber = (val) => {
    if (!disabled && hasUserTyped) {
      setValue(val);
    }
  };

  // Handle country change to update the phone number with the country code
  const handleOnCountryChange = (v) => {
    setHasUserTyped(true);
  };

  // Track user typing
  const handleOnKeyUp = () => {
    setHasUserTyped(true);
  };

  return (
    <FloatLabelIntlTelInput hasError={hasError} value={value}>
      {label && (
        <>
          <label>{label}{mandatory && <Mandatory>*</Mandatory>}</label>
          {helpText && helpText.length > 0 && <HelpTextSpan>{helpText}</HelpTextSpan>}
        </>
      )}
      <div ref={intlTelInputRef} style={{ width: '100%' }}>
        <StyledIntlTelInput
          {...props}
          ref={ref}
          value={value}
          initialValue={value}
          usePreciseValidation={usePreciseValidation}
          onChangeValidity={(isValid) => {
            if(setIsValid && hasUserTyped){
              setIsValid(isValid)
            }
            // setErrorMessage(isValid ? '' : 'Invalid phone number');
          }}
          // onChangeNumber={(val) => setValue(val)}
          onChangeNumber={handleOnChangeNumber} // Prevent updating when disabled
          onKeyUp={handleOnKeyUp}
          onKeyDown={handleOnKeyUp}
          placeholder={placeholder || '(201) 555 0123'}
          initOptions={initOptions}
          autoComplete="tel"
          disabled={disabled}
        />
      </div>
      {/* {errorMessage && <ErrorTextSpan>{errorMessage}</ErrorTextSpan>} */}
    </FloatLabelIntlTelInput>
  );
});

export default StyledTelInput;
