import {Button, Link} from '@startlibs/components';
import {RadioboxGroup, SimpleRadiobox, TextInput, ToggleCheckbox} from '@startlibs/form'
import {useToggle} from '@startlibs/core'
import React from 'react';
import _ from 'lodash/fp'
import styled from 'styled-components';
import {CASE_CLOSED} from '../../../enums/CaseState'
import {CaseRequestCard} from '../../CaseRequestCard'
import {ConfirmDialog, useConfirmDialog} from '../../../hooks/useConfirmDialog'
import {ContactCheckbox, ContactCheckboxGroup} from '../../../components/ContactCard';
import {REF_PHYSICIAN} from '../../../enums/ContactRelationType'
import {TransitionDiv} from '../../../components/TransitionDiv'
import {buildValidation} from '../../../utils/validation';
import {getContact, PRIMARY} from '../../../enums/ContactRelationKind'
import {getJwt} from '../../../hooks/useJwt'
import {isPaymentPending} from '../../../request/utils'
import {jwtFormFetcher, jwtGetFetcher} from '../../../utils/authFetch'
import {lazyProviderInfo, lazyUserInfo} from '../../../components/WithProvider'
import {useConfirmAnswers} from '../../../expert/customReport/useConfirmAnswers'
import {PendingInfo} from '../info/PendingInfo'
import {useIntl} from 'react-intl'
import { getTimezoneOffset } from '../../../utils/utils';

export const ContactSelectorHeading = styled.p`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const useConfirmFinishCase = (caseRequest, setCaseRequest) => {
  const providerInfo = lazyProviderInfo.read()
  const userInfo = lazyUserInfo.read()

  const referringPhysicianAsSecondary = caseRequest.contactDetails.contactRelationType !== "REF_PHYSICIAN" && caseRequest.referringPhysician.email
  const hasNoPaidPayment = !caseRequest.payments.find(payment => payment.paid)
  const hasAnyPendingPayment = caseRequest.payments.find(isPaymentPending)

  const confirmAnswers = useConfirmAnswers(caseRequest, setCaseRequest)

  const sendMails = useToggle(true)

  const intl = useIntl()

  const primary = getContact(intl, caseRequest, PRIMARY)
  const physician = getContact(intl, caseRequest, REF_PHYSICIAN)

  React.useEffect(() => {
    if (providerInfo) {
      sendMails.openWith(providerInfo?.notifications?.reportReady)
    }
  }, [providerInfo])

  const preValidation = ({useCustomReport, ...values}, ...args) => {
    return (useCustomReport ? Promise.resolve() : confirmAnswers()).then(() =>
      buildValidation({'sendToCaseContact': requiredCheck, 'sendToReferringPhysician': requiredCheck})(values, ...args)
    )
  }

  const requiredCheck = (v, k, props) => sendMails.isOpen && referringPhysicianAsSecondary && !props.sendToCaseContact && !props.sendToReferringPhysician && "No contact was selected."

  return useConfirmDialog(<ConfirmDialog
    confirmChanges={false}
    isLoading={false}
    notify="Case completed successfully."
    transform={_.update('sendToCaseContact', (v) => v || (sendMails.isOpen && !referringPhysicianAsSecondary))}
    preValidation={preValidation}
    title="Complete report"
    action={jwtFormFetcher(getJwt())("/api/admin/finalizeCase?requestId=" + caseRequest.requestId)}
    values={{useCustomReport: caseRequest.report?.useCustomReport}}
    onSuccess={({useCustomReport}) => {
      // Getting updated case data from database.
      // CaseRequest.report is empty before case is complete.
      // And now caseRequest.report is used in the final report to get persisted expert information
      jwtGetFetcher(getJwt())(`/api/viewCaseRequest?requestCode=${caseRequest.requestCode}&timezoneOffset=${getTimezoneOffset()}`)
        .then((response) => {
          setCaseRequest(response)
        })
        .catch((error) => {
          setCaseRequest(_.flow(_.set("state", CASE_CLOSED), _.set('report.useCustomReport', useCustomReport)))
        })
        // Fullstory disabled
        // var now = new Date()
        // var eventProperties = {
        //   expertName_str: userInfo.firstName + ' ' + userInfo.lastName,
        //   expertMail_str: userInfo.login,
        //   expertId_int: userInfo.id,
        //   customer_str: providerInfo.name,
        //   dateTime_date: now,
        //   requestId_int: caseRequest.requestId,
        //   caseId_str: caseRequest.requestCode
        // }
        // window.FS.event('Admin Case Finished',eventProperties)
      }
    }
    confirm={<Button highlight>{sendMails.isOpen ? "Complete and notify" : "Proceed"}</Button>}
  >
    <p>Complete the report for this case and optionally notify contact(s):</p>
    <CaseRequestCard caseRequest={caseRequest}/>
    {
      caseRequest.report?.customReport && <>
        <p>A custom report was uploaded. Would you like to use it as the final report?</p>
        <RadioboxGroup path="useCustomReport" framedBoxes horizontal wide css="margin-bottom:1.5rem">
          <SimpleRadiobox
            fieldValue={false}
            label="Use default report"
          />
          <SimpleRadiobox
            fieldValue={true}
            label="Use custom report"
          />
        </RadioboxGroup>
      </>}

    <ToggleCheckbox
      label="Notify contact(s) that the report is available. "
      descText="This notification can also be sent later from the report screen."
      raw
      value={sendMails.isOpen}
      setValue={_.unary(sendMails.openWith)}
    />

    <TransitionDiv>
      {sendMails.isOpen && <>
        <ContactSelectorHeading>
          {referringPhysicianAsSecondary ?
            "Select the contact(s) you would like to notify:"
            : "An email will be sent to the following contact:"
          }
          <Link
            className="link"
            to={`/admin/request/${caseRequest.requestCode}/patient`}
            state={{scrollToContact: true}}
          >Edit contacts</Link>
        </ContactSelectorHeading>
        <ContactCheckboxGroup>
          <ContactCheckbox
            path="sendToCaseContact"
            email={primary.email}
            name={primary.name}
            key="patient"
            type={primary.relationType}
          />
          {
            referringPhysicianAsSecondary && <ContactCheckbox
              path="sendToReferringPhysician"
              name={physician.name}
              email={physician.email}
              type="Referring physician"
              key="Referring physician"
            />
          }
        </ContactCheckboxGroup>
        <TextInput
          label="Message"
          path="message"
          textarea
          autoResize
          descText="To be included with the notification."
        />
      </>}
    </TransitionDiv>

    <p>This action will complete the case and move it to "Completed cases".</p>
    <PendingInfo caseRequest={caseRequest} hideServiceTerms={!providerInfo.serviceTerms || !providerInfo.requiresAgreement}/>
  </ConfirmDialog>, [sendMails.isOpen])
}
