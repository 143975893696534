import { Button, Icon } from '@startlibs/components';
import {RadioboxGroup} from '@startlibs/form'
import React from 'react';
import styled from 'styled-components'
import { AssignmentStatusBox } from './expertReview/ExpertReviewList';
import {
  CASE_CLOSED,
  UNDER_REVIEW,
  WAITING_ACCEPTANCE,
  WAITING_APPROVAL,
  WAITING_MEDICAL_RECORDS,
  WAITING_MORE_INFORMATION
} from '../../enums/CaseState';
import {Header} from '../../components/Header'
import {MedicalRecords} from '../../request/MedicalRecords'
import {PageContainer, PageFooter} from '../../components/PageLayout'
import {PurviewFooter} from '../../components/PurviewFooter'
import {WaitingMedicalRecordsBox} from './info/WaitingMedicalRecordsBox'
import {confirmAndNavigate} from '../../hooks/useConfirmExitPage'
import {useNavigate} from 'react-router'
import { isStateBefore } from '../../request/utils';
import { ADMIN } from '../../enums/UserRoles';
import { useIntl } from 'react-intl';

export const ContactSelectBoxes = styled(RadioboxGroup)`
  display: block;
  margin-bottom: 1rem;
`

export const SelectorTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  p {
    margin-bottom: 0;
  }
`

export const RecordsStep = ({caseRequest, setCaseRequest}) => {
  const navigate = useNavigate()
  const intl = useIntl()

  const readOnly = [WAITING_MEDICAL_RECORDS, UNDER_REVIEW, WAITING_MORE_INFORMATION, WAITING_APPROVAL, WAITING_ACCEPTANCE, CASE_CLOSED].indexOf(caseRequest.state) >= 0
  const {medicalRecordsInfo} = caseRequest
  const hasMedicalRecords = medicalRecordsInfo && (
    medicalRecordsInfo?.dicomStudies?.length ||
    medicalRecordsInfo?.nonCompliantDicom?.length ||
    medicalRecordsInfo?.nonDicomFiles?.length
  )
  const wasSentToExpert = !isStateBefore(caseRequest.state)(WAITING_ACCEPTANCE)
  const caseCompletedWithoutReport = caseRequest.state === CASE_CLOSED && !caseRequest.report?.customReport && !caseRequest.report?.useCustomReport && !caseRequest.report

  return <>
    <PageContainer>
      <Header title="Medical record files">
      </Header>
      <WaitingMedicalRecordsBox caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>
      {readOnly && wasSentToExpert &&
        <AssignmentStatusBox reviewed={caseRequest.state === CASE_CLOSED}>
          <div className="waitingBoxContent">
            <Icon icon={caseRequest.state === CASE_CLOSED ? 'check' : 'clock'}/>
            <h4>
              {caseRequest.state === CASE_CLOSED ? 'This case is already completed.' : 'Pending review'}
            </h4>
            <p>{caseRequest.state === CASE_CLOSED
              ? caseCompletedWithoutReport 
                ? 'The case has been marked as completed, but no report was generated.'
                : intl.formatMessage({
                  defaultMessage:"The report is already concluded and available for the patient contact.",
                  description:"Report concluded warning available to contact label"
                })
              : 'There is an expert currently reviewing this case.'}
            </p>
          </div>
        </AssignmentStatusBox>
      }
      <MedicalRecords
        setCaseRequest={setCaseRequest}
        readOnly={readOnly}
        forceAllowUpload={caseRequest.state === WAITING_MEDICAL_RECORDS}
        limitQuestions={500}
        values={caseRequest}
        allowReorder
        caseRequest={caseRequest}
        canOpenWorklist={true}
        role={ADMIN}
        autoGrouping
      >{() =>
        <PageFooter>
          {
            (caseRequest.state !== WAITING_MEDICAL_RECORDS || caseRequest.caseFlags.adminRecordsSubmitted) &&
            <Button onClick={confirmAndNavigate('../clinical',navigate)} highlight={hasMedicalRecords}>
              {readOnly
                ? "Advance"
                : hasMedicalRecords
                  ? "Save and advance"
                  : "Skip"
              }
            </Button>
          }
        </PageFooter>
      }</MedicalRecords>
    </PageContainer>
    <PurviewFooter/>
  </>
}
