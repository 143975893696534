import {
  Button,
  Dialog,
  DialogFooter,
  Icon,
  Link,
  Loading
} from '@startlibs/components';
import {
  Errors,
  Field,
  TextInput,
  WithForm,
  useProvideErrors
} from '@startlibs/form';
import {useNavigate} from 'react-router'
import {useToggle} from '@startlibs/core'
import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash/fp'
import {CASE_CLOSED, CASE_REVIEWED, UNDER_REVIEW, WAITING_MORE_INFORMATION} from '../enums/CaseState';
import {Card, HeaderButtons, PageContainer, PageFooter} from '../components/PageLayout';
import {CustomReportInfoBox} from './customReport/CustomReportInfoBox'
import {ExternalReportUpload} from '../request/components/ExternalReportUpload'
import {Header} from '../components/Header'
import {PdfReportFrame, PdfReportFrameWrapper} from '../request/components/PdfReportFrame';
import {PreviewForCaseRequest} from '../request/reviewedRequest/PreviewForCaseRequest'
import {PurviewFooter} from '../components/PurviewFooter'
import { StarRating } from '../components/StarRating';
import {SuccessBox} from '../components/InfoBox';
import {getJwt} from '../hooks/useJwt'
import {hasAnsweredAll} from '../request/utils'
import {jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import { setNotification } from '../components/Notifications';
import {useIframeIsLoading} from '../admin/steps/hooks/useIframeIsLoading'
import {useShareDialog} from '../hooks/useShareDialog'
import {useSubmitCase} from './dialogs/useSubmitCase';
import {useUploadCustomReport, useUploadDialogIsOpen} from './customReport/useUploadCustomReport'
import { FeedbackDialog } from './dialogs/FeedbackDialog';
import { lazyProviderInfo, lazyUserInfo } from '../components/WithProvider';
import { AssignmentStatusBox } from '../admin/steps/expertReview/ExpertReviewList';

export const ExpertReport = ({caseRequest, setCaseRequest, uploadPDF, feedbackPopup}) => {
  
  const navigate = useNavigate()
  const userInfo = lazyUserInfo.read()
  const providerInfo = lazyProviderInfo.read()

  const submitReview = useSubmitCase(
    caseRequest,
    _.update('additionalFields',(additionalFields) => additionalFields || [],caseRequest.caseInfo),
    ({useCustomReport}) => {
      setCaseRequest(_.flow(
        _.set("state", CASE_REVIEWED),
        _.set('report.useCustomReport', useCustomReport)
      ))
      // Fullstory disabled for now
      // var now = new Date()
      // var eventProperties = {
      //   expertName_str: userInfo.firstName + ' ' + userInfo.lastName,
      //   expertMail_str: userInfo.login,
      //   expertId_int: userInfo.id,
      //   customer_str: providerInfo.name,
      //   dateTime_date: now,
      //   requestId_int: caseRequest.requestId,
      //   caseId_str: caseRequest.requestCode,
      //   caseState_str: caseRequest.state
      // }
      // window.FS.event('Expert Case Reviewed',eventProperties)
    }
  )

  const [hasFeedback, setHasFeedback] = useState(false);
  
  useEffect(() => {
    if(caseRequest.isCoReview){
      navigate(`/expert/case/${caseRequest.requestId}/overview`)
      return 
    }
    if(caseRequest.state === CASE_REVIEWED || caseRequest.readOnly){
      jwtGetFetcher(getJwt())(`/api/expert/feedback?requestId=${caseRequest.requestId}`)
        .then((response) => {setHasFeedback(true)})
        .catch((error) => {setHasFeedback(false);feedbackPopup.open()})
    }
  },[caseRequest])

  const isOpen = (caseRequest.state === UNDER_REVIEW || caseRequest.state === WAITING_MORE_INFORMATION) && !caseRequest.readOnly
  const [openShareDialog, shareDialog] = useShareDialog(caseRequest)
  const uploadCustomReport = useUploadCustomReport(caseRequest, setCaseRequest)

  const iframeIsLoading = useIframeIsLoading(caseRequest.report?.useCustomReport)

  const [isUploadDialogOpen] = useUploadDialogIsOpen()

  const showUpload = uploadPDF || (!hasAnsweredAll(caseRequest.caseInfo.questionsConsultant) && !caseRequest.report?.customReport)
  const caseCompletedWithoutReport = caseRequest.state === CASE_CLOSED && !caseRequest.report?.customReport && !caseRequest.report?.useCustomReport && !caseRequest.report
  const caseClosedNavigation = caseRequest.state === CASE_CLOSED &&
    <HeaderButtons>
      <div className="left-wrapper">
        <Button onClick={() => navigate(`/expert/case/${caseRequest.requestId}/overview`)}>Go to overview</Button>
      </div>
      {!caseCompletedWithoutReport && <div className="right-wrapper">
        <Button.a
          href={`/api/report/download?token=${getJwt()}&requestId=${caseRequest.requestId}`}
          icon="download"
        >Save PDF</Button.a>
        <Button.a
          target="_blank"
          href={`/api/report/preview?token=${getJwt()}&requestId=${caseRequest.requestId}`}
          icon="print"
        >Print</Button.a>
        <Button highlight onClick={openShareDialog} icon="send">Share</Button>
      </div>}
    </HeaderButtons>

  const caseNavigation =
    (
      (caseRequest.state === CASE_REVIEWED || caseRequest.readOnly) &&
      <Button css="margin-left:1rem" onClick={() => navigate(`/expert/case/${caseRequest.requestId}/questions`)}>View answers</Button>
    ) ||
    (
      isOpen && <>
        <Button onClick={() => navigate(`/expert/case/${caseRequest.requestId}/questions`)}>Edit answers</Button>
        <Button
          highlight
          disabled={showUpload && !caseRequest.report?.customReport}
          onClick={submitReview}
        >Submit review</Button>
      </>
    )

  return !caseRequest.isCoReview && <> <PageContainer>
    <Header isExpert title="Case report">
      {
        (caseRequest.state === CASE_REVIEWED || caseRequest.readOnly) &&
          <>
            {!caseCompletedWithoutReport && <SuccessBox>
              <Icon icon="check"/><span>Review already submitted</span>
            </SuccessBox>}
          </>
      }
      {
        caseRequest.state === CASE_CLOSED &&
        <SuccessBox>
          <Icon icon="check"/><span>{caseCompletedWithoutReport ? 'Case' : 'Report'} already completed.</span>
        </SuccessBox>
      }
      {caseNavigation}
    </Header>
    {caseClosedNavigation}

    {
      caseRequest.report?.customReport && isOpen &&
      <CustomReportInfoBox setCaseRequest={setCaseRequest} caseRequest={caseRequest}/>
    }
    {
      !caseRequest.report?.customReport && isOpen && !showUpload &&
      <HeaderButtons>
        <div className="left-wrapper">
          <p css="margin-bottom:  0;">Previewing default case report.</p>
        </div>
        <div className="right-wrapper">
          <Button onClick={uploadCustomReport} small icon="upload">Upload custom PDF report</Button>
        </div>
      </HeaderButtons>
    }
    {
      caseCompletedWithoutReport 
        ? <AssignmentStatusBox reviewed={caseRequest.state === CASE_CLOSED}>
            <div className="waitingBoxContent">
              <Icon icon={'check'}/>
              <h4>
                {'This case is already completed.'}
              </h4>
              <p>The case has been marked as completed, but no report was generated.</p>
            </div>
          </AssignmentStatusBox> 
        : (showUpload && !caseRequest.report?.useCustomReport)
          ? <UploadCustomReport caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>
          : caseRequest.report?.useCustomReport
            ? <PdfReportFrameWrapper><PdfReportFrame
                pdfReport
                isUploadDialogOpen={isUploadDialogOpen}
                onLoad={iframeIsLoading.close}
                src={`/api/report/preview?token=${getJwt()}&requestId=${caseRequest.requestId}&refreshPdf=${caseRequest.pdfKey}#toolbar=0&navpanes=0`}
              />
                {iframeIsLoading.isOpen && <Loading absolute/>}
              </PdfReportFrameWrapper>
            : <PreviewForCaseRequest
                isExpert
                caseRequest={caseRequest}
              />
    }
    {
      caseRequest.state !== CASE_CLOSED &&
      <PageFooter>
        {caseNavigation}
      </PageFooter>
    }
    {caseClosedNavigation}
    {
      shareDialog
    }
  </PageContainer>
    <PurviewFooter/>
    {
      feedbackPopup.isOpen && <FeedbackDialog feedbackPopup={feedbackPopup} requestId={caseRequest.requestId} />
    }
  </>
}


const UploadCustomReport = ({caseRequest, setCaseRequest}) => {
  const isUploading = useToggle()

  return <>
    <p><strong>Not all answers were provided in "Questions & Answers".</strong> Would you like to upload a custom PDF report with your review?</p>
    <Card>
      {isUploading.isOpen && "Uploading..."}
      <WithForm>
        <ExternalReportUpload
          url={"/api/customReport?requestId=" + caseRequest.requestId}
          onSuccess={() => setCaseRequest(_.flow(_.set('report.useCustomReport', true), _.set('pdfKey', Date.now()), _.set('report.customReport', true)))}
        />
        <Errors/>
      </WithForm>
    </Card>
    <p css="margin-bottom:2rem">You can also <Link className="link" to={`/expert/case/${caseRequest.requestId}/questions`}>insert your answers</Link> for generating a pre-formatted Expert View report.</p>
  </>
}
