import {  Errors, TextInput, WithForm, validation } from '@startlibs/form';
import {Dialog, Button, SplitColumnsContainer,  Loading} from '@startlibs/components'
import {  getFetcher, postFetcher} from '@startlibs/utils'
import React, { useEffect, useState } from 'react'
import _ from 'lodash/fp'
import { buildValidation, required } from '../../../../../src/javascripts/utils/validation';

// validate only number and equal or lower than 65535
const checkPort = () => (item, k, props) => {
  const v = _.get('aePort',props)
  // check if it's a number
  if (!isNaN(v)) {
    if (v < 0 || v > 65535) {
      return 'Port must be between 0 and 65535'
    }
  }else{
    return 'Port must be a number'
  }
  return null
}
  
const preValidation = buildValidation({
  "name": [
    (v) => !v && required(), 
    (v) => v?.length > 64 && "Name must be shorter than 64 characters"
  ],
  "aePort": [(v) => !v && required(), checkPort()],
  "aeHost": (v) => !v && required(),
  "aeTitle": [(v) => !v &&  required(), (v) => v?.length > 64 && "AET must be shorter than 64 characters"]
})

const transform = (values, originalPatientData) => {

  // const name = buildName(values)
  // const dob = formatDate(values.patientDOB,TRANSFORM_DATE_FORMAT)

  return {
    form: values,
  }
}

export const AddPacsNodeDialog = ({pacs, closeDialog, isNew=false, setPacsNodes, dicomRouterJwt}) => {
  // const [,setNotification] = useNotificationRef()
  const [isLoading, setIsLoading] = useState(true)
  const [loadError, setLoadError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [enableSubmit, setEnableSubmit] = useState(false)
  const [pacsValue, setPacsValue] = useState()

  useEffect(() => {
    if (pacs && !isNew) {
      getFetcher((window.DICOM_ROUTER_ORIGIN || "") +`/destinations/${pacs.id}?jwt=${dicomRouterJwt}`)
      .then(res => {
        setPacsValue(res)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        // setIsLoading(false)
      })
    }else{
      setPacsValue(pacs)
    }
  }, [pacs])
  
  if(!pacsValue){
    return <Dialog
    title={isNew ? "Add PACS Node" : "Edit PACS Node"}
    closeDialog={closeDialog}
    footer={ () => {
      return <>
        <Button onClick={closeDialog}>Cancel</Button>
        </>
    }}
  >
    <Loading css="margin: 5rem auto;"></Loading>
  </Dialog>
  }

  return <WithForm
    action={(values) => {
    }}
    // onSuccess={(values) => {
    // }}
    values={pacsValue}
    // transform={(v) => transform(v, originalPatientData)}
    preValidation={preValidation}
  >{form =>
    <Dialog
      title={isNew ? "Add PACS Node" : "Edit PACS Node"}
      closeDialog={closeDialog}
      footer={ () => {
        return <>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button type="submit" highlight 
            disabled={!form.hasChanged && !enableSubmit}
            onClick={
              () => {
                form.willSubmitForm().then(() => {
                  let values = form.getValues()
                  let node = isNew ? {
                    name: values.name,
                    aeHost: values.aeHost,
                    aePort: values.aePort,
                    aeTitle: values.aeTitle,
                    pacsdbname: ""
                  } : {
                    id: pacs.id,
                    name: values.name,
                    aeHost: values.aeHost,
                    aePort: values.aePort,
                    aeTitle: values.aeTitle,
                    pacsdbname: pacs.pacsdbname
                  }
                  setIsSubmitting(true)
                  postFetcher(
                    (window.DICOM_ROUTER_ORIGIN || "")  +`/destinations?jwt=${dicomRouterJwt}`,
                    node,{
                      method: isNew ? 'POST' : 'PUT'  
                    })
                    .then((response) => {
                      if(isNew){
                        node.id = response
                        setPacsNodes((prev) => [...prev,node])
                      }else{
                        setPacsNodes((prev) => {
                          // replace the node with the updated one
                          return prev.map((n) => {
                            if(n.id == node.id){
                              return node
                            }else{
                              return n
                            }
                          })
                        })
                      }
                      closeDialog()
                    })
                    .catch((err) => {
                      console.log(err)
                      if(err && err[0] && err[0]?.includes('Destination')){
                        form.setError('name','There is already a node with this name')
                      }else if(err && err[0] && err[0]?.includes('AETitle')){
                        form.setError('aeHost','There is already a node with this same IP, Port and AET')
                      }else if(err && err[1] && err[1].status == 403){
                        form.setError('aeHost','You are not allowed to use this IP. Please contact Purview Support.')
                      }else{
                        form.setError('','It was not possible to save the changes')
                      }
                      setEnableSubmit(true)
                    })
                     .finally(() => {
                       setIsSubmitting(false)
                       setIsLoading(false)
                     })
                })
              }
            }
            isLoading={isSubmitting}>
            Save
          </Button>
        </>}
      }
    >
      <TextInput 
        label="Name"
        path="name"
        mandatory
      ></TextInput>
      <SplitColumnsContainer>
        <TextInput 
          label="IP address"
          path="aeHost"
          mandatory
        ></TextInput>
        <TextInput 
          label="Port"
          path="aePort"
          mandatory
        ></TextInput>
      </SplitColumnsContainer>
      <TextInput 
        label="AET"
        path="aeTitle"
        mandatory
      ></TextInput>
      <Errors/>
    </Dialog>
  }</WithForm>
}