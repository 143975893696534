import dicomParser from 'dicom-parser'
import {getFileNameAndExtension, getUUID} from './common'

export const DicomTextValue = (attr, dataSet, required) => {
  const element = dataSet.elements[attr]
  let text = ''

  if (element !== undefined) {
    const str = dataSet.string(attr)
    if (str !== undefined) {
      text = str
    }
  } else if (required) {
    throw new Error(`Element ${attr} not found`)
  }

  /* patientID */
  /* studyUID */
  /* seriesUID */
  /* instanceUID */
  if (
    attr === 'x00100020' ||
    attr === 'x0020000d' ||
    attr === 'x0020000e' ||
    attr === 'x00080018'
  ) {
    text = text.trim()
  }

  return text
}

export const isDicomDir = dataSet => {
  const DICOM_DIR_TAG = 'x00020002'
  const element = dataSet.elements[DICOM_DIR_TAG]

  if (element !== undefined) {
    const str = dataSet.string(DICOM_DIR_TAG)
    if (str === '1.2.840.10008.1.3.10') {
      return true
    }
  }

  return false
}

export const instanceFromServerAttributes = (serverInstance, prevInstance) => ({
  isCompliant: true,
  isDicomDir: false,
  instanceNumber: serverInstance.InstanceNumber || prevInstance.instanceNumber,
  instanceUID: serverInstance.SOPInstanceUID || prevInstance.instanceUID,
  patientDoB: serverInstance.PatientBirthDate || prevInstance.patientDoB,
  patientID: serverInstance.PatientID || prevInstance.patientID,
  patientName: serverInstance.PatientName || prevInstance.patientName,
  patientSex: serverInstance.PatientSex || prevInstance.patientSex,
  referringPhysician: serverInstance.ReferringPhysicianName || prevInstance.referringPhysician,
  seriesDate: serverInstance.SeriesDate || prevInstance.seriesDate,
  seriesInstitutionName: serverInstance.InstitutionName || prevInstance.seriesInstitutionName,
  seriesModality: serverInstance.Modality || prevInstance.seriesModality,
  seriesTime: serverInstance.SeriesTime || prevInstance.seriesTime,
  seriesUID: serverInstance.SeriesInstanceUID || prevInstance.seriesUID,
  studyDate: serverInstance.StudyDate || prevInstance.studyDate,
  studyDescription: serverInstance.StudyDescription || prevInstance.studyDescription,
  studyUID: serverInstance.StudyUID || prevInstance.studyUID,
  fileName:
    (prevInstance &&
      (prevInstance.fileName ||
        (prevInstance.sourceFile && getFileNameAndExtension(prevInstance.sourceFile)[0]))) ||
    'Missing filename',
  key: (prevInstance && prevInstance.key) || getUUID(),
})

export const isDicomPropertiesCompliant = dicomProperties => {
  return !!dicomProperties.studyUID && !!dicomProperties.instanceUID
}
export const getDicomInstanceJson = (byteArray, requireAll) => {
  const dataSet = dicomParser.parseDicom(byteArray, { untilTag: 'x00200013' })
  try {
    const dicomProperties = {
      ...getPatientPropertiesFromDataset(dataSet),
      ...getStudyPropertiesFromDataset(dataSet),
      ...getSeriesPropertiesFromDataset(dataSet),
      ...getInstancePropertiesFromDataset(dataSet),
      isDicomDir: isDicomDir(dataSet)
    }
    return { ...dicomProperties, isCompliant:isDicomPropertiesCompliant(dicomProperties) }
  } catch (e) {
    // console.log(e)
    return { nonCompliant: true }
  }
}
export const getPatientPropertiesFromDataset = (dataSet) =>
  ({
    patientName: DicomTextValue('x00100010', dataSet),
    patientID: DicomTextValue('x00100020', dataSet),
    patientDoB: DicomTextValue('x00100030', dataSet),
    patientSex: DicomTextValue('x00100040', dataSet)
  })

export const getStudyPropertiesFromDataset = (dataSet) => ({
  studyUID: DicomTextValue('x0020000d', dataSet, true),

  studyDate: DicomTextValue('x00080020', dataSet),
  studyDescription: DicomTextValue('x00081030', dataSet),

  referringPhysician: DicomTextValue('x00080090', dataSet),
})

export const getSeriesPropertiesFromDataset = (dataSet) => ({
  seriesDate: DicomTextValue('x00080021', dataSet),
  seriesTime: DicomTextValue('x00080031', dataSet),
  seriesModality: DicomTextValue('x00080060', dataSet),
  modalities: [DicomTextValue('x00080060', dataSet)].filter(Boolean),

  seriesUID: DicomTextValue('x0020000e', dataSet),
  seriesUIDs: [DicomTextValue('x0020000e', dataSet)].filter(Boolean),
  seriesInstitutionName: DicomTextValue('x00080080', dataSet),
})

export const getInstancePropertiesFromDataset = (dataSet) => {
  const result = {
    instanceUID: DicomTextValue('x00080018', dataSet) || DicomTextValue('x00041511', dataSet),
    instanceNumber: DicomTextValue('x00200013', dataSet)
  }
  if (!result.instanceUID) {
    throw new Error(`Element x00080018 not found`)
  }
  return result
}