export const NOT_RECOMMENDED = 'NOT_RECOMMENDED'
export const RECOMMENDED = 'RECOMMENDED'
export const SCHEDULED = 'SCHEDULED'
export const COMPLETED = 'COMPLETED'

export const INPERSONVISIT_LABELS = {
  [NOT_RECOMMENDED]: 'Not Recommended',
  [RECOMMENDED]: 'Recommended',
  [SCHEDULED]: 'Scheduled',
  [COMPLETED]: 'Completed'
}

