import React from 'react';
import styled from 'styled-components';
import { Icon } from '@startlibs/components';
import { SectionTitle } from '../Analytics';
import { round } from 'lodash';

const Text = styled.p`
  font-size: 16px !important;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
`;

const Star = styled(({ marked, starId, size, className }) => (
  <Icon
    icon={marked ? 'star' : 'start-line-ultra-light'}
    data-star-id={starId}
    className={className}
    size={size}
    marked={marked}
    role="button"
  />
))`
  font-size: ${props => props.size}px;
  color: ${props => (props.marked ? '#FFCB2D' : '#c8c8c8')};
  ${props => props.customColor ? `color: ${props.customColor};` : ''}
  padding: 0 0.25rem;
  ${props => props.customPadding ? `padding: ${props.customPadding};` : ''}
`;

// Adjusted PartialStar component to visually represent partial filling
const PartialStar = styled(Star)`
  background: linear-gradient(
    to right,
    ${props => props.customColor} 0%,
    ${props => props.customColor} ${props => props.fillPercentage}%,
    #f2f2f2 ${props => props.fillPercentage}%,
    #f2f2f2 100%
  );
  -webkit-background-clip: text;
  color: transparent; // Hide the original text color to show the gradient
`;

export const StarsRatingDisplay = ({ label, average, countAnswers }) => {
  const fullStars = Math.floor(average);
  const partialStarPercentage = (average - fullStars) * 100;
  const emptyStars = 5 - Math.ceil(average);
  
  const renderStars = (countAnswers) => {
    let stars = [];

    // Full stars
    for (let i = 0; i < fullStars; i++) {
      stars.push(<Star key={`full-${i}`} marked={true} size={48} customColor="#4b4b4b" />);
    }

    // Partial star
    if (partialStarPercentage > 0) {
      stars.push(
        <PartialStar
          key="partial"
          marked={true}
          size={48}
          fillPercentage={partialStarPercentage}
          customColor="#4b4b4b"
        />
      );
    }

    // Empty stars
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<Star key={`empty-${i}`} marked={true} size={48} customColor="#f2f2f2" />);
    }

    return stars;
  };

  return countAnswers > 0 ? <div style={{width: "50%"}}>
    <SectionTitle>{label}</SectionTitle>
    <Text><b>{round(average, 2)} / 5</b> - {countAnswers} answers</Text>
    {renderStars(countAnswers)}
  </div> : <div style={{width: "50%"}}>
    <SectionTitle>{label}</SectionTitle>
    <Text>No data available yet</Text>
    {renderStars(countAnswers)}
  </div> 
};
