import React from 'react'
import {Editor, Path, Transforms, Range} from "slate";
import { canUploadImageSlate, fixImageElementDragAndDropInsideEditor, insertImage, loadingImageOverlay, removeElementsFromHtml, removeImagesFromHtml, removeTheadFromHtml, removeTheadTbodyTfootFromHtml, uploadImageFetcher, wrapTdContentInsideHtml } from './slateUtils';
import { setNotification } from '@startlibs/components';
import { deserialize } from './slateSerializer';
import { SHORTCUTS } from './slatePhrases';

// Those are function that overwrite the default slate editor, allowing us to add custom behavior, like images, tables, etc.
const removeImage = true;
const phrasesAsMention = false;

export const withHtml = (editor, editorParams) => {
  // export const withHtml = (editorParams) => (editor) => {
  // export const withHtml = _.curry((editorParams, editor) => {
  const { insertData, isInline, isVoid, insertText} = editor

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = data => {
    const typesLength = data.types.length || 0
    const html = data.getData('text/html')
    const { files } = data
    // if (files && files.length > 0 && props.formatTools.findIndex(x => x === 'image') > 0 ) {
    if (files && files.length > 0 && editorParams.formatTools.findIndex(x => x === 'image') >= 0 && typesLength < 4) {
      for (const file of files) {
        const [mime] = file.type.split('/')
        if (mime === 'image') {
          const canUpload = canUploadImageSlate(file)
          if (canUpload === true) {
            // display a dialog on top of window while the image is being uploaded
            const loading = loadingImageOverlay()
            document.body.appendChild(loading)
            uploadImageFetcher(editorParams.requestId)(file)
            .then(response => {
              if(response && response?.url && response?.url.length > 0){
                insertImage(editor, response.url)
                document.body.removeChild(loading)
              }else{
                document.body.removeChild(loading)
              }
            })
            .catch(error => {
              document.body.removeChild(loading)
              if (error?.status === 413) {
                setNotification({type:"alert", timeout: 4000,msg:(close) => <span>File size too large</span>})
              }else{
                setNotification({type:"alert", timeout: 4000,msg:(close) => <span>An error has occurred while uploading</span>})
              }
            })
          }else{
            setNotification({type:"alert", timeout: 4000,msg:(close) => <span>{canUpload}</span>})
          }
        }
      }
      return
    }else{
      if (html) {
        const isSlateData = html.indexOf('data-slate-fragment') > -1
        const isTable = html.indexOf('<td') > -1 || html.indexOf('<th') > -1
        try{
          if(removeImage && !isSlateData){
            if(isTable){
              const isInTable = Editor.above(editor, {
                match: n => n.type === 'table',
              });        
              if (isInTable){
                const text = data.getData('text/plain')
                const fragmenttext = deserialize(text)
                Transforms.insertFragment(editor, fragmenttext)
              }else{
                const fragment = deserialize(wrapTdContentInsideHtml(removeElementsFromHtml(removeImagesFromHtml(html)),{},true))
                Transforms.insertFragment(editor, fragment)
              }
            }else{

              // Check if it is coming from Microsoft Office Word or Outlook
              if(html.includes('xmlns:o="urn:schemas-microsoft-com:office:office"') 
                || html.includes('xmlns:w="urn:schemas-microsoft-com:office:word"') 
                || html.includes('xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"')){
                  const fragment = deserialize(
                    removeImagesFromHtml(
                      html.replace(/<head>[\s\S]*<\/head>/gi, '')
                        .replace(/(>\r\n\r\n<|>\n\n<|>\r\r<)/gm, '><')
                        .replace(/(\r\n|\n|\r)/gm, ' ')
                        .replace(/<o:p>&nbsp;<\/o:p>/gi, '')
                        .replace(/<o:p><\/o:p>/gi, '')
                        .replace(/\s+/g, ' ')
                        .replace(/(<ol[^>]*start=[^>]*>)\s+<li/gi, "$1<li") // Remove the space between <ol> and <li>
                        .replace(/<\/li>\s+<\/ol>/gi, "</li></ol>")  // Remove the space between </li> and </ol>
                        .replace(/<ul[^>]*>\s+<li/gi, "<ul><li")  // Remove the space between <ul> and <li>
                        .replace(/<\/li>\s+<\/ul>/gi, "</li></ul>")  // Remove the space between </li> and </ul>
                        .replace(/<\/li>]*>\s+<li/gi, "<ol><li")  // Remove the space between </li> and <li
                        .replace(/<\/li>\s+<li/gi, "</li><li") // Remove the space between </li> and <li
                    ),{},true)
                  Transforms.insertFragment(editor, fragment)
              }else{
                const fragment = deserialize(removeImagesFromHtml(html),{},true)
                Transforms.insertFragment(editor, fragment)
              }
            }
          }else{
            if(isSlateData){ // Drag and drop an image within slate editor
              const fragment = deserialize(fixImageElementDragAndDropInsideEditor(html),{},true)
              Transforms.insertFragment(editor, fragment)
            }else{
              const fragment = deserialize(html,{},true)
              Transforms.insertFragment(editor, fragment)
            }
          }
        }catch(e){
          const text = data.getData('text/plain')
          const fragmenttext = deserialize(text)
          Transforms.insertFragment(editor, fragmenttext)
        }
        return
      }
    }
    insertData(data)
  }

  editor.insertText = text => {
    const { selection } = editor
    if (text.endsWith(' ') && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection
      const block = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n),
      })
      const path = block ? block[1] : []
      const start = Editor.start(editor, path)
      const range = { anchor, focus: start }
      const beforeText = Editor.string(editor, range) + text.slice(0, -1)
      const phrase = SHORTCUTS[beforeText]
      
      if (phrase) {
        Transforms.select(editor, range)

        if (!Range.isCollapsed(range)) {
          Transforms.delete(editor)
        }

        if(phrase.length > 0){
          phrase.map((item) => {
            let replaceText = phrasesAsMention ?
            {
              type:  'mention',
              phrase: item,
              children: [{ text: ''}]
            } : {
              type:  'span',
              text: item+' '
            }

            Transforms.insertNodes(editor, replaceText)
            Transforms.move(editor)
              })
            }
        
        return
      }
    }

    insertText(text)
  }
  return editor
}

export const withImages = (editor, editorParams) => {
  const { insertData, isVoid } = editor

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = data => {
    const { files } = data
    // if (files && files.length > 0 && props.formatTools.findIndex(x => x === 'image') >= 0 ) {
    if (files && files.length > 0 && editorParams.formatTools.findIndex(x => x === 'image') >= 0 ) {
      for (const file of files) {
        const [mime] = file.type.split('/')
        if (mime === 'image') {
          const canUpload = canUploadImageSlate(file)
          if (canUpload === true) {
            // display a dialog on top of window while the image is being uploaded
            const loading = loadingImageOverlay()
            document.body.appendChild(loading)
            uploadImageFetcher(editorParams.requestId)(file)
            .then(response => {
              if(response && response?.url && response?.url.length > 0){
                insertImage(editor, response.url)
                document.body.removeChild(loading)
              }else{
                document.body.removeChild(loading)
              }
            })
            .catch(error => {
              document.body.removeChild(loading)
              if (error?.status === 413) {
                setNotification({type:"alert", timeout: 4000,msg:(close) => <span>File size too large</span>})
              }else{
                setNotification({type:"alert", timeout: 4000,msg:(close) => <span>An error has occurred while uploading</span>})
              }
            })
          }else{
            setNotification({type:"alert", timeout: 4000,msg:(close) => <span>{canUpload}</span>})
          }
        }
      }
      return
    }
    insertData(data)
  }

  return editor
}

export const withSimpleInput = editor => {
  const { insertData } = editor
  
  editor.insertData = data => {
    const text = data.getData('text/plain')
    if(text && text.length > 0){
      const fragmenttext = deserialize(text.replace(/[^a-z0-9 ,.?!\n]/ig, '').replace(/\n\n/g, ""),{},true)
      Transforms.insertFragment(editor, fragmenttext)
      // insertData(data)
    }
  }

  return editor

}

export const withPhrases = (editor) => {
  
  const { isInline, isVoid, markableVoid} = editor;

  editor.isInline = element => {
    return element.type === 'mention' ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === 'mention' ? true : isVoid(element)
  }

  editor.markableVoid = element => {
    return element.type === 'mention' || markableVoid(element)
  }

  return editor;
};

export const withTable = (editor) => {
  const { insertBreak, normalizeNode, addMark, removeMark, deleteBackward, deleteForward, deleteFragment } = editor;

  const autoAdjustHeight = (editor, isFragment = false) => {
    const { selection } = editor;

    if (selection && (Range.isCollapsed(selection) || isFragment)) {
      const isInTable = Editor.above(editor, {
        match: n => n.type === 'table',
      });

      if (isInTable) {
        const start = Editor.start(editor, selection);
        const isStart = Editor.isStart(editor, start, selection);

        const currCell = Editor.above(editor, {
          match: n => n.type === 'td',
        });
          
        Transforms.setNodes(
          editor,
          {
            height: 22
          },
          {
            at: [],
            match: n => n.type === 'td'
          },
        );

        if (isStart && currCell && !Editor.string(editor, currCell[1])) {
          return;
        }
      }
    }
  }
  
  editor.normalizeNode = entry => {
    if (maybePreserveSpace(editor, entry)) return;
    normalizeNode(entry);
  };

  editor.insertBreak = (...args) => {
    const currentNode = Editor.above(editor, {
      match: n => Editor.isBlock(editor, n),
    })
    if(currentNode && currentNode[0]?.type === 'paragraph' && currentNode[0]?.class === 'frame-object'){
      insertParagraph(editor)
    }else{
      insertBreak(...args);
    }
  } 

  editor.addMark = (key, value) => {
    if (editor.selection) {
      const lastSelection = editor.selection;

      const selectedCells = Editor.nodes(editor, {
        match: n => n.selectedCell,
        at: [],
      });

      let isTable = false;

      for (let cell of selectedCells) {
        if (!isTable) {
          isTable = true;
        }

        const [content] = Editor.nodes(editor, {
          match: n => n.type === 'table-content',
          at: cell[1],
        });

        if (Editor.string(editor, content[1]) !== '') {
          Transforms.setSelection(editor, Editor.range(editor, cell[1]));
          addMark(key, value);
        }
      }

      if (isTable) {
        Transforms.select(editor, lastSelection);
        return;
      }
    }

    addMark(key, value);
  };

  editor.removeMark = key => {
    if (editor.selection) {
      const lastSelection = editor.selection;
      const selectedCells = Editor.nodes(editor, {
        match: n => {
          return n.selectedCell;
        },
        at: [],
      });

      let isTable = false;
      for (let cell of selectedCells) {
        if (!isTable) {
          isTable = true;
        }

        const [content] = Editor.nodes(editor, {
          match: n => n.type === 'table-content',
          at: cell[1],
        });

        if (Editor.string(editor, content[1]) !== '') {
          Transforms.setSelection(editor, Editor.range(editor, cell[1]));
          removeMark(key);
        }
      }

      if (isTable) {
        Transforms.select(editor, lastSelection);
        return;
      }
    }
    removeMark(key);
  };

  editor.deleteFragment = (...args) => {
    autoAdjustHeight(editor,true)
    deleteFragment(...args);
  };

  editor.deleteBackward = (...args) => {
    autoAdjustHeight(editor)
    deleteBackward(...args);
  };

  editor.deleteForward = (...args) => {
    autoAdjustHeight(editor)
    deleteForward(...args);
  };

  return editor;
};

const PreserveSpaceAfter = new Set(['table','image']);
const PreserveSpaceBefore = new Set(['table','image']);

const insertParagraphFrameObject = (editor, at, text = '') => {
  Transforms.insertNodes(editor, {
      type: 'paragraph',
      class: 'frame-object',
      children: [{ text }],
    },{ at },
  );
};

const insertParagraph = (editor, text = '',) => {
  Transforms.insertNodes(editor,{
      type: 'paragraph',
      children: [{ text }],
  });
};

const maybePreserveSpace = (
  editor,
  entry
) => {
  const [node, path] = entry;
  const { type } = node;
  let preserved = false;

  if (PreserveSpaceAfter.has(type)) {
    const next = Editor.next(editor, { at: path });
    if (!next || PreserveSpaceBefore.has(next[0].type)) {
      insertParagraphFrameObject(editor, Path.next(path));
      preserved = true;
    }
  }

  if (PreserveSpaceBefore.has(type)) {
    if (path[path.length - 1] === 0) {
      insertParagraphFrameObject(editor, path);
      preserved = true;
    } else {
      const prev = Editor.previous(editor, { at: path });
      if (!prev || PreserveSpaceAfter.has(prev[0].type)) {
        insertParagraphFrameObject(editor, path);
        preserved = true;
      }
    }
  }

  return preserved;
};