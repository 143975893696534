import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import * as uspaths from "admin/controlPanel/analytics/counties-albers-10m.json"
import { namemapjson } from './namemap';
import * as topojson from 'topojson-client';
import { CountryRegionData } from 'react-country-region-selector'
import legend from 'd3-color-legend';


export const MapCasesByState = ({data, hoveredState, setHoveredState}) => {

    const us = uspaths
    const namemap = new Map(us.objects.states.geometries.map(d => {
        return [d.properties.name, d.id]
    }))

    const UsStates = CountryRegionData
    .filter(d => d[1] === "US")
    .flatMap(d => {
        const list = d[2].split("|");
        return list.map(item => {
            const [stateAbbreviation, stateName] = item.split("~");
            return { [stateName.trim()]: stateAbbreviation.trim() };
        });
    });
    
    // Convert data into an array of objects
    // const processedData = data.map(([state, value]) => ({ state, value }));
    const processedData = data.map(([state, value, fullName]) => ({ state, value, fullName}));
    // Sort the data by value in descending order
    processedData.sort((a, b) => b.value - a.value);
    
    const chartRef = useRef();

    function getContrastYIQ(hexcolor){
        hexcolor = hexcolor.replace("#", "");
        const r = parseInt(hexcolor.substr(0,2),16);
        const g = parseInt(hexcolor.substr(2,2),16);
        const b = parseInt(hexcolor.substr(4,2),16);
        const yiq = ((r*299)+(g*587)+(b*114))/1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    // Create the gradient legend
    function createGradientLegend(svg, colorScale, x, y, width, height, minValue, maxValue) {
        // Define the gradient
        const gradientId = "gradient-legend";
        const defs = svg.append("defs");
        const linearGradient = defs.append("linearGradient").attr("id", gradientId);
    
        colorScale.range().forEach((color, i, arr) => {
            linearGradient.append("stop")
                .attr("offset", `${(i / arr.length) * 100}%`)
                .attr("stop-color", color);
        });
    
        // Append a rect to represent the gradient bar
        svg.append("rect")
            .attr("x", x)
            .attr("y", y)
            .attr("width", width)
            .attr("height", height)
            .style("fill", `url(#${gradientId})`);
    
        // Append text for min and max values
        // const [minValue, maxValue] = colorScale.domain();
    
        svg.append("text")
            .attr("x", x)
            .attr("y", y + height + 15)
            .attr("font-size", "18px")
            .text(minValue)
            .attr("alignment-baseline", "hanging");
    
        svg.append("text")
            .attr("x", x + width)
            .attr("y", y + height + 15)
            .attr("font-size", "18px")
            .text(maxValue)
            .attr("text-anchor", "end")
            .attr("alignment-baseline", "hanging");
        
        // add a title
        svg.append("text")
            .attr("x", x + width / 2)
            .attr("y", y - 20)
            // .attr("text-anchor", "middle")
            // .attr("alignment-baseline", "hanging")
            // .attr("font-size", "18px")
            // .attr("font-weight", "bold")
            // .text("Origin of Case Requests");

    }
    
    useEffect(() => {

        // Clear the existing chart before creating a new one
        d3.select(chartRef.current).selectAll("*").remove();

        const Chart = () => {

            const color = d3.scaleQuantize([0, processedData[0].value], d3.schemeBlues[5]);
            const path = d3.geoPath();
            const format = d => `${d}%`;

            // const valuemap = new Map(unemployment.map(d => [namemap.get(d.name), d.rate]));
            const valuemap = new Map(processedData.map(d => [namemap.get(d.fullName), d.value]));

            // The counties feature collection is all U.S. counties, each with a
            // five-digit FIPS identifier. The statemap lets us lookup the name of 
            // the state that contains a given county; a state’s two-digit identifier
            // corresponds to the first two digits of its counties’ identifiers.
            const counties = topojson.feature(us, us.objects.counties);
            const states = topojson.feature(us, us.objects.states);
            const statemap = new Map(states.features.map(d => [d.id, d]));

            // The statemesh is just the internal borders between states, i.e.,
            // everything but the coastlines and country borders. This avoids an
            // additional stroke on the perimeter of the map, which would otherwise
            // mask intricate features such as islands and inlets. (Try removing
            // the last argument to topojson.mesh below to see the effect.)
            const statemesh = topojson.mesh(us, us.objects.states, (a, b) => a !== b);
            

            // Invert UsStates array to map abbreviations to state names for easy lookup
            const stateAbbreviationMap = UsStates.reduce((acc, curr) => {
                const key = Object.keys(curr)[0]; // Original key (state name)
                const value = curr[key]; // Original value (state abbreviation)
                acc[value] = key; // Invert: now abbreviation is the key, and state name is the value
                return acc;
            }, {});

            const svg = d3.create("svg")
                .attr("width", 1096)
                .attr("height", 720)
                .attr("viewBox", [0, 0, 1096, 720])
                .attr("style", "max-width: 100%; height: auto;");

            // Set the position for the legend (you can adjust these values)
            const legendX = 610;
            const legendY = 20;

            // Call the createLegend function
            // createLegend(svg, color, legendX, legendY);
            createGradientLegend(svg, color, legendX, legendY, 200, 20, 0, processedData[0].value);

            const statePaths = svg.append("g")
                .selectAll("path")
                .data(topojson.feature(us, us.objects.states).features)
                .join("path")
                // .attr("fill", d => color(valuemap.get(d.id)))
                .attr("fill", d => {
                    const selectedState = processedData.find(item => item.state === hoveredState);
                    if(selectedState && d.properties.name === selectedState.fullName) {
                        return "#ff9900";
                    }
                    const stateData = processedData.find(item => item.fullName === d.properties.name);
                    return stateData ? color(valuemap.get(d.id)) : "white"; // Set to white if not in processedData
                })
                .attr("d", path)
                .on("mouseover", function(event, d) {
                    const [x, y] = d3.pointer(event, svg.node());
                    d3.select(this).attr("fill", "#ff9900");
                    const stateData = processedData.find(item => item.fullName === d.properties.name);
                    const value = stateData ? stateData.value : 0;
                    setHoveredState(hoveredState => {
                        return stateData ? stateData.state : null
                    })
                    // Add a rectangle for the background
                    svg.append("rect")
                        .attr("class", "tooltip-background")
                        .attr("x", x)
                        .attr("y", y - 20) // Adjust as needed
                        .attr("width", 190) // Adjust based on text length
                        .attr("height", 42)
                        .attr("fill", "white")
                        .attr("stroke", "black")
                        .attr("stroke-width", 2)
                        .attr("stroke-opacity", 0.1)
                        .attr("rx", 10)
                        .attr("ry", 10)
                        .attr("pointer-events", "none");

                    // Add text on top of the rectangle
                    svg.append("text")
                        .attr("class", "tooltip")
                        .attr("x", x + 10) // Adjust for padding
                        .attr("y", y + 6) // Adjust for vertical alignment
                        .text(`${d.properties.name}: ${value}`)
                        .attr("font-size", "18px")
                        .attr("font-weight", "bold")
                        .attr("fill", "black")
                        .attr("pointer-events", "none");
                })
                .on("mouseout", function() {
                    // d3.select(this).attr("fill", d => color(valuemap.get(d.id)));
                    d3.select(this).attr("fill", d => {
                        const stateData = processedData.find(item => item.fullName === d.properties.name);
                        return stateData ? color(valuemap.get(d.id)) : "white"; // Set to white if not in processedData
                    });
                    svg.selectAll(".tooltip-background").remove(); // Remove tooltip background
                    svg.selectAll(".tooltip").remove(); // Remove tooltip text
                    setHoveredState(null)
                });


            // Create group elements for each state
            const stateLabels = svg.append("g")
                .selectAll("g")
                .data(topojson.feature(us, us.objects.states).features)
                .enter()
                .append("g")
                .attr("transform", d => `translate(${path.centroid(d)[0]}, ${path.centroid(d)[1]})`);

            // Append rectangles as background for text
            // stateLabels
            //     .append("rect")
            //     .attr("x", -20) // Adjust these values based on text length and desired padding
            //     .attr("y", -10)
            //     .attr("width", 48) // Adjust width and height based on text size
            //     .attr("height", 20)
            //     .attr("fill", "white");

            // Append text on top of rectangles
            stateLabels
                .append("text")
                .attr("text-anchor", "middle")
                .attr("dominant-baseline", "central")
                // .attr("fill", "darkgrey")
                .attr("fill", d => color(valuemap.get(d.id)) ? getContrastYIQ(color(valuemap.get(d.id))) : "black")
                .attr("font-weight", "bold")
                .attr("font-size", "16px")
                .attr("font-family", "sans-serif")
                .attr("pointer-events", "none")
                // .text(d => stateAbbreviationMap[d.properties.name]);
                // append text full name
                // .text(d => d.properties.name);
                .text(d => {
                    const abreviation = stateAbbreviationMap[d.properties.name];
                    return abreviation ? `${abreviation}` : `${d.properties.name}`;
                    // return d.properties.name
                });


            // state borders
            svg.append("path")
                // .datum(topojson.mesh(us, us.objects.states, (a, b) => a !== b)) // only internal borders
                .datum(topojson.mesh(us, us.objects.states)) // all borders
                .attr("fill", "none")
                .attr("stroke", "grey")
                .attr("stroke-linejoin", "round")
                .attr("pointer-events", "none")
                .attr("d", path);

                
            d3.select(chartRef.current).append(() => svg.node());
            return svg.node();

        };

        Chart();
    }, [hoveredState]);

    return (
        <>
            {/* <div>State Cases Map Chart</div> */}
            <div style={{marginTop: "48px"}} ref={chartRef}></div>
        </>
    );
}