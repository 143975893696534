import {createPromiseQueue} from '@startlibs/utils'

export const processQueue = createPromiseQueue()

// export const uploadQueue = createPromiseQueue(1) // this number is the number of concurrent uploads
export const uploadQueue = createPromiseQueue(2) // this number is the number of concurrent uploads

// export const syncProcessLock = createPromiseQueue()
// export const syncUploadLock = createPromiseQueue()

export const syncControlLock = createPromiseQueue()
