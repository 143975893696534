import {useLocation, Routes, Route, useNavigate} from 'react-router';
import {Slot, useToggle} from '@startlibs/core'
import React, {Suspense, useEffect, useRef, useState} from 'react'
import {LogoutButton, Navbar} from '../components/Navbar';
import {PatientApproval} from './PatientApproval'
import {PatientApprovalSuccess} from './status/PatientApprovalSuccess'
import {PatientCheckout} from './PatientCheckout'
import {PatientCreateRequest} from './PatientCreateRequest'
import {PatientLogin} from './PatientLogin'
import {PatientOverview} from './PatientOverview'
import {PatientRecords} from './PatientRecords'
import {PatientReport} from './PatientReport'
import {lazyProviderInfo} from '../components/WithProvider'
import {PatientContactDetails} from './PatientContactDetails'
import {getFirstPage} from './utils/patientUtils'
import {PageContent, PageWrapper} from '../admin/steps/RequestSidebar'
import {PatientRequestSidebar} from './PatientRequestSidebar'
import {PatientMedicalRelease} from './PatientMedicalRelease'
import {PatientStatus} from './PatientStatus'
import {jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import {getJwt, setJwt} from '../hooks/useJwt'
import {confirmExitPage} from '../hooks/useConfirmExitPage'
import {PatientPayments} from './PatientPayments'
import {PatientAgreements} from './PatientSignTerms'
import {ProviderTitle} from '../components/ProviderTitle'
import {PatientCaseAwareness} from './PatientCaseAwereness'
import {willUseSuspense, WithLazyResource} from '../hooks/useSuspense'
import {CreateRequestSidebar} from '../admin/steps/CreateRequestSidebar'
import {lazyPublicCategories} from '../admin/experts/hooks/useEditCategories'
import {LoadingPage} from '../components/PageLoading'
import {getTimezoneOffset} from '../utils/utils'
import {ReadOnlyField} from '../request/RequestOverview'
import {isReadOnlyShare} from './utils/caseRequestUtils'
import {ReadOnlyOverview} from './ReadOnlyOverview'
import {ReadonlyRequestSidebar} from './readonly/ReadonlyRequestSidebar'
import {Redirect} from "../components/Redirect";
import { FeedbackButton } from '../expert/ExpertRoutes';
import { CASE_CLOSED } from '../enums/CaseState';
import { isStateBefore } from '../request/utils';
import { RequestOverviewMedicalRecordsOnly } from '../request/RequestOverviewMedicalRecordsOnly';
import { PATIENT } from '../enums/UserRoles';
import { PatientContactInstitution } from './PatientContactInstitution';


const Identity = () => null


export const PatientRoutes = ({isNurse,isRefPhysician}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [caseRequest, setCaseRequest] = useState()
  const feedbackPopup = useToggle()
  const isCurrentStateBefore = isStateBefore(caseRequest && caseRequest.state)
//      <Slot name="Navbar-Exit"/>

  if (location.pathname.indexOf('/access-case')>=0) {
    return <WithLazyResource value={lazyProviderInfo}>{providerInfo =>
      <PatientCaseAwareness providerInfo={providerInfo} setCaseRequest={setCaseRequest}/>
    }</WithLazyResource>
  }

  return <WithLazyResource value={lazyProviderInfo}>{providerInfo =>
    <>
      <ProviderTitle/>
      <Routes>
        <Route path="access" element={<Identity/>}/>
        {!caseRequest && <Route path="/" element={<Identity/>}/>}
        <Route path="records" element={<Navbar isPatient menu={<Slot className="navbar-action" name="Navbar-Action"/>}/>}/>
        <Route path="start" element={<Navbar isPatient menu={<Slot className="navbar-action" name="Navbar-Action"/>}/>}/>
        <Route path="*" element={<Navbar
          isPatient
          subtitle={isReadOnlyShare(caseRequest) && "Shared access"}
          menu={<>
            {!isCurrentStateBefore(CASE_CLOSED) && caseRequest.requestId && <FeedbackButton icon="feedback" textOnly onClick={()=>{feedbackPopup.open()}} css="margin-right:2rem;">Share feedback</FeedbackButton>}
            <LogoutButton className="navbar-action" onClick={() => confirmExitPage().then(() => jwtPostFetcher(getJwt())("/api/logout","")).finally(() => navigate("/access"))}>Exit</LogoutButton>
          </>}/>
        }/>
      </Routes>
      {
        caseRequest && location.pathname.indexOf('/access') !== 0
          ? <PatientCase
            caseRequest={caseRequest}
            setCaseRequest={setCaseRequest}
            providerInfo={providerInfo}
            isRefPhysician={isRefPhysician} 
            feedbackPopup={feedbackPopup}
          />
          : <Routes>
            <Route path="/" element={<PatientLogin
              isNurse={isNurse}
              isRefPhysician={isRefPhysician}
              providerInfo={providerInfo}
              setCaseRequest={setCaseRequest}
            />}/>
            <Route path="open" element={<SuspenseStep Component={PatientLoad} setCaseRequest={setCaseRequest}/>}/>
            <Route path="start" element={<PatientCreateCaseFromStart isNurse={isNurse} isRefPhysician={isRefPhysician} setCaseRequest={setCaseRequest} providerInfo={providerInfo}/>}/>
            <Route path="*" element={<Redirect noThrow to="/access"/>}/>
          </Routes>
      }
    </>
  }</WithLazyResource>
}

const PatientCreateCaseFromStart = ({setCaseRequest,isNurse,isRefPhysician, providerInfo}) => {
  const categories = useToggle([])
  const markedAsPriority = useToggle()
  const formRef = useRef()

  return <PageWrapper responsiveWorkaround noSidebar={!isNurse && !isRefPhysician}>
    {
      
      // (isNurse || isRefPhysician) &&
      isNurse &&
      <CreateRequestSidebar
        lazyCategories={lazyPublicCategories}
        caseRequest={{categories:categories.isOpen,markedAsPriority:markedAsPriority.isOpen}}
        setCaseRequest={(update) => formRef.current.setValues(update)}
      />
    }
    <PageContent>
      <SuspenseStep Component={PatientCreateRequest}
                    formRef={(isNurse || isRefPhysician) ? formRef : undefined}
                    onChange={(isNurse || isRefPhysician) ? (prev, next) => {
                      if (prev.markAsPriority !== next.markAsPriority) {
                        markedAsPriority.openWith(next.markAsPriority)
                      }
                      if (prev.categories !== next.categories) {
                        categories.openWith(next.categories)
                      }
                    } : undefined}
                    path="start" isNurse={isNurse} isRefPhysician={isRefPhysician} setCaseRequest={setCaseRequest} providerInfo={providerInfo}/>
    </PageContent>
  </PageWrapper>
}

const SuspenseStep = ({Component, ...props}) => <Suspense fallback={<LoadingPage />}><Component {...props} /></Suspense>

const PatientCase = ({caseRequest, setCaseRequest, providerInfo, isNurse, isRefPhysician, feedbackPopup}) => {

  const readOnlyShare = isReadOnlyShare(caseRequest)
  const showReadOnlyMedicalRecords = (readOnlyShare && caseRequest?.permission?.medicalRecords)

  return <PageWrapper responsiveWorkaround>
    {
      readOnlyShare
        ? <ReadonlyRequestSidebar providerInfo={providerInfo} caseRequest={caseRequest}/>
        : <PatientRequestSidebar providerInfo={providerInfo} caseRequest={caseRequest} isRefPhysician={isRefPhysician}/>
    }
    <PageContent>
      <Routes>
        <Route path="records" element={<PatientRecords caseRequest={caseRequest} setCaseRequest={setCaseRequest} isRefPhysician={isRefPhysician} feedbackPopup={feedbackPopup}/>}/>

        <Route path="release" element={<PatientMedicalRelease caseRequest={caseRequest} setCaseRequest={setCaseRequest} feedbackPopup={feedbackPopup}/>}/>
        <Route path="agreements" element={<SuspenseStep Component={PatientAgreements} caseRequest={caseRequest} setCaseRequest={setCaseRequest} feedbackPopup={feedbackPopup}/>}/>

        <Route path="approval" element={<PatientApproval caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>}/>
        <Route path="checkout/:position" element={<PatientCheckout providerInfo={providerInfo} caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>}/>
        <Route path="payments" element={<PatientPayments caseRequest={caseRequest} setCaseRequest={setCaseRequest} feedbackPopup={feedbackPopup}/>}/>

        <Route path="details" element={<PatientContactDetails caseRequest={caseRequest} setCaseRequest={setCaseRequest} feedbackPopup={feedbackPopup}/>}/>

        <Route path="report" element={<PatientReport caseRequest={caseRequest} feedbackPopup={feedbackPopup}/>}/>
        <Route path="paid" element={<PatientApprovalSuccess caseRequest={caseRequest}/>}/>
        <Route path="status" element={<PatientStatus caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>}/>
        <Route path="contact-institution" element={<PatientContactInstitution caseRequest={caseRequest} setCaseRequest={setCaseRequest} feedbackPopup={feedbackPopup}/> }/>

        {
          readOnlyShare
          ? <Route path="overview" element={<ReadOnlyOverview caseRequest={caseRequest} setCaseRequest={setCaseRequest} allowUpload={false} allowDownload={false} showHiddenRecords={false} />}/>
          : <Route path="overview" element={<PatientOverview caseRequest={caseRequest} setCaseRequest={setCaseRequest} />}/>
        }
        {
          showReadOnlyMedicalRecords &&
            <Route path="medical-records" element={
              <RequestOverviewMedicalRecordsOnly 
                caseRequest={caseRequest} 
                setCaseRequest={setCaseRequest} 
                allowUpload={false} 
                allowDownload={false} 
                role={PATIENT}
                allowDownloadMedicalImages={caseRequest?.permission?.allowDownload}
                isReadOnlyShare={true}
                showHiddenRecords={false} 
              />
            }/>
        }
        <Route path="/login" element={<PatientLogin
          isNurse={isNurse}
          isRefPhysician={isRefPhysician}
          providerInfo={providerInfo}
          setCaseRequest={setCaseRequest}
        />}/>
        <Route path="start" element={<SuspenseStep Component={PatientCreateRequest} setCaseRequest={setCaseRequest} providerInfo={providerInfo}/>}/>
        <Route element={<Redirect noThrow to={caseRequest ? getFirstPage(caseRequest) : '/patient/'} from="*"/>}/>
      </Routes>
    </PageContent>
  </PageWrapper>
}


const useLoadCase = willUseSuspense((jwt) => jwtGetFetcher(jwt)(`/api/viewCaseRequest?timezoneOffset=${getTimezoneOffset()}`))

const PatientLoad = ({setCaseRequest}) => {
  const navigate = useNavigate()
  const loadedCaseRequest = useLoadCase(window.JWT_URL_PARAM)
  useEffect(() => {
    setJwt(window.JWT_URL_PARAM)
    setCaseRequest(loadedCaseRequest)
    navigate(getFirstPage(loadedCaseRequest))
  },[])
  return null
}
