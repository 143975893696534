import {Button, Icon, Tooltip} from '@startlibs/components';
import {Errors, RichText, TextInput, WithForm} from '@startlibs/form'
import {getColor} from '@startlibs/utils'
import React, { useEffect, useState } from 'react'
import styled, {css} from 'styled-components';
import { darken, desaturate, lighten } from 'polished';
import _ from 'lodash/fp'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import { RichTextDisplay } from '../../components/RichTextDisplay';
import { lazyUserInfo } from '../../components/WithProvider';
import { WithLazyResource } from '../../hooks/useSuspense';
import { jwtPostFetcher } from '../../utils/authFetch';
import { getJwt } from '../../hooks/useJwt';
import { isRichText, RTF_TOOLBAR_OPTIONS, safeMultipleNewLineToBr, safeMultipleNewLineToBrOnly } from '../../utils/utils';
import { useRef } from 'react';
import { SUPER } from '../../enums/UserRole';
import { useAutoScroll } from '../../hooks/useAutoScroll';
import { FormSlateEditor } from '../../components/slateEditor/FormSlateEditor';
import { hasContent } from '../../components/slateEditor/slateSerializer';

const NoteContainer = styled.div`
  box-shadow: 0 0 0 1px ${getColor('gray210')};
  padding: 1rem;
  background: ${getColor('gray240')};
  border-radius: 6px;
  margin-bottom: 1.5rem;
  position: relative;
  transition: 0.5s ease;
  ${props => props.highlight && css`
    background: ${props => desaturate(0.5, lighten(0.525, getColor("main")(props)))};
    box-shadow: 0 0 0 1px ${props => desaturate(0.4, lighten(0.3, getColor("main")(props)))};
  `}
`

const NoteHeader = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  margin-top: -0.25rem;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  color: ${getColor('gray150')};
  font-size: 12px;
  .title {
    margin-right: 0.75rem;
    font-weight: 600;
    font-size: 14px;
    color: ${getColor('gray60')};
  }
  .externalLabel {
    margin-left: -0.25rem;
    margin-right: 0.75rem;
    display: inline-block;
  }
  ${Icon} {
    color: ${getColor('gray60')};
    font-size: 21px;
    margin-right: 0.5rem;
    vertical-align: -4px;
  }
  ${Button} ~ ${Button} {
    margin-left: 0.5rem;
  }
`
const NoteFooter = styled.div `
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: auto;
`
const LastEditTimestamp = styled.div`
  color: rgba(0,0,0,0.4);
  margin-top: 0.5rem;
`


export const NoteInfo = ({caseRequest, caseNotes, setCaseNotes, note, className, children, i, onMouseDown, onTouchStart, onBlur, formRef, noteId}) => {

  const inputRef = useAutoScroll(caseNotes[i].id == noteId)
  const rtRef = useRef()
  const [focus, setFocus] = useState(false)

  const confirmRemove = () => {

    if(note.id){
      setIsLoadingDelete(true)
      jwtPostFetcher(getJwt())(`/api/case/note?requestId=${caseRequest.requestId}&caseNoteId=${caseNotes[i].id}`, '', {method: "DELETE"})
        .then(response => {
          setCaseNotes(_.differenceBy('id', caseNotes, [{ 'id': caseNotes[i].id }]))
          setIsLoadingDelete(false)
        })
    }else{
      var pulled = _.pullAt([i], caseNotes);
      setCaseNotes(pulled)
    }
  }
  const [isReadOnly, setReadOnly] = useState(caseNotes[i]?.createdBy ? true : false)
  const [isNew, setIsNew] = useState(caseNotes[i]?.createdBy ? false : true)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const parse = _.flow(
    // _.update('note', (note) => (note && note.includes("\n")) ? safeMultipleNewLineToBr(note)  : note)
    _.update('note', (note) => {
      return note && note.includes("\n") 
      ? isRichText(note) ? safeMultipleNewLineToBrOnly(note) : safeMultipleNewLineToBr(note)
      : note 
    }))

  const confirmDelete = useConfirmDialog(
    <ConfirmDialog
      title="Delete note"
      confirm={<Button alert>Delete</Button>}
      action={confirmRemove}
    >
      <p><b>Are you sure you want to delete this note?</b></p>
      <p>This cannot be undone.</p>
    </ConfirmDialog>
  )

  const removeQuestion = () => {
    if (note.id) {
      confirmDelete()
    } else {
      confirmRemove()
    }
  }

  useEffect(() => {
    if(isNew || !isReadOnly){
      setFocus(caseNotes[i].id+''+Date.now())
    }
  },[isNew, isReadOnly])

  return <WithLazyResource value={lazyUserInfo}>{userInfo =>
    <NoteContainer
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      submitted={isReadOnly}
      canRemove
      className={className}
      highlight={caseNotes[i].id && caseNotes[i].id == noteId}
    >
      {(isReadOnly || !isNew) && <>
        <NoteHeader>
          <div ref={inputRef}>
            <Icon icon={caseNotes[i]?.external ? "external-user": "user-line"}/>
            <span className='title'>{caseNotes[i]?.createdBy}</span>
            {caseNotes[i]?.external && <span className='externalLabel'> (external)</span>}
            {caseNotes[i]?.creationDateEpochMilli && <Tooltip
              content={caseNotes[i]?.creationDateEpochMilli !== caseNotes[i]?.lastUpdatedEpochMilli && <>Last Updated on: {new Date(caseNotes[i]?.lastUpdatedEpochMilli).toLocaleString('en-us', { month: 'short', day: '2-digit', year: 'numeric' })} - {new Date(caseNotes[i]?.lastUpdatedEpochMilli).toLocaleString('en-us', { hour: '2-digit', minute:'2-digit', second: '2-digit' })}</>}>
                {new Date(caseNotes[i]?.creationDateEpochMilli).toLocaleString('en-us', { month: 'short', day: '2-digit', year: 'numeric' })} - {new Date(caseNotes[i]?.creationDateEpochMilli).toLocaleString('en-us', { hour: '2-digit', minute:'2-digit' })}
                </Tooltip>
            }
            {caseNotes[i]?.external && caseNotes[i]?.info?.email && <div>
                <span className='externalLabel'>{caseNotes[i]?.info?.email}</span>
                {caseNotes[i]?.external && caseNotes[i]?.info?.phoneNumber && 
                  <span className='externalLabel'> - {caseNotes[i]?.info?.phoneNumber}</span>
                }
            </div>}
          </div>
          <div>
            <Button small disabled={(userInfo.id !== caseNotes[i]?.creatorId && userInfo.role !== SUPER) || !isReadOnly} onClick={() => {
              setFocus(caseNotes[i].id+''+Date.now())
              setReadOnly(false)}
            }>Edit</Button>
            <Button small disabled={userInfo.id !== caseNotes[i]?.creatorId && userInfo.role !== SUPER} onClick={removeQuestion} isLoading={isLoadingDelete}>Delete</Button>
          </div>
        </NoteHeader>
        </>
      }
      {isReadOnly && <RichTextDisplay className='fs-exclude' content={caseNotes[i]?.info.note} useSafeNewLine={!isRichText(caseNotes[i]?.info.note)} />}
      {isReadOnly && caseNotes[i]?.creationDateEpochMilli !== caseNotes[i]?.lastUpdatedEpochMilli && 
          <LastEditTimestamp>Last edit on {
            new Date(caseNotes[i]?.lastUpdatedEpochMilli).toLocaleString('en-us', { month: 'short', day: '2-digit', year: 'numeric' })
          } at {
            new Date(caseNotes[i]?.lastUpdatedEpochMilli).toLocaleString('en-us', { hour: '2-digit', minute:'2-digit' })
          } by {caseNotes[i]?.updatedBy}
        </LastEditTimestamp>
      }
      {!isReadOnly &&
        <WithForm
          ref={formRef}
          values={parse(caseNotes[i]?.info)}
        >{form => <>
          {/* <RichText */}
          <div ref={inputRef}>
            <FormSlateEditor
              formatTools={RTF_TOOLBAR_OPTIONS}
              label={"Notes"}
              path="note"
              placeholder="Write your note here"
              disabled={isReadOnly}
              focus={focus}
              useHtml={true}
              requestId={caseRequest.requestId}
            />
          </div>
          <Errors css="margin-bottom: 1rem;"/>
          <NoteFooter>
            <Button onClick={() => {
              return isNew ? removeQuestion() : setReadOnly(true)
            }}>Cancel</Button>
            <Button highlight isLoading={isLoading} onClick={() => {
              if(!hasContent(form.getValues('note')) || !form.getValues('note')){
                setFocus(caseNotes[i].id+''+Date.now())
                return form.setError('note','Notes can not be empty')
              }
              form.clearErrors()
              setIsLoading(true)
              setIsNew(false)
              if(note.id){
                const editObj = {
                  id: note.id,
                  note: form.getValues('note')
                }
                jwtPostFetcher(getJwt())('/api/case/note?requestId=' + caseRequest.requestId, editObj,{method: "PUT"})
                  .then((response) => {
                    setCaseNotes(_.set('['+i+']',response,caseNotes))
                    setIsLoading(false)
                    setReadOnly(true)
                  })
              }else{
                const newObj = {
                  note: form.getValues('note'),
                  external: false
                }
                jwtPostFetcher(getJwt())('/api/case/note?requestId=' + caseRequest.requestId, newObj)
                  .then((response) => {
                    setCaseNotes(_.set('['+i+']',response,caseNotes))
                    setIsLoading(false)
                    setReadOnly(true)
                  })
              }
            }}>Save note</Button>
          </NoteFooter>
        </>}</WithForm>
      }
      {children}
    </NoteContainer>
  }</WithLazyResource>
}
