import { Button, DraggableItem, SplitColumnsContainer } from '@startlibs/components';
import React, { useEffect, useRef, useState } from 'react';
import { Card, PageContainer } from '../../components/PageLayout'
import { Header } from '../../components/Header'
import { PageLoadingSuspense } from '../../components/PageLoading'
import { PurviewFooter } from '../../components/PurviewFooter'
import { getJwt } from '../../hooks/useJwt'
import { jwtGetFetcher, jwtPostFetcher } from '../../utils/authFetch'
import { willUseSuspense } from '../../hooks/useSuspense'
import { Errors, FormValue, RadioboxGroup, SimpleRadiobox, TextInput, WithForm } from '@startlibs/form';
import { buildValidation, required } from '../../utils/validation';
import { setNotification } from '../../components/Notifications';
import { COMPLETED, INPERSONVISIT_LABELS, NOT_RECOMMENDED, RECOMMENDED, SCHEDULED } from '../../enums/InPersonVisit';
import { PriceInput } from '../../components/PriceInput';
import { _s, getColor, smoothScroll } from '@startlibs/utils';
import { DragDropHelp } from './ExpertReviewStep';
import { QuestionList } from '../../components/QuestionList';
import _ from 'lodash/fp';
import styled, { css } from 'styled-components';
import { RemoveIcon } from '../../request/forms/Answer';
import { ConfirmDialog, useConfirmDialog, useConfirmDialogWithProps } from '../../hooks/useConfirmDialog';
import { useConfirmExit } from './hooks/useConfirmExit';
import { ConfirmChangesDialog } from './PatientStep';
import { useSetConfirmExitPage } from '../../hooks/useConfirmExitPage';

const CustomFieldsWrapper = styled(Card)`
  position: relative;
  border: 0px solid ${getColor('gray210')};
  padding: 0;
  margin-top: 1rem;
`

const CustomFieldContainer = styled.div`
  position: relative;
  margin-bottom: 2rem;
  label {
    font-size: 13px;
    font-weight: 600;
  }
  ${props => props.submitted && css`
    textarea {
      color: #000000;
      }
  `}
  ${props => props.addedByExpert && css`
    position: relative;
    margin-bottom: 2rem;
    box-shadow: 0 0 0 1px ${getColor('gray210')};
    padding: 1.25rem;
    background: ${getColor('gray240')};
    border-radius: 6px;
  `}
`

// const GET_EMPTY_CUSTOM_FIELD = () => ({ title: '', value: '', id: Date.now().toString(36) + '' })
const GET_EMPTY_CUSTOM_FIELD = (formValue) => ({ 
  title: `Custom Field ${formValue.length + 1}`, 
  value: '', 
  id: `CUSTOM_FIELD_${formValue.length + 1}` 
})
const preValidation = buildValidation({
  // 'mrn': (v) => v != null && !v.length && required(),
})

const onFailure = (e) => {
  console.log(e)
  setNotification({ type: "alert", msg: <>It was not possible to save the changes. Please try again later.</> })
}

const useAuthSuspense = willUseSuspense((requestId) =>
  jwtGetFetcher(getJwt())(`/api/admin/continuingCare?requestId=${requestId}`)
)

export const ContinuingCareStep = (props) => <PageLoadingSuspense>
  <LoadedContinuingCareStep {...props} />
</PageLoadingSuspense>

export const LoadedContinuingCareStep = ({ caseRequest, setCaseRequest }) => {

  const [isLoading, setLoading] = useState(false)
  const [continueCareValues, setContinueCareValues] = useState(useAuthSuspense(caseRequest.requestId))

  const newValues = {
    mrn: caseRequest?.caseInfo?.ehrInfo?.mrn || "",
    ...continueCareValues,
    additionalPatientRevenue: continueCareValues.additionalPatientRevenue === null ? "" : continueCareValues.additionalPatientRevenue,
    inPersonVisit: continueCareValues.inPersonVisit === null ? NOT_RECOMMENDED : continueCareValues.inPersonVisit,
  }

  const formRef = useRef()
  const submitRef = useRef()
  const [isSubmiting, setIsSubmiting] = useState(false)

  useEffect(() => {
    formRef.current.setHasChanged(false)
  }, [])

  // This works on default browser close tab/window
  useConfirmExit(formRef)

  const confirmExitDialog = useConfirmDialogWithProps(({exit, save}) => <ConfirmChangesDialog
      title={'Save changes'}
      closeButton={
        <Button hover="alert" onClick={exit}>Discard</Button>}
      alternativeButton={
        <Button onClick={() => {confirmExitDialog.close();}}>Continue editing</Button>}
      confirm={
        <Button
          success onClick={() => {
          confirmExitDialog.close();
          smoothScroll(submitRef.current);
          save().then(exit)
        }}>Save</Button>}
    >
      <p css="max-width: 24rem;">
        <b>{'There are unsaved changes on this page. Would you like to save them?'}</b>
      </p>
    </ConfirmChangesDialog>
  )

  const confirmExitPage = () => () => {
    if (!formRef.current) {
      return false
    }
    
    // var saveAction = () => {
    //   setIsSubmiting(true); 
    //   return action(formRef.current.getTransformedValues())
    //     .then(()=>{
    //       setIsSubmiting(false);
    //       setCaseRequest(_.assign(_,formRef.current.getTransformedValues()))
    //     })
    // }

    var saveAction = () => {
      setIsSubmiting(true); 
      return formRef.current.willSubmitForm()
      .then(()=>{
        setIsSubmiting(false);
        // setCaseRequest(_.assign(_,formRef.current.getTransformedValues()))
      })
      .catch(()=>{
        setIsSubmiting(false);
        throw new Error('Please fill all required fields')
      })
    }
    
    
    if (formRef && formRef.current && formRef.current.hasChanged) {
      return new Promise((exit) => {
        confirmExitDialog.openWith({exit, save: saveAction})
      })
    }
  }


  const action = (values) => {

    // cast additionalPatientRevenue to double if not null
    // values.additionalPatientRevenue =  values.additionalPatientRevenue !=null ? parseFloat(values.additionalPatientRevenue) : null
    // remove id from custom fields
    // values.customFields = values.customFields.map((field) => {
    //   delete field.id
    //   return field
    // })
    const postData = {
      mrn: values.mrn,
      continuingCare: {
        inPersonVisit: values.inPersonVisit,
        additionalPatientRevenue: values.additionalPatientRevenue !=null ? parseFloat(values.additionalPatientRevenue) : null,
        customFields: values.customFields
      }
    }

    return jwtPostFetcher(getJwt())("/api/admin/continuingCare?requestId=" + caseRequest.requestId, postData, { method: "POST" })
  }
  useSetConfirmExitPage(confirmExitPage(false))
  return <>
    <PageContainer>
      <Header title="Continuing care" />
      <Card>
        <WithForm
          // alwaysSave
          values={newValues}
          preValidation={preValidation}
          action={action}
          onSuccess={(values) => {
            setNotification("All changes saved")
            // If MRN has changed, update caseRequest
            if (values.mrn != null && values.mrn != caseRequest?.caseInfo?.ehrInfo?.mrn) {
              setCaseRequest(_.update('caseInfo.ehrInfo.mrn', _.constant(values.mrn)))
            }
          }}
          ref={formRef}
          onFailure={onFailure}
        >{form =>
          <>
            <SplitColumnsContainer>
              <TextInput
                label="Medical Record Number"
                helpText="(Patient Identification Number)"
                css="min-width: 400px;"
                path="mrn"
              />
            </SplitColumnsContainer>
            <SplitColumnsContainer css="margin-top: 0.25rem;">
              <RadioboxGroup label="In-person visit" horizontal path="inPersonVisit" framedBoxes wide>
                <SimpleRadiobox fieldValue={NOT_RECOMMENDED} label={INPERSONVISIT_LABELS[NOT_RECOMMENDED]} />
                <SimpleRadiobox fieldValue={RECOMMENDED} label={INPERSONVISIT_LABELS[RECOMMENDED]} />
                <SimpleRadiobox fieldValue={SCHEDULED} label={INPERSONVISIT_LABELS[SCHEDULED]} />
                <SimpleRadiobox fieldValue={COMPLETED} label={INPERSONVISIT_LABELS[COMPLETED]} />
              </RadioboxGroup>
            </SplitColumnsContainer>
            <SplitColumnsContainer css="margin-top: 0.25rem;">
              <PriceInput
                label="Additional patient revenue"
                path="additionalPatientRevenue"
                placeholder="0.00"
              />
            </SplitColumnsContainer>
            <CustomFields
              form={form}
              requestId={caseRequest.requestId}
            />
            <Errors />
            <div css="text-align: right; margin-top: .25rem;">
              <Button ref={submitRef} type='submit' highlight isLoading={form.isLoading} onClick={() => form.clearErrors()}>Save</Button>
            </div>
          </>
          }</WithForm>
      </Card>
    </PageContainer>
    <PurviewFooter />
  </>
}


const CustomFields = ({ form, readOnly, location, requestId }) => {

  const setItemOrder = (from, to) => {
    return
    // const path = "customFields"
    // const oldOrder = form.getValue(path)
    // const newOrder = _s.moveElement(from, to, oldOrder)
    // form.setValue(path, newOrder)
  }

  return <CustomFieldsWrapper>
    {/* <FormValue path="customFields">{additionalInfos =>
      additionalInfos?.length > 1 && <DragDropHelp><span>Drag and drop items to reorder</span></DragDropHelp>
    }</FormValue> */}
    <FormValue path="customFields">{additionalInfos =>
      <QuestionList
        disabled={readOnly}
        path="customFields"
        addLabel="Add custom field"
        // getEmpty={GET_EMPTY_CUSTOM_FIELD}
        getEmpty={() => GET_EMPTY_CUSTOM_FIELD(additionalInfos)}
        limit={4}
        setItemOrder={setItemOrder}
      >{(field, i) =>
        <DraggableItem
          DragTag={CustomFieldsInputs}
          submitted={readOnly}
          i={i} key={field.id}
          additionalInfos={additionalInfos}
          form={form}
          isAdmin
          path={['customFields']}
          requestId={requestId}
          isDraggable={false}
        />
        }</QuestionList>
    }</FormValue>
  </CustomFieldsWrapper>
}

export const CustomFieldsInputs = ({ path, autoFocus, className, children, submitted, form, i, onMouseDown, onTouchStart, onBlur, additionalInfos, isAdmin = false, requestId }) => {
  // const confirmRemove = () => form.setValue(path, _.pullAt([i]))
  const confirmRemove = () => {
    const field = form.getValue([...path, i])
    const cf = form.getValue([...path])
    if(cf.length > 1){
      // check if field is last one
      if(i === cf.length - 1){
        // is the last one, remove it
        return  form.setValue(path, _.pullAt([i]))
      }else{
        // is not the last one, check for other fields
        // check if every other field has CUSTOM_FIELD_ as id
        if(cf.filter(field => field.id.includes('CUSTOM_FIELD_')).length === cf.length){

          if(field.id == 'CUSTOM_FIELD_1'){
            // change title and id of the other fields
            for (let j = 0; j < cf.length; j++) {
              if (j !== i && cf[j].id !== 'CUSTOM_FIELD_1') {
                // change title and id of the other fields here
                const updatedField = {
                  ...cf[j],
                  title: 'Custom Field ' + (j),
                  id: 'CUSTOM_FIELD_' + (j)
                };
                form.setValue([...path, j], updatedField);
              }
            }
            return form.setValue(path, _.pullAt([i]))
          }else{
            if(field.id == 'CUSTOM_FIELD_2'){
              // change title and id of the other fields
              for (let j = 0; j < cf.length; j++) {
                if (j !== i && cf[j].id !== 'CUSTOM_FIELD_2' && cf[j].id !== 'CUSTOM_FIELD_1') {
                  // change title and id of the other fields here
                  const updatedField = {
                    ...cf[j],
                    title: 'Custom Field ' + (j),
                    id: 'CUSTOM_FIELD_' + (j)
                  };
                  form.setValue([...path, j], updatedField);
                }
              }
              return  form.setValue(path, _.pullAt([i]))
            }else{
              if(field.id == 'CUSTOM_FIELD_3'){
                // change title and id of the other fields
                for (let j = 0; j < cf.length; j++) {
                  if (j !== i && cf[j].id !== 'CUSTOM_FIELD_3' && cf[j].id !== 'CUSTOM_FIELD_2' && cf[j].id !== 'CUSTOM_FIELD_1') {
                    // change title and id of the other fields here
                    const updatedField = {
                      ...cf[j],
                      title: 'Custom Field ' + (j),
                      id: 'CUSTOM_FIELD_' + (j)
                    };
                    form.setValue([...path, j], updatedField);
                  }
                }
                return  form.setValue(path, _.pullAt([i]))
              }
            }
          }
        }else{
          // not all fields have CUSTOM_FIELD_
          return  form.setValue(path, _.pullAt([i]))
        }
      }
    }
    return  form.setValue(path, _.pullAt([i]))
  }

  const [value, setValue] = useState(additionalInfos ? additionalInfos[i].content : form.getValue([...path, i, "content"]))
  const [focus, setFocus] = useState(false)
  const handleEditorFocus = (event, change) => {
    setFocus(true)
  };

  const confirmDelete = useConfirmDialog(
    <ConfirmDialog
      title="Confirm field deletion"
      confirm={<Button alert>Delete</Button>}
      action={confirmRemove}
    >
      <p><b>Are you sure you want to delete this field?</b></p>
      <p>Once confirmed this cannot be undone.</p>
    </ConfirmDialog>
  )

  const removeQuestion = () => {
    const field = form.getValue([...path, i])
    if (field.value || field.title) {
      confirmDelete()
    } else {
      confirmRemove()
    }
  }

  return <CustomFieldContainer
    // onMouseDown={onMouseDown}
    // onTouchStart={onTouchStart}
    submitted={submitted}
    addedByExpert
    className={className}
  >
    {/* <div data-drag={false}> */}
      <TextInput
        className='fs-exclude'
        path={[...path, i, "title"]}
        placeholder="Custom field"
        minHeight={30}
        disabled
      />
    {/* </div> */}
    <div data-drag={false} onBlur={onBlur}>
      <TextInput
        className='fs-exclude'
        textarea
        autoResize
        path={[...path, i, "value"]}
        placeholder=""
        css="margin-top: .75rem;"
      />
    </div>
    {!submitted && <RemoveIcon data-drag={false} icon='x' addedByExpert onClick={removeQuestion} />}
    {children}
  </CustomFieldContainer>
}
