import {Button} from '@startlibs/components'
import {TextInput} from '@startlibs/form'
import React from 'react'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import { ExpertCaseCard } from '../ExpertCaseCard';
import {getJwt} from '../../hooks/useJwt'
import {jwtPostFetcher} from '../../utils/authFetch'
import {buildValidation, required} from '../../utils/validation'
import {FormattedMessage} from 'react-intl'

const preValidation = buildValidation({
  'reason': [required]
})

export const useDeclineCase = (caseRequest,onSuccess) => useConfirmDialog(
  <ConfirmDialog
    title="Decline case"
    action={(v) => jwtPostFetcher(getJwt())("/api/reviewer/rejectCase/" + (caseRequest.requestId || caseRequest.id), v)}
    onSuccess={onSuccess}
    preValidation={preValidation}
    notify="Case declined successfully."
    confirm={<Button alert>Decline</Button>}
  >
    <p>You will lose access to the following case:</p>
    <ExpertCaseCard caseRequest={caseRequest} viewOnly />
    <TextInput
      textarea
      autoResize
      mandatory
      path="reason"
      label="Reason:"
      descText="This will be shared with your Expert View administrator."
      placeholder="Provide your reason for declining this case here."
    />
  <p><FormattedMessage description='useDeclineCase decline notification information message' defaultMessage='This will alert your Expert View administrator, but not the patient.' /></p>
  <p>Are you sure you want to proceed?</p>
  </ConfirmDialog>
)
