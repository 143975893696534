import {
  Button,
  Icon,
  Loading,
  SplitColumnsContainer
} from '@startlibs/components';
import { Field, FileInput, TextInput } from '@startlibs/form';
import {useRefState, useToggle} from '@startlibs/core'
import React, {useRef} from 'react'
import styled from 'styled-components'
import {ConfirmDialog} from '../../../hooks/useConfirmDialog'
import { SignatureContainer } from '../../../expert/ExpertSignatureManagement';
import {buildValidation, emailValidation, required} from '../../../utils/validation'
import {getJwt} from '../../../hooks/useJwt'
import {jwtPostFetcher} from '../../../utils/authFetch'
import { refreshInstitutions } from './lazyInstitutions';
import { limitLength } from '../../../utils/utils';

const LogoContainer = styled(SignatureContainer)`
  position: relative;
  ${props => props.image && `background-image: url('${props.image}');`}
  margin-bottom: 1.5rem;
`

const FormSeparator = styled.div`
  border-bottom: 1px solid rgba(0,0,0,0.2);
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
`


export const checkImage = (file) => {
  if (file.size > 4194304 / 4) {
    return ['Your file needs to be smaller than 1mb.']
  }
  if (['image/gif', 'image/png', 'image/jpeg', 'image/jpg'].indexOf(file.type.toLowerCase()) < 0) {
    return ['Invalid file format. Please select a .JPG or .PNG file.']
  }
}

const getFormDataFile = (file) => {
  const formData = new FormData()
  formData.append('file', file)
  return formData
}

const preValidation = buildValidation(({
  name: [required],
  email: [required,emailValidation],
  address:[required],
  city:[required],
  state:[required],
}))

export const EditInstitution = ({institution,closeDialog, providerInfo}) => {

  const institutionWithReportDisclaimer = 
    institution && institution.reportDisclaimer != null
      ? institution : {...institution, reportDisclaimer: providerInfo.reportDisclaimer}

  const fileToUpload = useRefState()
  const picture = useToggle()
  const formRef = useRef()
  const uploadingFile = useToggle()
  const draggingOver = useToggle() // Change this
  const uploading = uploadingFile.isOpen

  const setFile = (e) => {
    e.preventDefault()
    const files = (e.nativeEvent.dataTransfer || e.nativeEvent.target).files
    if (files.length > 0) {
      const file = files[0]
      const validation = checkImage(file)
      if (validation) {
        formRef.current.setError("image", validation)
        return
      }
      fileToUpload.set(file)
      const image = URL.createObjectURL(file)
      picture.openWith(image)
    }
  }

  const action = (values) => jwtPostFetcher(getJwt())(`/api/admin/institutionDetails`, values, {method: institution ? "PUT" : "POST"})
    .then(({id}) =>
      (fileToUpload.get()
        ? jwtPostFetcher(getJwt())(`/api/admin/${id}/institutionDetailsLogo`, getFormDataFile(fileToUpload.get()))
        : Promise.resolve(""))
        .then((signature) => ({...values, id, signature}))
    )


  return <ConfirmDialog
    title={institution ? "Edit institution details" : "Create new institution"}
    values={institutionWithReportDisclaimer}
    closeDialog={closeDialog}
    action={action}
    formRef={formRef}
    onSuccess={() => refreshInstitutions()}
    preValidation={preValidation}
    confirm={<Button highlight type="submit">Save</Button>}
  >
    <Field
      label="Institution logo"
      descText="Click below or drag and drop your image file into the box to upload it. (PNG or JPG)"
    >
      <LogoContainer
        image={picture.isOpen}
        draggingOver={draggingOver.isOpen}
        uploading={uploadingFile.isOpen}
        onDragEnter={draggingOver.open}
        onDragLeave={draggingOver.close}
        >
        {!picture.isOpen && !uploading &&
          <div>
            {draggingOver.isOpen ? "Drop your image here to upload it"
              : <>
              <div>Click or drag and drop an image file here to upload it.</div>
              <div>If no logo is uploaded, the standard logo will be used.</div>
            </>}
          </div>
        }
        {uploading && <div><Loading /> Uploading image</div>}
        <FileInput onChange={setFile} tabIndex={-1}/>
        <Icon icon="image" />
      </LogoContainer>
    </Field>

    <SplitColumnsContainer>
      <TextInput
        path="name"
        label="Institution name"
        mandatory
        placeholder="Ex: Example Hospital New York"
      />
      <TextInput
        path="email"
        label="Institution email"
        mandatory
        placeholder="Ex: contact@email.com"
      />
    </SplitColumnsContainer>
    <SplitColumnsContainer>
      <TextInput
        path="phoneNumber"
        label="Phone number"
        placeholder="Ex: +1 234-567-890"
      />
      <TextInput
        path="faxNumber"
        label="Fax number"
        placeholder="Ex: +1 234-567-890"
      />
    </SplitColumnsContainer>

    <TextInput
      path="address"
      label="Address"
      placeholder="Ex: 123 Sesame street"
      mandatory
    />


    <SplitColumnsContainer>
      <TextInput
        path="zipCode"
        label="Zip code"
        placeholder="Ex: 12321-0000"
      />
      <TextInput
        path="city"
        label="City"
        placeholder="Ex: New York"
        mandatory
      />
    </SplitColumnsContainer>

    <SplitColumnsContainer>
      <TextInput
        path="state"
        label="State"
        placeholder="Ex: NY"
        mandatory
      />
      <TextInput
        path="country"
        label="Country"
        placeholder="Ex: USA"
      />
    </SplitColumnsContainer>

    <FormSeparator/>

    <TextInput
      label="Cover note"
      path="coverNote"
      textarea
      autoResize
      transform={limitLength(800,"Cover note text length limit reached.")}
    />

    <TextInput
      label="Back cover note"
      path="lastPageNote"
      textarea
      autoResize
      transform={limitLength(800,"Back cover note text length limit reached.")}
    />

    <TextInput
      label="Report disclaimer"
      path="reportDisclaimer"
      textarea
      autoResize
      transform={limitLength(1000,"Report disclaimer text length limit reached.")}
    />

  </ConfirmDialog>
}
