import React, {useEffect, useRef} from 'react'
import _ from 'lodash/fp'
import {useRemoveWindowScroll} from './useRemoveWindowScroll'
import {callIfFunction, identityTranslateFrames, isMobile, isTouchDevice} from '@startlibs/utils';
import {useFill, useRefState, useToggle, willUseSharedCallback, willUseSharedState} from '@startlibs/core'
import {AnimatedModal, dialogModalOut} from '@startlibs/components';
import Hammer from 'hammerjs'
import userAgent from 'ua-parser-js'

let counter = 1;

const ua = userAgent()
const isCompleteSwipe = () => (ua.os.name === "Android" && parseInt(ua.os.version, 10) >= 6) ||
  (ua.os.name === "iOS" && parseInt(ua.os.version, 10) >= 9)

export const [useOpenPanelState,usePanelStateRef,setPanelState] = willUseSharedState()

export const [focusPanel, forceFocusPanel, usePanelIsMaximized, useRegisterPanelIsMaximized] = (() => {
  const [useIsMaximized, , setIsMaximized] = willUseSharedState()
  const [useRegisterFocus, callFocus] = willUseSharedCallback()
  return [
    (nextValue = true) => {
      setIsMaximized((v) => {
        const newValue = !!callIfFunction(nextValue, v)
        if (newValue && v) {
          callFocus(nextValue)
        }
        return newValue
      })
    },
    callFocus,
    useIsMaximized,
    (callback) => {
      useRegisterFocus(callback)
      return useIsMaximized()
    }
  ]
})()



export const usePanelUtils2 = (panelType = 'upload') => {
  const highlighting = useToggle()
  const refocusPanel = useToggle()

  const modalOut = useRefState(dialogModalOut)
  const modalRef = useRef()
  const panelRef = useRef()
  const scrollContainerRef = useRef()

  const [openPanel,setOpenPanel] = useOpenPanelState()


  const [maximized, setMaximized] = useRegisterPanelIsMaximized((delayed) => {
      if (isMobile()) {
        highlighting.openWith(counter++)
      } else {
        refocusPanel.openWith(delayed)
      }
  })

  const toggleMaximized = (newV) => {
    panelRef.current.style.transform = ''
    panelRef.current.style.transitionDuration = ''
    panelRef.current.style.transition = ''
    panelRef.current.style.zIndex = ''
    setMaximized(v => _.isBoolean(newV) ? newV : !v)
  }

  useEffect(() => {
    if (refocusPanel.isOpen) {
      refocusPanel.closeAfter(120)
    }
  },[refocusPanel.isOpen])

  useRemoveWindowScroll(isMobile() && maximized)

  useEffect(() => {
    if (openPanel!==panelType && maximized) {
      setMaximized(false)
    }
  },[openPanel])// eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (maximized) {
      setOpenPanel(panelType)
    }
  },[maximized])// eslint-disable-line react-hooks/exhaustive-deps

  const leaveEffect = (clonedModal) => {
    if (modalOut.get() === dialogModalOut) return;
    clonedModal.style.transition = `opacity 0.25s`
    requestAnimationFrame(() => clonedModal.style.opacity = `0`)
    return () => {
      modalOut.set(dialogModalOut)
    }
  }

  useEffect(() => {
    if (!isTouchDevice() || !isCompleteSwipe() || !panelRef.current) {
      return
    }

    // const SENSITIVITY = {distance: 0.2, velocity: 0.8, angleTolerance: 35}
    const PANEL_HEADER_HEIGHT = 56
    const SWIPE_ANIMATION_VELOCITY_MULTIPLIER = 0.2
    const sliderManager = new Hammer(panelRef.current, {inputClass: Hammer.TouchInput});

    const getPanelHeight = () => panelRef.current.offsetHeight
    const constrainDelta = (delta) => Math.min(Math.max(0,delta),getPanelHeight() - PANEL_HEADER_HEIGHT)
    const getPercentualDelta = (delta) =>  1 - (constrainDelta(delta) / (getPanelHeight() - PANEL_HEADER_HEIGHT))
    const transform = (delta, modal = true) => {
      const constrainedDelta = constrainDelta(delta)
      if (modalRef.current && modal) {
        modalRef.current.style.opacity = getPercentualDelta(delta)
      }
      panelRef.current.style.transform = `translateY(${constrainedDelta}px)`;
    }
    const removeTransform =  (immediate) => {
      const doRemove = () => {
        panelRef.current.style.transitionDuration = ''
        panelRef.current.style.zIndex = ''
        panelRef.current.removeEventListener('transitionend',doRemove)
      }
      if (immediate) {
        doRemove()
      } else {
        panelRef.current.addEventListener('transitionend',doRemove)
        setTimeout(doRemove,250)
      }
    }

    sliderManager.add(new Hammer.Pan({threshold: 0, pointers: 0, direction: Hammer.DIRECTION_VERTICAL}));
    sliderManager.on('panstart', function (e) {
      const scrollContainer = scrollContainerRef.current
      const panOnScroll = scrollContainer?.contains(e.target)
      const scrollSize = scrollContainer?.scrollHeight - scrollContainer?.offsetHeight
      const scrolled = panOnScroll && (
        (e.deltaY>0 && !!scrollSize && scrollContainer.scrollTop > 0) ||
        (e.deltaY<0 && !!scrollSize && scrollContainer.scrollTop < scrollSize)
      )

      if (!scrolled) {
        panelRef.current.style.transition = 'none'
      }

      sliderManager.on('pandown panup', function (e) {
        if (scrolled) { return; }
        // transform(e.deltaY)
        transform(maximized ? e.deltaY : (getPanelHeight() - PANEL_HEADER_HEIGHT + e.deltaY))
      })
      sliderManager.on('panend', function (e) {
        sliderManager.off("pandown panup panend")
        panelRef.current.style.transition = ''
        if (scrolled) { return }
        if ((e.velocityY < -0.3 && e.distance > 10) || (e.velocityY <= 0 && e.deltaY < -(getPanelHeight() * 0.25))) {
          transform(0)
          setMaximized(true)

          panelRef.current.style.transitionDuration = Math.min(0.4,SWIPE_ANIMATION_VELOCITY_MULTIPLIER/-e.velocityY)+'s'

          removeTransform(false)
        } else if ((e.velocityY > 0.3 && e.distance > 10) || (e.velocityY >= 0 && e.deltaY > (getPanelHeight() * 0.25))) {
          transform(Number.MAX_SAFE_INTEGER, false)
          setMaximized(false)

          panelRef.current.style.transitionDuration = Math.min(0.4,SWIPE_ANIMATION_VELOCITY_MULTIPLIER/e.velocityY)+'s'

          modalOut.set(identityTranslateFrames)
          removeTransform(false)
        } else {
          removeTransform(true)
        }
      })
    })

    return () => {
      panelRef.current.style.transform = ''
      sliderManager.destroy()
    }
  }, [!!panelRef.current,maximized])// eslint-disable-line react-hooks/exhaustive-deps


  useFill("Overlay", isMobile() && maximized && <AnimatedModal leaveEffect={leaveEffect} modalOut={modalOut.get} ref={modalRef} onClick={() => setMaximized(v => !v)}/>,[isMobile(),maximized])

  return [{refs:{panel:panelRef,scrollContainer:scrollContainerRef}}, maximized,toggleMaximized,refocusPanel.isOpen,highlighting.isOpen]
}